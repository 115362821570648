import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------

export default function TreeCuttingListMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const permissions = props.userPermissions;
  const handleClose = () => {
    setIsOpen(false);
    props.handleEdit();
  };

  const handlePermissionLetter = () => {
    setIsOpen(false);
    props.handlePermissionLetterDownload()
  }

  const handleDelete = () => {
    setIsOpen(false);
    props.handleDelete();
  };

  const handleUnlink = () => {
    // console.log("unlink");
    setIsOpen(false);
    props.handleUnlink();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} style={{ color: '#000' }} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
            <MenuItem
              component={RouterLink}
              to={`/dashboard/tree-cutting/view-mom/${props.id}`}
              sx={{ color: 'text.secondary' }}
              onClick={handleClose}
            >
              <ListItemIcon style={{ color: '#214c50' }}>
                <Iconify icon="guidance:meeting-point" width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="MOM" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem
              // component={RouterLink}
              // to={`/dashboard/tree-cutting/view-mom/${props.id}`}
              sx={{ color: 'text.secondary' }}
              onClick={handlePermissionLetter}
            >
              <ListItemIcon style={{ color: '#214c50' }}>
                <Iconify icon="solar:letter-bold" width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Permission Letter" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>

            <MenuItem
              component={RouterLink}
              to={`/dashboard/tree-cutting/compensation/${props.id}/${props.name}/${props.totalTrees}`}
              sx={{ color: 'text.secondary' }}
              onClick={handleClose}
              disabled={!(props.status==="Approved" || props.status==="Partial Approved")}
            >
              <ListItemIcon style={{ color: '#214c50' }}>
                <Iconify icon="nimbus:money" width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="Tree Compensation" primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>


      </Menu>
    </>
  );
}
