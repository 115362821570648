import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Pagination,
  Chip,
  CircularProgress,
  IconButton
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteRole, GetActiveRole, GetRole, SearchRole } from '../../actions/RoleAction';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import USERLIST from '../../_mock/user';
// import NewUserDialog from '../components/DialogBox/NewUserDialog';
import UserTableData from '../../components/JsonFiles/UserTableData.json';
import CreateRoleDialog from '../../components/DialogBox/CreateRoleDialog';
import {MasterBreadCrum, breadCrumDrop} from '../../sections/@dashboard/master/MasterBreadCrum';
import {MasterBreadCrumChip} from '../../sections/@dashboard/master/MasterBreadCrumChip';
import StatusButton from '../../components/statusbutton/StatusButton';
import { TrainingRoleBreadCrum } from '../../sections/@dashboard/training/TrainingRoleBreadCrum';
import { ShowLoader } from '../../actions/CommonAction';
import { DeleteTraining, GetTraining, SearchTraining } from '../../actions/TrainingAction';
import TrainingDialog from '../../components/DialogBox/TrainingDialog';
import TrainingMoreMenu from '../../sections/@dashboard/training/TrainingMoreMenu';

// import './style.css';
// import Menu from './Menu';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'topic', label: 'Topic', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'pdf', label: 'Pdf', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Training() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState();
  const [dialogData, setDialogData] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dropPage, setDropPage] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  const handleDropChange = (event,roleId) => {
    // console.log(event);
    setDropPage(event);
    dispatch(GetTraining(1,rowsPerPage,roleId))
    setSelectedRole(roleId)
  };
  console.log("DROP PAGE",dropPage);
  const userPermissions = [];

  const { roles, addTrainingLog, editTrainingLog, deleteTrainingLog, pageInfo, loggedUser, showLoader, trainings } = useSelector((state) => ({
    roles: state.roles.roles,
    addTrainingLog:state.trainings.addTrainingLog,
    editTrainingLog:state.trainings.editTrainingLog,
    deleteTrainingLog:state.trainings.deleteTrainingLog,
    pageInfo: state.trainings.pageInfo,
    loggedUser: state.auth.loggedUser,
    showLoader: state.common.showLoader,
    trainings: state.trainings.trainings
  }));

  // console.log("ROLES",roles);

  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  useEffect(() => {
    dispatch(GetActiveRole(1));
    dispatch(ShowLoader(true))
  }, []);

  const getTrainingRun = React.useRef(true);
  useEffect(() => {
    if (getTrainingRun.current) {
      getTrainingRun.current = false;
      return;
    }
    if (search) {
      dispatch(SearchTraining(page, rowsPerPage,selectedRole, searchValue));
    } else {
      dispatch(GetTraining(page, rowsPerPage,selectedRole));
    }
  }, [addTrainingLog, editTrainingLog, deleteTrainingLog]);

  const loaderRun = React.useRef(true);
  useEffect(() => {
    if (loaderRun.current) {
        loaderRun.current = false;
        return;
      }
    dispatch(ShowLoader(false))
    if(roles && roles?.length!==0){
      const filterRole = roles?.filter(value => value.slug === "base_color_user" || value.slug ==="qc_base_color_offsite" || value.slug==="qc_base_color_onsite" || value.slug === "census_user" || value.slug === "qc_census_offsite" || value.slug === "qc_census_onsite" || value.slug === "manager");
      dispatch(GetTraining(1,rowsPerPage,filterRole[0].id))
      setSelectedRole(filterRole[0].id)
    }
    
  }, [roles]);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
    }
  }, [pageInfo]);

  const handleNewUserClick = () => {
    setDialogData(null);
    setOpen(!open);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (search) {
      dispatch(SearchTraining(newPage, rowsPerPage,selectedRole, searchValue));
    } else {
      dispatch(GetTraining(newPage, rowsPerPage,selectedRole));
    }
  };

  const handleEdit = (data) => {
    setDialogData(data);
    setOpen(!open);
  };

  const handleDelete = (data) => {
    dispatch(DeleteTraining(data.id, data.status ? 0 : 1));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    if (search) {
      dispatch(SearchRole(1, parseInt(event.target.value, 10), searchValue));
    } else {
      dispatch(GetRole(1, parseInt(event.target.value, 10)));
    }
  };

  let timer = null;
  const filterByName = (event) => {
    const value = event.currentTarget.value;
    clearTimeout(timer);
    // console.log("---",value)
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        // console.log("---...",value)
        dispatch(SearchTraining(1, rowsPerPage,selectedRole, value));
        setSearch(true);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetTraining(1, rowsPerPage,selectedRole));
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
    // console.log("rolesss", roles)
  };
  function handleClick(event) {
    event.preventDefault();
    // console.info('You clicked a breadcrumb.');
  }

  console.log("Roles",roles);

  const filterRole = roles?.filter(value => value.slug === "base_color_user" || value.slug ==="qc_base_color_offsite" || value.slug==="qc_base_color_onsite" || value.slug === "census_user" || value.slug === "qc_census_offsite" || value.slug === "qc_census_onsite" || value.slug === "manager");

  return (
    <Page title="Training">
        {showLoader ? (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <CircularProgress style={{ color: '#214c50' }} />
    </div>
  ) :
      <Container >
        {open ? <TrainingDialog isOpen={open} handleClose={handleNewUserClick} data={dialogData} selectedRole={selectedRole} /> : null}
        <Scrollbar className='padscreen_' style={{overflow:'hidden'}}>
        {userPermissions.includes('create-role') ? (
            <Button
              onClick={handleNewUserClick}
              variant="contained"
              component={RouterLink}
              to="#"
              // startIcon={<Iconify icon="eva:plus-fill" />}
               sx={{float: 'right',mt: 1}}
               className='padscreenadd'
            >
              Add Training
            </Button>
          ) : null}
        <Stack direction="row" alignItems="center" justifyContent="space-between"  mb={2} mt={5}>
          {/* <div role="presentation" onClick={handleClick}>
            <MasterBreadCrum dropDownPage={dropPage} handleDropChange={handleDropChange} />
          </div> */}
        
        <div role="presentation" className='mob-master-' onClick={handleClick} >
        <TrainingRoleBreadCrum dropDownPage={dropPage} handleDropChange={(e,roleId) =>handleDropChange(e,roleId)} slug={dropPage || filterRole?.[0].role} data={filterRole} />
        </div>
         
        </Stack>
        </Scrollbar>
        <Card>
          <UserListToolbar numSelected={0} placeHolder={'Search Training...'} onFilterName={filterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {trainings?.map((option, index) => {
                    return (
                      <TableRow hover>
                        <TableCell align="left"><b>{(page - 1) * rowsPerPage + (index + 1)}</b></TableCell>
                        <TableCell align="left">{option.topic}</TableCell>
                        <TableCell align="left">
                          <div style={{height: option.description.length >= 250 ? '95px': null,margin:'10px',overflow:'auto'}}>
                            {option.description.length}
                          {option.description}
                          </div>
                          </TableCell>
                        <TableCell align="left">
                          {/* <IconButton
                                aria-label="delete"
                                size="large"
                                // onClick={() => handleViewOpen(option.images)}
                                onClick={(e) => {
    
                                  window.open(option.pdf_url, '_blank');
                    
                                }}
                                sx={{color: '#214c50'}}
                                align="left"
                              > */}
                                <Visibility 
                                size="large"
                                sx={{color: '#214c50',cursor:'pointer'}}
                                
                                onClick={(e) => {
    
                                  window.open(option.pdf_url, '_blank');
                    
                                }}

                                />
                              {/* </IconButton> */}
                        </TableCell>
                        <TableCell align="right">
                          <TrainingMoreMenu
                            status={option.status}
                            permissions={userPermissions}
                            handleEdit={() => handleEdit(option)}
                            handleDelete={() => handleDelete(option)}
                            disable={option.is_protected}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {roles ? (
            <Pagination
              count={pageInfo.last_page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
         
        </Card>
      </Container>
    }
    </Page>
  );
}
