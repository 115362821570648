import { GET_USER_LOCATION, RESET_STATE , GET_ROOT_LOCATION} from "../actions/Types";

const INIT_STATE = {
    userLocateData: {},
    rootTravelData:[]
};

export default function ZoneReducer(state = INIT_STATE, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_USER_LOCATION:
            return {
                ...state,
                userLocateData: payload.data,
            };
            case GET_ROOT_LOCATION:
                return {
                    ...state,
                    rootTravelData: payload.data,
                };
        case RESET_STATE:
            return { ...INIT_STATE };

        default:
            return state;
    }
};
