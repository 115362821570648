import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  GET_NEWS_PAPERS,
  GET_NEWS_PAPER_BY_ID,
  ADD_NEWS_PAPER,
  UPDATE_NEWS_PAPER,
  DELETE_NEWS_PAPER,
  SEARCH_NEWS_PAPER,
} from './Types';

const GetNewsPapers = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/newspaper?page=${page}&limit=${limit}`);
    dispatch({
      type: GET_NEWS_PAPERS,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetGetNewsPaperById = (id) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/newspaper/${id}`);

    dispatch({
      type: GET_NEWS_PAPER_BY_ID,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchNewsPaper = (page, limit, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/newspaper?page=${page}&limit=${limit}&search=${searchValue}`);
    dispatch({
      type: GET_NEWS_PAPERS,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const AddNewsPaper = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`api/newspaper `, params);
    dispatch({
      type: ADD_NEWS_PAPER,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const UpdateNewsPaper = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`api/newspaper/${id}`, params);
    dispatch({
      type: UPDATE_NEWS_PAPER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const DeleteNewsPaper = (id) => async (dispatch) => {
  try {
    const response = await JWTServer.delete(`api/newspaper/${id}`);
    console.log('fdggg', id);
    dispatch({
      type: DELETE_NEWS_PAPER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export { GetNewsPapers, GetGetNewsPaperById, SearchNewsPaper, AddNewsPaper, UpdateNewsPaper, DeleteNewsPaper };
