import JWTServer from "../api/withJWTServer";
import formDataJWTServer from "../api/formDataJWTServer";
import { HandleExceptionWithSecureCatch } from "./CombineCatch";
import { GET_PROPERTY_BY_COUNCIL_AND_WARD, GET_PROPERTY_BY_COUNCIL_ID, GET_PROPERTY_BY_COUNCIL_ZONE_WARD, IMPORT_PROPERTIES, SHOW_PROPERTY_IMPORT_ERROR } from "./Types";
import { SetNewAlert } from "./AlertActions";
import { ShowLoader } from "./CommonAction";

const GetPropertyByCouncilZoneWard = (council,zone,ward) => async (dispatch) => {
  try {
    let url = `/api/properties/council-ward`
    if(council){
      url = `${url}?where[council_id]=${council}`;
    }
    if(zone){
      url = `${url}&where[zone_id]=${zone}`;
    }
    if(ward){
      url = `${url}&where[ward_id]=${ward}`
    }
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_PROPERTY_BY_COUNCIL_ZONE_WARD,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetPropertyByCouncilId = (councilId,page,limit) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/properties?where[council_id]=${councilId}&page=${page}&limit=${limit}`);
      dispatch({
        type: GET_PROPERTY_BY_COUNCIL_ID,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const GetPropertyByCouncilAndWard = (councilId,wardId) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/properties/council-ward?where[council_id]=${councilId}&where[ward_id]=${wardId}`);
      dispatch({
        type: GET_PROPERTY_BY_COUNCIL_AND_WARD,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const SearchPropertyByCouncilId = (councilId,page,limit,searchValue) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/properties?where[council_id]=${councilId}&page=${page}&limit=${limit}&search=${searchValue}`);
      dispatch({
        type: GET_PROPERTY_BY_COUNCIL_ID,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const ImportProperty = (params) => async (dispatch) => {
    try {
      const response = await formDataJWTServer.post("/api/properties/import-properties", params);
      dispatch({
        type: IMPORT_PROPERTIES,
        payload: response.data,
      });
      dispatch(SetNewAlert({
        msg: response.data.message,
        alertType: "success",
      }));
    } catch (e) {
      // console.log("ERROR RESPONSE",e.response);
      if(e.response.status===422){
        if(Array.isArray(e.response.data)){
          dispatch({
            type: SHOW_PROPERTY_IMPORT_ERROR,
            payload: e.response,
          });
        }
        else {
          dispatch(SetNewAlert({
            msg: e.response.data.message,
            alertType: "danger",
          }));
          dispatch(ShowLoader(false))
        }
        
      }
      else {
        dispatch(HandleExceptionWithSecureCatch(e));
      }
      
    }
  };

  
  export {
      GetPropertyByCouncilId,
      GetPropertyByCouncilZoneWard,
      SearchPropertyByCouncilId,
      ImportProperty,
      GetPropertyByCouncilAndWard
  }