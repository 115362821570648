import { GET_STATUS,GET_TREE_CENSUS, UPDATE_QC_STATUS_TREE_CENSUS, GET_TREE_CENSUS_HISTORY, GET_TREE_CENSUS_PENDING_QC_STATUS, UPDATE_CENSUS_TREE, REFER_TO_EXPERT, RESET_STATE, DELETE_TREE_CENSUS, EXPORT_CALL_LOG, IMPORT_CENSUS_TREES, EXCEPTION_IMPORT_CENSUS_TREES } from "../actions/Types";

const INIT_STATE = {
  treeCensus: null,
  addTreeCensusLog: false,
  editTreeCensusLog: false,
  deleteTreeCensusLog: false,
  updateQCStatusLog: false,
  pageInfo: {},
  // baseColorRemarks:[],
  treeCensusHistory: [],
  treeCensusPendingQCStatus: null,
  updateCensusTreeLog: false,
  referToExpertLog: false,
  exportCallLogs: [],
  ImportStatus:[],
  isReportDownloadable: false,
  importCensusTrees : {},
  importCensusTreesLog : false,
  exceptionCensusTreesLog : false,
  importStatus:null
};

export default function TreeCensusReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TREE_CENSUS:
      return {
        ...state,
        treeCensus: payload.data,
        pageInfo: payload.data
      };

    case GET_TREE_CENSUS_HISTORY:
      return {
        ...state,
        treeCensusHistory: payload.data.data,
        pageInfo: payload.data
      };

    case GET_TREE_CENSUS_PENDING_QC_STATUS:
      return {
        ...state,
        treeCensusPendingQCStatus: payload.data,
        pageInfo: payload.data
      }

    case UPDATE_QC_STATUS_TREE_CENSUS:
      return {
        ...state,
        updateQCStatusLog: !state.updateQCStatusLog,
      };

    case UPDATE_CENSUS_TREE:
      return {
        ...state,
        updateCensusTreeLog: !state.updateCensusTreeLog,
      };

    case REFER_TO_EXPERT:
      return {
        ...state,
        referToExpertLog: !state.referToExpertLog,
      };

    case DELETE_TREE_CENSUS:
      return {
        ...state,
        deleteTreeCensusLog: !state.deleteTreeCensusLog
      };

    case EXPORT_CALL_LOG:

      return {
        ...state,
        exportCallLogs: payload.data,
        isReportDownloadable: !state.isReportDownloadable
        //   pageInfo: payload.data
      };

      case IMPORT_CENSUS_TREES:
        return {
          ...state,
          importCensusTreesLog : !state.importCensusTreesLog
        };
      
        case EXCEPTION_IMPORT_CENSUS_TREES:
          return {
            ...state,
            importCensusTrees: payload,
            exceptionCensusTreesLog : !state.exceptionCensusTreesLog
          };
          case GET_STATUS:
            return {
              ...state,
              importStatus:payload,
              pageInfo: payload.data

              // importCensusTrees: payload,
              // exceptionCensusTreesLog : !state.exceptionCensusTreesLog
            };


    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
