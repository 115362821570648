import {
  ADD_TREE_CUTTING_REASON,
  DELETE_TREE_CUTTING_REASON,
  UPDATE_CUTTING_REASON,
  GET_TREE_CUTTING_REASON,
  RESET_STATE,
  GET_TREE_CUTTING_REASON_LIST,
  TREE_CUTTING_REASON_STATUS_CHANGE,
  GET_TREE_NAME
} from '../actions/Types';

const INIT_STATE = {
  treeCuttingReason: null,
  treeName: null,
  activeTreeCondition: null,
  addTreeCuttingReasonLog: false,
  editTreeCuttingReasonLog: false,
  deleteTreeCuttingReason: false,
  treeCuttingReasonStatusChange: false,
  pageInfo: {},
};

export default function TreeCuttingReasonReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TREE_CUTTING_REASON:
      console.log('GET_TREE_CUTTING_REASON ', JSON.stringify(payload.data.data));
    
      return {
        ...state,
        treeCuttingReason: payload.data.data,
        pageInfo: payload.data,
      };

    case GET_TREE_CUTTING_REASON_LIST:
      return {
        ...state,
        treeCuttingReason: payload.data,
      };
      case GET_TREE_NAME:
        return {
          ...state,
          treeName: payload.data,
          
        }
    case ADD_TREE_CUTTING_REASON:
      return {
        ...state,
        addTreeCuttingReasonLog: !state.addTreeCuttingReasonLog,
      };

    case UPDATE_CUTTING_REASON:
      return {
        ...state,
        editTreeCuttingReasonLog: !state.editTreeCuttingReasonLog,
      };

    case DELETE_TREE_CUTTING_REASON:
      return {
        ...state,
        deleteTreeCuttingReason: !state.deleteTreeCuttingReason,
      };

      case TREE_CUTTING_REASON_STATUS_CHANGE:
        return {
          ...state,
          treeCuttingReasonStatusChange: !state.treeCuttingReasonStatusChange,
        };
  

      

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
}
