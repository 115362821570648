import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch } from "./CombineCatch";
import { ShowLoader } from './CommonAction';
import { GET_ALL_SELFIE, GET_PENDING_SELFIE, UPDATE_PENDING_SELFIE_STATUS } from "./Types";


const GetAllSelfie = (page,limit,council,zone,ward,addedByForm,fromDateForm, toDateForm) => async (dispatch) => {
  // console.log("page....", page,limit,council,zone,ward,addedByForm,fromDateForm, toDateForm);
  console.log("FROM DATE FORM",fromDateForm)
  let url = `/api/user-selfie-verifications?page=${page}&limit=${limit}`
    if(council){
      url = `${url}&council_id=${council}`;
    }
    if(zone){
      url = `${url}&zone_id=${zone}`;
    }
    if(ward){
      url = `${url}&ward_id=${ward}`
    }
    if(addedByForm){
      url = `${url}&user_id=${addedByForm}`
    }
    if(fromDateForm && toDateForm){
      url = `${url}&from_date=${fromDateForm}&to_date=${toDateForm}`
    }

    try {
      const response = await JWTServer.get(`${url}`);
      // console.log("res", response);
      dispatch({
        type: GET_ALL_SELFIE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
      dispatch({
        type:GET_ALL_SELFIE,
        payload: {data:{data:[], last_page:0}},
      });
    }
  };


  const SearchSelfie = (page,limit,council,zone,ward,searchValue) => async (dispatch) => {

    let url = `/api/base-color-trees?page=${page}&limit=${limit}`
    if(council){
      url = `${url}&where[council_id]=${council}`;
    }
    if(zone){
      url = `${url}&where[zone_id]=${zone}`;
    }
    if(ward){
      url = `${url}&where[ward_id]=${ward}`
    }

    url=`${url}&search=${searchValue}`

    try {
      const response = await JWTServer.get(`${url}`);
      dispatch({
        type: GET_ALL_SELFIE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const UpdatePendingSelfieStatus = (id,params) => async (dispatch) => {
    try {
      const response = await JWTServer.put(`/api/user-selfie-verifications/status/${id}`,params);
      dispatch({
        type: UPDATE_PENDING_SELFIE_STATUS,
        payload: response.data,
      });
      dispatch(SetNewAlert({
        msg: response.data.message,
        alertType: "success",
      }));
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const GetPendingSelfie = (councilId, zoneId, wardId, fromDate, toDate,addedBy) => async (dispatch) => {
    
    let url = `/api/user-selfie-verifications/status/pending`;
    if(councilId){
      url = `${url}?council_id=${councilId}`;
    }
    if(zoneId){
      url = `${url}&zone_id=${zoneId}`;
    }
    if(wardId){
      url = `${url}&ward_id=${wardId}`
    }
    if(addedBy){
      url = `${url}&user_id=${addedBy}`
    }
    if(fromDate && toDate){
      url = `${url}&from_date=${fromDate}&to_date=${toDate}`
    }
    try {
      const response = await JWTServer.get(`${url}`);
      console.log("PENDING SELFIE response",response.data);
      dispatch({
        type: GET_PENDING_SELFIE,
        payload: response.data,
      });
      dispatch(ShowLoader(false));
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  export {
      GetAllSelfie,
      GetPendingSelfie,
      UpdatePendingSelfieStatus
  }