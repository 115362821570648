import { GET_DENIED_ENTRY, RESET_STATE, EXPORT_CALL_LOG } from "../actions/Types";

const INIT_STATE = {
  pageInfo: {},
  deniedEntry: [],
  exportCallLogs: [],
  isReportDownloadable: false,
};

export default function DeniedEntryReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DENIED_ENTRY:
      return {
        ...state,
        deniedEntry: payload.data.data,
        pageInfo: payload.data
      };
    case EXPORT_CALL_LOG:
      return {
        ...state,
        exportCallLogs: payload.data,
        isReportDownloadable: !state.isReportDownloadable
        //   pageInfo: payload.data
      };
    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
