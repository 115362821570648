/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import { Stack, Avatar, Checkbox, Card, CircularProgress, InputAdornment, Chip, CardMedia, CardContent,Modal,Container } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik } from 'formik';
import moment from 'moment';
import { makeStyles, styled } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';




import UploadPng from '../../Assets/upload.png';
import { UpdateTreeImage, UploadFile, UploadImage, UploadTreeImage } from '../../actions/UploadActions';

import { SetNewAlert } from '../../actions/AlertActions';
import { CreateTreeCuttingApplication, GetCensusTreeByWardOrPropertyNumber, GetTreeCuttingFineDetails, GetTreeNeedToBePlantList, PostTreeCuttingFineDetails, PostTreeNeedToBePlantList, UpdateTreeNeedToBePlantList } from '../../actions/TreeCuttingPermissionAction';
import { GetActiveTreeName } from '../../actions/TreeNameAction';
import { GetCouncilById } from '../../actions/CouncilAction';
import UpdateCompensationModal from '../../components/DialogBox/TreeCutting/UpdateCompensationModal';
import ImageCarousel from '../../components/ImageCarousel';


export default function TreeCompensation(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fineError, setFineError] = useState("");
  const [showTreeList, setShowTreeList] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [isNewCompensation, setIsNewCompensation] = useState(false);
  const [treeArray, setTreeArray] = useState([]);
  const [notTreeFound,setNotTreeFound] = useState(false);
  const [notUpdateTreeList,setNotUpdateTreeList] = useState([]);
  const [updateTreeList,setUpdateTreeList] = useState([]);
  const [imageModal, setImageModal] = useState(false)
  const [imageList, setImageList] = useState([])

  console.log("TREE ARRAY",treeArray);
  const {
    uploadImage,
    uploadImageLog,
    postTreeCuttingFineDetailsLog,
    treeCuttingFineDetails,
    activeTreeName,
    treeNeedToBePlant,
    treeNeedToBePlantLog,
    council,
    councilById,
    updateImage,
    updateImageLog,
    treeCuttingFineDetailsLog
  } = useSelector((state) => ({
    uploadImage: state.upload.uploadImage,
    uploadImageLog: state.upload.uploadImageLog,
    postTreeCuttingFineDetailsLog: state.treeCutting.postTreeCuttingFineDetailsLog,
    treeCuttingFineDetails: state.treeCutting.treeCuttingFineDetails,
    activeTreeName: state.treeName.activeTreeName,
    treeNeedToBePlant: state.treeCutting.treeNeedToBePlant,
    treeNeedToBePlantLog: state.treeCutting.treeNeedToBePlantLog,
    council: state.council.activeCouncil,
    councilById: state.council.councilById,
    updateImage: state.upload.updateImage,
    updateImageLog: state.upload.updateImageLog,
    treeCuttingFineDetailsLog: state.treeCutting.treeCuttingFineDetailsLog
  }));

  const { applicationId,applicationNumber,grantedTrees } = useParams();

  console.log("COUNCILBY ID",councilById);

  const finePerTree = councilById?.cutting_trees_fees_per_tree?councilById?.cutting_trees_fees_per_tree:"10"

  useEffect(()=>{
    dispatch(GetActiveTreeName(1))
    dispatch(GetTreeNeedToBePlantList(applicationId))
    dispatch(GetCouncilById(council[0].id))
  },[])

  const treeFetchRun = React.useRef(true);
  useEffect(() => {
    if (treeFetchRun.current) {
      treeFetchRun.current = false;
      return;
    }
    
    if(treeNeedToBePlant.length===0){
      dispatch(GetTreeCuttingFineDetails(applicationId))
    }
    else {
      setIsNewCompensation(false)
      setShowTreeList(true)
      setShowContent(true);
      setTreeArray(treeNeedToBePlant)
      // dispatch(GetTreeNeedToBePlantList(applicationId))
    }
  }, [treeNeedToBePlant]);

  const treeFeesRun = React.useRef(true);
  useEffect(() => {
    if (treeFeesRun.current) {
      treeFeesRun.current = false;
      return;
    }
    
    if(treeCuttingFineDetails?.compensation_trees_amount !== null){
      console.log("ISNDIE TREE CUTTINF FINE DETAILS",treeCuttingFineDetails)
      setIsNewCompensation(false)
      setNotTreeFound(true)
      setShowContent(true);
    }
    else {
      console.log("INSDIE ELSE")
      setIsNewCompensation(true)
      setShowContent(true);
    }
  }, [treeCuttingFineDetailsLog]);

  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }

    dispatch(GetTreeNeedToBePlantList(applicationId))
    setIsNewCompensation(false)
    setShowTreeList(true)
    setShowContent(true);
    
  }, [treeNeedToBePlantLog]);

  const getFineRun = React.useRef(true);
  useEffect(() => {
    if (getFineRun.current) {
      getFineRun.current = false;
      return;
    }
    setShowTreeList(true)
  }, [postTreeCuttingFineDetailsLog]);

  const thirdRun = React.useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    if (uploadImage) {
      console.log("UPLOAD IMAGE",uploadImage);
      const newTreeArray = [...treeArray];
      const value = newTreeArray[uploadImage.index];
      if(uploadImage.url.includes(process.env.REACT_APP_BASE_URL)){
        value.image_url = uploadImage.url;
      }
      else {
        // value.url = uploadImage.url;
        value.image_url =process.env.REACT_APP_BASE_URL.concat('/').concat(uploadImage.url);
      }

      value.is_loading = false;
      console.log("VALUE",value);
      
      newTreeArray[uploadImage.index] = value;
      setTreeArray(newTreeArray);
    }
  }, [uploadImageLog]);

  const updateRun = React.useRef(true);
  useEffect(() => {
    if (updateRun.current) {
      updateRun.current = false;
      return;
    }
    if (updateImage) {
      console.log("UPDATE IMAGE",updateImage);
      const newTreeArray = [...treeArray];
      const value = newTreeArray[updateImage.index];
      if(updateImage.url.includes(process.env.REACT_APP_BASE_URL)){
        value.image_url = updateImage.url;
      }
      else {
        // value.url = uploadImage.url;
        value.image_url =process.env.REACT_APP_BASE_URL.concat('/').concat(updateImage.url);
      }

      value.is_updated = true;
      value.is_loading = false;

      console.log("Value",value);
      
      newTreeArray[updateImage.index] = value;
      setTreeArray(newTreeArray);
    }
  }, [updateImageLog]);

  const handleFineChange = (e) => {
    const fineValue = e.target.value;
    const maxFine = Number(finePerTree) * Number(grantedTrees)

    if(Number(fineValue) < Number(finePerTree)){
        setFineError(`Total Fees can not be less than ${maxFine}`)
    }
    
    else if(Number(fineValue) > Number(maxFine)){
        setFineError(`Total Fees can not be more that ${maxFine}`)
    }
    else if(Number(fineValue) % Number(finePerTree) ===0 ){
        setFieldValue('totalTreeNeedTobePlant',grantedTrees - (fineValue/finePerTree))
        setFineError("")
    }
    else {
        setFineError("Total Fees Must be multiple of Fees per tree")
    }

  }



  const DistrictsSchema = Yup.object().shape(
        {
        totalFees: Yup.number().typeError('Total Fine Must be Number').required('Total Fine is required'),
        totalTreeNeedTobePlant: Yup.number().typeError('Must be Number').required('Total Tree is required'),
        
      }
  );
  // console.log("-------",userById)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues : {
      totalFees : "0",
      totalTreeNeedTobePlant : Number(grantedTrees)
    },
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {
      const reqObj = {
        "cutting_tree_application_id": applicationId,
        "compensation_trees_amount": value.totalFees,
        // "total_trees_permitted": grantedTrees,
        "total_trees_to_be_planted": value.totalTreeNeedTobePlant
      }
      dispatch(PostTreeCuttingFineDetails(reqObj))
  
      if(Number(value.totalTreeNeedTobePlant===0)){
        setNotTreeFound(true)
        setIsNewCompensation(false);
      }
      else {
        const newTreeArray = [];
        for(let i=0;i<Number(value.totalTreeNeedTobePlant);i+=1){
          const reqObj = {
            "tree_name": "",
          "plantation_date": "",
          "image_url": "",
          "status": "Available",
          "remark": "",
          "next_inspection_date": "",
          "lat":"",
          "lng":""
          }
          newTreeArray.push(reqObj)
        }
  
        setTreeArray(newTreeArray);
      }
      
      
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm,setFieldValue,setFieldError } = formik;


  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
    chipSelected: {
      backgroundColor: '#214c50',
      color: '#fff',
    },
    chip: {
      backgroundColor: '#fff',
      color: '#000',
    },
  });


const ButtonStyled = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: '0',
  top: 0,
  bottom: 0,
  margin: 'auto',
  width: 'fit-content',
  height: '40px',
  display: 'none',
}));

const ContainerStyled = styled('div')(({ theme }) => ({
  position: 'absolute',
  left:0,
  right:0,
  '& .test-button': {
    display: 'none'
  },
  '&:hover' : {
    '& .test-button' : {
      display: 'block',
    }
  }
}));

const handleTreeName = (event, newValue,index) => {
  const getID = JSON.stringify(newValue, null, ' ')
  const getLocatedUserId = JSON.parse(getID)
  const newTreeArray = [...treeArray];
  const value = newTreeArray[index];
  value.tree_name = getLocatedUserId.label;
  value.treeId = getLocatedUserId.value;
  newTreeArray[index] = value;
  setTreeArray(newTreeArray);
}

const handleTreeLat = (e,index) => {
  const newTreeArray = [...treeArray];
  const value = newTreeArray[index];
  value.lat = e.target.value;
  newTreeArray[index] = value;
  setTreeArray(newTreeArray);
}

const handleTreeLong = (e,index) => {
  const newTreeArray = [...treeArray];
  const value = newTreeArray[index];
  value.lng = e.target.value;
  newTreeArray[index] = value;
  setTreeArray(newTreeArray);
}

const handlePlantationDate = (e,index) => {
  const newTreeArray = [...treeArray];
  const value = newTreeArray[index];
  value.plantation_date = e.target.value;
  newTreeArray[index] = value;
  setTreeArray(newTreeArray);
}

const handleImage = (e,index) => {
  const formData = new FormData();
  formData.append('upload_for', 'councils');
  formData.append('image', e.target.files[0]);
  const newTreeArray = [...treeArray];
  const value = newTreeArray[index];
  value.is_loading = true;
  newTreeArray[index] = value;
  setTreeArray(newTreeArray);
  dispatch(UploadTreeImage(formData, index)).then((response) => {
    // console.log("upload file",response);
  });
}

const handleImageUpdate = (e,index) => {
  console.log("HANDLE IMAGE UPDATE")
  const formData = new FormData();
  formData.append('upload_for', 'councils');
  formData.append('image', e.target.files[0]);

  const newTreeArray = [...treeArray];
  const value = newTreeArray[index];
  value.is_loading = true;
  newTreeArray[index] = value;
  setTreeArray(newTreeArray);

  dispatch(UpdateTreeImage(formData, index)).then((response) => {
    // console.log("upload file",response);
  });
}

console.log("TREE ARRAY",treeArray);

const handleTreeAdd = () => {
  let isValid = true
  const treeArrayTopost = []
  treeArray?.map((value,index)=>{
    if(value.tree_name==="" || value.lat==="" || value.lng==="" || value.plantation_date==="" || value.image_url===""){
      isValid= false;
      
    }
    const reqObj = {
      "cutting_tree_application_id": applicationId,
      "tree_name_id": value?.treeId,
      "plantation_date": value?.plantation_date,
      "image_url": value?.image_url,
      "lat": value?.lat,
      "lng": value?.lng,
      "status": "Available"
    }
    treeArrayTopost.push(reqObj)
    return null;
  })
  if(isValid){
    dispatch(PostTreeNeedToBePlantList(treeArrayTopost))
  }
  else {
    dispatch(SetNewAlert({
      msg: "please fill all the details of tree",
      alertType: "danger",
    }));
  }
}

const handleCloseModal = () => {
  setShowModal(false);
}

const handleTreeUpdate = () => {
  let isValid = true
  const treeArrayTopost = []
  const newNotUPdateTreeList = [];
  treeArray?.map((value,index)=>{
    if(value.status==="Available"){
      if(value.is_updated){
        const obj = {
          "id": value.id,
          "image_url": value.image_url,
          "status": "Available"
        }
        treeArrayTopost.push(obj);
      }
      else {
        newNotUPdateTreeList.push(value);
        isValid= false;
      }
    }


    return null;
  })
  if(isValid){
    dispatch(UpdateTreeNeedToBePlantList(treeArrayTopost))
    setUpdateTreeList([])
  }
  else {
    setShowModal(true);
    setUpdateTreeList(treeArrayTopost)
    setNotUpdateTreeList(newNotUPdateTreeList)
  }
}

const handleImageModalClose = () => {
  setImageModal(false);
}

const handleImageModalOpen = (url) => {
  setImageModal(true);
  const imageList = [];
  const imageUrl = { original: url };
  imageList.push(imageUrl);
  setImageList(imageList);
}

const TreeList = activeTreeName?.map((item) => ({ value: item.id, label: `${item.name} - ${item.botanical_name}` }));


  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  console.log("VALUES",values);
  return (
    showContent?
    
    (!isNewCompensation || showTreeList)?
    isNewCompensation?
        <div>
          <Modal
              open={imageModal}
              onClose={handleImageModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Container style={{width: '526px'}}>
                <ImageCarousel imagelist={imageList} />
              </Container>
              {/* <Box sx={style}>
                        <img src={val.original} alt="gallery" height="100%" width="100%" />
                      </Box> */}
            </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          Tree Need To be Plant Details
          {/* <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography> */}
        </Typography>
        
      </Stack>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {treeArray?.map((value,index)=>{
            return <Grid item xs={12} md={4} sm={4} mb={2}>
            <Card style={{position:'relative',boxShadow:'0px 0px 10px 1px grey'}}>
            <ContainerStyled>
                <CardMedia
                component="img"
                height="150"
                image={value.image_url ? value.image_url : UploadPng}
                alt="work portfolio"
                style={{objectFit:'contain',backgroundSize:'cover'}}
                onClick={(e)=>handleImageModalOpen(value.image_url)}
                sx={{cursor:'pointer'}}

                />
                {!value.image_url?
                <ButtonStyled  className="test-button" aria-label="upload picture" component="label">
                  
                <input hidden accept="image/*" type="file" onChange={(e)=>handleImage(e,index)} />
                <PhotoCamera />
                </ButtonStyled> :null} 
                </ContainerStyled>
                <CardContent style={{marginTop:150}}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  // value={null}
                  value={value?.tree_name}
                  options={TreeList}
                  onChange={(e, newValue) => {
                    handleTreeName(e, newValue,index)
                  }}
                  sx={{ marginTop: '7px' }}
                  renderInput={(params) => <TextField {...params} label="Tree Name" />}
                />
                <TextField
                    fullWidth
                    size='small'
                    id="lat"
                    name="lat"
                    autoComplete="lat"
                    label="Lat*"
                    value={value?.lat}
                    style={{ marginTop: '7px' }}
                    onChange={(e)=>{
                      handleTreeLat(e,index)
                    }}
                    />
                      <TextField
                    fullWidth
                    size='small'
                    id="lng"
                    name="lng"
                    autoComplete="lng"
                    label="Lng*"
                    value={value?.lng}
                    style={{ marginTop: '7px' }}
                    onChange={(e)=>{
                      handleTreeLong(e,index)
                    }}
                    />
                    <TextField
                    type='date'
                    fullWidth
                    size='small'
                    id="planatationDate"
                    name="planatationDate"
                    autoComplete="planatationDate"
                    label="Plantation date*"
                    value={value?.plantation_date}
                    style={{ marginTop: '7px' }}
                    onChange={(e)=>{
                      handlePlantationDate(e,index)
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    />
                </CardContent>
            </Card>
        </Grid>
          })}
            
        </Grid>
        <Button
          variant="contained"
          style={{ display: 'flex', fontSize: 15, marginTop: 20, alignSelf: 'end', marginLeft: ' 90%' }}
          onClick={(e) => {
            // validateDropDown();
            handleTreeAdd(e);
          }}
        >
          {'Save'}
        </Button>
    </Card>
    </div>
    :
    notTreeFound?
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {applicationNumber}  Compensation Trees
        </Typography>
        
      </Stack>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
      {/* <Typography variant="h6" style={{ fontWeight: '400' }}>
        No need to plant any trees
      </Typography> */}
      <Typography variant="h5" style={{ fontWeight: '400' }}>
        Total Fees Paid : {treeCuttingFineDetails?.compensation_trees_amount}
      </Typography>
      <Typography variant="h5" style={{ fontWeight: '400' }}>
        Total trees cutting permission granted : {treeCuttingFineDetails?.total_trees_permitted}
      </Typography>
      <Typography variant="h5" style={{ fontWeight: '400' }}>
        Total trees needs to be plant : {treeCuttingFineDetails?.total_trees_to_be_planted}
      </Typography>
      </Card>
    </div>:
    <div>
      <Modal
              open={imageModal}
              onClose={handleImageModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Container style={{width: '526px'}}>
                <ImageCarousel imagelist={imageList} />
              </Container>
              {/* <Box sx={style}>
                        <img src={val.original} alt="gallery" height="100%" width="100%" />
                      </Box> */}
            </Modal>
      {showModal?
      <UpdateCompensationModal isOpen={showModal} handleClose={handleCloseModal} notUpdateTreeList={notUpdateTreeList} updateTreeList={updateTreeList}  />:null}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {applicationNumber}  Compensation Trees
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Last Updated At : {treeNeedToBePlant?.[0]?.updated_at ? new Date(treeNeedToBePlant?.[0]?.updated_at).toISOString().substring(0, 10):''}
          </Typography>
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Next Inspection date : {treeNeedToBePlant?.[0]?.next_inspection_date}
          </Typography>
        </Typography>
        
      </Stack>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          {treeArray?.map((value,index)=>{
            return <Grid item xs={12} md={4} sm={4} mb={2}>
            <Card style={{position:'relative',boxShadow:'0px 0px 10px 1px grey',opacity: value.status==="Unavailable"?'0.6':'1.0',cursor: value.status==="Unavailable"?'not-allowed':'default'}}>
              {value.status==='Unvailable'?
            <Button
          variant="contained"
          style={{ display: 'flex',position:'absolute',backgroundColor:'red', right: 0, top: 0,zIndex:'999'}}
        >
          {'Unavailable'}
        </Button>:null}
            <ContainerStyled>
              {value.is_loading===true?
              <div style={{
                height:'150',
                width: '100%',
                justifyContent: 'center',
                margin: 'auto',
                
                }}>
                <CircularProgress style={{ color: '#214c50', paddding: '10px' }} />
              </div>:
              
                <CardMedia
                component="img"
                height="150"
                image={value.image_url ? value.image_url : UploadPng}
                // image={UploadPng}
                alt="work portfolio"
                style={{objectFit:'contain',backgroundSize:'cover'}}
                onClick={(e)=>handleImageModalOpen(value.image_url)}
                sx={{cursor:'pointer'}}

                />
                }
                {(new Date(value?.next_inspection_date) < new Date()) && value.status!=='Unavailable'?
                <ButtonStyled  className="test-button" aria-label="upload picture" component="label">
                  
                <input hidden accept="image/*" type="file" onChange={(e)=>handleImageUpdate(e,index)} />
                <PhotoCamera />
                </ButtonStyled> :null} 
                </ContainerStyled>
                <CardContent style={{marginTop:150}}>
                <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    size='small'
                    id="treeName"
                    name="treeName"
                    autoComplete="treeName"
                    label="Tree Name*"
                    value={value?.tree_name?.name}
                    style={{ marginTop: '7px' }}
                    // onChange={(e)=>{
                    //   handleTreeLong(e,index)
                    // }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    />
                <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    size='small'
                    id="lat"
                    name="lat"
                    autoComplete="lat"
                    label="Lat*"
                    value={value?.lat}
                    style={{ marginTop: '7px' }}
                    onChange={(e)=>{
                      handleTreeLat(e,index)
                    }}
                    />
                      <TextField
                    fullWidth
                    InputProps={{
                      readOnly: true,
                    }}
                    size='small'
                    id="lng"
                    name="lng"
                    autoComplete="lng"
                    label="Lng*"
                    value={value?.lng}
                    style={{ marginTop: '7px' }}
                    onChange={(e)=>{
                      handleTreeLong(e,index)
                    }}
                    />
                    <TextField
                    type='date'
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    size='small'
                    id="planatationDate"
                    name="planatationDate"
                    autoComplete="planatationDate"
                    label="Plantation date*"
                    value={value?.plantation_date}
                    style={{ marginTop: '7px' }}
                    onChange={(e)=>{
                      handlePlantationDate(e,index)
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    />
                </CardContent>
            </Card>
        </Grid>
          })}
            
        </Grid>
        {new Date(treeNeedToBePlant?.[0]?.next_inspection_date) < new Date()?
        <Button
          variant="contained"
          style={{ display: 'flex', fontSize: 15, marginTop: 20, alignSelf: 'end', marginLeft: ' 90%' }}
          onClick={(e) => {
            // validateDropDown();
            handleTreeUpdate(e);
          }}
        >
          {'Update'}
        </Button>:null}
    </Card>
    </div>
        :
        <div>
    
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          Tree Cutting Compensation
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography>
        </Typography>
      </Stack>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
      <Typography variant="h5" style={{ fontWeight: '400',marginTop:'10px',marginBottom:'5px' }}>
                {`Fees per cutting tree is ₹${finePerTree}`} 
          </Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
          <Grid item xs={6}>
            <TextField
              fullWidth
              //  style={{width: '10%'}}
              // style={{width:'57.5%', marginLeft: 40,marginTop:5}}
              id="totalFees"
              name="totalFees"
              autoComplete="totalFees"
              label="Total Fees Paid*"
              placeholder="Total Fees Paid*"
              style={{ marginTop: '7px' }}
              value={values.totalFees}
              onChange={(e) => {
                formik.handleChange(e);
                handleFineChange(e)
              }}
              error={Boolean(touched.totalFees && errors.totalFees)}
              helperText={touched.totalFees && errors.totalFees}
            // {...getFieldProps("firstName")}
            />
            <Typography variant="body2" color={'#FF0000'} style={{marginLeft:'40px'}}>
              {fineError}
            </Typography>
          </Grid>
          
          <Grid item xs={6}>
            <TextField
                fullWidth
                disabled
                id="totalTreeNeedTobePlant"
                name="totalTreeNeedTobePlant"
                autoComplete="totalTreeNeedTobePlant"
                label="Total Trees need to be plant*"
                placeholder="Total Trees need to be plant*"
                style={{ marginTop: '7px' }}
                value={values.totalTreeNeedTobePlant}
                // onChange={(e) => {
                //   handleMobile(e);
                //   formik.handleChange(e);
                // }}
                error={Boolean(touched.totalTreeNeedTobePlant && errors.totalTreeNeedTobePlant)}
                helperText={touched.totalTreeNeedTobePlant && errors.totalTreeNeedTobePlant}
              {...getFieldProps("totalTreeNeedTobePlant")}
              />
          </Grid>
        </Grid>
        <Button
          variant="contained"
          style={{ display: 'flex', fontSize: 15, marginTop: 20, alignSelf: 'end', marginLeft: ' 90%' }}
          onClick={(e) => {
            // validateDropDown();
            formik.handleSubmit(e);
          }}
        >
          {'Save'}
        </Button>
      </Card>
    </div>:null

  );
}
