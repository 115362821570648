
import formDataJWTServer from "../api/formDataJWTServer";

import {
  UPDATE_TREE_IMAGE,
  UPLOAD_COUNCIL_IMAGE,
  UPLOAD_FILE, UPLOAD_IMAGE, UPLOAD_KML_FILE
} from "./Types";

import { HandleExceptionWithSecureCatch } from "./CombineCatch";
import { SetNewAlert } from "./AlertActions";



const UploadFile = (params,index) => async (dispatch) => {
    try {
      const response = await formDataJWTServer.post("/api/upload-file", params);
      // console.log("UPLOAD FILE RESPONSE",response.data);
      const uploadFile = response.data.data;

      uploadFile.index = index;
      dispatch({
        type: UPLOAD_FILE,
        payload: uploadFile,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const UploadImage = (params) => async (dispatch) => {
    try {
      const response = await formDataJWTServer.post("/api/upload-image", params);
      // console.log("UPLOAD FILE RESPONSE",response.data);
      dispatch({
        type: UPLOAD_IMAGE,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const UploadCouncilImage = (params,index) => async (dispatch) => {
    try {
      const response = await formDataJWTServer.post("/api/upload-image", params);
      // console.log("UPLOAD FILE RESPONSE",response.data);
      const uploadFile = response.data.data;
      console.log("UPLOAD FILE",uploadFile)
      uploadFile.index = index;

      dispatch({
        type: UPLOAD_COUNCIL_IMAGE,
        payload: uploadFile,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const UploadTreeImage = (params,index) => async (dispatch) => {
    try {
      const response = await formDataJWTServer.post("/api/upload-image", params);
      // console.log("UPLOAD FILE RESPONSE",response.data);
      const uploadFile = response.data.data;
      console.log("UPLOAD FILE",uploadFile)
      uploadFile.index = index;

      dispatch({
        type: UPLOAD_COUNCIL_IMAGE,
        payload: uploadFile,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const UpdateTreeImage = (params,index) => async (dispatch) => {
    try {
      const response = await formDataJWTServer.post("/api/upload-image", params);
      // console.log("UPLOAD FILE RESPONSE",response.data);
      const uploadFile = response.data.data;
      console.log("UPLOAD FILE",uploadFile)
      uploadFile.index = index;

      dispatch({
        type: UPDATE_TREE_IMAGE,
        payload: uploadFile,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const UploadKml = (params,index) => async (dispatch) => {
    try {
      const response = await formDataJWTServer.post("/api/parse-kml", params);
      // console.log("UPLOAD FILE RESPONSE",response.data);
      const uploadFile = response.data;
      uploadFile.index = index;

      dispatch({
        type: UPLOAD_KML_FILE,
        payload: uploadFile,
        
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


export {
  UploadFile,
  UploadImage,
  UploadCouncilImage,
  UploadTreeImage,
  UpdateTreeImage,
  UploadKml
};
