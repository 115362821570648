import { filter, wrap } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Pagination,
  Stack,
  CircularProgress,
  Chip,
  Toolbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { downloadExcel } from 'react-export-table-to-excel';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteState, GetAllState, SearchState } from '../../actions/MasterActions';
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import USERLIST from '../../_mock/user';
// import NewUserDialog from '../components/DialogBox/NewUserDialog';
import UserTableData from '../../components/JsonFiles/UserTableData.json';
import StateDialog from '../../components/DialogBox/StateDialog';
import { MasterBreadCrum } from '../../sections/@dashboard/master/MasterBreadCrum';
import UserTypeReportToolBar from '../../sections/@dashboard/reports/UserTypeReportToolBar';
import { GetAllWorkReports, GetWorkReports, SearchWorkReports } from '../../actions/WorkReportAction';
import CountButton from '../../components/statusbutton/CountButton';

import { ShowLoader } from '../../actions/CommonAction';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'Name', label: 'Name', alignRight: false },
  { id: 'total', label: 'Total Count', alignRight: false },
  { id: 'approved', label: 'Approved Count', alignRight: false },
  { id: 'unapproved', label: 'Unapproved Count', alignRight: false },
  { id: 'pending', label: 'Pending Count', alignRight: false }
];

const TABLE_HEAD2 = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'totalqc', label: 'Total QC Count', alignRight: false }
];

const TABLE_HEAD_DATE = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'Name', label: 'Name', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'teamCode', label: 'Team Code', alignRight: false },
  { id: 'firstAdded', label: 'First Tree Added Time', alignRight: false },
  { id: 'lastAdded', label: 'Last Tree Added Time', alignRight: false },
  { id: 'total', label: 'Total Count', alignRight: false },
  { id: 'approved', label: 'Approved Count', alignRight: false },
  { id: 'unapproved', label: 'Unapproved Count', alignRight: false },
  { id: 'pending', label: 'Pending Count', alignRight: false },
  
  // { id: 'censusCount', label: 'Census Count', alignRight: false },
  // { id: 'censusOffsiteQCCount', label: 'Census Offsite QC Count', alignRight: false },
  // { id: 'censusOnsiteQCCount', label: 'Census Onsite QC Count', alignRight: false },
  //   { id: 'action', label: 'Action', alignRight: true },
];

const TABLE_HEAD_DATE2 = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'totalqc', label: 'Total QC Count', alignRight: false },
  
];

export default function UserTypeList(props) {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [dropPage, setDropPage] = useState(3);
  const [downloadButtonPressed, setDownloadButtonPressed] = useState(false);
  const [workType, setWorkType] = useState(props.workType);
  const [dayWiseFilter, setDayWiseFilter] = useState('total_count');
  const userPermissions = [];
  const { reportType, fromDate, toDate } = props;

  const handleDropChange = (event) => {
    setDropPage(event.target.value);
  };

  const { workReports, pageInfo, excelWorkReports, showLoader } = useSelector((state) => ({
    workReports: state.workReports.workReports,
    excelWorkReports: state.workReports.excelWorkReports,
    pageInfo: state.workReports.pageInfo,
    showLoader: state.common.showLoader,
  }));

// loader
const loderRun = React.useRef(true);
useEffect(() => {
  if (loderRun.current) {
    loderRun.current = false;
    return;
  }
  dispatch(ShowLoader(false));
}, [workReports]);


useEffect(() => {
  setWorkType(props.workType)
}, [props.workType]);


  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
    }
  }, [pageInfo]);

  useEffect(() => {
    if (excelWorkReports && downloadButtonPressed) {
      handleDownloadExcel();
      setDownloadButtonPressed(false);
    }
  }, [excelWorkReports]);

  const handleDelete = (data) => {
    dispatch(DeleteState(data.id, data.status ? 0 : 1));
  };

  const secondRun = React.useRef(true);

  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    setPage(1);
  }, [fromDate, toDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // if(search){
    //   dispatch(SearchWorkReports(newPage,rowsPerPage,searchValue));
    // }
    // else {
    dispatch(GetWorkReports(reportType, undefined, undefined, undefined, fromDate, toDate,workType, dayWiseFilter, newPage, rowsPerPage));
  };

  let timer = null;
  const filterByName = (event) => {
    const value = event.currentTarget.value;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      dispatch(GetWorkReports(1, rowsPerPage));
      setSearch(false);
      setPage(1);
      // }
    }, 1000);
  };

  const header = [
    '#',
    'User',
    'Current Role',
    'Base Color Count',
    'Base Color Offsite Qc Count',
    'Base Color Onsite QC Count',
    'Census Count',
    'Census Offsite Qc Count',
    'Census Onsite QC Count',
    'From Date',
    'To Date',
  ];

  const EXCEL_HEAD = [
    '#',
    'Name',
    'Total Count',
    'Approved Count',
    'Unapproved Count',
    'Pending Count'
  ];
  
  const EXCEL_HEAD2 = [
    '#',
    'Name',
    'Total QC Count'
  ];
  
  const EXCEL_HEAD_DATE = [
    '#',
    'Name',
    'Date',
    "Team Code",
    'First Tree Added Time',
    'Last Tree Added Time',
    'Total Count',
    'Approved Count',
    'Unapproved Count',
    'Pending Count'
  ];
  
  const EXCEL_HEAD_DATE2 = [
    '#',
    'Name',
    'Date',
    'Total QC Count',
  ];

  const filterList = [
    {
      name:'Base Color',
      id : 'base_color_user'
    },
    {
      name:'Base Color Offsite',
      id : 'qc_base_color_offsite'
    },
    {
      name:'Base Color Onsite',
      id : 'qc_base_color_onsite'
    },
    {
      name:'Census',
      id : 'census_user'
    },
    {
      name:'Census offiste',
      id : 'qc_census_offsite'
    },
    {
      name:'Census Onsite',
      id : 'qc_census_onsite'
    },
    {
      name:'Numbering User',
      id : 'numbering_user'
    },
  ]

  const handleDownloadButtonPressed = () => {
    setDownloadButtonPressed(true);
    dispatch(GetAllWorkReports(reportType, undefined, undefined, fromDate, toDate,workType,dayWiseFilter));
  };

  function handleDownloadExcel() {
    // dispatch(GetAllWorkReports(reportType, fromDate,toDate));

    const dataValue = excelWorkReports;
    console.log("EXCEL WORK REPORT",excelWorkReports);
    console.log("Work Report",workReports);
    const value1 = [];
    if(workType==="base_color_user" || workType==="census_user" || workType==="numbering_user"){
      dataValue?.map((option, index) => {
        const value2 = [index + 1];
        value2.push(option.name);
        if(dayWiseFilter==="date_wise_count"){
          value2.push(option.date);
          value2.push(option?.team_code);
          value2.push(option?.first_tree_added);
          value2.push(option?.last_tree_added);
        }
        value2.push(option.total_trees);
        value2.push(option.approved_trees);
        value2.push(option.unapproved_trees);
        value2.push(option.pending_trees);

        // if (index === 0) {
        //   value2.push(fromDate);
        //   value2.push(toDate);
        // }
        value1.push(value2);
        return null;
      });
    }
    else {
      dataValue?.map((option, index) => {
        const value2 = [index + 1];
        value2.push(option.name);
        if(dayWiseFilter==="date_wise_count"){
          value2.push(option.date);
        }
        value2.push(option.total_qc_count);
        // if (index === 0) {
        //   value2.push(fromDate);
        //   value2.push(toDate);
        // }
        value1.push(value2);
        return null;
      });
    }
    
    downloadExcel({
      fileName: `By User Report -${workType}`,
      sheet: 'Report',
      tablePayload: {
        header : (workType==="base_color_user" || workType==="census_user" || workType==='numbering_user') ? dayWiseFilter==="total_count" ? EXCEL_HEAD : EXCEL_HEAD_DATE : dayWiseFilter==='total_count' ? EXCEL_HEAD2 : EXCEL_HEAD_DATE2,
        // accept two different data structures
        body: value1,
      },
    });
  }

  const useStyles = makeStyles({
    chipSelected: {
      backgroundColor: '#214c50',
      color: '#fff',
    },
    chip: {
      backgroundColor: '#fff',
      color: '#000',
    },
  });
  const classes = useStyles();
  const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    padding: theme.spacing(1, 1, 0, 3),
    width: '100%',
    flexWrap: 'wrap',
    backgroundColor: '#f1f1f1',
  }));

  const handleWorkTypeChange = (id) => {
    setWorkType(id);
    dispatch(
      GetWorkReports(reportType, undefined, undefined, undefined, fromDate, toDate,id,dayWiseFilter, 1, 10),
    );
    setPage(1)
    dispatch(ShowLoader(true));
  }

  const handleDayWiseChange = (value) => {
    setDayWiseFilter(value)
    dispatch(
      GetWorkReports(reportType, undefined, undefined, undefined, fromDate, toDate,workType,value, 1, 10),
    );
    setPage(1)
    dispatch(ShowLoader(true));
  }

  const handleCountClick = (option,status) => {
    if(workType==="census_user"){
      window.open(`${process.env.PUBLIC_URL}/dashboard/work-report/treeCensus/history/${option.user_id}/${fromDate}/${toDate}/${status}/total_count`, "_blank", "noreferrer");
    }
    if(workType==="base_color_user"){
      window.open(`${process.env.PUBLIC_URL}/dashboard/work-report/treeBaseColor/history/${option.user_id}/${fromDate}/${toDate}/${status}/total_count`, "_blank", "noreferrer");
    }
  }

  return (
    <Page title="User">
      <Container>
        <Card style={{ marginTop: 40 }}>
          <UserTypeReportToolBar
            handleExportexcel={() => handleDownloadButtonPressed()}
            numSelected={0}
            placeHolder={'Search here...'}
            dayWiseFilter= {dayWiseFilter}
            handleDayWiseChange = {(e)=>handleDayWiseChange(e)}
          />
          <RootStyle>
          {filterList?.map((value,index)=>{
            return <Chip
            label={value.name}
            key={value.id}
            onClick={() => {  
              handleWorkTypeChange(value.id);
            }}
            variant="outlined"
            value={workType}
            className={value.id === workType ? classes.chipSelected : classes.chip}
            style={{
              fontWeight: '700',
              borderRadius: '7px',
              border: 'none',
            }}
            sx={{ mr: 1.5,mb: 1.5 }}
          />
          })}
          </RootStyle>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                {showLoader ? (
                  <>
                    <TableBody sx={{ pb: 5 }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          margin: 'auto',
                          height: '100%',
                          padding: '30px',
                        }}>
                        <CircularProgress style={{ color: '#214c50', paddding: '10px' }} />
                      </div>
                    </TableBody>
                  </>
                ) : (
                  <>
                    <UserListHead headLabel={(workType==="base_color_user" || workType==="census_user" || workType==='numbering_user') ? dayWiseFilter==="total_count" ? TABLE_HEAD : TABLE_HEAD_DATE : dayWiseFilter==='total_count' ? TABLE_HEAD2 : TABLE_HEAD_DATE2} />
                    {workReports?.data?.map((option, index) => {
                      return (
                        <>
                          <TableBody>
                            <TableRow hover>
                              <TableCell align="left">
                                <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                              </TableCell>
                              <TableCell align="left">{option.name}</TableCell>
                              {dayWiseFilter==="date_wise_count"?
                              <>
                              <TableCell align="left">
                              {option?.date}
                            </TableCell>
                            </>
                            :null}
                              {/* <TableCell align="left">{option.current_role}</TableCell> */}
                              {(workType==="base_color_user" || workType==="census_user" || workType==="numbering_user")?
                              <>
                              {dayWiseFilter==="date_wise_count"?
                              <>
                              <TableCell align="left">
                            {option?.team_code}
                          </TableCell>
                          <TableCell align="left">
                            {option?.first_tree_added}
                          </TableCell>
                          <TableCell align="left">
                            {option?.last_tree_added}
                          </TableCell>
                          </>:null}
                              <TableCell align="left">
                                <CountButton count={option?.total_trees} onClick={()=>handleCountClick(option,"Total")} />
                              </TableCell>
                              <TableCell align="left">
                                <CountButton count={option?.approved_trees} onClick={()=>handleCountClick(option,"Approved")} />
                              </TableCell>
                              <TableCell align="left">
                                <CountButton count={option?.unapproved_trees} onClick={()=>handleCountClick(option,"Unapproved")}/>
                              </TableCell>
                              <TableCell align="left">
                                <CountButton count={option?.pending_trees} onClick={()=>handleCountClick(option,"Pending")} />
                              </TableCell>
                              </>:
                              <TableCell align="left">
                              <CountButton count={option.total_qc_count} />
                            </TableCell>

                              }
                              
                              {/* <TableCell align="left">
                                <CountButton count={option.census_trees_count} />
                              </TableCell>
                              <TableCell align="left">
                                <CountButton count={option.census_trees_offsite_qc_count} />
                              </TableCell>
                              <TableCell align="left">
                                <CountButton count={option.census_trees_onsite_qc_count} />
                              </TableCell> */}
                            </TableRow>
                          </TableBody>
                        </>
                      );
                    })}
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          {workReports?.data ? (
            <Pagination
              count={pageInfo.last_page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
