import { RESET_STATE, UPDATE_TREE_IMAGE, UPLOAD_COUNCIL_IMAGE, UPLOAD_FILE, UPLOAD_IMAGE, UPLOAD_KML_FILE } from "../actions/Types";
  
  const INIT_STATE = {
    uploadFileLog:false,
    uploadFile:{},
    uploadImage:{},
    uploadImageLog:false,
    updateImage:{},
    updateImageLog: false,
    kmlFile: {},
    kmlFileLog: false,
  };
  
  export default function UploadReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;
  
    switch (type) {
        case UPLOAD_FILE:
          return{
              ...state,
              uploadFile: payload,
              uploadFileLog: !state.uploadFileLog
        };

        case UPLOAD_IMAGE:
          return{
              ...state,
              uploadImage: payload.data,
              uploadImageLog: !state.uploadImageLog
        };

        case UPLOAD_COUNCIL_IMAGE:
          return{
              ...state,
              uploadImage: payload,
              uploadImageLog: !state.uploadImageLog
        };

        case UPDATE_TREE_IMAGE:
          return{
              ...state,
              updateImage: payload,
              updateImageLog: !state.updateImageLog
        };

        case UPLOAD_KML_FILE:
          return{
              ...state,
              kmlFile: payload,
              kmlFileLog: !state.kmlFileLog
        };

        case RESET_STATE:
        return { ...INIT_STATE };

        default:
        return state;
    }
  };
  