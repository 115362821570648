import React from 'react';
import { Tooltip, Typography } from '@material-ui/core';

const ArrayTooltip = ({ records }) => {
  const tooltipContent = records.map((app, index) => (
    <Typography key={index}>{app.application_number}</Typography>
  ));

  return (
    <Tooltip
      title={<>{tooltipContent}</>}
      arrow
    >
      <span style={{ cursor: 'pointer', }}>
        {records.slice(0, 2).map((app, index) => (
          <React.Fragment key={index}>
            <span>{app.application_number}</span>

            {index < records.slice(0, 2).length - 1 && ', '} 
            {/* {index < 1 && ', '} Add a comma between items */}
          </React.Fragment>
        ))}
        {records.length > 2 && '...'}
      </span>
    </Tooltip>
  );
};

export default ArrayTooltip;
