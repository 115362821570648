import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// material
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup';
import moment from 'moment';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
  Button,
} from '@mui/material';
// component
import Html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import TreeVarietyTable from './TreeVarietyTable';
import { GetReports } from '../../../actions/ReportsAction';
import abellLogo from '../../../Assets/abell_.png';
import vasudharaLogo from '../../../Assets/vasudhara_logo.png';
import muncipal1Img from '../../../Assets/municipal1.png';
import muncipal2Img from '../../../Assets/municipal2.png';
import benefit1Img from '../../../Assets/benefit1.png';
import benefit2Img from '../../../Assets/benefit2.png';
import planningImg from '../../../Assets/planning_.png';
import method1Img from '../../../Assets/method1.png';
import method2Img from '../../../Assets/method2.png';
import method3Img from '../../../Assets/method3.png';
import method4Img from '../../../Assets/method4.png';
import method5Img from '../../../Assets/method5.png';
import method6Img from '../../../Assets/method6.png';
import method7Img from '../../../Assets/method7.png';
import method8Img from '../../../Assets/method8.png';
import dashboardImg from '../../../Assets/dashboard.png';
import mapTreeImg from '../../../Assets/map_tree.png';
import treeDetailImg from '../../../Assets/addTreeDetail.png';
import treeDataImg from '../../../Assets/treeData.png';
import googleImg from '../../../Assets/google_earth.png';
import TempCouncilLogo from '../../../Assets/council_logo.png';
import councilbounImg from '../../../Assets/council_boundray.png';
import councilwardImg from '../../../Assets/council_ward.png';
import greenTagImg from '../../../Assets/green_tags.png';
import clickTagImg from '../../../Assets/clicktag_tree.png';
import overallTreeImg from '../../../Assets/overallTree_Img.png';
import {titleBody_ , titleBody, treeSpecies, scopeHeader, scopeBody, headerBody, header, titleHeader,history,treeNameWithCondition} from './ReportJson';
import { ShowLoader,ShowLoadingButton } from '../../../actions/CommonAction';
// import { fr } from 'date-fns/locale';
// import Barchart from '../chart/Barchart';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ReportListToolbar.propTypes = {
  callType: PropTypes.string,
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  placeHolder: PropTypes.string,
  handleCoucilChange: PropTypes.func,
  handleGetData: PropTypes.func,
  // handleWardChange:PropTypes.func,
  // handleZoneChange:PropTypes.func,
  coucilId: PropTypes.any,
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
  zoneId: PropTypes.any,
  wardId: PropTypes.any,
  showReport: PropTypes.any
};

export default function ReportListToolbar({
  numSelected,
  handleGetData,
  handleCouncil,
  councilId,
  wardImage,
  typeImage,
  treeImage,
  logoImage,
  conditionImage,
  propertyImage,
  locationImage,
  wardWiseImage,
  overallCount,
  handleViewReport,
  hideReport,
  showReport
}) {
  const dispatch = useDispatch();
  const [coucilId, setCouncilId] = useState('');
  const [councilName, setCouncilName] = useState('');
  const [imageData, setImageData] = useState('');
  const todayDate = moment(new Date()).format('YYYY-MM-DD');
  const inputRef = useRef(null);

  const handleCouncilChange = (e) => {
    setCouncilId(e.target.value);
    handleCouncil(e.target.value);
    hideReport();

    council.map((value, index) => {
      if (value.id === e.target.value) {
        setCouncilName(value.name);
      }
      return null;
    });
    // councilId(e.target.value)
    // // console.log("councilName",councilName)
    // setZoneId("")
    // setWardId("")
    // dispatch(GetZonesByCouncilId(1,1000,e.target.value))
    // dispatch(GetWardsByCouncilId(1,1000,e.target.value))
  };

  const DistrictsSchema = Yup.object().shape({
    council: Yup.string().required('Council is required'),
    fromDate: Yup.string().required('From Date is Required'),
    toDate: Yup.string().required('To Date is Required'),
  });

  const { council, reports } = useSelector((state) => ({
    council: state.council.activeCouncil,
    reports: state.reports.reports,
  }));

  const councilArr = council?.find((val) => val.id === coucilId);

  const separateId = (id) => {
    council.map((value, index) => {
      if (value.id === id) {
        setCouncilName(value.name);
      }
      return null;
    });
  };

  const formatDate = (date) => {
    return date.split('-').reverse().join('-');
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      council: councilArr?.id || coucilId,
      fromDate: councilArr?.project_start_date || todayDate,
      toDate: councilArr?.project_end_date || todayDate,
    },
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {
      dispatch(GetReports(value.council, formatDate(value.fromDate), formatDate(value.toDate)));
      handleViewReport();
      dispatch(ShowLoader(true));
    },
  });

  const dataValue = reports?.by_wards;
  const value1 = [];
  dataValue?.map((option, index) => {
    // const value2 = [index + 1];
    const value2 = [];
    value2.push(option.name);
    value2.push(option.census_trees_count);
    value1.push(value2);
    return null;
  });

  const TreeName = reports?.by_tree_names;
  const treeNameValue1 = [];
  TreeName?.map((option, index) => {
    // const treeNameValue2 = [index + 1];
    const treeNameValue2 = [];
    treeNameValue2.push(option.name);
    treeNameValue2.push(option.census_trees_count);
    treeNameValue1.push(treeNameValue2);
    return null;
  });
  const treeType = reports?.by_tree_types;
  const treeType1 = [];
  treeType?.map((option, index) => {
    // const treeType2 = [index + 1];
    const treeType2 = [];
    treeType2.push(option.tree_type);
    treeType2.push(option.census_trees_count);
    treeType1.push(treeType2);
    return null;
  });


  const TreeCondition = reports?.by_tree_conditions;
  const TreeCondition1 = [];
  TreeCondition?.map((option, index) => {
    // const TreeCondition2 = [index + 1];
    const TreeCondition2 = [];
    TreeCondition2.push(option.condition);
    TreeCondition2.push(option.census_trees_count);
    TreeCondition1.push(TreeCondition2);
    return null;
  });

  const propertyTypes = reports?.by_property_types;
  const propertyTypes1 = [];
  propertyTypes?.map((option, index) => {
    // const TreeCondition2 = [index + 1];
    const propertyTypes2 = [];
    propertyTypes2.push(option.property_type);
    propertyTypes2.push(option.census_trees_count);
    propertyTypes1.push(propertyTypes2);
    return null;
  });

  const locationTypes = reports?.by_location_types;
  const locationTypes1 = [];
  locationTypes?.map((option, index) => {
    // const TreeCondition2 = [index + 1];
    const locationTypes2 = [];
    locationTypes2.push(option.location_type);
    locationTypes2.push(option.census_trees_count);
    locationTypes1.push(locationTypes2);
    return null;
  });

  const differentSpeciesList = [];

  reports?.by_tree_names?.map((option,index)=>{
    const differentSpeciesList2 = [];
    differentSpeciesList2.push(index+1);
    differentSpeciesList2.push(option?.name);
    differentSpeciesList2.push(option?.botanical_name);
    differentSpeciesList2.push(option?.tree_type?.tree_type);
    differentSpeciesList2.push(option?.tree_family?.tree_family);
     differentSpeciesList2.push(option?.uses);
      differentSpeciesList2.push(option?.origin);
    differentSpeciesList.push(differentSpeciesList2);
    return null;
  })

  const rareSpeciesList = [];

  reports?.by_tree_names?.map((option,index)=>{
    const rareSpeciesList2 = [];
    rareSpeciesList2.push(index+1);
    rareSpeciesList2.push(option?.name);
    rareSpeciesList2.push(option?.botanical_name);
    rareSpeciesList2.push(option?.tree_type?.tree_type);
    rareSpeciesList2.push(option?.tree_family?.tree_family);
    rareSpeciesList2.push(option?.uses);
    rareSpeciesList2.push(option?.origin);
    rareSpeciesList.push(rareSpeciesList2);
    return null;
  })

  const exportPdf = async () => {
    dispatch(ShowLoadingButton(true))
    const img = await wardImage();
    const treeCanvas = await treeImage();
    const logoCanvas = await logoImage();
    const typeCanvas = await typeImage();
    const conditionCanvas = await conditionImage();
    const propertyCanvas = await propertyImage();
    const locationCanvas = await locationImage();
    const wardWiseCanvas = await wardWiseImage();
    const overallCanvas = await overallCount();
    // const header = [['WARD WISE TREE SUMMARY'], ['BY TREE NAMES'], ['BY TREE TYPES'], ['BY TREE CONDITIONS']];
    
    const treeCountHead = [[`${councilName} Tree Count`, '']];
   
    // const canvas = [img, treeCanvas, typeCanvas, conditionCanvas];
    // const body_ = [value1, treeNameValue1, treeType1, TreeCondition1];
    const canvas = [img, typeCanvas, conditionCanvas, treeCanvas,propertyCanvas,locationCanvas];
    const body_ = [value1, treeType1, TreeCondition1,treeNameValue1,propertyTypes1,locationTypes1];
    const treeCountBody = [
      [`Total area of ${councilName}`, `${reports?.council_details?.total_area} Sq. Km`],
      ['Total Trees observed in Census', `${reports?.total_trees} Trees`],
    ];
    const councilReportYear = reports?.council_report_year;
    const councilTotalArea = `${reports?.council_details?.total_area} Sq. Km`;
    const councilTotalTrees = reports?.total_trees;
    const councilLogo = reports?.council_details?.logo;
    const councilCity = reports?.council_details?.city;

    const groupedCouncilMeta = reports?.council_meta_details?.reduce( (r, a) => {
      r[a.key] = r[a.key] || [];
      r[a.key].push(a);
      return r;
    }, Object.create(null));

    const chiefOfficerString = `${reports?.council_details?.co}`
    const officeSupredentString = `${groupedCouncilMeta['Office Supretendent']?.map((list) => list.value).join(",")}`
    const treeOfficerString = `${groupedCouncilMeta['Tree officer']?.map((list) => list.value).join(",")}`
    const gardanDepartmentString = `${groupedCouncilMeta['Garden Department Officer']?.map((list) => list.value).join(",")}`
    const gardanSupervisorString = `${groupedCouncilMeta['Garden Supervisor']?.map((list) => list.value).join(",")}`
    const councilImage1 = `${groupedCouncilMeta?.image_url_1?.map((list) => list.value).join(",")}`
    
    // const councilImage1 = await getDataUri(`${groupedCouncilMeta?.image_url_1?.map((list) => list.value).join(",")}`);
    const councilImage2 = `${groupedCouncilMeta?.image_url_2?.map((list) => list.value).join(",")}`
    // const councilImage2 = await getDataUri(`${groupedCouncilMeta?.image_url_2?.map((list) => list.value).join(",")}`);
    const historyString = `${groupedCouncilMeta['History Of Council']?.[0].value}`
    const finalImage1 = new Image()
    finalImage1.src = process.env.REACT_APP_BASE_URL.concat('/').concat(councilImage1);
    const finalImage2 = new Image()
    finalImage2.src = process.env.REACT_APP_BASE_URL.concat('/').concat(councilImage2);
    const councilMetaTableBody = []
    councilMetaTableBody.push(['Hon. Chief Officer ',chiefOfficerString])

    Object.keys(groupedCouncilMeta).forEach((e) => {
      if(e !== "History Of Council" && e !== "image_url_1" && e !== 'image_url_2'){
        groupedCouncilMeta[e]?.map((value,index)=>{
          if(index===0){
            councilMetaTableBody.push([e,value.value])
          }
          else {
            councilMetaTableBody.push(['',value.value])
          }
          return null;  
        })
      }
      
    }
    );

    const groupedAbellMeta = reports?.abell_meta_details?.reduce( (r, a) => {
      r[a.key] = r[a.key] || [];
      r[a.key].push(a);
      return r;
    }, Object.create(null));

    const abellMetaTableBody = []

    Object.keys(groupedAbellMeta).forEach((e) => {
      groupedAbellMeta[e]?.map((value,index)=>{
        if(index===0){
          abellMetaTableBody.push([e,value.value])
        }
        else {
          abellMetaTableBody.push(['',value.value])
        }
        return null;  
      })
    }
    );



    function push() {
      const masterArray = [];
      for (let i = 0; i < body_.length; i += 1) {
        const master = {
          header: header[i],
          headerBody: headerBody[i],
          canvas: canvas[i],
          body_: body_[i],
        };
        masterArray.push(master);
      }
      return masterArray;
    }
    const arr = push();

    const doc = new JsPDF({filters: ['ASCIIHexEncode']});
    doc.page = 1; // use this as a counter.

    function footer() {
      doc.setFontSize(11);
      doc.text(185, 287, `Page: ${doc.page}`); // print number bottom right
      doc.page += 1;
    }

    function header() {
      doc.addImage(abellLogo, 'JPEG', 10, 10, 35, 30);
    }

    const toIMAGETOURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      console.log("BLOB",blob);
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }))

    const getBase64FromUrl = async (url) => {
      const data = await fetch(url);
      const blob = await data.blob();
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = () => {
          const base64data = reader.result;   
          resolve(base64data);
        }
      });
    }


    const base64Img =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyBAMAAADsEZWCAAAAG1BMVEXMzMyWlpaqqqq3t7exsbGcnJy+vr6jo6PFxcUFpPI/AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQUlEQVQ4jWNgGAWjgP6ASdncAEaiAhaGiACmFhCJLsMaIiDAEQEi0WXYEiMCOCJAJIY9KuYGTC0gknpuHwXDGwAA5fsIZw0iYWYAAAAASUVORK5CYII=';
    const headStyles_ = { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 15 };
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    doc.setFont('italic');
    doc.setFont(undefined, 'bold');
    doc.text('TREE CENSUS BY USING GPS AND GIS TECHNOLOGY WITHIN', 20, 30);

    const xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(councilName) * doc.internal.getFontSize() / 2); 

    doc.text(`${councilName.toUpperCase()} JURISDICTION`, xOffset, 40,);
    // doc.addImage(councilLogo, 'JPEG', 75, 50, 50, 50);


    // const base64 = getBase64Image(document.getElementById("co_logo"));
    const c = document.getElementById("logoCanvas");
    // const ctx = c.getContext("2d");
    // const imgs = document.getElementById("co_logo");
    // ctx.drawImage(imgs, 10, 10);
    // alert(c.toDataURL());
    const responseImage = await getBase64FromUrl(councilLogo)
    doc.addImage(responseImage, 'PNG', 75, 50, 50, 50);
    doc.text(councilReportYear, 85, 110);
    doc.addImage(vasudharaLogo, 'JPEG', 65, 130, 70, 60);
    doc.setTextColor(0, 85, 169);
   
    doc.text('MAZI VASUNDHARA ABHIYAN', 60, 205);

    doc.text(councilReportYear, 85, 215);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 15);
    header()
    // doc.text(councilName, 65, 30);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`${councilName.toUpperCase()}`, xOffset, 35);
    // autoTable(doc, {
    //   margin: { top: 30, bottom: 10, left: 65 },
    //   headStyles: headStyles_,
    //   head: [[`${councilName}`]],
    // });

    if(councilImage1 !==undefined && councilImage1!=="undefined"){
      console.log("inside 1")
      const responseCoucilImage1= await getBase64FromUrl(councilImage1);
      doc.addImage(responseCoucilImage1, 'PNG', 55, 45, 100, 100);
    }
    if(councilImage2 !==undefined && councilImage2 !=="undefined"){
      console.log("inside 2")
      const responseCoucilImage2= await getBase64FromUrl(councilImage2);
      doc.addImage(responseCoucilImage2, 'PNG', 25, 155, 160, 100);
    }
    
    

    
    
    
    doc.text(`TREE CENSUS REPORT (${councilReportYear})`, 53, 270);

    const margin_ = { top: 0, bottom: 0 };

    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 15);
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text('INDEX', 90, 35);
    // autoTable(doc, {
    //   margin: { top: 30, bottom: 2, left: 90 },
    //   headStyles: headStyles_,
    //   head: [['INDEX']],
    // });
    const tableIndex = titleBody;

    tableIndex.splice(1, 0, [2, `History of ${councilCity}`]);
    autoTable(doc, {
      headStyles: { fillColor: [129, 130, 131] },
      alternateRowStyles: { fillColor: [247, 247, 247] },
      tableLineColor: [129, 130, 131],
      tableLineWidth: 0.1,
      margin: { top: 45, bottom: 10 },
      head: titleHeader,
      body: tableIndex,
    });
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 25);
    header()
    doc.addImage(benefit1Img, 'JPEG', 10, 35, 190, 240);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 17);
    header()
    doc.addImage(benefit2Img, 'JPEG', 10, 35, 190, 240);
    footer();
    doc.addPage();
    const line = 25; // Line height to start text at
    const lineHeight = 5;
    const leftMargin = 20;
    const wrapWidth = 170;
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
   
    doc.setFont(undefined, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text('ACKNOWLEDGEMENT', 73, 40);
    doc.setFont('normal');
    // autoTable(doc, {
    //   margin: { top: 35, bottom: 10, left: 70 },
    //   headStyles: headStyles_,
    //   head: [['ACKNOWLEDGEMENT']],
    // });
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    // doc.fromHTML( 'Paranyan <b>loves</b> jsPDF', 35, 25)
    const aknowlArray = [
      'sanjay',
      'Thank you for all your wonderful contributions',
      'It was really'
    ]

    // aknowlArray.map((value,index)=>{
      
    //   if(index===1){
    //     doc.setFont(undefined,'bold')
    //   }
    //   else {
    //     doc.setFont(undefined,'normal')
    //   }
    //   doc.text(aknowlArray[index],startX,55)
    //   startX += doc.getStringUnitWidth(aknowlArray[index]) * fontSize;
    //   console.log("START X",startX)
    //   return null;
    // })
    
    doc.text(
      '      Thank you for all your wonderful contributions during our recent project work. It was really',
      20,
      55
    );
    doc.text(
      ' helpful to have your input, I really appreciate your guidance and I especially appreciate all of the',
      20,
      60
    );
    doc.text(
      ' time you’ve taken away from your own work to guide us and to ensure the work is completed in time.',
      20,
      65
    );
    doc.text(
      `      Thus we are grateful to the ${chiefOfficerString}. (Hon. Chief officer ) for leading the `,
      20,
      80
    );
    doc.text(
      `passionate team. The important role played by ${officeSupredentString} (Hon.Office supretendent ) in execu-`,
      20,
      85
    );
    doc.text(
      'ting Tree Census project was essential and inputs received from him on various occasion was helpful,',
      20,
      90
    );
    doc.text(`and thus we are thankful to him. We would also like to thank ${treeOfficerString} (Tree Officer )`, 20, 95);
    doc.text(
      `${gardanDepartmentString} (Gardan department officer ) ${gardanSupervisorString} ( Gardan supervisor ) for`,
      20,
      100
    );
    doc.text('their regular inputs & support. ', 20, 105);
    doc.text(
      '      The project would not have been successful without sincere and dedicated field work carried',
      20,
      120
    );
    doc.text(
      'out by all technical team including field officers. We place on record our sincere appreciation ',
      20,
      125
    );
    doc.text(
      'for all of them. We also thanked administrative team for supporting the project throughout its duration.',
      20,
      130
    );

    doc.text('Thanking You,', 20, 190);
    doc.text('For,', 20, 230);
    doc.text('Abell Electro-soft Technologies Pvt. Ltd.', 20, 250);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 17);
    header()

    // autoTable(doc, {
    //   margin: { top: 33, bottom: 10, left: 55 },
    //   headStyles: headStyles_,
    //   head: [[`History of ${councilName}`]],
    // });
   
    
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`History of ${councilCity}`, 50, 40);
    doc.setFontSize(12);
    doc.setFont(undefined, 'normal');
    doc.text(
      historyString,
      20,
      55,
      { maxWidth: doc.internal.pageSize.width - 30 }
    );
    
    footer();

    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    // autoTable(doc, {
    //   margin: { top: 30, bottom: 10, left: 65 },
    //   headStyles: headStyles_,
    //   head: [[`WHAT IS TREE CENSUS?`]],
    // });
    doc.setFont(undefined, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(`WHAT IS TREE CENSUS?`, 65, 40);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.text(
      'A census is the procedure of systematically enumerating, and acquiring and recording information',
      20,
      55
    );
    doc.text(
      'about the Number of Trees in particular area. The census is a list that keeps track of all Trees',
      20,
      60
    );
    doc.text('for example tree name, girth, height, age, health condition, ownership etc.', 20, 65);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(`WHAT SAID THE MAHARASHTRA (URBAN AREAS) PROTECTION`, 15, 80);
    doc.text(`AND PRESERVATION OF TREES ACT, 1975?`, 45, 90);
    doc.text(`CHAPTER IV`, 80, 100);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.text(
      `7. Duties of Tree Authority. - Notwithstanding anything contained in the relevant Act or in any other`,
      20,
      115
    );
    doc.text(
      `law for the time being in force, and subject to any special or general directions given by the State`,
      20,
      120
    );
    doc.text(`Government, the Tree Authority shall be responsible for-`, 20, 125);
    doc.text(`(a) [protection and preservation] of all trees in all lands within its jurisdiction;`, 20, 135);
    doc.text(
      `[(b)] carrying out a census of the existing trees in all lands within its jurisdiction, [once before`,
      20,
      145
    );
    doc.text(`December 1996 and thereafter once in every five years;]`, 20, 150);
    doc.text(
      `(c) prescribing standards specifying the number and types of trees which each plot of land shall`,
      20,
      160
    );
    doc.text(`have and which shall be planted therein;`, 20, 165);
    doc.text(`(d) development and maintenance of nurseries for the supply of seeds, saplings and trees to`, 20, 175);
    doc.text(
      `persons who desire to plant new trees or to replace trees which have been felled with the previous`,
      20,
      180
    );
    doc.text(`permission of the TreeOfficer [or involuntarily uprooted;]`, 20, 185);
    doc.text(
      `transplanting of trees necessitated by construction of new roads orwidening of existing roads or for`,
      20,
      190
    );
    doc.text(`safeguarding danger to life or property;`, 20, 195);
    doc.text(
      `[(f) Organization of flower, fruit, vegetable, tree or plant shows [at least once a year] and assisting`,
      20,
      205
    );
    doc.text(`private and public Institutions in organizing such shows, and creation of consciousness of`, 20, 210);
    doc.text(`importance of trees and vegetation to the human welfare;].`, 20, 215);
    doc.text(
      `(g) grant of advice and technical assistance to any person seeking such advice or assistance in any`,
      20,
      220
    );
    doc.text(`matter connected with [planting protection and preservation] of trees;`, 20, 225);
    doc.text(`(h) planting and maintaining such number of trees as it considers necessary,according to the`, 20, 235);
    doc.text(
      `prescribed standards, [along the roads] in public parks and gardens and on banks of rivers or lakes`,
      20,
      240
    );
    doc.text(`or [sea shores, on hills, open spaces or public places.`, 20, 245);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(`WHY TREE CENSUS IS IMPORTANT?`, 53, 40);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    // autoTable(doc, {
    //   margin: { top: 35, bottom: 10, left: 55 },
    //   headStyles: headStyles_,
    //   head: [[`WHY TREE CENSUS IS IMPORTANT?`]],
    // });
    doc.text(`       A tree census also helps in future environmental planning. The census is also aimed at  `, 20, 55);
    doc.text(
      `encouraging community awareness of the need for tree conservation, regulating pruning and felling`,
      20,
      60
    );
    doc.text(`and increasing green coverwithpeople’s participation.`, 20, 65);
    doc.text(
      `       Once completed, the tree census may become a regular feature that can be repeated every five`,
      20,
      75
    );
    doc.text(
      `years and serve as a potential platform for an environmental education programme that would help`,
      20,
      80
    );
    doc.text(
      'spread awareness about the need for clean air, recharging of ground water, maintenance of biodive-',
      20,
      85
    );
    doc.text('rsity, reduction of noise pollution and so on.', 20, 90);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(`HOW MANY TREES ARE NEEDED TO PRODUCE ENOUGH`, 27, 105);
    doc.text(`OXYGEN FOR ONE PERSON`, 65, 115);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.text(
      `        You’ll hear a range of numbers and ways of presenting them because the amount of oxygen`,
      20,
      130
    );
    doc.text(`produced by a tree depends on the species of tree, its age, its health, and also on the tree’s`, 20, 135);
    doc.text(`surroundings. The bigger the tree the more total oxygen it produces and more is its own`, 20, 140);
    doc.text(`requirement for oxygen for survival. Yes, a 59-year-old Neem tree produces more oxygen than a`, 20, 145);
    doc.text(`1-year-old Neem tree. According to the Arbor Day Foundation, “a mature leafy tree produces as`, 20, 150);
    doc.text(`much oxygen in a season as 10 people inhale in a year.“A single`, 20, 155);
    doc.text(
      `        mature tree can absorb carbon dioxide at a rate of 48 lbs./year and release enough oxygen`,
      20,
      165
    );
    doc.text(`back into the atmosphere to support 2 human beings.”`, 20, 170);
    doc.text(
      `        One acre of trees annually consumes the amount of carbon dioxide equivalent to that produ-`,
      20,
      180
    );
    doc.text(
      `ced by driving an average car for 26,000 miles. That same acre of trees also produces enough oxygen`,
      20,
      185
    );
    doc.text(
      `for 18 people to breathe fora year. A 100-ft tree, 18 diameter at its base, produces 6,000 pounds of`,
      20,
      190
    );
    doc.text(`oxygen. “On average, one tree produces nearly 260 pounds of oxygen each year. Two mature trees`, 20, 195);
    doc.text(`can provide enough oxygen for a family of four”`, 20, 200);
    doc.text(`Conclusion - We need at least 1 mature trees or 4 to 5 small trees to make enoughoxygen for 1`, 20, 210);
    doc.text(`person. The bigger and older the tree is; the more oxygen we will get.`, 20, 215);
    footer();

    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 17);
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(15);
    doc.text('STEP BY STEP WORKING PROCESS', 57, 40);
    doc.addImage(planningImg, 'JPEG', 35, 50, 130, 140);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(11);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`${councilName} - Co-ordinators`, 40, 200);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(12);
    // autoTable(doc, {
    //   margin: { top: 195, bottom: 10, left: 40 },
    //   headStyles: headStyles_,
    //   head: [[`${councilName} - Co-ordinators`]],
    // });
    autoTable(doc, {
      margin: { top: 210, bottom: 10 },
      // head: titleHeader,
      body: councilMetaTableBody,
    });
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`Abell Electro-soft Technologies Pvt. Ltd (OPC )- Team Details`, 20, 40);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    // autoTable(doc, {
    //   margin: { top: 33, bottom: 10, left: 20 },
    //   headStyles: headStyles_,
    //   head: [[`Abell Electro-soft Technologies Pvt. Ltd (OPC )- Team Details`]],
    // });
    autoTable(doc, {
      margin: { top: 50, bottom: 10 },
      // head: titleHeader,
      body: abellMetaTableBody,
    });
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text('STEP BY STEP WORKING PROCESS', 55, 35);
    // autoTable(doc, {
    //   margin: { top: 30, bottom: 10, left: 55 },
    //   headStyles: headStyles_,
    //   head: [[`METHOD OF CONDUCTING TREE CENSUS.`]],
    // });
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.text('We have our own application https://census.abellserver.in in a GIS based dynamic software which', 20, 45);
    doc.text(
      'helps us manage records and related details of trees i.e. local tree name, scientific name, family,',
      20,
      50
    );
    doc.text('height,girth, age, ownership, healthcondition, GPS location of the tree enabled on our mobile', 20, 55);
    doc.text('application.Whilewe tag a tree with its GPSlocation, date & time is automatically recorded by', 20, 60);
    doc.text('the application.', 20, 65);

    doc.addImage(method1Img, 'JPEG', 20, 70, 75, 105);
    doc.addImage(method2Img, 'JPEG', 110, 70, 75, 105);
    doc.addImage(method3Img, 'JPEG', 20, 180, 75, 105);
    doc.addImage(method4Img, 'JPEG', 110, 180, 75, 105);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.addImage(method5Img, 'JPEG', 20, 40, 75, 105);
    doc.addImage(method6Img, 'JPEG', 110, 40, 75, 105);
    doc.addImage(method7Img, 'JPEG', 20, 150, 75, 105);
    doc.addImage(method8Img, 'JPEG', 110, 150, 75, 105);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.text('Above pictures shows how the data is collected on fields, by our field officers.', 30, 270);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(15);
    doc.setTextColor(255, 0, 0);
    doc.text('GPS & GIS based Mobile Application', 60, 40);
    // autoTable(doc, {
    //   margin: { top: 30, bottom: 10, left: 55 },
    //   headStyles: headStyles_,
    //   head: [[`GPS & GIS based Mobile Application`]],
    // });
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text(
      'Given image is the GPS based application we use for the datacollection & the details to be taken on field',
      15,
      50
    );
    doc.text('by our team', 90, 55);
    doc.addImage(treeDetailImg, 'JPEG', 65, 65, 80, 140);
    doc.setTextColor(55, 129, 43);
    doc.setFont(undefined, 'bold');
    doc.setFontSize(13);
    doc.text('BELOW IS THE SCREENSHOT OF OUR WEB BASED APPLICATION', 20, 225);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);
    doc.text('Step 1 – Login using below login details', 20, 235);
    doc.setTextColor(60, 109, 159);
    doc.text(' Website- https://census.abellserver.in', 20, 245);
    doc.setTextColor(0, 0, 0);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFontSize(12);
    doc.text('Step 2 - You will find dashboard as below :-', 20, 40);
    doc.addImage(dashboardImg, 'JPEG', 30, 50, 150, 90);
    doc.text('Step 3 – Go to the navigation option and select Tree Data and then Census ', 20, 160);
    doc.text(`List and then select council  that is ${councilName} tree Census from filter .`, 20, 170);
    doc.addImage(treeDataImg, 'JPEG', 30, 180, 150, 90);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFontSize(12);
    doc.text('Step 4 – For Maps please go to navigation than select Tree Data and then Trees on Map ', 20, 40);
    doc.text('and select project, ward no & other required criteria from filter. (Imaging shown)', 20, 50);
    doc.addImage(mapTreeImg, 'JPEG', 30, 60, 150, 90);
    doc.text(`The data collected on our application, will be super imposed ${councilName} City Map on`, 20, 170);
    doc.text('Google Earth:-', 20, 180);
    doc.addImage(googleImg, 'JPEG', 30, 190, 150, 90);
    footer();
    // doc.addPage();
    // doc.setDrawColor(0);
    // doc.setFillColor(255, 255, 255);
    // doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 17);
    // doc.setFontSize(12);
    // doc.text(`1) ${councilName} boundary line , and word wise map:-`, 20, 40);
    // doc.addImage(councilbounImg, 'JPEG', 30, 50, 150, 90);
    // doc.text(`2) ${councilName} word wise map:-`, 20, 160);
    // doc.addImage(councilwardImg, 'JPEG', 30, 170, 150, 90);
    // footer();
    // doc.addPage();
    // doc.setDrawColor(0);
    // doc.setFillColor(255, 255, 255);
    // doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 17);
    // doc.setFontSize(12);
    // doc.text(`3) The green tags (dot) in the image indicate trees in ${councilName}:--`, 20, 40);
    // doc.addImage(greenTagImg, 'JPEG', 30, 50, 150, 90);
    // doc.setFontSize(12);
    // doc.text(`4) On clicking the black tags one can see the details of the trees:-`, 20, 160);
    // doc.addImage(clickTagImg, 'JPEG', 30, 170, 150, 90);
    // footer();


    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    autoTable(doc, {
      headStyles: { fillColor: [129, 130, 131] },
      alternateRowStyles: { fillColor: [247, 247, 247] },
      tableLineColor: [129, 130, 131],
      tableLineWidth: 0.1,
      margin: { top: 40, bottom: 10 },
      head: treeCountHead,
      body: treeCountBody,
    });

    /* eslint-disable no-await-in-loop */

    for (let i = 0; i < arr.length; i += 1) {
      const res = await Html2canvas(canvas[i]);
      // .then((res) => {
      //  imgData = await canvas_.toDataURL('image/png');
      if(i===0){
        
        doc.text(treeNameWithCondition[i][0], 57, 70);
        autoTable(doc, {
        headStyles: { fillColor: [129, 130, 131] },
        alternateRowStyles: { fillColor: [247, 247, 247] },
        tableLineColor: [129, 130, 131],
        tableLineWidth: 0.1,
        margin: { top: 60, bottom: 0 },
        head: [headerBody[i]],
        body: body_[i],
      });

      doc.addImage(res.toDataURL('image/png'), 'JPEG', 10, doc.lastAutoTable.finalY + 30, 180, 120);
      
    }
    else {
      doc.addPage();
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.rect(5, 5, 200, 287, 'FD');
      header()
      doc.text(treeNameWithCondition[i][0], 57, 40);
        autoTable(doc, {
        headStyles: { fillColor: [129, 130, 131] },
        alternateRowStyles: { fillColor: [247, 247, 247] },
        tableLineColor: [129, 130, 131],
        tableLineWidth: 0.1,
        margin: { top: 50, bottom: 10 },
        head: [headerBody[i]],
        body: body_[i],
      });
      doc.addImage(res.toDataURL('image/png'), 'JPEG', 10, doc.lastAutoTable.finalY + 30, 180, 120);

      footer();
    }

    }

    if(reports?.total_trees_by_tree_name_ward) {
    Object.entries(reports?.total_trees_by_tree_name_ward).map( async (item) => {
      doc.addPage()
      doc.setDrawColor(0);
      doc.setFillColor(255, 255, 255);
      doc.rect(5, 5, 200, 287, 'FD');
      header()
      doc.text(`All Tree Variety found in  ${item[0]}`, 57, 40);
      const itemList = []
      item[1]?.map((value,index)=>{
        const itemList1 = []
        itemList1.push(value.tree_name);
        itemList1.push(value.tree_count);
        itemList.push(itemList1)
        return null;
      })
      autoTable(doc, {
        headStyles: { fillColor: [129, 130, 131] },
        alternateRowStyles: { fillColor: [247, 247, 247] },
        tableLineColor: [129, 130, 131],
        tableLineWidth: 0.1,
        margin: { top: 50, bottom: 10 },
        head: [["Tree Name","Count"]],
        body: itemList,
      });
      const wardRes = await Html2canvas(canvas[0]);
      doc.addImage(wardRes.toDataURL('image/png'), 'JPEG', 10, 57, 180, 120);
        

      footer();
    })
    }
    // autoTable(doc, {
    //   margin: { top: 165, bottom: 10, left: 18 },
    //   headStyles: headStyles_,
    //   head: [[`OVERALL TREE COUNT`]],
    // });
    // doc.setFontSize(12);
    // doc.text(`Major Tree Varieties In ${councilName}`, 20, 180);
    // const overallCountRes = await Html2canvas(overallCanvas);
    // doc.addImage(overallCountRes.toDataURL('image/png'), 'JPEG', 55, 185, 85, 85);
    // footer();
    // doc.addPage();
    // doc.setDrawColor(0);
    // doc.setFillColor(255, 255, 255);
    // doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 17);
    // doc.addImage(overallTreeImg, 'JPEG', 15, 40, 180, 110);
    // autoTable(doc, {
    //   headStyles: { fillColor: [129, 130, 131] },
    //   alternateRowStyles: { fillColor: [247, 247, 247] },
    //   tableLineColor: [129, 130, 131],
    //   tableLineWidth: 0.1,
    //   margin: { top: 165, bottom: 10 },
    //   head: [headerBody[2]],
    //   body: body_[2],
    // });
    // footer();
    // doc.addPage();
    // doc.setDrawColor(0);
    // doc.setFillColor(255, 255, 255);
    // doc.rect(5, 5, 200, 287, 'FD');
    // doc.addImage(abellLogo, 'JPEG', 10, 10, 40, 17);
    // // autoTable(doc, {
    // //   margin: { top: 20, bottom: 10 },
    // //   headStyles: headStyles_,
    // //   head: [[`MAJOR TREE FOUND IN ${councilName}:-`]],
    // // });
    // doc.setFont(undefined, 'bold');
    // doc.setFontSize(15);
    // doc.text(`Major tree found in ${councilName}:-`, 20, 40);
    // doc.setFont(undefined, 'normal');
    // doc.setFontSize(12);
    // doc.text(`TOP 15 TREES COUNT`, 20, 50);
    // const majorTreeGraph = await Html2canvas(canvas[2]);
    // const treeLessGraph = await Html2canvas(canvas[3]);
    // doc.addImage(majorTreeGraph.toDataURL('image/png'), 'JPEG', 10, 55, 180, 100);
    // doc.setFont(undefined, 'bold');
    // doc.setFontSize(15);
    // doc.text(`Trees less than 10 in number found in Katol municipal council.`, 20, 165);
    // doc.setFont(undefined, 'normal');
    // doc.setFontSize(12);
    // doc.text(`TREES LESS THAN 10`, 20, 175);
    // doc.addImage(treeLessGraph.toDataURL('image/png'), 'JPEG', 10, 180, 180, 100);
    // footer();


    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`Different Tree Species found in ${councilName} : -`, 15, 38);
    // autoTable(doc, {
    //   margin: { top: 35, bottom: 10 },
    //   headStyles: headStyles_,
    //   head: [[`Different Tree Species found in ${councilName} : -`]],
    // });
    autoTable(doc, {
      headStyles: { fillColor: [129, 130, 131] },
      alternateRowStyles: { fillColor: [247, 247, 247] },
      tableLineColor: [129, 130, 131],
      tableLineWidth: 0.1,
      margin: { top: 50, bottom: 10 },
      head: [['Sr. No', 'LOCAL NAME', 'SCIENTIFIC NAME','Tree Type', 'FAMILY', 'USES', 'ORIGIN']],
      body: differentSpeciesList,
    });

    footer()

    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`Rare Plant Species found in ${councilName} : -`, 15, 38);
    // autoTable(doc, {
    //   margin: { top: 35, bottom: 10 },
    //   headStyles: headStyles_,
    //   head: [[`Different Tree Species found in ${councilName} : -`]],
    // });
    autoTable(doc, {
      headStyles: { fillColor: [129, 130, 131] },
      alternateRowStyles: { fillColor: [247, 247, 247] },
      tableLineColor: [129, 130, 131],
      tableLineWidth: 0.1,
      margin: { top: 50, bottom: 10 },
      head: [['Sr. No', 'LOCAL NAME', 'SCIENTIFIC NAME','Tree Type', 'FAMILY', 'USES', 'ORIGIN']],
      body: rareSpeciesList,
    });

    footer();

    // doc.addPage();
    // doc.setDrawColor(0);
    // doc.setFillColor(255, 255, 255);
    // doc.rect(5, 5, 200, 287, 'FD');
    // header()
    // // autoTable(doc, {
    // //   margin: { top: 30 },
    // //   headStyles: headStyles_,
    // //   head: [['SCOPE OF PLANTATION: -']],
    // // });

    // doc.setFont(undefined, 'bold');
    // doc.setFontSize(16);
    // doc.text(70, 35, 'SCOPE OF PLANTATION: -');
    // // doc.text(`SCOPE OF PLANTATION: -`, 15, 40);
    // doc.setFont(undefined, 'normal');
    // doc.setFontSize(12);
    // doc.text(
    //   `We are hereby suggesting local native trees for tree plantations, which will help increasebiodiversity,`,
    //   15,
    //   50
    // );
    // doc.text(`as they have high mortality rate. As given below,`, 15, 55);
    // autoTable(doc, {
    //   headStyles: { fillColor: [129, 130, 131] },
    //   alternateRowStyles: { fillColor: [247, 247, 247] },
    //   tableLineColor: [129, 130, 131],
    //   tableLineWidth: 0.1,
    //   margin: { top: 65 },
    //   head: scopeHeader,
    //   body: scopeBody,
    // });
    // footer();

    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(`REFERENCES: -`, 80, 37);
    // autoTable(doc, {
    //   margin: { top: 30, bottom: 10, left: 80 },
    //   headStyles: headStyles_,
    //   head: [[`REFERENCES: -`]],
    // });
    doc.setFontSize(12);
    doc.setFont(undefined, 'normal');
    doc.text('1. Almeida, M.R. (1996). Flora of Maharashtra—Vol. 1. Blatter Herbarium, St. Xavier’s College,', 20, 50);
    doc.text('Mumbai.', 20, 55);
    doc.text('2. Almeida, M.R. (1998). Flora of Maharashtra—Vol. 2. Blatter Herbarium, St. Xavier’s College,', 20, 65);
    doc.text('Mumbai.', 20, 70);
    doc.text('3. Almeida, M.R. (2001). Flora of Maharashtra—Vol. 3 a & b. Blatter Herbarium, St.Xavier’s ', 20, 80);
    doc.text('College, Mumbai.', 20, 85);
    doc.text('4. Almeida, M.R. (2003). Flora of Maharashtra—Vol. 4a. Blatter Herbarium, St. Xavier’s College,', 20, 95);
    doc.text('Mumbai.', 20, 100);
    doc.text('5. Almeida, M.R. (1996). Flora of Maharashtra—Vol. 1. Blatter Herbarium, St. Xavier’s College,', 20, 110);
    doc.text('Mumbai.', 20, 115);
    doc.text('6. Almeida, M.R. (1998). Flora of Maharashtra—Vol. 2. Blatter Herbarium, St. Xavier’s College,', 20, 120);
    doc.text('Mumbai.', 20, 125);
    doc.text('7. Almeida, M.R. (2001). Flora of Maharashtra—Vol. 3 a & b. Blatter Herbarium, St. Xavier’s', 20, 135);
    doc.text('College, Mumbai.', 20, 140);
    doc.text(
      '8. Almeida, M.R. (2003). Flora of Maharashtra—Vol. 4a. Blatter Herbarium, St. Xavier’s College,',
      20,
      150
    );
    doc.text('Mumbai.', 20, 155);
    doc.text('9. Almeida, M.R. (2009). Flora of Maharashtra—Vol. 5a & b. Blatter Herbarium, St. Xavier’s', 20, 165);
    doc.text('College, Mumbai.', 20, 170);
    doc.text('10. Websites -', 20, 180);
    doc.text('https://www.wikipedia.org/', 20, 185);
    doc.text('http://www.theplantlist.org/', 20, 190);
    doc.text('http://www.ipni.org/', 20, 195);
    doc.text(
      '11. Almeida, M.R. (1996). Flora of Maharashtra—Vol. 1. Blatter Herbarium, St. Xavier’s College,',
      20,
      205
    );
    doc.text('Mumbai.', 20, 210);
    doc.text(
      '12. Almeida, M.R. (1998). Flora of Maharashtra—Vol. 2. Blatter Herbarium, St. Xavier’s College,',
      20,
      220
    );
    doc.text('Mumbai.', 20, 225);
    doc.text('13. Almeida, M.R. (2001). Flora of Maharashtra—Vol. 3 a & b. Blatter Herbarium, St. Xavier’s', 20, 235);
    doc.text('College, Mumbai.', 20, 240);
    doc.text(
      '14. Almeida, M.R. (2003). Flora of Maharashtra—Vol. 4a. Blatter Herbarium, St. Xavier’s College,',
      20,
      250
    );
    doc.text('Mumbai.', 20, 255);
   
    // doc.text('http://www.theplantlist.org/', 20, 285);
    // doc.text('http://www.ipni.org/', 20, 290);

    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text(`RECOMMENDATIONS: -`, 73, 37);
    // autoTable(doc, {
    //   margin: { top: 30, bottom: 10, left: 75 },
    //   headStyles: headStyles_,
    //   head: [[`RECOMMENDATIONS: -`]],
    // });
    doc.setFontSize(12);
    doc.setFont(undefined, 'normal');
    doc.text(
      '1. Subabhul & Vilayti Kikar are invasive tree species & there is increase in their density. Which is ',
      20,
      50
    );
    doc.text('harmful to the local biodiversity. Hence, we suggest to avoid new plantation of this species.', 20, 55);
    doc.text(
      '2. The tree density is less within the residential area of the city compared to extension areas. So ',
      20,
      65
    );
    doc.text('we need to plant new trees in such areas. Which will help is control the air pollution &', 20, 70);
    doc.text('temperature.', 20, 75);
    doc.text('3. We observed that there are less or no trees on the roadsides. We suggest to plant more trees ', 20, 85);
    doc.text('on such roads.', 20, 90);
    doc.text(
      '4. There are increase in number of vehicles in Katol city which leads to air pollution, hence we ',
      20,
      100
    );
    doc.text('suggest to plant trees which are dust resistance such as Haldu, Chinch, Amba, Shisam,', 20, 105);
    doc.text('Bahavaetc.', 20, 110);
    doc.text(
      '5. Digitization of the tree census data and making it available online, will be helpful for regular ',
      20,
      120
    );
    doc.text(
      'maintaining and monitoring the data. It will also help in connecting citizens to the greenness ',
      20,
      125
    );
    doc.text('of the city. Also, it will help controlling tree felling by involving citizen.', 20, 130);
    footer();
    doc.addPage();
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    header()
    doc.setFont(undefined, 'bold');
    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`Tree Varities found in ${councilName}`, 30, 40);
   
    doc.autoTable({
      html: '#mytable',
      bodyStyles: { minCellHeight: 90 },
      margin: { top: 50 },
      didDrawCell(data) {
        if (
          (data.column.index === 0 && data.cell.section === 'body') ||
          (data.column.index === 1 && data.cell.section === 'body')
        ) {
          const td = data.cell.raw;
          const img = td.getElementsByTagName('img')[0];
          const dim = data.cell.height - data.cell.padding('vertical');
          doc.addImage(img.src, data.cell.x, data.cell.y, dim, dim);
        }
      },
    });


    // doc.autoTable({
    //   html: '#mytable',
    //   bodyStyles: {minCellHeight: 15},
    //   didDrawCell: function(data) {
    //     if (data.column.index === 5 && data.cell.section === 'body') {
    //        var td = data.cell.raw;
    //        var img = td.getElementsByTagName('img')[0];
    //        var dim = data.cell.height - data.cell.padding('vertical');
    //        var textPos = data.cell.textPos;
    //        doc.addImage(img.src, textPos.x,  textPos.y, dim, dim);
    //     }
    //   }
    // });

    doc.text('Thank you…', 160, 260);
    
    footer();
    doc.save(`${councilName}.pdf`);
    dispatch(ShowLoadingButton(false))
    /* eslint-enable no-await-in-loop */
  };
  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter',
          }),
        }}
      >
        <Grid item xs={4} justifyContent="flex-end">
          <TextField
            select
            id="council"
            name="council"
            label="Select Council*"
            value={coucilId}
            displayEmpty
            style={{ width: '180px' }}
            // onChange={handleRoleChange}
            onChange={(e) => {
              handleCouncilChange(e);
              formik.handleChange(e);
            }}
            placeholder="Select council*"
            // defaultValue={data? data.council: ""}
            renderValue={(selected) => {
              if (selected?.length === 0) {
                return <em>Select Council*</em>;
              }
            }}
            error={Boolean(touched.council && errors.council)}
            helperText={touched.council && errors.council}
            // {...getFieldProps("council")}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            <MenuItem disabled value="">
              <em>Select Councils</em>
            </MenuItem>
            {council?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {coucilId ? (
          <>
            <Grid item xs={4}>
              <TextField
                id="date"
                // label="Date Of Birth"
                type="date"
                label="From Date*"
                placeholder="From Date*"
                // defaultValue={councilArr?.project_start_date}
                style={{ width: '90.5%', marginLeft: '15px' }}
                // className={classes.textField}
                error={Boolean(touched.fromDate && errors.fromDate)}
                helperText={touched.fromDate && errors.fromDate}
                {...getFieldProps('fromDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: todayDate }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="date"
                // value={toDate}
                type="date"
                label="To Date*"
                placeholder="To Date*"
                // defaultValue={project_end_date}
                style={{ width: '90.5%', marginLeft: '15px' }}
                // className={classes.textField}
                error={Boolean(touched.toDate && errors.toDate)}
                helperText={touched.toDate && errors.toDate}
                {...getFieldProps('toDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{ max: todayDate }}
              />
            </Grid>
            <Grid item sm={4}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                style={{ marginLeft: '15px', marginTop: 2, height: 50, width: 150 }}
              >
                View Report
              </Button>
            </Grid>
                {showReport?
                
            <Grid item sm={4}>
              {!coucilId ? (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{ marginLeft: '15px', marginTop: 2, height: 50, width: 150, backgroundColor: '#DF6526' }}
                >
                  Export Report
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={exportPdf}
                  style={{ marginLeft: '15px', marginTop: 5, height: 50, width: 150, backgroundColor: '#DF6526' }}
                >
                  Export Report
                </Button>
              )}
            </Grid>:null}
          </>
        ) : (
          <></>
        )}
        <TreeVarietyTable />
        <Grid container justifyContent="flex-end">
          {/* {(callType === "BaseColor")?(
         <h5 style={{marginTop: 10}}>Please select council to get  base color data</h5> 
         ):null} */}
          {/* <Grid item sm={4}>
            <Button variant="contained" onClick={handleSubmit} style={{marginLeft: 30, marginTop: 5, backgroundColor: "#008000", height: 50, width: 100}}  >Get Data</Button>
            </Grid> */}
        </Grid>
        <Grid container>
          {/* <div ref={inputRef}>

        <Barchart  id="barChart123" />
        </div> */}
        </Grid>
      </RootStyle>
    </>
  );
}
