import React, { useEffect } from 'react';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
// import MenuItem from '@mui/material/MenuItem';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, Pagination, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { AddCZWToTeam } from '../../../actions/TeamsAction';
import { ShowLoader , ShowLoadingButton} from '../../../actions/CommonAction';
import { GetActiveCouncil } from '../../../actions/CouncilAction';
import { GetZones, GetActiveZones, GetActiveZonesByCouncilId } from '../../../actions/ZonesAction';
import { GetWards, GetActiveWards, GetActiveWardsByCouncilId } from '../../../actions/WardsActions';
import warningSound from '../../../Assets/warning_sound.mp3';
import TreeWardWiseToolbar from '../../../sections/@dashboard/tree-cutting/TreeWardWiseToolbar';
import { GetCensusTreeByWardOrPropertyNumber } from '../../../actions/TreeCuttingPermissionAction';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  
}));

const Input = styled('input')({
  display: 'none',
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CreateTreeCuttingPermissionDialog(props) {
  
  const dispatch = useDispatch();
  
  const CouncilNameValue = [
    {
      value: 'liberty Council',
      label: 'Liberty Council',
    },
    {
      value: 'Board of Eternity',
      label: 'Board of Eternity',
    },
    {
      value: 'Living Council',
      label: 'Living Council',
    },
  ];
  const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];
  
  const { isOpen, data, isOpenConfirm,teamId,selectedTreeList } = props;
  const [open, setOpen] = React.useState(false);
  const [gender, setGender] = React.useState('');
  const [councilName, setCouncilName] = React.useState('');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [ZoneName, setZoneName] = React.useState([]);
  const [WardName, setWardName] = React.useState([]);
  const [topModalOpen, setTopModalOpen] = React.useState(false);
  const [reqObj, setReqObj] = React.useState(null)
  const [id, setId] = React.useState(null)
  const [showSubMenu, setShowSubMenu] = React.useState(false)
  const [showInitial, setShowInitial] = React.useState(false)
  const [wardPropertyWiseFilter, setWardPropertyWiseFilter] = React.useState(false)
  const [treeNumberList, setTreeNumberList] = React.useState("TSJS-0001")
  const [fromTreeNumber, setFromTreeNumber] = React.useState("")
  const [toTreeNumber, setToTreeNumber] = React.useState("")
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showList, setShowList] = React.useState(true);
  const [allSelected, setAllSelected] = React.useState(false);
  const [treeList, setTreeList] = React.useState([]);
  
  const {
    council,
    zones,
    wards,
    activeZonesByCID,
    activeWardsByCID,
    assignCWZToTeamLog,
    deleteCWZFromteamLog,
    showLoader,
    alerts,
    showLoadingButton,
    pageInfo,
    censusTree,
    censusTreePageInfo

  } = useSelector((state) => ({
    council:state.council.activeCouncil,
    zones:state.zones.activeZones,
    wards:state.wards.activeWards,
    activeWardsByCID:state.wards.activeWardsByCID,
    activeZonesByCID:state.zones.activeZonesByCID,
    assignCWZToTeamLog:state.teams.assignCWZToTeamLog,
    deleteCWZFromteamLog:state.teams.deleteCWZFromteamLog,
    showLoader: state.common.showLoader,
    alerts: state.alerts,
    showLoadingButton: state.common.showLoadingButton,
    pageInfo: state.deniedEntry.pageInfo,
    censusTree: state.treeCutting.censusTree,
    censusTreePageInfo: state.treeCutting.censusTreePageInfo
    
    
  }));

  const TREE_LIST = ["TMSD-0001","TMSD-0002","TMSD-0003","TMSD-0004","TMSD-0005","TMSD-0006","TMSD-0007","TMSD-0008","TMSD-0009","TMSD-0010"]


// console.log("assignczw_showLoadingButton",showLoadingButton);
//   console.log("showLoadingButton", showLoadingButton)

  useEffect(()=>{
    
  },[])

  useEffect(()=>{
    if(data){
      setShowSubMenu(true);
      checkAllSelected()
    }
  },[data])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShowList(false);
    
  };

  const audio = new Audio(warningSound);

  const firstRun = React.useRef(true);
  useEffect(()=>{
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setShowInitial(false);
    props.handleClose()
  },[assignCWZToTeamLog])


  
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleCouncilName = (event) => {
    dispatch(GetActiveZonesByCouncilId(1,event.target.value))
    dispatch(GetActiveWardsByCouncilId(1,event.target.value))
    setShowSubMenu(true);
    setCouncilName(event.target.value);
    setShowInitial(true)
  };
  const handleConfirmationDialogClick = () => {
    setOpen(open)
  }
  const handleClose = () => {
    console.log("HANDLE CLOSE CALLED");
    audio.loop = false;
    setShowInitial(false);
    props.handleClose();
  };
  const handleZoneChange = (event) => {
    setZoneName(event.target.value);
  };

  const handleWardChange = (event) => {
    setWardName(event.target.value);
  };

  const DistrictsSchema = Yup.object().shape({
    council: Yup.string().required('Council is required'),
    zones: Yup.string().required('Zone is required'),
    wards: Yup.string().required('Ward is required'),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      council:data? data.council_id : "",
      // eslint-disable-next-line no-nested-ternary
      zones:data?showInitial?"":data.zone_id:"",
      // eslint-disable-next-line no-nested-ternary
      wards:data?showInitial?"": data.ward_id:""
    },
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {
      handleTopModalClose();

      if(data){
        setReqObj({
          "name":value.districts,
          "state_id":value.state
        })
  
        setId(data.id);
      }

      else {

        setReqObj({
          "team_id": teamId,
          "council_id": value.council,
          "zone_id": value.zones,
          "ward_id": value.wards
        })
      }
      

     
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps,handleChange } = formik;
  const useStyles = makeStyles({
    
    icon: {
        fill: '#214C50',
    },
   
})
const classes = useStyles()

  const handleTopModalClose = () => {
    setTopModalOpen(!topModalOpen)
  }


  const handlewardPropertyWiseChange = (value) => {
    setWardPropertyWiseFilter(value)
    console.log("HANDLE WARD PROPERTY WISE CHANGE")
    if(value){
      dispatch(GetCensusTreeByWardOrPropertyNumber(council[0].id,props.wardId,props.propertyId,1,rowsPerPage))
      setPage(1)
    }
    else {
      dispatch(GetCensusTreeByWardOrPropertyNumber(council[0].id,props.wardId,null,1,rowsPerPage))
      setPage(1)
    }
    // dispatch(
    //   GetWorkReports(reportType, undefined, undefined, undefined, fromDate, toDate,workType,value, 1, 10),
    // );
    // setPage(1)
    // dispatch(ShowLoader(true));
  }

  const handleWardFilterSubmit = () => {
    console.log("hii")
  }

  const handleFromTreeNumberChange = (e) => {
    setFromTreeNumber(e.target.value)
  }

  const handleToTreeNumberChange = (e) => {
    setToTreeNumber(e.target.value)
  }

  const handleSelectedChange = (e,value) => {
    if(e.target.checked===true){
      props.handleChangeSelectedTreeList("add",[value])
    }
    else {
      props.handleChangeSelectedTreeList("delete",[value])
    }

    checkAllSelected();
  }

  const checkAllSelected = () => {
    let allFound = true;
    censusTree?.map((value,index)=>{
      if(selectedTreeList.some(treeNumber => treeNumber.census_tree_id === value.id)){
        allFound = false
      }
      return null;
    })

    if(allFound){
      setAllSelected(true)
    }
    else {
      setAllSelected(false);
    }
  }

  const handleAllSelectChange = (e) => {
    if(e.target.checked===true){
      const newTreeList =[]
      censusTree?.map((value,index)=>{
        if(!selectedTreeList.some(treeNumber => treeNumber.census_tree_id === value.id)){
          newTreeList.push(value)
        }
        return null;
        
      })
      props.handleChangeSelectedTreeList("add",newTreeList)
      setAllSelected(true)
    }
    else {
      const newTreeList =[]
      censusTree?.map((value,index)=>{
        if(selectedTreeList.some(treeNumber => treeNumber.census_tree_id === value.id)){
          newTreeList.push(value)
        }
        return null;
      })
      props.handleChangeSelectedTreeList("delete",newTreeList)
      setAllSelected(false)
    }
  }

  console.log("SELECTED TREE LIST",selectedTreeList);

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open max-width dialog
      </Button> */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        onClose={handleClose}
        // onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // width: "100%",
              minWidth: "900px",  // Set your width here
              minHeight: "500px"
            },
          },
        }}
      >
        <BootstrapDialogTitle onClose={handleClose}>New Tree Condition Permission</BootstrapDialogTitle>
        <Divider/>
        <DialogContent>
        <Grid container spacing={1}>
         <TreeWardWiseToolbar
            handleWardWiseSubmit={() => handleWardFilterSubmit()}
            numSelected={0}
            placeHolder={'Search here...'}
            wardPropertyWiseFilter= {wardPropertyWiseFilter}
            handlewardPropertyWiseChange = {(e)=>handlewardPropertyWiseChange(e)}
            treeNumberList={treeNumberList}
            fromTreeNumber={fromTreeNumber}
            toTreeNumber={toTreeNumber}
            handleFromTreeNumberChange={(e)=>handleFromTreeNumberChange(e)}
            handleToTreeNumberChange={(e)=>handleToTreeNumberChange(e)}
          />
          </Grid>
          <hr style={{ color: '#000', opacity: 0.3, marginTop: '5px' }} />
         
        <FormControlLabel 
        control={<Checkbox checked={allSelected} onChange={(e)=>handleAllSelectChange(e)}  />} label="Select All" 
        sx={{ justifyContent: 'right', display: 'flex', mt: 1, mb: 1 }} 
        />

        <Grid container spacing={1}>
          {censusTree?.map((value,index)=>{
            return <FormControlLabel 
            control={<Checkbox checked={selectedTreeList.some(treeNumber => treeNumber.census_tree_id === value.id)} onChange={(e)=>handleSelectedChange(e,value)} />} label={value.tree_number} 
            sx={{ justifyContent: 'right', display: 'flex', mt: 1, mb: 1 }} 
            />
          })}
        </Grid>
        
        </DialogContent>
        <Divider/>
        
        <DialogActions>
       
            <Pagination
              count={showList ? censusTreePageInfo.last_page : 0}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
        </DialogActions>
      </Dialog>
      </div>
  );
}
