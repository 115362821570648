import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import useResponsive from '../hooks/useResponsive';
import Server from "../api";
import { SetNewAlert } from '../actions/AlertActions';


export default function PermissionLetter() {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [data, setData] = useState({});
  const { applicationId } = useParams();
  const dispatch = useDispatch();

  const downloadPDF = () => {
    const input = document.getElementById('content-to-print'); // The HTML content you want to convert t

    html2canvas(input, {
      scale: 2, // Adjust scale for better quality
      useCORS: true, // Handle cross-origin images
      scrollY: -window.scrollY, // Adjust for page scroll
      windowWidth: document.documentElement.scrollWidth, // Ensure full width
      windowHeight: document.documentElement.scrollHeight, // Ensure full height
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new JsPDF('p', 'mm', 'a4'); // Create a new PDF instance

      // Calculate image dimensions for the PDF
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width; // Calculate height proportional to width

      let heightLeft = imgHeight;
      let position = 0;

      // Add image to PDF, handling page breaks
      while (heightLeft > 0) {
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, Math.min(imgHeight, pageHeight));
        heightLeft -= pageHeight;
        position -= pageHeight;

        // Add new page if content is longer than one page
        if (heightLeft > 0) {
          pdf.addPage();
        }
      }
      pdf.save('permission-letter.pdf');
    });
  };

  const mdUp = useResponsive('up', 'md');

  useEffect(async () => {
    if(applicationId) {
      try {
        const response = await Server.get(`/api/get-for-permission-letter/${applicationId}`);
        console.log("RESPONSE",response.data);
        setData(response.data)
        setIsDataLoaded(true);
      } catch(e) {
        console.log("E>RESPONSE")
        dispatch(SetNewAlert({
          msg: e.response.data.message,
          alertType: "danger",
        }));
      }
      
    }
    else {
      dispatch(SetNewAlert({
        msg: "Invalid Application Id",
        alertType: "danger",
      }));
    }
    
  }, []);

  useEffect(() => {
    if (isDataLoaded && isImageLoaded) {
      // window.print()
    }
  }, [isDataLoaded, isImageLoaded]);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <div>
      {data && isDataLoaded && 
      <div id="content-to-print" style={{ padding: '30px' }}>
        {mdUp ? (
          <div>
            <div style={{ marginLeft: '-30px', marginRight: '-30px' }}>
              <img src={data?.letterhead} alt="QR Code" style={{ width: '100%', height: '200px' }}  onLoad={handleImageLoad} />
            </div>
            {/* <div style={{ textAlign: 'center', paddingBottom: '30px' }}>
              <h2 style={{ margin: 0, fontSize: '24px' }}>शेगाव नगरपरिषद, शेगाव</h2>
              <p style={{ margin: 0 }}>
                जिल्हा: बुलढाणा &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; पिनकोड: 444203
              </p>
              <p style={{ margin: 0 }}>दूरध्वनी क्रमांक: 07265221595</p>
              <p style={{ margin: 0 }}>ई-मेल: co.shegaon@gmail.com</p>
              <h3 style={{ margin: '10px 0 0 0' }}>मिळकत विभाग</h3>
            </div> */}

            <div style={{ justifyContent:'space-between',display:'flex', marginTop: '30px', paddingBottom: '20px' }}>
              {/* <p style={{ margin: 0 }}>
                <strong>पत्र क्रमांक:</strong> जा.क्र.न.प.शे/आरखा.वि./&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/2024
              </p> */}
              <p style={{ margin: 0 }}>
                <strong>{data?.application_number}</strong> 
              </p>
              <p style={{ margin: 0 }}>
                <strong>दिनांक:</strong> {data?.permission_date}
              </p>
            </div>
            <div style={{ marginTop: '30px', paddingBottom: '20px' }}>
              <p>प्रति,</p>
              <p>{data?.applicant_name}</p>
              <p>{data?.address}</p>
              <br />
              <p>
                <strong>विषय:</strong> झाडे तोडण्याची परवानगी मिळणेबाबत
              </p>
              <p>
                <strong>संदर्भ:</strong> 1. आपले दिनांक {data?.application_date} रोजीचा अर्ज
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. जाहीरनामा दिनांक {data?.proclamation_date}
              </p>
              <br />
              <p>
              &nbsp;&nbsp;&nbsp;&nbsp;वरील संदर्भांकित विषयाच्या अनुषंगाने कळविण्यात येते की, संदर्भित पत्रात नमूद केल्यानुसार झाड तोडण्यास
                महाराष्ट्र नागरी क्षेत्र झाडाची जतन व संरक्षण अधिनियम १९७५ चे कलम ८ (३) तसेच शासन निर्णय क्र
                टीपीएस/१८१५/सीआर-२१६/युडी-१३ / दि ११/७/२०१७ अन्वये खालील प्रमाणे शर्ती अटी वर निर्देशित केलीली व
                अर्जदाराच्या अर्जानुसार चिन्हांकित केल्यापैकी खालील झाडे तोडण्याची परवानगी देण्यात येत आहे.
              </p>
              <p>
              <ol style={{marginTop: '10px', paddingLeft: '20px', }}>
                {data?.approved_trees?.map((value,index)=>{
                    return <li><ul style={{marginTop: '10px', paddingLeft: '20px', }}>
                    <li><strong>झाडाचे नाव : </strong>{value.tree_name}</li>
                    <li><strong>झाडाचे वय : </strong>{value.tree_age} </li>
                    </ul></li>
                })}
              
              </ol>
              </p>
            </div>
                <strong>शर्ती अटी :</strong>
            <ol style={{ marginTop: '10px', paddingLeft: '20px', paddingBottom: '20px' }}>
              <li>किमान {data?.total_trees_to_be_planted} वृक्ष लागवड करून त्याचे संगोपन व संवर्धन करण्यात यावे</li>
              <li>
                सदर झाड आपल्या स्वखर्चाने काढण्यात यावे , कोणत्याही प्रकारची हानी होणार नाही याची दक्षता घ्यावी ,
                कोणत्याही प्रकारची हानी झाल्यास नगर परिषद जबाबदार राहणार नाही
              </li>
              <li>
                वृक्ष अधिकारी यांनी स्थळ निरीक्षण केल्यानुसार व निदर्शनानुसारच झाड किंवा फांद्या तोडण्यात यावी , त्या
                पेक्षा जास्त झाडे तोडल्यास आपणावर नियमानुसार कारवाही करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
              <li>झाडाबाबत किंवा मालकी हक्काबाबत वाद निर्माण झाल्यास परवानगी रद्द समझण्यात यावी</li>
              <li>
                झाड किंवा फांद्या तोडताना विद्युत वाहक तार असल्यास त्यासाठी विद्युत विभागाची परवानगी घेऊनच तोडण्याची
                कारवाही करावी{' '}
              </li>
              <li>कायदा व सुव्यवस्था अबाधित राहील याची दक्षता घ्यावी </li>
              <li>वरील अटी शर्तीचे उल्लंघन केल्यास सदर परवानगी रद्द समझण्यात यावी </li>
              <li>
                आत क्र १ चे पालन करून दार ६ महिन्याला अहवाल सादर करावा , अहवाल सादर न केल्यास सुरक्षा अमानत रक्कम जप्त
                करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
            </ol>

            <div style={{ textAlign: 'right', marginTop: '40px' }}>
              <p>मुख्याधिकारी,</p>
              <p>{data?.council}</p>
            </div>
          </div>
        ) : (
          <div style={{ fontSize: '10px' }}>
          <div style={{ marginLeft: '-30px', marginRight: '-30px', marginTop: '-10px' }}>
              <img src={data?.letterhead} alt="QR Code" style={{ width: '100%', height: '120px' }}  onLoad={handleImageLoad} />
            </div>
            {/* <div style={{ textAlign: 'center', paddingBottom: '20px' }}>
              <h2 style={{ margin: 0, fontSize: '18px' }}>शेगाव नगरपरिषद, शेगाव</h2>
              <p style={{ margin: 0 }}>
                जिल्हा: बुलढाणा &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; पिनकोड: 444203
              </p>
              <p style={{ margin: 0 }}>दूरध्वनी क्रमांक: 07265221595</p>
              <p style={{ margin: 0 }}>ई-मेल: co.shegaon@gmail.com</p>
              <h3 style={{ margin: '10px 0 0 0' }}>मिळकत विभाग</h3>
            </div> */}

            <div style={{ justifyContent:'space-between',display:'flex', marginTop: '20px', paddingBottom: '20px' }}>
              {/* <p style={{ margin: 0 }}>
                <strong>पत्र क्रमांक:</strong> जा.क्र.न.प.शे/आरखा.वि./&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/2024
              </p> */}
              <p style={{ margin: 0 }}>
                <strong>{data?.application_number}</strong> 
              </p>
              <p style={{ margin: 0 }}>
              <strong>दिनांक:</strong> {data?.permission_date}
              </p>
            </div>
            <div style={{ marginTop: '20px', paddingBottom: '20px' }}>
              <p>प्रति,</p>
              <p>{data?.applicant_name}</p>
              <p>{data?.address}</p>
              <br />
              <p>
                <strong>विषय:</strong> झाडे तोडण्याची परवानगी मिळणेबाबत
              </p>
              <p>
              <strong>संदर्भ:</strong> 1. आपले दिनांक {data?.application_date} रोजीचा अर्ज
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 2. जाहीरनामा दिनांक {data?.proclamation_date}
              </p>
              <br />
              <p>
              &nbsp;&nbsp;&nbsp;&nbsp;वरील संदर्भांकित विषयाच्या अनुषंगाने कळविण्यात येते की, संदर्भित पत्रात नमूद केल्यानुसार झाड तोडण्यास
                महाराष्ट्र नागरी क्षेत्र झाडाची जतन व संरक्षण अधिनियम १९७५ चे कलम ८ (३) तसेच शासन निर्णय क्र
                टीपीएस/१८१५/सीआर-२१६/युडी-१३ / दि ११/७/२०१७ अन्वये खालील प्रमाणे शर्ती अटी वर निर्देशित केलीली व
                अर्जदाराच्या अर्जानुसार चिन्हांकित केल्यापैकी खालील झाडे तोडण्याची परवानगी देण्यात येत आहे.
              </p>
              <ol style={{ marginTop: '10px',paddingLeft: '10px' }}>
              {data?.approved_trees?.map((value,index)=>{
                    return <li><ul style={{marginTop: '10px', paddingLeft: '10px', }}>
                    <li><strong>झाडाचे नाव : </strong>{value.tree_name}</li>
                    <li><strong>झाडाचे वय : </strong>{value.tree_age} </li>
                    </ul></li>
                })}
              </ol>
            </div>
            <strong>शर्ती अटी :</strong>
            <ol style={{ marginTop: '10px', paddingLeft: '10px', paddingBottom: '20px' }}>
            <li>किमान {data?.total_trees_to_be_planted} वृक्ष लागवड करून त्याचे संगोपन व संवर्धन करण्यात यावे</li>
              <li>
                सदर झाड आपल्या स्वखर्चाने काढण्यात यावे , कोणत्याही प्रकारची हानी होणार नाही याची दक्षता घ्यावी ,
                कोणत्याही प्रकारची हानी झाल्यास नगर परिषद जबाबदार राहणार नाही
              </li>
              <li>
                वृक्ष अधिकारी यांनी स्थळ निरीक्षण केल्यानुसार व निदर्शनानुसारच झाड किंवा फांद्या तोडण्यात यावी , त्या
                पेक्षा जास्त झाडे तोडल्यास आपणावर नियमानुसार कारवाही करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
              <li>झाडाबाबत किंवा मालकी हक्काबाबत वाद निर्माण झाल्यास परवानगी रद्द समझण्यात यावी</li>
              <li>
                झाड किंवा फांद्या तोडताना विद्युत वाहक तार असल्यास त्यासाठी विद्युत विभागाची परवानगी घेऊनच तोडण्याची
                कारवाही करावी{' '}
              </li>
              <li>कायदा व सुव्यवस्था अबाधित राहील याची दक्षता घ्यावी </li>
              <li>वरील अटी शर्तीचे उल्लंघन केल्यास सदर परवानगी रद्द समझण्यात यावी </li>
              <li>
                आत क्र १ चे पालन करून दार ६ महिन्याला अहवाल सादर करावा , अहवाल सादर न केल्यास सुरक्षा अमानत रक्कम जप्त
                करण्यात येईल याची नोंद घ्यावी{' '}
              </li>
            </ol>

            <div style={{ textAlign: 'right', marginTop: '30px' }}>
              <p>मुख्याधिकारी,</p>
              <p>{data?.council}</p>
            </div>
          </div>
        )}
      </div>
      }
      {data && isDataLoaded && <center style={{paddingBottom:'10px'}}><button className='print-btn' style={{padding:'5px',cursor:'pointer',minWidth:'80px'}} onClick={()=>window.print()}>Print</button></center>}
    </div>
  );
}
