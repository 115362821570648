import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';

import * as Yup from 'yup';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  Box,
  Drawer,
  TextField,
  Grid,
  MenuItem,
} from '@mui/material';
import moment from 'moment';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';

import { UserListHead } from '../../sections/@dashboard/user';
import TreeCensusMenu from '../../sections/@dashboard/tree/TreeCensusMenu';

import {
  exportCensusReportsApi
} from '../../actions/TreeCensusAction';
import DeleteConfirmationDialog from '../../components/DialogBox/DeleteConformationDialog';
import StatusPendngButton from '../../components/statusbutton/StatusPendngButton';
import StatusApprovedButton from '../../components/statusbutton/StatusApprovedButton';
import StatusUnapprovedButton from '../../components/statusbutton/StatusUnapprovedButton';
import FullLoader from '../../components/Loader/FullLoader';
import { ShowLoader } from '../../actions/CommonAction';
import { GetUsersByRoleArray, GetUsersByRoleID } from '../../actions/UserAction';
import { GetExcelWorkReportCensusTreeHistory, GetWorkReportCensusTreeHistory } from '../../actions/WorkReportAction';

// import JWTServer  from '../../api/withJWTServer'
import './Census.css';
import WorkReportTreeDetailDialog from '../../components/DialogBox/tree-data/WorkReportTreeDetailDialog';
import WorkReportTreeCensusMenu from '../../sections/@dashboard/tree/WorkReportTreeCensusMenu';





// import { HandleExceptionWithSecureCatch } from "../../actions/CombineCatch";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'treeNumber', label: 'Tree Number', alignRight: false },
  { id: 'treeName', label: 'Tree Name', alignRight: false },
  { id: 'addedBy', label: 'Added By', alignRight: false },
  { id: 'addedOn', label: 'Added On', alignRight: false },
  { id: 'age', label: 'Tree Age', alignRight: false },
  { id: 'locationAccuracyNeeded', label: 'Location Accuracy Captured', alignRight: false },
  { id: 'QCStatus', label: 'QC Status', alignRight: false },
  { id: 'isReferredToExpert', label: 'Is Referred To Expert?', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

export default function WorkReportTreeHistory() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formDate, setFromDate] = React.useState();
  const [toDate, setToDate] = React.useState();
  const [addedBy, setAddedBy] = React.useState('');
  const [countType, setCountType] = React.useState('');
  const [viewCensusDetails, setViewCensusDetails] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  const userPermissions = [];

  const { censusUserId, censusFromDate, censusToDate, treeStatus, dateType } = useParams();

  const [newState, setNewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const {
    workTypeCensusTreeHistory,
    userByRoleID,
    pageInfo,
    loggedUser,
    showLoader,
    excelWorkTypeCensusTreeHistory,
    isReportDownloadable
  } = useSelector((state) => ({

    workTypeCensusTreeHistory: state.workReports.workTypeCensusTreeHistory,
    userByRoleID: state.users.userByRoleID,
    pageInfo: state.workReports.pageInfo,
    loggedUser: state.auth.loggedUser,
    showLoader: state.common.showLoader,
    excelWorkTypeCensusTreeHistory: state.workReports.excelWorkTypeCensusTreeHistory,
    isReportDownloadabletCallLogs: state.treeCensus.isReportDownloadable

  }));
  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  const countTypeList = [
    {
    id: "Total",
    name: "Total"
    },
    {
      id: "Pending",
      name: "Pending"
    },
    {
      id: "Approved",
      name: "Approved"
    },
    {
      id: "Unapproved",
      name: "Unapproved"
    }
  ]
  
  useEffect(()=>{
    dispatch(GetWorkReportCensusTreeHistory(censusUserId,censusFromDate,censusToDate,treeStatus,1,10,"census_user"))
  },[])

  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    dispatch(ShowLoader(false))
  }, [workTypeCensusTreeHistory]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(GetWorkReportCensusTreeHistory(values.userId,values.fromDateForm,values.toDateForm,values.status,newPage,rowsPerPage,"census_user"))
  };

  const [exportButtonPressed, setExportButtonPressed] = useState(false);

  useEffect(() => {
    dispatch(GetUsersByRoleArray(1, [3, 4, 5, 6, 7, 8]));
  },[])

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel()
      setExportButtonPressed(false);
    }
    dispatch(ShowLoader(false));
  }, [isReportDownloadable, excelWorkTypeCensusTreeHistory]);
  const header = ["#", "Zone", "Ward", "Latitude", "Longitude", "Location Accuracy", "Location Type", "Property Type",
    "Property Number", "Owner Name", "Tenant Name", "Address", "Area", "Tree Type", "Name", "Botanical Name", "Girth", "Height", "Canopy", "Condition", "Tree Disease",
    "Plantation Date", "Action Need", "Added By User", "Added On", "Qc Status", "Qc By User", "Qc Date"];
  function DownloadReportExcel() {
    const value1 = [];
    console.log("REPRT",excelWorkTypeCensusTreeHistory);
    excelWorkTypeCensusTreeHistory?.map((option, index) => {
      const value2 = [index + 1]
      value2.push(option?.zone?.name)
      value2.push(option?.ward?.name)
      // value2.push(option.tree_number)
      value2.push(option?.lat)
      value2.push(option?.long)
      // value2.push(option.location)
      value2.push(option.location_accuracy)
      value2.push(option?.location_type?.location_type)
      value2.push(option?.property_type?.property_type)
      value2.push(option?.property?.property_number)
      value2.push(option?.property?.owner_name)
      value2.push(option?.property?.tenant_name)
      value2.push(option?.property?.address)
      value2.push(option?.property?.area)
      value2.push(option?.tree_type?.tree_type)
      value2.push(option?.tree_name?.name)
      value2.push(option?.tree_name?.botanical_name)
      value2.push(option.girth)
      value2.push(option.height)
      value2.push(option.canopy)
      value2.push(option?.tree_condition?.condition)
      value2.push(option?.tree_disease?.tree_disease)
      value2.push(option.plantation_date)
      value2.push(option.action_need)
      value2.push(option?.added_by?.first_name)
      value2.push(option.added_on)
      value2.push(option.qc_status)
      value2.push(option?.qc_by?.first_name)
      value2.push(option.qc_date)



      value1.push(value2)
      return null
    })
    console.log("Value1",value1)
    downloadExcel({
      fileName: `Work Report History`,
      sheet: "Census Report",
      tablePayload: {
        header,
        // accept two different data structures
        body: value1
      },
    });
  }

  const exportCensusReports = () => {

    dispatch(GetExcelWorkReportCensusTreeHistory(values.userId,values.fromDateForm,values.toDateForm,values.status,"census_user"))
    setExportButtonPressed(true);
    dispatch(ShowLoader(true));

  }



  const handleAddedByChange = (event) => {
    setAddedBy(event.target.value);
  };

  const handleCountTypeChange = (event) => {
    setCountType(event.target.value)
  }


  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });

  const FilterSchema = Yup.object().shape({
    userId: Yup.string().required('User Name Required'),
    toDateForm: Yup.string().required('Please select End Date'),
    fromDateForm: Yup.string().required('Please select Start Date'),
    status : Yup.string().required('Tree Status Required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: censusUserId,
      toDateForm: censusToDate,
      fromDateForm : censusFromDate,
      status: treeStatus
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {
      
      // dispatch(ShowLoader(true));
      setNewState({ ...newState, right: false });
      setPage(1);
      dispatch(GetWorkReportCensusTreeHistory(value.userId,value.fromDateForm,value.toDateForm,value.status,page,rowsPerPage,"census_user"))
    },
  });

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const handleCensusViewDetailsDialog = (data) => {
    setDialogData(data);
    setViewCensusDetails(!viewCensusDetails);

  };

  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Page title="Base Color">
      <FullLoader showLoader={showLoader} />
      <Container>
      {viewCensusDetails ? 
      <WorkReportTreeDetailDialog
        isOpen={viewCensusDetails}
        handleClose={() => handleCensusViewDetailsDialog()}
        data={dialogData}
       />:null}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <div role="presentation">
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ color: '#000000', fontWeight: 900, fontSize: '20px' }}
              separator=":">
              <Typography variant="h4" gutterBottom style={{ color: '#000000' }}>
                Tree Data
              </Typography>
              <Typography variant="h4" gutterBottom style={{ color: '#000000', fontWeight: 400 }}>
                Total number of counts
              </Typography>
            </Breadcrumbs>
            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: '400', marginTop: '-8px' }}>
              It is showing list of trees with its details
            </Typography>
          </div>
          <div className="actionContainer" style={{ flexDirection: 'row-reverse' }}>
            <Button
              onClick={toggleDrawer('right', true)}
              variant="contained"
              to="#"
              startIcon={<Iconify icon="eva:funnel-fill" />}>
              Filters
            </Button>
            {userPermissions.includes("view-census-report") ?
              <Button
                variant="contained"
                // className="desktop-button-"
                onClick={exportCensusReports}
                // onClick={() => (buttonClick ? requestForWithoutFilter() : requestForReport())}
                // onClick={handleExportCallLogReport}
                style={{ fontWeight: 500, fontSize: '15px', backgroundColor: '#E8762F', color: '#fff' }}
              >
                Export Report
              </Button>

              :
              null}
          </div>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Box sx={{ height: '100' }}>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  width: '300px',
                  maxWidth: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
              anchor={'right'}
              open={newState.right}
              onClose={toggleDrawer('right', false)}>
              <div>
                <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
                    <Grid item xs={12}>
                      <TextField
                        select
                        id="userId"
                        name="userId"
                        label="Added By"
                        displayEmpty
                        value={values.userId}
                        style={{ width: '100%', marginTop: 5 }}
                        size="small"
                        // placeholder='*Status'
                        onChange={(e) => {
                          handleAddedByChange(e);
                          formik.handleChange(e);
                        }}
                        // onChange={handleAddedByChange}
                        // {...getFieldProps("addedByForm")}
                        inputProps={{
                          classes: {
                            icon: classes.icon,
                          },
                        }}
                        error={Boolean(touched.userId && errors.userId)}
                    helperText={touched.userId && errors.userId}
                        {...getFieldProps('userId')}
                        >
                        <MenuItem disabled value="">
                          <em>Select Added By</em>
                        </MenuItem>
                        <MenuItem value="">
                          <em>----Null----</em>
                        </MenuItem>
                        {userByRoleID?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.first_name} {option.last_name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="fromDate"
                    type="date"
                    label="Start Date*"
                    margin="normal"
                    name="fromDateForm"
                    onChange={(e) => {
                      handleFromDateChange(e);
                      formik.handleChange(e);
                    }}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    // label="Plantation Date"
                    value={values.fromDateForm || ''}
                    // }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: todayDate }}
                    error={Boolean(touched.fromDateForm && errors.fromDateForm)}
                    helperText={touched.fromDateForm && errors.fromDateForm}
                    // {...getFieldProps('fromDateForm')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="toDate"
                    label="End Date*"
                    type="date"
                    margin="normal"
                    name="toDateForm"
                    onChange={(e) => {
                      handleToDateChange(e);
                      formik.handleChange(e);
                    }}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    // label="Plantation Date"
                    value={values.toDateForm || ''}
                    // helperText={
                    //     errors.toDateForm && touched.toDateForm

                    // }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: todayDate }}
                    error={Boolean(touched.toDateForm && errors.toDateForm)}
                    helperText={touched.toDateForm && errors.toDateForm}
                    // {...getFieldProps('toDateForm')}
                  />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    select
                    id="status"
                    name="status"
                    label="Tree Count Type*"
                    displayempty
                    // value={countType}
                    style={{ width: '100%', marginTop: 5 }}
                    // placeholder='*Status'
                    onChange={(e) => {
                      handleCountTypeChange(e);
                      formik.handleChange(e);
                    }}
                    // onChange={handleAddedByChange}
                    // error={Boolean(touched.workType && errors.workType)}
                    // helperText={touched.workType && errors.workType}
                    {...getFieldProps('status')}
                    inputProps={{
                      classes: {
                          icon: classes.icon,
                      },
                  }}
                  >
                    <MenuItem disabled value="">
                      <em>Select Count Type</em>
                    </MenuItem>
                    {countTypeList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  </Grid>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 5 }}>
                    Apply
                  </Button>
                </Grid>
              </div>
              {/* <FilterDrawer data={toggleDrawer("right", false)}/> */}
            </Drawer>
          </Box>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {
                    workTypeCensusTreeHistory?.data?.map((option, index) => {
                      return (
                        <TableRow hover>
                          <TableCell align="left">
                            <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                          </TableCell>
                          <TableCell align="left">
                            <b>{option.tree_number ? option.tree_number : '-'}</b>
                          </TableCell>
                          <TableCell align="left">{option.tree_name?.name}</TableCell>
                          <TableCell align="left">
                            {option.added_by?.first_name} {option.added_by?.last_name}{' '}
                          </TableCell>
                          <TableCell align="left">{option.added_on_date}</TableCell>
                          <TableCell align="left">{option.age}</TableCell>
                          <TableCell align="left">{option.location_accuracy}</TableCell>
                          <TableCell align="left">
                            {option.qc_status === 'Pending' ? <StatusPendngButton qcStatus={option.qc_status} /> : ''}
                            {option.qc_status === 'Approved' ? (
                              <StatusApprovedButton qcStatus={option.qc_status} />
                            ) : (
                              ''
                            )}
                            {option.qc_status === 'Unapproved' ? (
                              <StatusUnapprovedButton qcStatus={option.qc_status} />
                            ) : (
                              ''
                            )}
                          </TableCell>
                          <TableCell align="left">
                            {option.referred_to_expert === 1 ? (
                              <b style={{ color: 'green' }}>Yes</b>
                            ) : (
                              <b style={{ color: '#E8762F' }}>No</b>
                            )}
                          </TableCell>
                          <TableCell align="right">
                          < WorkReportTreeCensusMenu
                              permissions={userPermissions}
                              treeCensusId={option.id}
                              handleCensusViewDialog={() => handleCensusViewDetailsDialog(option)}
                            />
                          </TableCell>
                          
                        </TableRow>

                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {workTypeCensusTreeHistory ? (
            <Pagination
              count={pageInfo.last_page || 0}
              
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
