import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

const TextWithTooltip = ({ text, maxLength }) => {
  console.log("tooltip",text)
  const isTextLong = text?.length > maxLength;
  const displayText = isTextLong ? `${text.slice(0, maxLength)}...` : text;
 console.log("displayText76576",displayText)
  return (
    <Tooltip title={text} disableHoverListener={!isTextLong} arrow>
      <span style={{ textAlign: 'left', display: 'inline-block', width: '100%' }}>
        {displayText}
      </span>
    </Tooltip>
  );
};

export default TextWithTooltip;
