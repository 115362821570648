import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  ADD_USER,
  DELETE_USER,
  GET_MANAGER_ROLE,
  GET_MANAGER_REQUEST,
  EDIT_USER,
  GET_RELIGIONS,
  GET_SALARY_DEDUCTION_TYPES,
  GET_USER,
  GET_USER_BY_ID,
  GET_TREE_CHANGES_REQUEST,
  GET_ACTIVE_USER,
  GET_USERS_BY_ROLEID,
  GET_USER_DOCUMENT_TYPES,
  SEARCH_USER,
  UNLINK_DEVICE,
  // GET_MANAGER,
} from './Types';

const GetManagerRequest = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/czw-change-requests?page=${page}&limit=${limit}`);
    dispatch({
      type: GET_MANAGER_REQUEST,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const GetTreeChangeRequest = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/census-trees-change-request`);
    dispatch({
      type: GET_TREE_CHANGES_REQUEST,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const GetManagerRole = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-managers`);
    dispatch({
      type: GET_MANAGER_ROLE,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


const GetAssociateByID = (userId) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-assigned-manager/${userId}`);
    dispatch({
      type: GET_USER_BY_ID,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchUsers = (page, limit, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-managers?page=${page}&limit=${limit}&search=${searchValue}`);
    dispatch({
      type: GET_USER,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchUserByManager = (page, limit,userId, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-assigned-manager/${userId}?search=${searchValue}`);
    dispatch({
      type: GET_USER,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
}
const AddRequestManager = (params, getAssociateId) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`/api/czw-change-request/${getAssociateId}`, params);
    dispatch({
      type: ADD_USER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const AddTreeChangeRequest = (params, getAssociateId) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`/api/census-trees-change-request/${getAssociateId}`, params);
    dispatch({
      type: ADD_USER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const ApproveUnapproveRequest = (params, getAssociateId) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`/api/associate-travel-request/${getAssociateId}`, params);
    dispatch({
      type: ADD_USER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export {
  GetManagerRequest,
  GetTreeChangeRequest,
  GetManagerRole,
  GetAssociateByID,
  SearchUsers,
  AddRequestManager,
  AddTreeChangeRequest,
  SearchUserByManager,
  ApproveUnapproveRequest
};
