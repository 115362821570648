import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, Box, Button } from '@mui/material';
import PropTypes from 'prop-types';

import { AppWidgetSummary } from '../../sections/@dashboard/app';
import ProjectImg from '../../Assets/project_ongoing.png';
//   import Iconify from '../../../components/Iconify';
function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                // p: 1,
                // m: 1,
                // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                // border: '1px solid',
                // borderColor: (theme) =>
                //     theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                // borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};
const LightCard = (props) => {
    const loggedUser = useSelector((state) => state.auth.loggedUser);

    const userPermissions = loggedUser.roles[0];
    console.log("userPermissions", userPermissions)
    const useStyles = makeStyles({
        wrapper: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, auto)',
            padding: '10px 15px',
        },
        paddingClass: {
            padding: '10px 15px',
            textAlign: 'center'

        },
        cardleftSection: {
            padding: '5px 20px 15px 7px',
            fontWeight: 600,
        },
        cardCount: {
            padding: '5px 5px 5px 5px',
            // borderBottom: '1px solid #EEEEEE'
        },
        border: {
            borderBottom: '1px solid #EEEEEE',
            width: '60%'
        },
        borderRight: {
            borderRight: '1px solid',
        }
    });
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <Grid container spacing={1}>
            <Grid container alignItems="center"
                justifyContent="center">
                <Grid item xs={12}>
                    <Card style={{ height: '160px' }}>

                        <div className={classes.paddingClass}>
                            <div style={{ textAlign: 'right', marginBottom: '5px' }}>
                                {
                                    userPermissions.slug === 'qc_census_offsite'
                                        ?
                                        <div>
                                            <Button variant="contained" onClick={() => {
                                                navigate(`/dashboard/censusQC`)
                                            }}>View All</Button>
                                        </div>
                                        :
                                        <div>
                                            <Button variant="contained" onClick={() => {
                                                navigate(`/dashboard/baseColorPendingQC`)
                                            }}>View All</Button>
                                        </div>
                                }
                            </div>
                            <Grid container
                                alignItems="center"
                                justifyContent="center">
                                <Grid item xs={4}>
                                    <div className={classes.borderRight}>
                                        <h2 className={classes.cardCount}>
                                            {props.projects?.total_trees_count}
                                        </h2>
                                        <h6 className={classes.cardCount}>
                                            TOTAL TREE COUNT
                                        </h6>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.borderRight}>
                                        <h2 className={classes.cardCount}>
                                            {props.projects?.total_qc_count}

                                        </h2>
                                        <h6 className={classes.cardCount}>
                                            QC DONE COUNT
                                        </h6>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div>
                                        {
                                            props.projects?.pending_qc_count === 0?

                                        <h2 className={classes.cardCount} style={{color:'red'}}>
                                            {props.projects?.pending_qc_count}
                                        </h2>
                                        :
                                        <h2 className={classes.cardCount}>
                                            {props.projects?.pending_qc_count}
                                        </h2>
                                        }
                                        <h6 className={classes.cardCount}>
                                            PENDING QC COUNT
                                        </h6>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LightCard;
