import React, { useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes, { object } from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Typography } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
// import MenuItem from '@mui/material/MenuItem';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link, TextField, InputAdornment, Switch, FormControlLabel } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
// import SelectInput from '../Inputs/SelectInput';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import moment from 'moment';
import set from 'date-fns/fp/set/index';
import Iconify from '../Iconify';
import {
  GetActiveDistricts,
  GetActiveDistrictsByStateId,
  GetAllActiveDistrictsByStateId,
  GetActiveState,
  GetAllActiveTalukaByDistrictId,
  GetActiveTalukas,
} from '../../actions/MasterActions';
import {
  AddCouncil,
  AddCouncilWithLogo,
  EditCouncil,
  EditCouncilWithLogo,
  GetCouncilById,
} from '../../actions/CouncilAction';
import { GetActiveZones } from '../../actions/ZonesAction';
import { GetActiveWards } from '../../actions/WardsActions';
import DefaultInput from '../Inputs/DefaultInput';
import { UploadImage, UploadKml } from '../../actions/UploadActions';

import { SetNewAlert } from '../../actions/AlertActions';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Input = styled('input')({
  display: 'none',
});

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CreateCouncilDialog(props) {
  const dispatch = useDispatch();

  const { isOpen, data } = props;
  const [open, setOpen] = React.useState(false);
  const [gender, setGender] = React.useState('');
  const [role, setRole] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [district, setDistrict] = React.useState('');
  const [stateName, setStateName] = React.useState('');
  const [taluka, setTaluka] = React.useState('');
  const [zoneName, setZoneName] = React.useState([]);
  const [wardName, setWardName] = React.useState([]);
  const [logoValue, setLogoValue] = React.useState('');
  const [leterHeadValue,setLetterHeadValue] =React.useState('')
  const [logoPath, setLogoPath] = React.useState('');
  const [files, setFiles] = React.useState('');
  const [leterHead,setLeterHead] = React.useState('');
  const [logoError, setLogoError] = React.useState('');
  const [leterHeadError,setleterHeadError] =React.useState('')
  const [isEditable, setIsEditable] = React.useState(false);
  const [isImageRemoved, setIsImageRemoved] = React.useState(true);
  const [leterHeadRemove, setLeterHeadRemove] = React.useState(true);
 
  const [showDistrict, setShowDistrict] = React.useState(false);
  const [showTaluka, setShowTaluka] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [vrukshaArray, setVrukshaArray] = React.useState([]);
  const [kmlValue, setKmlValue] = React.useState();
  // const todayDate = moment(new Date()).format('YYYY-MM-DD');
  console.log('data98', props.data)
  const {
    addCouncilLog,
    editCouncilLog,
    zones,
    wards,
    states,
    districts,
    talukas,
    councilById,
    uploadImage,
    uploadImageLog,
    kmlFile,
    kmlFileLog,
  } = useSelector((state) => ({
    addCouncilLog: state.council.addCouncilLog,
    editCouncilLog: state.council.editCouncilLog,
    zones: state.zones.activeZonesByCID,
    wards: state.wards.activeWardsByCID,
    states: state.master.activeStates,
    districts: state.master.activeDistricts,
    talukas: state.master.activeTalukas,
    councilById: state.council.councilById,
    uploadImage: state.upload.uploadImage,
    uploadImageLog: state.upload.uploadImageLog,
    kmlFile: state.upload.kmlFile,

    kmlFileLog: state.upload.kmlFileLog,
  }));
  // console.log('wards', wards);

  React.useEffect(() => {
    if (data && isOpen) {
      dispatch(GetCouncilById(data.id));
      setIsEditable(true);
    }
  }, [data]);

  useEffect(() => {
    dispatch(GetActiveZones(1));
    dispatch(GetActiveWards(1));
    dispatch(GetActiveState(1));
    // dispatch(GetActiveDistricts(1));
    // dispatch(GetActiveTalukas(1));
    if (data) {
      //  console.log("console");
      dispatch(GetAllActiveDistrictsByStateId(data.state_id, 1));
      dispatch(GetAllActiveTalukaByDistrictId(data.district_id.name, 1));
      setShowDistrict(true);
      setShowTaluka(true);
    }
  }, []);

  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    props.handleClose();
  }, [addCouncilLog, editCouncilLog]);

  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (councilById) {
      separateId(councilById.zones, councilById.wards);
      setLogoValue(councilById.logo ? councilById.logo : '');
      setLetterHeadValue(typeof(councilById?.letterhead)=== 'string' ? councilById.letterhead : '')
      console.log('***********COUNIL BY ID', councilById);
      const wards = councilById.wards;
      console.log("wardsss",wards)
      wards.forEach((v) => delete v.status);
      if(councilById.logo){
        setIsImageRemoved(false);
      }
      if(typeof(councilById?.letterhead)=== 'string'){
        setLeterHeadRemove(false);
      }
      let i;
      for (i = 0; i < wards.length; i += 1) {
        wards[i].ward_id = wards[i].id;
        delete wards[i].id;
      }
      setVrukshaArray(wards);
    }
  }, [councilById]);

  const thirdRun = React.useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    if (kmlFile) {
      const previousVrikshArray = [...vrukshaArray];
      const obj = previousVrikshArray[kmlFile.index];
      obj.coordinates = kmlFile?.coordinates;
      obj.kml_path = kmlFile?.kml_path;
      previousVrikshArray[kmlFile.index] = obj;
      setVrukshaArray(previousVrikshArray);
    }
  }, [kmlFileLog]);

  const separateId = (zones, wards) => {
    const zoneArray = [];
    zones.map((value, index) => {
      zoneArray.push(value.id);
      return null;
    });
    setZoneName(zoneArray);

    const wardArray = [];
    wards.map((value, index) => {
      wardArray.push(value.id);
      return null;
    });
    setWardName(wardArray);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // console.log("///", districts);
  const handleClose = () => {
    setLogoValue('');
    props.handleClose();
  };

  const handleDistrictChange = (event) => {
    // console.log("inn");
    dispatch(GetAllActiveTalukaByDistrictId(event.target.value, 1));
    setDistrict(event.target.value);
    setShowTaluka(true);
    setTaluka('Taluka');
  };

  const handleTalukaChange = (event) => {
    setTaluka(event.target.value);
  };
  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setZoneName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };

  const isFound = (listOfObj, id) => {
    const found = listOfObj.find((e) => e.ward_id === id);
    return found;
  };

  const handleWardChange = (event) => {
    console.log('HADNLE WARD CALLED', event.target);
    const {
      target: { value },
    } = event;
    setWardName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );

    const updatedVrukshaArrayList = [];

    event.target.value?.map((value, index) => {
      const wardName = findWardName(wards, value);
      const vrukshaValue = isFound(vrukshaArray, value);
      if (vrukshaValue) {
        updatedVrukshaArrayList.push(vrukshaValue);
      } else {
        updatedVrukshaArrayList.push({ ward_id: value, name: wardName, vriksha_adhikari: '', coordinates: null });
      }
      return null;
    });

    setVrukshaArray(updatedVrukshaArrayList);
  };

  console.log('VRUSKHA ARRAY', vrukshaArray);
  const handleVrikshaAdhikariChange = (e, index) => {
    const previousVrikshArray = vrukshaArray;
    const obj = previousVrikshArray[index];
    obj.vriksha_adhikari = e.target.value;
    previousVrikshArray[index] = obj;
    setVrukshaArray(previousVrikshArray);
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleStateChange = (event) => {
    dispatch(GetAllActiveDistrictsByStateId(event.target.value, 1));
    setShowDistrict(true);
    setDistrict('District');
    setTaluka('Taluka');
    setStateName(event.target.value);
  };

  const findValue = (listOfObj, id) => {
    const found = listOfObj.find((e) => e.id === id);
    if (found) {
      return found.name;
    }
  };

  console.log('VRUKSHA ARRAY', vrukshaArray);
  console.log('kmmm', kmlFile);

  const handleLogoChange = (e) => {
    // const formData = new FormData();
    // formData.append('upload_for', 'councils');
    // formData.append('image', e.target.files[0]);
    // dispatch(UploadImage(formData));
    setLogoValue(e.target.value);
    setFiles(e.target.files[0]);
  };
  const handleLetterHeadChange =(e)=>{
    setLetterHeadValue(e.target.value);
    setLeterHead(e.target.files[0]);
  }

  const handleUploadKml = (e, index) => {
    const validExtensions = ['kml'];
    const fileExtension = e.target.files[0].name.split('.')[1];

    if (validExtensions.includes(fileExtension)) {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      dispatch(UploadKml(formData, index));
    } else {
      dispatch(
        SetNewAlert({
          msg: 'Invalid file. Only KML files are allowed',
          alertType: 'danger',
        })
      );
      e.target.value = '';
    }
  };

  const handleImageRemove = (e) => {
    setLogoValue('');
    setFiles('');
    setIsImageRemoved(true);
  };
  const handleleterHeadRemove = (e) => {
    setLetterHeadValue('');
    setLeterHead('');
    setLeterHeadRemove(true);
  };


  const handleKmlRemove = (index) => {
    const previousVrikshArray = [...vrukshaArray];
    const obj = previousVrikshArray[index];
    obj.coordinates = null;
    obj.kml_path=null;
    previousVrikshArray[index] = obj;
    setVrukshaArray(previousVrikshArray);
  };

  const validateLogo = () => {
    let validated = true;

    if (!logoValue) {
      validated = false;
      setLogoError('Upload Logo');
    } else {
      setLogoError('');
    }
    return validated;
  };
  const validateLeterHead = () => {
    let validated = true;

    if (!leterHeadValue) {
      validated = false;
      setleterHeadError('Upload Letterhead Header');
    } else {
      setleterHeadError('');
    }
    return validated;
  };

  const findWardName = (listOfObj, id) => {
    const found = listOfObj.find((e) => e.id === id);
    // console.log("FOUND",found);
    if (found) {
      return found.name;
    }
  };

  const IsDenseAreaDropDown = [
    {
      label: 'Yes',
      value: '1',
    },
    {
      label: 'No',
      value: '0',
    },
  ];

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const DistrictsSchema = Yup.object().shape(
    data
      ? {
          name: Yup.string()
            .matches(/^[A-Za-z ]+$/, 'Please Use Alphabets Only')
            .min(2, 'Council Name Is Too Short!')
            .max(30, 'Council Name Is Too Long!')
            .required('Name is required'),
          district: Yup.string().required('Districts is required'),
          state: Yup.string().required('State is required'),
          city: Yup.string()
            .matches(/^[A-Za-z ]+$/, 'Please Use Alphabets Only')
            .min(2, 'City Name Is Too Short!')
            .max(30, 'City Name Is Too Long!')
            .required('City Name is required'),
          
            applicationNumberPrefix:Yup.string().required('Application Number Prefix is required'),
          // taluka: Yup.string().required('Taluka is required'),
          baseColorTarget: Yup.number().typeError('Base Color must be number').required('Base Color is required'),
          censusTarget: Yup.number().typeError('Census Target must be number').required('Census Target is required'),
          total_area: Yup.string()
            .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Only Numbers & Decimals are allowed')
            .required('Total area is required'),
          zones: Yup.array().min(1, 'Zone is required'),
          wards: Yup.array().min(1, 'Ward is required'),
          locationAccuracyNeeded: Yup.string().required('Accuracy Needed is required'),
          project_start_date: Yup.date().required('Start Date is required'),
          project_end_date: Yup.date().min(Yup.ref('project_start_date'), "end date can't be before start date"),
          locationTimeout: Yup.string().required('Location Timeout is required'),
          // fine: Yup.number().typeError('Fine must be number').required('Fine is required'),
          fees: Yup.number().typeError('Fees must be number').required('Fees is required'),
          co: Yup.string()
            .matches(/^[A-Za-z ]+$/, 'Please Use Alphabets Only')
            .min(2, 'Co Name Is Too Short!')
            .max(30, 'Co Name Is Too Long!')
            .required('Co is required'),
        }
      : {
          name: Yup.string()
            .matches(/^[a-zA-Z ]+$/, 'Please Use Alphabets Only')
            .min(2, 'Council Name Is Too Short!')
            .max(30, 'Council Name Is Too Long!')
            .required('Name is required'),
          district: Yup.string().required('Districts is required'),
          state: Yup.string().required('State is required'),
          city: Yup.string()
            .matches(/^[A-Za-z ]+$/, 'Please Use Alphabets Only')
            .min(2, 'City Name Is Too Short!')
            .max(30, 'City Name Is Too Long!')
            .required('City Name is required'),
          // taluka: Yup.string().required('Taluka is required'),
          applicationNumberPrefix:Yup.string().required('Application Number Prefix is required'),
          baseColorTarget: Yup.number().typeError('Base Color must be number').required('Base Color is required'),
          censusTarget: Yup.number().typeError('Census Target must be number').required('Census Target is required'),
          total_area: Yup.string()
            .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Only Numbers & Decimals are allowed for this field ')
            .required('Total area is required'),
          firstName: Yup.string()
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
            .max(20, 'Maximum length 20 character only')
            .required('First Name is required'),
          // middleName: Yup.string().required('Middle Name is required'),
          lastName: Yup.string()
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
            .max(20, 'Maximum length 20 character only')
            .required('Last Name is required'),
          email: Yup.string().email('Email must be a valid email address').required('Email is required'),
          mobile: Yup.string()
            .matches(/^[0-9]\d{9}$/, 'Mobile number is not valid')
            .required('Mobile number is required'),
          username: Yup.string().required('Username is required'),
          password: Yup.string()
            .matches(/^.{6,}$/, 'password should have at least 6 characters')
            .required('Password is required'),
          zones: Yup.array().min(1, 'Zone is required'),
          wards: Yup.array().min(1, 'Ward is required'),

          locationAccuracyNeeded: Yup.number()
            .typeError('Accuracy must be number')
            .required('Accuracy Needed is required'),
          minHeight: Yup.number().typeError('Min height must be number').required('Min height is required'),
          minGirth: Yup.number().typeError('Min girth must be number').required('Min girth is required'),
          project_start_date: Yup.date().required('Start Date is required'),
          project_end_date: Yup.date().min(Yup.ref('project_start_date'), "end date can't be before start date"),
          locationTimeout: Yup.number()
            .typeError('Location Timeout must be number')
            .required('Location Timeout is required'),
          // fine: Yup.number().typeError('Fine must be number').required('Fine is required'),
          fees: Yup.number().typeError('Fees must be number').required('Fees is required'),
          processingFees: Yup.number().typeError('Fees must be number').required('Fees is required'),
          co: Yup.string()
            .matches(/^[A-Za-z ]+$/, 'Please Use Alphabets Only')
            .min(2, 'Co Name Is Too Short!')
            .max(30, 'Co Name Is Too Long!')
            .required('Co is required'),

          // "project_end_date": value.project_end_date
        }
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data
      ? {
          district: data ? data.district_id : '',
          state: data ? data.state_id : '',
          taluka: data ? data.taluka_id : '',
          city: data ? data.city : '',
         
          applicationNumberPrefix:data ?data.application_number_prefix===null?"":data.application_number_prefix:"",
          name: data ? data.name : '',
          baseColorTarget: data ? data.base_color_target : '',
          censusTarget: data ? data.census_target : '',
          zones: data ? zoneName : [],
          wards: data ? wardName : [],
          total_area: data ? data.total_area : '',
          locationAccuracyNeeded: data ? data.location_accuracy_needed : '',
          minHeight: data ? data.min_height : '',
          minGirth: data ? data.min_girth : '',
          project_start_date: data ? data.project_start_date : '',
          project_end_date: data ? data.project_end_date : '',
          locationTimeout: data ? data.location_timeout : '',
          isDenseArea: data ? data.is_dense_area : 'no',
          co: data ? data.co : '',
          // fine: data ? data.fine : '',
          fees: data ? data.cutting_trees_fees_per_tree : '',
          processingFees: data ? data.processing_fees_per_tree: "",
          treeNumberConfirmation: data ? data.tree_number_confirmation === 1 : false,
        }
      : {
          district: data ? data.district_id : '',
          state: data ? data.state_id : '',
          taluka: data ? data.taluka_id : '',
          city: data ? data.city : '',
          applicationNumberPrefix:data ?data.application_number_prefix:"",
          name: data ? data.name : '',
          baseColorTarget: data ? data.base_color_target : '',
          censusTarget: data ? data.census_target : '',
          total_area: data ? data.total_area : '',
          firstName: data ? data.contact_person.first_name : '',
          middleName: data ? data.contact_person.middle_name : '',
          lastName: data ? data.contact_person.last_name : '',
          email: data ? data.contact_person.email : '',
          mobile: data ? data.contact_person.mobile : '',
          username: data ? data.contact_person.username : '',
          password: data ? data?.password : '',
          zones: data ? [] : [],
          wards: data ? [] : [],
          locationAccuracyNeeded: data ? data.location_accuracy_needed : '',
          minHeight: data ? data.min_height : '',
          minGirth: data ? data.min_girth : '',
          project_start_date: data ? data.project_start_date : '',
          project_end_date: data ? data.project_end_date : '',
          locationTimeout: data ? data.location_timeout : '',
          isDenseArea: data ? data.is_dense_area : 'no',
          co: data ? data.co : '',
          // fine: data ? data.fine : '',
          fees: data ? data.cutting_trees_fees_per_tree : '',
          processingFees: data ? data.processing_fees_per_tree: "",
          treeNumberConfirmation: data ? data.tree_number_confirmation === 1 : false,
          
        },
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {
      console.log("submit",value);
      if (data) {
        if (validateLogo() && (isImageRemoved ||leterHeadRemove) && validateLeterHead()  && validateVrkshaAdhikariName()) {
          
          const formData = new FormData();
          if(isImageRemoved) {
            formData.append('upload_for', 'councils');
            formData.append('image', files);
          }
          
          const formData2 = new FormData();
          if(leterHeadRemove) {
            formData2.append('upload_for', 'councils');
            formData2.append('image',leterHead)
          }
         
          dispatch(
            EditCouncilWithLogo(
              isImageRemoved ? formData: null,
              leterHeadRemove ? formData2 : null,
              {
                council: {
                  name: value.name,
                  state_id: value.state,
                  district_id: value.district,
                  city: value.city,
                  taluka_id: value.taluka,
                  base_color_target: value.baseColorTarget,
                  census_target: value.censusTarget,
                  total_area: value.total_area,
                  location_accuracy_needed: value.locationAccuracyNeeded,
                  min_height: value.minHeight,
                  min_girth: value.minGirth,
                  project_start_date: value.project_start_date,
                  project_end_date: value.project_end_date,
                  location_timeout: value.locationTimeout,
                  is_dense_area: value.isDenseArea,
                  co: value.co,
                  fine: value.fine,
                  cutting_trees_fees_per_tree: value.fees,
                  processing_fees_per_tree: value.processingFees,
                  tree_number_confirmation: value.treeNumberConfirmation ? 1 : 0,
                  application_number_prefix:value.applicationNumberPrefix,
                },

                zones: value.zones,
                wards: vrukshaArray.map((item) => {
                  delete item.name;

                  return item;
                }),
              },
              data.id
            )
          );
        } else if (validateLogo() && validateVrkshaAdhikariName() && validateLeterHead()) {
          dispatch(
            EditCouncil(
              {
                council: {
                  name: value.name,
                  logo: logoValue,
                  state_id: value.state,
                  district_id: value.district,
                  taluka_id: value.taluka,
                  city: value.city,
                  base_color_target: value.baseColorTarget,
                  census_target: value.censusTarget,
                  total_area: value.total_area,
                  location_accuracy_needed: value.locationAccuracyNeeded,
                  min_height: value.minHeight,
                  min_girth: value.minGirth,
                  project_start_date: value.project_start_date,
                  project_end_date: value.project_end_date,
                  location_timeout: value.locationTimeout,
                  is_dense_area: value.isDenseArea,
                  co: value.co,
                  // fine: value.fine,
                  fine:1,
                  cutting_trees_fees_per_tree: value.fees,
                  processing_fees_per_tree: value.processingFees,
                  tree_number_confirmation: value.treeNumberConfirmation ? 1 : 0,
                  application_number_prefix:value.applicationNumberPrefix,
                },
                zones: value.zones,
                wards: vrukshaArray.map((item) => {
                  delete item.name;
                  return item;
                }),
              },
              data.id
            )
          );
        }
      } else if (validateLogo() && validateLeterHead() && validateVrkshaAdhikariName()) {
        const formData = new FormData();
        formData.append('upload_for', 'councils');
        formData.append('image', files);

        const formData2 = new FormData();
        formData2.append('upload_for', 'councils');
        formData2.append('image',leterHead)

     

        dispatch(
          AddCouncilWithLogo(formData,formData2, {
            council: {
              name: value.name,
              state_id: value.state,
              district_id: value.district,
              taluka_id: value.taluka,
              city: value.city,
              application_number_prefix:value.applicationNumberPrefix,
              base_color_target: value.baseColorTarget,
              census_target: value.censusTarget,
              total_area: value.total_area,
              location_accuracy_needed: value.locationAccuracyNeeded,
              min_height: value.minHeight,
              min_girth: value.minGirth,
              project_start_date: value.project_start_date,
              project_end_date: value.project_end_date,
              location_timeout: value.locationTimeout,
              is_dense_area: value.isDenseArea,
              co: value.co,
              // fine: value.fine,
              fine:1,
              cutting_trees_fees_per_tree: value.fees,
              processing_fees_per_tree: value.processingFees,
              tree_number_confirmation: value.treeNumberConfirmation ? 1 : 0,
            },
            contact_person: {
              first_name: value.firstName,
              middle_name: value.middleName,
              last_name: value.lastName,
              email: value.email,
              mobile: value.mobile,
              username: value.username,
              password: value.password,
            },
            zones: value.zones,
            wards: vrukshaArray.map((item) => {
              delete item.name;
              return item;
            }),
          })
        );
      }
    },
  });

  const validateVrkshaAdhikariName = () => {
    let isValid = true;
    vrukshaArray.map((e, index) => {
      if (e.vriksha_adhikari === '' || e.vriksha_adhikari === null) {
        isValid = false;
      }
      return null;
    });
    console.log('is valid', isValid);
    if (!isValid) {
      dispatch(
        SetNewAlert({
          msg: 'Please enter all vriksha adhikari name',
          alertType: 'danger',
        })
      );
    }
    return isValid;
  };

  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
  });
  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, handleChange, setFieldValue } = formik;
  // console.log("errors",errors)
  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={isOpen}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {data ? 'Edit Council' : 'New Council'}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            Council Details
          </BootstrapDialogTitle>
          <Grid container spacing={1}>
            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="name"
                autoComplete="name"
                placeholder="Name*"
                label="Name*"
                style={{ width: '100%' }}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                {...getFieldProps('name')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />
            {/* <UploadButtons/> */}
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                select
                id="state"
                name="state"
                displayEmpty
                label="State*"
                value={values.state}
                style={{ width: '80%', marginLeft: '40px' }}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                onChange={(e) => {
                  handleStateChange(e);
                  formik.handleChange(e);
                }}
                error={Boolean(touched.state && errors.state)}
                helperText={touched.state && errors.state}
                // {...getFieldProps("state")}
              >
                <MenuItem disabled value="">
                  <em>State*</em>
                </MenuItem>
                {states?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                select
                id="district"
                name="district"
                label="District*"
                displayEmpty
                value={values.district}
                style={{ width: '80%', marginLeft: '40px' }}
                placeholder="*Select District"
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                onChange={(e) => {
                  handleDistrictChange(e);
                  formik.handleChange(e);
                }}
                error={Boolean(touched.district && errors.district)}
                helperText={touched.district && errors.district}
                // {...getFieldProps("district")}
              >
                <MenuItem disabled value="">
                  <em>Select District*</em>
                </MenuItem>
                {showDistrict
                  ? districts?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                select
                id="taluka"
                name="taluka"
                displayEmpty
                label="Taluka"
                // name="gender"
                value={taluka}
                style={{ width: '80%', marginLeft: '40px' }}
                defaultValue={data ? data.taluka : ''}
                onChange={handleTalukaChange}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                error={Boolean(touched.taluka && errors.taluka)}
                helperText={touched.taluka && errors.taluka}
                {...getFieldProps('taluka')}>
                <MenuItem disabled value="">
                  <em>Select Taluka</em>
                </MenuItem>
                {showTaluka
                  ? talukas?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  : null}
              </TextField>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="city"
                autoComplete="city"
                label="City*"
                placeholder="Enter City Name*"
                error={Boolean(touched.city && errors.city)}
                helperText={touched.city && errors.city}
                {...getFieldProps('city')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />
            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="co"
                autoComplete="name"
                label="Co Name*"
                placeholder="Enter Co Name*"
                error={Boolean(touched.co && errors.co)}
                helperText={touched.co && errors.co}
                {...getFieldProps('co')}
              />
            </Grid>
            {/* <Grid item md={6} sm={6} xs={6} /> */}
            <Grid item md={12} sm={12} xs={12}>
              <DefaultInput
                fullWidth
                id="fees"
                name="fees"
                autoComplete="name"
                label="Deposite amount for tree Cutting/(per tree)"
                placeholder="Enter Fees*"
                error={Boolean(touched.fees && errors.fees)}
                helperText={touched.fees && errors.fees}
                {...getFieldProps('fees')}
              />
            </Grid>
            {/* <Grid item md={6} sm={6} xs={6} /> */}
            <Grid item md={12} sm={12} xs={12}>
              <DefaultInput
                fullWidth
                id="processingFees"
                name="processingFees"
                autoComplete="name"
                label="Processing fees for tree Cutting/(per tree)"
                placeholder="Enter Processing Fees*"
                error={Boolean(touched.processingFees && errors.processingFees)}
                helperText={touched.processingFees && errors.processingFees}
                {...getFieldProps('processingFees')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="baseColorTarget"
                autoComplete="name"
                label="Base Color Target*"
                placeholder="Enter Base Color Target*"
                error={Boolean(touched.baseColorTarget && errors.baseColorTarget)}
                helperText={touched.baseColorTarget && errors.baseColorTarget}
                {...getFieldProps('baseColorTarget')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />
            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="censusTarget"
                autoComplete="name"
                label="Census Target*"
                placeholder="Enter Census Target*"
                error={Boolean(touched.censusTarget && errors.censusTarget)}
                helperText={touched.censusTarget && errors.censusTarget}
                {...getFieldProps('censusTarget')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                id="total_area"
                autoComplete="name"
                label="Total Area (sq km)*"
                placeholder="Total Area(sq km)*"
                style={{ width: '80%', marginLeft: '40px' }}
                error={Boolean(touched.total_area && errors.total_area)}
                helperText={touched.total_area && errors.total_area}
                {...getFieldProps('total_area')}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                }}
                label="Zone*"
                displayEmpty
                value={zoneName}
                // onChange={handleChange}
                style={{ width: '81%', marginLeft: 40, marginTop: 5 }}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Zone*</em>;
                  }
                  const result = [];
                  selected.map((value) => {
                    const found = findValue(zones, value);
                    result.push(found);
                    return null;
                  });

                  return result.join(',');
                }}
                error={Boolean(touched.zones && errors.zones)}
                helperText={touched.zones && errors.zones}
                // MenuProps={MenuProps}
                {...getFieldProps('zones')}
                // inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <em>Zone*</em>
                </MenuItem>
                {zones?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    // style={getStyles(name, personName, theme)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                id="wards"
                name="wards"
                select
                SelectProps={{
                  multiple: true,
                }}
                label="Ward*"
                displayEmpty
                value={wardName}
                onChange={(e) => {
                  handleWardChange(e);
                  formik.handleChange(e);
                }}
                style={{ width: '80%', marginLeft: '40px' }}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <em>Ward*</em>;
                  }
                  const result = [];
                  selected.map((value) => {
                    const found = findValue(wards, value);
                    result.push(found);
                    return null;
                  });

                  return result.join(',');
                }}
                error={Boolean(touched.wards && errors.wards)}
                helperText={touched.wards && errors.wards}
                // MenuProps={MenuProps}
                // {...getFieldProps('wards')}
                // inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value="">
                  <em>Ward</em>
                </MenuItem>
                {wards?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}

                    // style={getStyles(name, personName, theme)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {vrukshaArray?.map((value, index) => (
              <>
                <Grid item md={4} sm={4} xs={4}>
                  <TextField
                    id="vlabel"
                    placeholder="Ward"
                    disabled="true"
                    type="text"
                    value={value.name}
                    // defaultValue="2017-05-24"

                    style={{ width: '80%', marginLeft: '40px', marginTop: '5px', height: '40' }}
                    // className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // inputProps={{ min: todayDate }}
                  />
                </Grid>
                <Grid item md={4} sm={4} xs={4}>
                  <TextField
                    id={value.ward_id}
                    // label="Date Of Birth"
                    type="text"
                    label="Vriksha Adhikari Name*"
                    placeholder="Vriksha Adhikari Name*"
                    // defaultValue="2017-05-24"
                    onChange={(e) => {
                      handleVrikshaAdhikariChange(e, index);
                      formik.handleChange(e);
                    }}
                    value={value.vriksha_adhikari}
                    style={{ width: '100%', marginTop: '5px', height: '40' }}
                    // className={classes.textField}
                    // {...getFieldProps('vName')}

                    // inputProps={{ min: todayDate }}
                    // error={Boolean(touched.vriksha_adhikari && errors.vriksha_adhikari)}
                    // helperText={touched.vriksha_adhikari && errors.vriksha_adhikari}
                  />
                </Grid>
                <Grid item md={4} xs={4} sm={4}>
                  {value.coordinates !== null && value.coordinates ? (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Link
                          fullWidth
                          style={{ width: '88%', marginLeft: 10 }}
                          target="_blank"
                          rel="noopener"
                          // href={`${logoValue}`}
                        >
                          Kml File
                        </Link>

                        <IconButton
                          color={'error'}
                          aria-label={'delete'}
                          size="large"
                          onClick={() => handleKmlRemove(index)}>
                          <CancelIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      {value.kml_path ? (
                         <Grid item xs={12}>
                         <Link
                           fullWidth
                           style={{ width: '88%', marginLeft: 10 }}
                           target="_blank"
                           rel="noopener"
                           // href={`${logoValue}`}
                         >
                           Kml File
                         </Link>
 
                         <IconButton
                           color={'error'}
                           aria-label={'delete'}
                           size="large"
                           onClick={() => handleKmlRemove(index)}>
                           <CancelIcon fontSize="inherit" />
                         </IconButton>
                       </Grid>
                      ) : (
                        <TextField
                          fullWidth
                          style={{ width: '80%', marginTop: '5px' }}
                          id="logo"
                          type={'file'}
                          label="Kml"
                          accept=".kml"
                          restrictions={{
                            minFileSize: 500000,
                            fileType: '.kml',
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // placeholder="Upload Logo"
                          value={kmlValue}
                          // error={Boolean(logoError)}
                          // helperText={logoError}
                          onChange={(e) => handleUploadKml(e, index)}
                        />
                      )}
                      {/* <label htmlFor="logo">Click me to upload image</label> */}
                    </Grid>
                  )}
                </Grid>
              </>
            ))}

            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="locationAccuracyNeeded"
                autoComplete="name"
                label="Accuracy Needed* (in meter)"
                placeholder="Accuracy Needed* (in meter)"
                style={{ width: '80%', marginLeft: '40px' }}
                error={Boolean(touched.locationAccuracyNeeded && errors.locationAccuracyNeeded)}
                helperText={touched.locationAccuracyNeeded && errors.locationAccuracyNeeded}
                {...getFieldProps('locationAccuracyNeeded')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />

            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="minHeight"
                autoComplete="name"
                label="Min height needed* (in meter)"
                placeholder="Min height needed* (in meter)"
                style={{ width: '80%', marginLeft: '40px' }}
                error={Boolean(touched.minHeight && errors.minHeight)}
                helperText={touched.minHeight && errors.minHeight}
                {...getFieldProps('minHeight')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />

            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="minGirth"
                autoComplete="name"
                label="Min girth needed* (in meter)"
                placeholder="Min girth needed* (in meter)"
                style={{ width: '80%', marginLeft: '40px' }}
                error={Boolean(touched.minGirth && errors.minGirth)}
                helperText={touched.minGirth && errors.minGirth}
                {...getFieldProps('minGirth')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />
            <Grid item xs={12}>
              <TextField
                select
                id="denseArea"
                name="isDenseArea"
                displayEmpty
                label="Is it a dense Area?*"
                value={values.isDenseArea}
                style={{ width: '80%', marginLeft: '40px' }}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                onChange={(e) => {
                  // handleStateChange(e);
                  formik.handleChange(e);
                }}
                error={Boolean(touched.isDenseArea && errors.isDenseArea)}
                helperText={touched.isDenseArea && errors.isDenseArea}
                // {...getFieldProps("state")}
              >
                {IsDenseAreaDropDown?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="locationTimout"
                label="Location Timout* (in minutes)"
                placeholder="Location Timout* (in minutes)"
                style={{ width: '80%', marginLeft: '40px' }}
                error={Boolean(touched.locationTimeout && errors.locationTimeout)}
                helperText={touched.locationTimeout && errors.locationTimeout}
                {...getFieldProps('locationTimeout')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />
            <Grid item md={6} sm={6} xs={6}>
              <DefaultInput
                fullWidth
                id="applicationNumberPrefix"
                autoComplete="applicationNumberPrefix"
                label="Application Number Prefix*"
                placeholder="Enter Application Number Prefix*"
                error={Boolean(touched.applicationNumberPrefix && errors.applicationNumberPrefix)}
                helperText={touched.applicationNumberPrefix && errors.applicationNumberPrefix}
                {...getFieldProps('applicationNumberPrefix')}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6} />

            <Grid item md={6} sm={6} xs={6}>
              <TextField
                id="date"
                // label="Date Of Birth"
                type="date"
                label="Project Start Date*"
                placeholder="Project Start Date*"
                // defaultValue="2017-05-24"
                style={{ width: '80%', marginLeft: '40px', marginTop: 5 }}
                // className={classes.textField}
                error={Boolean(touched.project_start_date && errors.project_start_date)}
                helperText={touched.project_start_date && errors.project_start_date}
                {...getFieldProps('project_start_date')}
                InputLabelProps={{
                  shrink: true,
                }}
                // inputProps={{ min: todayDate }}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <TextField
                id="date"
                // value={toDate}
                type="date"
                label="Project End Date"
                placeholder="Project End Date"
                // defaultValue="2017-05-24"
                style={{ width: '80%', marginTop: 5 }}
                // className={classes.textField}
                error={Boolean(touched.project_end_date && errors.project_end_date)}
                helperText={touched.project_end_date && errors.project_end_date}
                {...getFieldProps('project_end_date')}
                InputLabelProps={{
                  shrink: true,
                }}
                // inputProps={{ min: todayDate }}
              />
            </Grid>

            <Divider />
            {!data ? (
              <>
                <BootstrapDialogTitle id="customized-dialog-title" style={{ marginLeft: 10 }}>
                  Contact Person
                </BootstrapDialogTitle>
              </>
            ) : null}

            <Grid container spacing={1}>
              {!data ? (
                <>
                  <Grid item md={6} sm={6} xs={6}>
                    <DefaultInput
                      fullWidth
                      id="firstName"
                      autoComplete="firstName"
                      label="First Name*"
                      placeholder="Enter First Name*"
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      {...getFieldProps('firstName')}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} />
                  <Grid item md={6} sm={6} xs={6}>
                    <DefaultInput
                      fullWidth
                      id="middleName"
                      autoComplete="middleName"
                      label="Middle Name"
                      placeholder="Enter Middle Name"
                      error={Boolean(touched.middleName && errors.middleName)}
                      helperText={touched.middleName && errors.middleName}
                      {...getFieldProps('middleName')}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} />
                  <Grid item md={6} sm={6} xs={6}>
                    <DefaultInput
                      fullWidth
                      id="lName"
                      autoComplete="lName"
                      label="Last Name*"
                      placeholder="Enter Last Name*"
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      {...getFieldProps('lastName')}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} />
                  <Grid item md={6} sm={6} xs={6}>
                    <DefaultInput
                      fullWidth
                      id="email"
                      autoComplete="email"
                      label="Email*"
                      placeholder="Enter Email*"
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      {...getFieldProps('email')}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} />
                  <Grid item md={6} sm={6} xs={6}>
                    <DefaultInput
                      fullWidth
                      id="contact"
                      type="number"
                      autoComplete="contact"
                      label="Mobile Number*"
                      placeholder="Enter Mobile No*"
                      error={Boolean(touched.mobile && errors.mobile)}
                      helperText={touched.mobile && errors.mobile}
                      {...getFieldProps('mobile')}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} />
                  <Grid item md={6} sm={6} xs={6}>
                    <DefaultInput
                      fullWidth
                      id="username"
                      autoComplete="username"
                      placeholder="Enter UserName*"
                      label="Username*"
                      error={Boolean(touched.username && errors.username)}
                      helperText={touched.username && errors.username}
                      {...getFieldProps('username')}
                    />
                  </Grid>
                  <Grid item md={6} sm={6} xs={6} />
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      fullWidth
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="password"
                      label="Password*"
                      placeholder="Password*"
                      style={{ width: '80%', marginLeft: '40px' }}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      InputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword} edge="end">
                              <Iconify
                                icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                                style={{ color: '#214c50' }}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('password')}
                    />
                  </Grid>
                </>
              ) : null}

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    style={{ marginLeft: '50px', marginTop: '12px' }}
                    control={
                      <Switch
                        thumbSwitchedStyle={{ backgroundColor: 'green' }}
                        checked={values.treeNumberConfirmation}
                        onChange={(e) => setFieldValue('treeNumberConfirmation', e.target.checked)}
                      />
                    }
                    label="Show Tree Number Confirmation Dialog"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>

              <BootstrapDialogTitle id="customized-dialog-title">
                {logoValue ? 'Uploaded Logo' : 'Upload Logo*'}
              </BootstrapDialogTitle>
              {isEditable && logoValue && ! isImageRemoved ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Link
                      fullWidth
                      style={{ width: '88%', marginLeft: 55 }}
                      target="_blank"
                      rel="noopener"
                      href={`${logoValue}`}>
                      View Uploaded Image
                    </Link>

                    <IconButton
                      color={'error'}
                      aria-label={'delete'}
                      size="large"
                      onClick={() => handleImageRemove(logoValue)}>
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ width: '80%', marginLeft: '40px' }}
                    id="logo"
                    type={'file'}
                    autoComplete="amount"
                    // placeholder="Upload Logo"
                    value={logoValue}
                    error={Boolean(logoError)}
                    helperText={logoError}
                    onChange={(e) => handleLogoChange(e)}
                  />
                  {/* <label htmlFor="logo">Click me to upload image</label> */}
                </Grid>
              )}


              
<BootstrapDialogTitle id="customized-dialog-title">
              {leterHeadValue  ? 'Uploaded Letterhead Header' :" Upload Letterhead Header *"}
              </BootstrapDialogTitle>
              {isEditable && leterHeadValue && !leterHeadRemove ? (
                   <Grid container spacing={1}>
                   <Grid item xs={12}>
                     <Link
                       fullWidth
                       style={{ width: '88%', marginLeft: 55 }}
                       target="_blank"
                       rel="noopener"
                       href={`${leterHeadValue}`}>
                     View Uploaded Letterhead Header
                     </Link>
 
                     <IconButton
                       color={'error'}
                       aria-label={'delete'}
                       size="large"
                       onClick={() => handleleterHeadRemove(leterHeadValue)}>
                       <CancelIcon fontSize="inherit" />
                     </IconButton>
                   </Grid>
                 </Grid>
              ):

              <Grid item xs={12}>
                  <TextField
                    fullWidth
                    style={{ width: '80%', marginLeft: '40px' }}
                    id="logo"
                    type={'file'}
                    autoComplete="amount"
                    // placeholder="Upload Logo"
                    value={leterHeadValue}
                    error={Boolean(leterHeadError)}
                    helperText={leterHeadError}
                    onChange={(e) => handleLetterHeadChange(e)}
                  />
                 
                </Grid>
}
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            autoFocus
            onClick={(e) => {
              validateLogo();
              validateLeterHead();
              formik.handleSubmit(e);
            }}
            variant="contained">
            {data ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
