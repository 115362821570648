import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  IconButton,
  Modal,
} from '@mui/material';
import moment from 'moment';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Visibility } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import CountButton from '../../components/statusbutton/CountButton';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import USERLIST from '../../_mock/user';
import TreeData from '../../components/JsonFiles/TreeData.json';
import WardDialog from '../../components/DialogBox/WardDialog';
import { GetDeniedEntry, SearchDeniedEntry, exportDeniedReports } from '../../actions/DeniedEntryAction';
import { GetActiveCouncil } from '../../actions/CouncilAction';
import { GetActiveZonesByCouncilId } from '../../actions/ZonesAction';
import { GetActiveWardsByCouncilId } from '../../actions/WardsActions';
import ViewImageDialog from '../../components/DialogBox/tree-data/ViewImageDialog';
// import TeamListToolbar from '../../sections/@dashboard/teams/TeamListToolbar';
import ImageCarousel from '../../components/ImageCarousel';
import DeniedListToolbar from "../../sections/@dashboard/tree/DeniedListToolBar"
import { SetNewAlert } from '../../actions/AlertActions';
import TreeCuttingListMenu from '../../sections/@dashboard/tree-cutting/TreeCuttingListMenu';
import TreeCuttingToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingToolbar';
import MomDialog from '../../components/DialogBox/TreeCutting/MomDialog';
import { GetDemandedTreeCuttingList, GetTreeCuttingMom } from '../../actions/TreeCuttingPermissionAction';
import StatusPendngButton from '../../components/statusbutton/StatusPendngButton';
import StatusApprovedButton from '../../components/statusbutton/StatusApprovedButton';
import StatusUnapprovedButton from '../../components/statusbutton/StatusUnapprovedButton';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'dateOfMeeting', label: 'Date of Meeting', alignRight: false },
  { id: 'remark', label: 'Remark', alignRight: false },
  { id: 'document', label: 'Document', alignRight: false }
];

// ----------------------------------------------------------------------

export default function MomTreeCutting() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [zoneId, setZoneId] = useState('');
  const [wardId, setWardId] = useState('');
  const [coucilId, setCouncilId] = useState('');
  const [coucilName, setCouncilName] = useState('');
  const [imageList, setImageList] = useState([]);
  const [showList, setShowList] = useState(true);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [dialogData, setDialogData] = useState('');
  const [openImageList, setOpenImageList] = useState(false);
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);
  const { council, zones, wards, deniedEntry, pageInfo, exportCensusReport,
    isReportDownloadable,treeCuttingMom,addTreeCuttingMomLog } = useSelector((state) => ({
      council: state.council.activeCouncil,
      zones: state.zones.zones,
      wards: state.wards.wards,
      treeCuttingMom: state.treeCutting.treeCuttingMom,
      addTreeCuttingMomLog: state.treeCutting.addTreeCuttingMomLog,
      excelTreeCuttingApplications: state.treeCutting.excelTreeCuttingApplications,
      pageInfo: state.treeCutting.pageInfo,
      exportCensusReport: state.deniedEntry.exportCallLogs,
      isReportDownloadabletCallLogs: state.deniedEntry.isReportDownloadable
    }));


    const { applicationId } = useParams();
  const councilArr = council?.find((val) => val.id === coucilId);
  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  const secondRun = useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    setShowList(true);
  }, [treeCuttingMom]);
  console.log("TREE CUTTING MOM",treeCuttingMom)

  useEffect(() => {
    dispatch(GetActiveCouncil(1));
    dispatch(GetTreeCuttingMom(applicationId,1,rowsPerPage));
    dispatch(GetDemandedTreeCuttingList(applicationId))
    // dispatch(GetBaseColorTreeById(1));
  }, [addTreeCuttingMomLog]);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
    }
  }, [pageInfo]);

  const handleNewUserClick = (data) => {
    setOpen(!open);
    setDialogData(data)
  };

  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen);
    setImageList(images || []);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShowList(false);
    dispatch(GetTreeCuttingMom(applicationId,newPage, rowsPerPage));
  };

  const handleChangeRowsPerPage = (event, value) => {
    setShowList(false);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    if (search) {
      dispatch(SearchDeniedEntry(1, parseInt(event.target.value, 10), coucilId, zoneId, wardId, searchValue));
    } else {
      dispatch(GetDeniedEntry(1, parseInt(event.target.value, 10), coucilId, zoneId, fromDate, toDate));
    }
  };
  function handleClick(event) {
    event.preventDefault();
    // console.info('You clicked a breadcrumb.');
  }

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        dispatch(SearchDeniedEntry(1, rowsPerPage, coucilId, zoneId, wardId));
        setSearch(true);
        setShowList(false);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetDeniedEntry(1, rowsPerPage, coucilId, zoneId, wardId, fromDate, toDate));
        setShowList(false);
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
  };


  const [exportButtonPressed, setExportButtonPressed] = useState(false);

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel()
      setExportButtonPressed(false);
    }
  }, [isReportDownloadable, exportCensusReport]);
  const header = ["#", "Application Number", "Name of Applicant",'Property Numebr', "Ward", "Mobile", "Email", "Location",
    "Total Trees", "Purpose"];
  function DownloadReportExcel() {
    const value1 = [];
    exportCensusReport?.map((option, index) => {
      const value2 = [index + 1]
      value2.push(option.zone)
      value2.push(option.ward)
      value2.push(option.denied_for)
      value2.push(option.latitude)
      value2.push(option.longitude)
      value2.push(option.location_accuracy)
      // value2.push(option.location_type)
      value2.push(option.property_type)
      value2.push(option.property_number)
      value2.push(option.owner_name)
      value1.push(value2)
      return null
    })
    downloadExcel({
      fileName: `Tree Cutting`,
      sheet: "Tree Cutting",
      tablePayload: {
        header,
        // accept two different data structures
        body: value1
      },
    });
  }

  const requestForWithoutFilter = () => {
    // buttonClick(false)
    // console.log("ClickButton true")
    dispatch(SetNewAlert({
      msg: "Please Select Council First",
      alertType: "danger",
    }))

  }
  const exportReports = () => {
    if(coucilId && fromDate && toDate){
    const requestObj = {
      council_id: coucilId,
      ward_id: wardId,
      zone_id: zoneId,
      from_date: fromDate.split('-').reverse().join('-'),
      to_date: toDate.split('-').reverse().join('-'),
    };
    console.log("coucilId", coucilId, zoneId, wardId, fromDate, toDate)
    dispatch(exportDeniedReports(requestObj))
    setExportButtonPressed(true);
  }
  else {
    requestForWithoutFilter()
  }

  }

  const handleSubmit = () => {
   
    console.log("HANDLE SUBMIR")

  }

  const handleViewDocument = (fpath) => {
    if (fpath.includes(process.env.REACT_APP_BASE_URL)) {
      // console.log("file path", fpath);
      window.open(fpath, '_blank');
    } else {
      const fLink = process.env.REACT_APP_BASE_URL.concat('/').concat(fpath);
      //  console.log("file path", fLink);
      window.open(fLink, '_blank');
    }
  };


  return (
    <Page title="User">
      <Container>
      {open ? <MomDialog isOpen={open} handleClose={handleNewUserClick} data={applicationId} /> : null}
        {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Mom
            
            <Typography variant="h6" style={{ fontWeight: 400 }}  >
              It is showing list of all mom
            </Typography>
          </Typography>
          {treeCuttingMom?.length===0 || treeCuttingMom?.[0].applicant_status==="Pending"?
            <Button
              variant="contained"
              onClick={()=>handleNewUserClick("1")}
            >
              Add Mom
            </Button>:null}
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead headLabel={TABLE_HEAD} />
                {/* {!coucilId ? (
                  <TableRow>
                    <TableCell align="center" colSpan={8} fontWeight={700}>
                      Please select council to get data
                    </TableCell>
                  </TableRow>
                ) : null} */}
                <TableBody>
                  {showList
                    ? treeCuttingMom?.map((option, index) => {
                      return (
                        <TableRow hover >
                          <TableCell align="left" >
                            <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                          </TableCell>
                          <TableCell align="left">
                            {option?.date_of_meeting}
                          </TableCell>
                    
                          <TableCell align="left">
                            {option?.remarks}
                          </TableCell>
                          
                          <TableCell align="left">
                            <IconButton
                              aria-label="delete"
                              size="large"
                              // onClick={()=>handleViewOpen(option.images)}
                              onClick={(e) => {
                                handleViewDocument(option?.cutting_tree_meeting_document[0].url)
                                // window.open(option?.cutting_tree_meeting_document[0].url,'_blank')
                              }}
                              sx={{ color: '#214c50' }}
                            >
                              <Visibility />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal
              open={openImageList}
              onClose={handleCloseImageList}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Container style={{ width: '526px' }}>
                <ImageCarousel imagelist={imageList} />
              </Container>
              {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
            </Modal>
          </Scrollbar>
          {deniedEntry ? (
            <Pagination
              count={showList ? pageInfo.last_page : 0}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
