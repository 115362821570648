import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Chip } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  
  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

export default function CuttingTreeListModal(props) {
  const { isOpen } = props;
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const handleClose = () => {
    props.handleClose(false);
  };

  const handleTrueClose = () => {
    props.handleClose(true);
  };

  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
    chipSelected: {
      backgroundColor: '#214c50',
      color: '#fff',
    },
    chip: {
      backgroundColor: '#fff',
      color: '#000',
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();


  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <BootstrapDialogTitle onClose={handleClose}>{"Tree List"}</BootstrapDialogTitle>
        <Divider/>
        <DialogContent>
        <Grid container spacing={1} style={{marginTop:'10px'}}>
          {props?.treeList?.map((value,index)=>{
            return  <Chip
            label={value.tree_number}
            key={value.tree_number}
            variant="outlined"
            className={classes.chipSelected}
            style={{
              fontWeight: '700',
              borderRadius: '7px',
              border: 'none',
            }}
            sx={{ mr: 1.5,mb: 1.5 }}
          />
          })}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
