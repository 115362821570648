import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
// import { MarkerClusterer } from "@googlemaps/markerclusterer";
// import type { Marker } from "@googlemaps/markerclusterer";
import {
  IconButton,
  Box,
  Container,
  Modal,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
} from '@mui/material';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
  GoogleMap,
  Marker,
  AdvancedMarker,
  MarkerClusterer,
  InfoWindow,
  useLoadScript,
  withGoogleMap,
} from '@react-google-maps/api';
import { Visibility } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import ImageCarousel from '../../ImageCarousel';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const style = {
  margin: '10px',
  position: 'relative',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.transparent',
  // border: '1px solid #000',
  boxShadow: 24,
  p: 0,
};

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'relative',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: true,
};

export default function TreeDetailsWithReasonDialog(props) {
  const dispatch = useDispatch();
  const { isOpen, data } = props;
  const [close, setClose] = React.useState(true);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [imageList, setImageList] = useState([]);
  console.log('imageList', imageList);
  const [openImageList, setOpenImageList] = useState(false);
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);

  const handleClose = () => {
    props.handleClose(false);
  };

  const handleClick = () => {
    setClose(false);
  };

  console.log('data1998', data);
  return (
    //   <Modal
    //   open={isOpen}
    //   onClose={handleClose}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    // >
    <Dialog open={isOpen && close} onClose={handleClose} aria-describedby="modal-modal-description" fl>
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <Table
          style={{ border: 'none' }}
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}>
          <TableBody style={{ border: 'none' }}>
            <TableRow>
              <TableCell
                style={{
                  paddingLeft: '0px',
                  paddingTop: '5px',
                  paddingBottom: '0px',
                  textIndent: '10px',
                  whiteSpace: 'nowrap',
                }}
              />
              <TableCell
                align="right"
                style={{
                  paddingLeft: '0px',
                  paddingTop: '5px',
                  paddingBottom: '0px',
                  textIndent: '10px',
                  whiteSpace: 'nowrap',
                }}>
                {' '}
                <IconButton
                  sx={{
                    color: 'black',
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    boxShadow: '0 0 5px rgba(0,0,0,0.2)',
                    width: '30px',
                    height: '30px',
                  }}>
                  <CloseIcon onClick={() => handleClick()} fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableCell style={{fontWeight:700}}>Tree Details:</TableCell>

            <TableRow>
              <TableCell align="left" style={{ paddingLeft: '0px', paddingBottom: '0px', textIndent: '10px' }}>
                Tree Number
              </TableCell>
              <TableCell align="left" style={{ paddingBottom: '0px' }}>
                {data.tree_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  paddingLeft: '0px',
                  paddingTop: '5px',
                  paddingBottom: '0px',
                  textIndent: '10px',
                  whiteSpace: 'nowrap',
                }}>
                Tree Name
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data.tree_name?.name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{
                  paddingLeft: '0px',
                  paddingTop: '5px',
                  paddingBottom: '0px',
                  textIndent: '10px',
                  whiteSpace: 'nowrap',
                }}>
                Botanical Name
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px', textIndent: '10px',marginRight:'19px' }}>
                {data.tree_name?.botanical_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Tree Type
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data.tree_type?.tree_type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Location Type
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data?.location_type?.location_type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Property Type
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data.property_type?.property_type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Property Number
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data.property?.property_number}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Owner Name
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data.property?.owner_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Tenant Name
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data.property?.tenant_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={{ paddingLeft: '0px', paddingBottom: '0px', textIndent: '10px' }}>
                Area(Sq feet)
              </TableCell>
              <TableCell align="left" style={{ paddingBottom: '0px' }}>
                {data.property?.area ? data.property?.area : '-'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Plantation Date
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data.plantation_date}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Location Type
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data?.location_type?.location_type}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Tree Condition
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data?.tree_condition?.condition}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Girth(cm)
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data?.girth}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Height(markers)
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data?.height}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="left"
                style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', textIndent: '10px' }}>
                Canopy
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                {data?.canopy}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '0px', textIndent: '10px' }}>
                Images
              </TableCell>
              <TableCell align="left" style={{ paddingTop: '0px' }}>
                <IconButton
                  aria-label="delete"
                  size="large"
                  // onClick={()=>handleViewOpen(treeDetails.images)}
                  onClick={(e) => {
                    setImageList(data.images || []);
                    handleOpenImageList(e);
                  }}
                  style={{ color: '#214c50' }}>
                  <Visibility />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Modal
        open={openImageList}
        onClose={handleCloseImageList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Container style={{ width: '526px' }}>
          <ImageCarousel imagelist={imageList} />
        </Container>
      </Modal>
    </Dialog>
  );
}
