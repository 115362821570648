import {
  ADD_NEWSPAPER_PROCLAMATION,
  UPLOAD_PROCLAMATION_RECIPT,
  ADD_PROCLAMATION_OBJECTION,
  RESET_STATE,
  ADD_DEPOSITE_AMOUNT_RECEIPT,
} from '../actions/Types';

const INIT_STATE = {
  newspaperProclamation: null,
  uploadProclamationRecipt: null,
  proclamationObjection: null,
  depositeAmountReceipt: null
  
};

export default function ProclamationReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_NEWSPAPER_PROCLAMATION:
      return {
        ...state,
        newspaperProclamation: payload.data.data,
      };
    case UPLOAD_PROCLAMATION_RECIPT:
      return {
        ...state,
        uploadProclamationRecipt: payload.data,
      };
    case ADD_PROCLAMATION_OBJECTION:
      return {
        ...state,
        proclamationObjection: payload.data,
      };
      case ADD_DEPOSITE_AMOUNT_RECEIPT:
        return{
          ...state,
          depositeAmountReceipt: payload.data

        }

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
}
