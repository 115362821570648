import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import moment from 'moment';
import Stack from '@mui/material/Stack';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Visibility } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Container, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GetActiveTreeType } from '../../../actions/TreeTypeActions';
import { GetActiveTreeName } from '../../../actions/TreeNameAction';
import { GetActiveTreeDisease } from '../../../actions/TreeDiseaseAction';
import { GetActiveTreeConditions } from '../../../actions/TreeConditionAction';

import { GetUsers } from '../../../actions/UserAction';
import { AddUserToTeam } from '../../../actions/TeamsAction';
import { GetQcRemarksForBaseColor } from '../../../actions/BaseColorAction';
import ViewImageDialog from './ViewImageDialog';
import Iconify from '../../Iconify';
import ImageCarousel from '../../ImageCarousel';
// import Typography from 'src/theme/overrides/Typography';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
}));
export default function CensusViewDetailsDialog(props) {
  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, data, isOpenConfirm, teamId } = props;
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [state, setState] = React.useState('');
  const [role, setRole] = React.useState([]);
  const [topModalOpen, setTopModalOpen] = React.useState(false);
  const [reqObj, setReqObj] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [imageList, setImageList] = React.useState([]);
  const [openImageList, setOpenImageList] = React.useState(false);
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);
  const userPermissions = [];

  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  const { treeActiveType, treeName, treeDisease, treeCondition, loggedUser } = useSelector((state) => ({
    treeActiveType: state.treeType.activeTreeType,
    treeName: state.treeName.activeTreeName,
    treeDisease: state.treeDisease.activeTreeDisease,
    treeCondition: state.treeConditions.activeTreeCondition,
    loggedUser: state.auth.loggedUser,
  }));
  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  const userRole = loggedUser?.roles[0].slug;

  useEffect(() => {
    dispatch(GetActiveTreeType(1));
    dispatch(GetActiveTreeName(1));
    dispatch(GetActiveTreeDisease(1));
    dispatch(GetActiveTreeConditions(1));
  }, []);
  const [inputField, setInputField] = useState({
    locationType: '',
    treeNumber: '',
    // treeType: "",
    treeName: '',
    treeBName: '',
    girth: '',
    height: '',
    treeCanopy: '',
    treeCondition: '',
    plantationDate: '',
    actionToBeTaken: '',
    censunTreeId: '',
  });
  const handleClose = () => {
    props.handleClose(inputField);
  };
  console.log('DSATA', data);

  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen);
    setImageList(images || []);
  };

  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTreeNameChange = (e) => {
    const { name, value } = e.target;
    setInputField((prevState) => ({
      ...prevState,
      // [name]: value,
      treeName: e.target.value,
    }));
  };
  const handleTreeDiseasechange = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      // [name]: value,
      treeDisease: e.target.value,
    }));
  };
  const handleTreeConditionchange = (e) => {
    setInputField((prevState) => ({
      ...prevState,
      // [name]: value,
      treeCondition: e.target.value,
    }));
  };

  const [showEdit, setShowEdit] = useState(false);
  const showEditableView = () => {
    setShowEdit(!showEdit);
  };

  const DesignationsSchema = Yup.object().shape({
    // treeNumber: Yup.string().required('Tree number is required'),
    treeNumber: Yup.number().typeError('Tree number should be number').required('Tree number is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      treeNumber: props.treeNumber ? props.treeNumber.split('-')[props.treeNumber.split('-').length - 1] : '',
    },
    validationSchema: DesignationsSchema,
    onSubmit: (value) => {
      props.saveTreeDetails();
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const reLast = /-\d+$/;

  console.log('VALUES', values);

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open max-width dialog
      </Button> */}
      {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        // onClose={handleClose}
        // onClose={handleClose}
      >
        <Box>
          <Stack justifyContent="space-between" direction="row" useFlexGap flexWrap="wrap">
            <BootstrapDialogTitle>View Details</BootstrapDialogTitle>
            {data?.qc_status === 'Approved' && userPermissions.includes('update-census-tree-details') ? (
              <Button
                size="small"
                variant="contained"
                style={{ height: '35px', margin: 'auto 4px' }}
                onClick={showEditableView}>
                <Iconify icon="eva:edit-fill" width={20} height={20} /> Edit
              </Button>
            ) : (
              <></>
            )}
          </Stack>
        </Box>

        <Divider />
        <DialogContent>
          {data ? (
            <div>
              <div>
                {showEdit === true ? (
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Property Type"
                          disabled
                          variant="outlined"
                          name="locationType"
                          value={data?.location_type?.location_type}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Accuracy Captured"
                          disabled
                          variant="outlined"
                          name="accuracyCaptured"
                          value={data?.location_accuracy}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Property Type"
                          disabled
                          variant="outlined"
                          name="propertyType"
                          value={data?.property_type?.property_type}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="treeNumber"
                          variant="outlined"
                          label="Tree Number"
                          name="treeNumber"
                          defaultValue={data?.tree_number.split('-')[data?.tree_number.split('-').length - 1]}
                          value={props.treeNumber.split('-')[props.treeNumber.split('-').length - 1]}
                          onChange={(e) => {
                            props.handleTreeNumberDataChange(e);
                            formik.handleChange(e);
                          }}
                          // onChange={props.handleTreeNumberDataChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">{`${data?.tree_number?.replace(
                                reLast,
                                ''
                              )}-`}</InputAdornment>
                            ),
                          }}
                          error={Boolean(touched.treeNumber && errors.treeNumber)}
                          helperText={touched.treeNumber && errors.treeNumber}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Property Owner"
                          disabled
                          variant="outlined"
                          name="propertyOwner"
                          value={data?.property?.owner_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Property Address"
                          disabled
                          variant="outlined"
                          name="propertyAddress"
                          value={props.data?.property?.address ? props.data?.property?.address : '-'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Property Number"
                          disabled
                          variant="outlined"
                          name="propertyNumber"
                          value={props.data?.property?.property_number ? props.data?.property?.property_number : '-'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Tenant Name"
                          disabled
                          variant="outlined"
                          name="tenantName"
                          value={props.data?.property?.area}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Area(sq feet)"
                          disabled
                          variant="outlined"
                          name="tenantName"
                          value={data?.property?.tenant_name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          select
                          id="treeType"
                          name="treeType"
                          label="Tree Type"
                          value={props.treeType}
                          displayEmpty
                          inputProps={{
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                          onChange={props.handleTreeTypehange}>
                          {treeActiveType?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.tree_type}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          select
                          id="treeName"
                          name="treeName"
                          label="Tree Name"
                          value={props.treeName}
                          displayEmpty
                          inputProps={{
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                          onChange={props.handleTreeNameChange}>
                          {treeName?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          disabled
                          id="outlined-basic"
                          variant="outlined"
                          label="Tree Name(Botanical)"
                          name="treeBName"
                          labek="Tree Name(Botanical)"
                          value={props.treeBName}
                          onChange={props.inputsHandler}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          name="girth"
                          label="Girth"
                          value={props.girth}
                          onChange={props.inputsHandler}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          name="height"
                          label="Height"
                          value={props.height}
                          onChange={props.inputsHandler}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          name="treeCanopy"
                          label="Canopy"
                          value={props.treeCanopy}
                          onChange={props.inputsHandler}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          select
                          id="treeCondition"
                          name="treeCondition"
                          label="Tree Condition"
                          value={props.treeCondition}
                          displayEmpty
                          inputProps={{
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                          onChange={props.handleTreeConditionchange}>
                          {treeCondition?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.condition}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          select
                          id="treeDisease"
                          name="treeDisease"
                          label="Tree Disease"
                          value={props.treeDisease}
                          displayEmpty
                          inputProps={{
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                          onChange={props.handleTreeDiseasechange}>
                          {treeDisease?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.tree_disease}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          name="plantationDate"
                          label="Plantation Date"
                          InputLabelProps={{ shrink: true }}
                          type="date"
                          value={props.plantationDate}
                          onChange={props.inputsHandler}
                          inputProps={{ max: todayDate }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Referred To Expert?"
                          disabled
                          variant="outlined"
                          name="referredExpert"
                          value={data?.referred_to_expert === 1 ? 'Yes' : 'No'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          name="actionToBeTaken"
                          label="Action To Be Taken"
                          value={inputField.actionToBeTaken}
                          onChange={inputsHandler}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Added By"
                          disabled
                          variant="outlined"
                          name="addedBy"
                          value={
                            (props.data.added_by?.first_name ? props.data.added_by?.first_name : '-') +
                            (props.data.added_by?.last_name ? props.data.added_by?.last_name : '-')
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Updated By"
                          disabled
                          variant="outlined"
                          name="updatedBy"
                          value={
                            (props.data.updated_by?.first_name ? props.data.updated_by?.first_name : '-') +
                            (props.data.updated_by?.last_name ? props.data.updated_by?.last_name : '-')
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="QC Status"
                          disabled
                          variant="outlined"
                          name="QcStatus"
                          value={data?.qc_status}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Qc By"
                          disabled
                          variant="outlined"
                          name="QcBy"
                          value={
                            (data?.qc_by ? props.data.qc_by.first_name : '-') +
                            (data?.qc_by ? props.data.qc_by.last_name : '-')
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Qc On Date"
                          disabled
                          variant="outlined"
                          name="QCDate"
                          value={moment(data?.qc_date ? props.data.qc_date : '-').format('YYYY-DD-MM')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <h6>Images:</h6>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          // onClick={() => handleViewOpen(props.data.images)}
                          onClick={(e) => {
                            setImageList(props.data.images || []);
                            handleOpenImageList(e);
                          }}
                          sx={{ color: '#214c50' }}>
                          <Visibility />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <table style={{ fontFamily: 'arial, sans-serif', borderCollapse: 'collapse', width: '100%' }}>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Location Type
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.location_type?.location_type}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Accuracy Captured
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.location_accuracy}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Property Type
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.property_type?.property_type}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Tree Number</td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.tree_number}
                        </td>

                        {/* <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="treeNumber"
                          value={props.treeNumber}
                          onChange={props.inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Property Owner
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.property?.owner_name}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Property Address
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {props.data?.property?.address ? props.data?.property?.address : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Property Number
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {props.data?.property?.property_number ? props.data?.property?.property_number : '-'}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Tenant Name </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.property?.tenant_name}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Area(sq feet)
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {props.data?.property?.area}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Tree Type</td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.tree_type.tree_type}
                        </td>

                        {/* <TextField
                          fullWidth
fullWidth
fullWidth
fullWidth
select
                          id="treeType"
                          name="treeType"
                          value={props.treeType}
                          displayEmpty
                           inputProps={{
                              classes: {
                                icon: classes.icon
                              }
                            }}
                          onChange={props.handleTreeTypehange}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        >

                          {treeActiveType?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.tree_type}
                            </MenuItem>
                          ))}
                        </TextField> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Tree Name(local)
                        </td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.tree_name.name}
                        </td>

                        {/* <TextField
                          fullWidth
fullWidth
fullWidth
fullWidth
select
                          id="treeName"
                          name="treeName"
                          value={props.treeName}
                          displayEmpty
                           inputProps={{
                              classes: {
                                icon: classes.icon
                              }
                            }}
                          onChange={props.handleTreeNameChange}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        >

                          {treeName?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Tree Name(Botanical)
                        </td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.tree_name.botanical_name}
                          {/* <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="treeBName"
                          value={props.treeBName}
                          onChange={props.inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                        </td>

                        {/* <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="treeBName"
                          value={props.treeBName}
                          onChange={props.inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Girth(cm)</td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.girth}
                        </td>

                        {/* <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="girth"
                          value={props.girth}
                          onChange={props.inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Height(m)</td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.height}
                        </td>
                        {/*                       
                        <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="height"
                          value={props.height}
                          onChange={props.inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Canopy</td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.canopy}
                        </td>
                        {/* <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="treeCanopy"
                          value={props.treeCanopy}
                          onChange={props.inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Tree Condition
                        </td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.tree_condition?.condition}
                        </td>

                        {/* <TextField
                          fullWidth
fullWidth
fullWidth
fullWidth
select
                          id="treeCondition"
                          name="treeCondition"
                          value={props.treeCondition}
                          displayEmpty
                           inputProps={{
                              classes: {
                                icon: classes.icon
                              }
                            }}
                          onChange={props.handleTreeConditionchange}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        >

                          {treeCondition?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.condition}
                            </MenuItem>
                          ))}
                        </TextField> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Disease</td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.tree_disease?.tree_disease ? props.data.tree_disease?.tree_disease : '-'}
                        </td>
                        {/* <TextField
                          fullWidth
fullWidth
fullWidth
fullWidth
select
                          id="treeDisease"
                          name="treeDisease"
                          value={props.treeDisease}
                          displayEmpty
                           inputProps={{
                              classes: {
                                icon: classes.icon
                              }
                            }}
                          onChange={props.handleTreeDiseasechange}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        >

                          {treeDisease?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.tree_disease}
                            </MenuItem>
                          ))}
                        </TextField> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Plantation Date
                        </td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.plantation_date}
                        </td>

                        {/* <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="plantationDate"
                          type="date"
                          value={props.plantationDate}
                          onChange={props.inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                      </tr>
                      {userRole === 'council' ? null : (
                        <tr>
                          <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                            Referred To Expert?
                          </td>
                          <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                            {data?.referred_to_expert === 1 ? 'Yes' : 'No'}
                          </td>
                        </tr>
                      )}

                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          Action Need To Be Taken
                        </td>

                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {data?.action_need ? props.data.action_need : '-'}
                        </td>
                        {/* <TextField
                          fullWidth
                            id="outlined-basic"
                          variant="outlined"
                          name="actionToBeTaken"
                          value={inputField.actionToBeTaken}
                          onChange={inputsHandler}
                          disableUnderline={false}
                          className={classes.textField}
                          InputProps={{
                            classes: { notchedOutline: classes.noBorder }
                          }}
                        /> */}
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Images</td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          <IconButton
                            aria-label="delete"
                            size="large"
                            // onClick={() => handleViewOpen(props.data.images)}
                            onClick={(e) => {
                              setImageList(props.data.images || []);
                              handleOpenImageList(e);
                            }}
                            sx={{ color: '#214c50' }}>
                            <Visibility />
                          </IconButton>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Added By</td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {props.data.added_by?.first_name ? props.data.added_by?.first_name : '-'}{' '}
                          {props.data.added_by?.last_name ? props.data.added_by?.last_name : '-'}{' '}
                        </td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Updated By</td>
                        <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                          {props.data.updated_by?.first_name ? props.data.updated_by?.first_name : '-'}{' '}
                          {props.data.updated_by?.last_name ? props.data.updated_by?.last_name : '-'}
                        </td>
                      </tr>
                      {userRole === 'council' ? null : (
                        <>
                          <tr>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                              QC Status
                            </td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                              {data?.qc_status}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>Qc By</td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                              {data?.qc_by ? props.data.qc_by.first_name : '-'}{' '}
                              {data?.qc_by ? props.data.qc_by.last_name : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                              Qc On Date
                            </td>
                            <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}>
                              {data?.qc_date ? props.data.qc_date : '-'}
                            </td>
                          </tr>
                        </>
                      )}
                    </table>
                  </div>
                )}
              </div>
            </div>
          ) : null}
        </DialogContent>
        <Modal
          open={openImageList}
          onClose={handleCloseImageList}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Container style={{ width: '526px' }}>
            <ImageCarousel imagelist={imageList} />
          </Container>
          {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
        </Modal>
        <Divider />
        <DialogActions>
          {showEdit === true ? (
            <Button onClick={handleSubmit} color="success" variant="contained" style={{ color: '#fff' }}>
              Save
            </Button>
          ) : (
            <></>
          )}
          <Button onClick={handleClose} style={{ background: '#E85454', color: '#fff' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
