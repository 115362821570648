/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { GoogleMap, Marker, InfoWindow, useLoadScript, withGoogleMap } from '@react-google-maps/api';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import DescriptionIcon from '@mui/icons-material/Description';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import { Code } from '@mui/icons-material';
import Icon from '@mui/material/Icon';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  InputLabel,
  FormControl,
  Stack,
  Avatar,
  Checkbox,
  Card,
  CircularProgress,
  InputAdornment,
  Chip,
  Alert,
  Link,
  FormControlLabel,
  CardMedia,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik, useFormikContext } from 'formik';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import CardContent from '@mui/joy/CardContent';
import zIndex from '@material-ui/core/styles/zIndex';
import { UploadFile, UploadImage } from '../../actions/UploadActions';
// import trees from '../../_mock/trees';
import Map from '../Map/CustomGoogleMaps';

import WarningMessageDialog from '../../components/DialogBox/WarningMessageDialog';
import { GetActiveWards, GetActiveWardsByCouncilId } from '../../actions/WardsActions';
import { GetTreeCuttingReasonsList, GetTreeNameList } from '../../actions/TreeCuttingReasonAction';
import CreateTreeCuttingPermissionDialog from '../../components/DialogBox/TreeCutting/CreateTreeCuttingPermissionDialog';
import { GetPropertyByCouncilAndWard, GetPropertyByCouncilZoneWard } from '../../actions/PropertyAction';
import { SetNewAlert } from '../../actions/AlertActions';
import {
  CreateTreeCuttingApplication,
  GetCensusTreeByWardOrPropertyNumber,
  GetTreeCuttingApplicationById,
  UpdateTreeCuttingApplication,
} from '../../actions/TreeCuttingPermissionAction';
import {
  SearchTreesByTreeNumber,
  SearchTreesInSpecificLocation,
  GetSpecificTreeInfo,
} from '../../actions/TreeOnMapAction';
import TreeFill from '../../Assets/tree_on_map.png';
import TreeDetailsWithReasonDialog from '../../components/DialogBox/TreeCutting/TreeDetailsWithReasonDialog';
import { data } from '../Reports/Chart/Piechart';
import FullLoader from '../../components/Loader/FullLoader';
import TreeCuttingMap from '../Map/TreeCuttingMap';
// import { ShowLoader, ShowLoadingButton } from '../../actions/CommonAction';


export default function NewTreeCuttingPermission(props, item) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const applicationData = useParams();
  const applicationId = applicationData?.applicationId;

  const [open, setOpen] = React.useState(false);
  const [infoWindowOpen, setInfoWindowOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [topModalOpen, setTopModalOpen] = useState(false);

  const [treeDetailsOpen, setDialogOpen] = useState(false);
  const [applicationDocument, setApplicationDocument] = useState('');
  const [propertyNumber, setPropertyNumber] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [WardName, setWardName] = React.useState([]);
  const [selectedTreeList, setSelectedTreeList] = React.useState([]);

  const [Purpose, setCuttingPurpose] = useState('');
  const [treeNumber, setTreeNumber] = useState('');
  const [checked, setChecked] = React.useState(false);
  const [reasonForAll, setReasonState] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [documentType, setDocumentType] = useState([]);

  const [newFile, setNewFile] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentList, setDocumentList] = useState([
    { documentName: '', documentValue: '', errorName: '', errorValue: '', url: '' },
  ]);
  const [editDocList, setEditDocList] = useState([{ documentName: '', url: '' }]);

  const [editFile, setFile] = useState(false);
  const [uploadClick, setUploadClick] = useState('');
  const [uploadClickError, setUploadClickError] = useState('');
  const [uploadedPdf, setUploadedPdf] = React.useState('');
  const [fileUploadError, setFileUploadError] = React.useState('');
  const [fileSizeError, setFileSizeError] = React.useState('');
  const [showUploadLoader, setShowUploadLoader] = React.useState(false);
  const [isSubmittingapplication, setIsSubmittingapplication] = useState(false);
  // Remove below line
  const [latlong, setLatitudeLong] = React.useState({ latitude: 21.146633, longitude: 79.08886 });
  const [Addresslatlong, setAddressLatitudeLong] = React.useState({ latitude: 21.146633, longitude: 79.08886 });

  const [latLng, setLatLng] = React.useState({ lat: 21.146633, lng: 79.08886 });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const[submitLoader, setSubmitLoader] = useState(false);
  const[showPinDropMarker, setShowPinDropMarker] = useState(false);
  const[pinDropLatlng, setPinDropLatlng] = useState({});
  const[showPinLocationMessage, setShowPinLocationMessage] = useState(false);
  const[isSearchedLocationChanged, setIsSearchedLocationChanged] = useState(false);
  const [fixedMarkerPosition, setFixedMarkerPosition] = useState([])

  const [centerPosition, setCenterPosition] = useState({latitude:'',longitude:''})
  
  

  console.log('selectedOptions', selectedOptions);
  const [editCuttingTree, setEditCuttingTree] = useState([]);

  const [selectedTreesIn5Km, setSelectedTreesIn5Km] = useState([]);
  const [showList, setShowList] = useState(false);
  const [showImage, setShowImage] = useState(true);

  const [error, setError] = useState('');
  const [toggleFileUpload, setToggleFileUpload] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const options = [];
  const documentTypes = [
    { id: 'tax_receipt', type: 'Tax receipt' },
    { id: 'written_application', type: 'Written application' },
  ];

  const [visibleCards, setVisibleCards] = useState(options.map((item, index) => item.id));

  const [searchValue, setSearchValue] = React.useState('');
  const [applyToAll, setApplyToAll] = useState(false);

  const [selectedValue, setSelectedValue] = React.useState({ '': '' });
  const [showLoader, setShowLoader] = useState(false);
  const [isLocationChanged , setIsLocationChanged] = useState(false)
  const icon = {
    url: TreeFill,
    scaledSize: { width: 50, height: 42 },
  };
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'application/pdf'];
  const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'svg', 'pdf'];

  let timer = null;
  const filterByTreeNumber = (event) => {
    const { value } = event.currentTarget;
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const {
    uploadFile,
    uploadFileLog,
    wards,
    addTreeCuttingApplicationLog,
    council,
    properties,
    cuttingPurpose,
    treeLocation,
    treeDetails,
    treesIn5Km,
    treesByNumber,
    treeName,
    loggedUser,
    showLoadingButton
  } = useSelector((state) => ({
    uploadFile: state.upload.uploadFile,
    uploadFileLog: state.upload.uploadFileLog,
    wards: state.wards.activeWardsByCID,
    addTreeCuttingApplicationLog: state.treeCutting.addTreeCuttingApplicationLog,
    council: state.council.activeCouncil,
    properties: state.properties.properties,
    cuttingPurpose: state.treeCuttingReason.treeCuttingReason,
    treeName: state.treeCuttingReason.treeName,
    treeLocation: state.treeLocation.treeLocation,
    treesIn5Km: state.treeLocation.treesIn5Km,
    treesByNumber: state.treeLocation.treesByNumber || [],
    treeDetails: state.treeLocation.treeDetails,
    loggedUser: state.auth.loggedUser,
    showLoadingButton: state.common.showLoadingButton,
  }));
  console.log('trree', treeName);

  useEffect(() => {
    dispatch(GetActiveWardsByCouncilId(1, loggedUser?.council_id));
  }, []);
  const [treeByNumberData, setTreeByNumberData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (applicationId) {
      setShowLoader(true);

      dispatch(GetTreeCuttingApplicationById(applicationId)).then((response) => {
        setShowLoader(true);
        if (response.status === 200) {
          const data = response.data.data;
          console.log("datadatadata",data)
          const treeName = data?.cutting_trees?.[0]?.tree_name?.name;
          console.log("treeName",treeName)
          setSelectedName(treeName)
          if (data) {
            setShowLoader(false);
          }
          // Update Formik values

          setFieldValue('council_id', loggedUser.roles[0].slug === 'council' ? council?.[0]?.id : '');
          setFieldValue('applicantName', data.name || '');
          setFieldValue('mobile', data.mobile || '');
          setFieldValue('ward_id', data.ward_id || '');
          setFieldValue('property_number', data.property_number || '');
          setFieldValue('userLocation', data.location || '');
          setFieldValue('cutting_trees', data?.cutting_trees);
          // setFieldValue('tree_cutting_reason_id', data?.tree_cutting_reason_id)
          setFieldValue('address', data?.location);
          setFieldValue('address', data?.location);
          

          setSelectedOptions(data?.cutting_trees.map((treeData) => treeData) || []);
          // const editImges = data?.cutting_trees.map((treeData) => treeData.image_url);
          // console.log('editImges', editImges);
          // setEditCuttingTree((prevState) => [...prevState, editImges]);

          if (data && data.documents.length >= 0) {
            // setDocumentList(
            //   data.documents.map((doc) => ({
            //     documentName: doc.document_type || '',
            //     documentValue: doc.document_url || '',
            //     errorName: '',
            //     errorValue: '',
            //     url: doc.document_url || '',
            //   }))
            // );
            setEditDocList(
              data.documents.map((doc) => ({
                documentName: doc.document_type || '',
                url: doc.document_url || '',
              }))
            );
          }

          const newSelectedList = [...selectedTreeList];

          if (selectedOptions?.length > 0) {
            // Initialize newSelectedList with selectedOptions
            if (newSelectedList.length === 0) {
              selectedOptions.forEach((value) => {
                console.log("values78",value)
                const info = {
                  census_tree_id: value.id,
                  tree_number: value.tree_number,
                  tree_cutting_reason_id: 1,
                  tree_image: value.image_url,
                };
                newSelectedList.push(info);
              });
            }

            // Update state and Formik fields
            setSelectedTreeList(newSelectedList);
            setFieldValue('cutting_trees', newSelectedList);
            setFieldValue('total_trees', newSelectedList.length);
          }
        }
      });
    }
  }, [applicationId, dispatch]);

  useEffect(() => {
    if (!searchValue) {
      setTreeByNumberData([]);
      setLoading(false);
    }
    if (searchValue?.length > 2) {
      setLoading(true);
      dispatch(SearchTreesByTreeNumber(searchValue, loggedUser?.council_id)).then((response) => {
        if (response.status === 200) {
          setTreeByNumberData(response.data.data);
        } else {
          setLoading(false);
        }
      });
    }
  }, [dispatch, searchValue]);

  useEffect(() => {
    dispatch(GetTreeCuttingReasonsList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetTreeNameList());
  }, [dispatch]);

  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setDocumentList([{ documentName: '', documentValue: '', errorName: '', errorValue: '' }]);
    navigate('/dashboard/tree-cutting-application', { replace: true });
  }, [addTreeCuttingApplicationLog]);

  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    dispatch(SearchTreesInSpecificLocation(latlong)).then((response) => {
      console.log("INSDIE RESPONSE");
      // setIsSearchedLocationChanged(isSearchedLocationChanged)
      if (response?.status === 404) {
        // setPinDropLatlng(latlong)
        setShowPinDropMarker(true);
      }
      else {
        setShowPinDropMarker(false);
      }
    });
    setSelectedTreesIn5Km(treesIn5Km);
    setActiveMarker(activeMarker);
    setShowList(true);
  }, [latlong, treeLocation]);


  const locationRun = React.useRef(true);
  useEffect(() => {
    if (locationRun.current) {
      locationRun.current = false;
      return;
    }
      // setPinDropLatlng(latlong)
      setShowPinLocationMessage(true);
      // setShowPinDropMarker(false);
    setSelectedTreesIn5Km(treesIn5Km);
  }, [treesIn5Km]);

  // const searchLocationRun = React.useRef(true);
  // useEffect(() => {
  //   if (searchLocationRun.current) {
  //     locationRun.current = false;
  //     return;
  //   }
  //     setPinDropLatlng(latlong)
  //     setShowPinLocationMessage(true);
  //     setShowPinDropMarker(false);
  // }, [isSearchedLocationChanged]);



  console.log("PINFDROP",treesIn5Km)

  const handleShowPinDropmarker = () => {
    setShowPinDropMarker(true);
    setPinDropLatlng(centerPosition.latitude!=="" ? centerPosition : latlong)
    // setShowPinLocationMessage(false);
  }


  const thirdRun = React.useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    if (uploadFile) {
      const newDocumentList = [...documentList];
      const value = newDocumentList[uploadFile.index];
      value.documentValue = uploadFile?.url ?? uploadFile?.name;
      newDocumentList[uploadFile.index] = value;
      setDocumentList(newDocumentList);
    }
  }, [uploadFileLog]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Disable the default Enter key action
    }
  };


  const handleCheckboxChange = (event) => {
    console.log('selectedOptions[0]', selectedOptions[0]);
    // const reasonId = selectedOptions[0].cut_obj.tree_cutting_reason_id;
    console.log("selectedOption",)
    const reasonId = selectedOptions[0].cut_obj.tree_cutting_reason_id
      ? selectedOptions[0].cut_obj.tree_cutting_reason_id
      : selectedOptions[0].tree_cutting_reason_id;

    console.log('reasonIdchecked', reasonId);
    if (reasonId === 0) {
      alert('Please select tree reason');
      selectedOptions.cut_obj.reason_error = 'Please select ';
    } else {
      const isChecked = event.target.checked;
      setApplyToAll(isChecked);
      const newSelectedOptionList = [];
      if (isChecked) {
        selectedOptions.forEach((value, index) => {
          console.log("98", value)

          // if (value.cut_obj.tree_cutting_reason_id) {
          value.cut_obj.tree_cutting_reason_id = reasonId;
          // } else {
          //   value.tree_cutting_reason_id = reasonId;
          // }
          newSelectedOptionList.push(value);
        });
      } else {
        selectedOptions.forEach((value, index) => {
          if (index === 0) {
            // if (value.cut_obj.tree_cutting_reason_id) {
            value.cut_obj.tree_cutting_reason_id = reasonId;
            // } else {
            //   value.tree_cutting_reason_id = reasonId;
            // }
          } else {
            value.cut_obj.tree_cutting_reason_id = 0;

            // value.tree_cutting_reason_id = 0;
          }

          newSelectedOptionList.push(value);
        });
      }

      setSelectedOptions(newSelectedOptionList);
    }
  };

  const handleMobile = (e) => {
    const regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    if (regex.test(e.target.value)) {
      setMobileError('');
    } else {
      setMobileError('Please Enter Mobile Number Containing 10 Digits Only');
    }
    setMobile(e.target.value);
  };

  const separateDocument = (document) => {
    const documentList = [];

    if (document.length === 0) {
      const infoToAdd = {
        documentName: '',
        documentValue: '',
        errorName: '',
        errorValue: '',
      };
      documentList.push(infoToAdd);
    } else {
      document.map((value, index) => {
        const infoToAdd = {
          documentName: value.user_document_type_id,
          documentValue: value.document_path,
          errorName: '',
          errorValue: '',
        };
        documentList.push(infoToAdd);
        return null;
      });
    }
    setDocumentList(documentList);
  };

  const handleEmail = (e) => {
    const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (regex.test(e.target.value)) {
      setEmailError('');
    } else {
      setEmailError('Please Enter Valid Email Address Only');
    }
    setEmail(e.target.value);
  };

  const pdfChange = (e) => {
    const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);
    const validExtensions = ['pdf'];
    const fileExtension = e.target.files[0].name.split('.')[1];
    if (validExtensions.includes(fileExtension)) {
      setFileUploadError('');
      if (e.target.files[0].size < 15728640) {
        setFileSizeError('');
        const formData = new FormData();
        formData.append('upload_for', 'cuttingTreeApplication');
        formData.append('file', e.target.files[0]);
        setShowUploadLoader(true);
        // dispatch(UploadFile(formData, 1)).then((response) => {

        // });
      } else {
        setFileUploadError('Please upload documents within 15MB only');
      }
    } else {
      setFileUploadError('Please upload documents with pdf format only');
    }
  };


  const TreeSchema = Yup.object().shape({
    applicantName: Yup.string()
      .matches(/^[a-zA-Z ]{2,30}$/, 'Please enter valid name')
      .required(' Application name is required'),
    mobile: Yup.string()
      .matches(/^[0-9]\d{9}$/, 'Phone number is not valid')
      .required('Mobile number is required'),
    address: Yup.string().required('Address is required'),
    ward_id: Yup.string().required('Ward is required'),
    // property_number: Yup.string().required('Property number is required'),

    cutting_trees: Yup.array().min(1, 'Atleast one tree is required').nullable(),

    

  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      council_id: loggedUser.roles[0].slug === 'council' ? council?.[0].id : '',
      ward_id: '',
      address: '',
      property_number: '',
      total_trees: '',
      tree_cutting_reason_id: '',
      tree_name_id:'',
      document: [],
      cutting_trees: [],
      // cutting_trees: applicationId ? cut_obj.tree_image:[],
      applicantName: '',
      mobile: '',
      // userLocation: '',
      documentType: [],
      file: [],
      tree_image: null,
      documentName: null,
    },
    validationSchema: TreeSchema,
    onSubmit: (value) => {
      console.log('submitValue', value);
      

      
 
      if (validateDropDown() && validateTreeCard()) {
        setBtnDisabled(true);
      }
      const formData = new FormData();
      formData.append('council_id', loggedUser?.council_id);
      formData.append('property_number', value?.property_number);
      //  formData.append('location', value?.userLocation);
      formData.append('location', value?.address);
 
      formData.append('ward_id', value?.ward_id);
      formData.append('name', value?.applicantName);
      formData.append('mobile', value?.mobile);
      formData.append('lat', value?.lat);
      formData.append('lng', value?.long);
 
      if (value?.document?.length > 0) {
        value.document.forEach((doc, index) => {
          formData.append(`document[${index}][document_type]`, doc.document_type);
          formData.append(`document[${index}][documents]`, doc.file);
        });
      }
 
      if (value.cutting_trees && value.cutting_trees.length > 0) {
        formData.append('total_trees', value.cutting_trees.length);
        value.cutting_trees.forEach((tree, index) => {
          console.log("tree98",tree)
          const treeNumber = tree?.cut_obj?.tree_number || tree?.tree_number;
          const censusTreeId = tree?.id;
          const cuttingReasonId = tree?.cut_obj?.tree_cutting_reason_id;
          console.log("cuttingReasonId",cuttingReasonId);
          const treeNameId=tree?.cut_obj?.tree_name_id || tree?.tree_name_id;
          const image = tree?.cut_obj?.tree_image || '';
          if(! tree.locationId){
            formData.append(`cutting_trees[${index}][tree_number]`, treeNumber);
          }
          if(! tree.locationId){
            formData.append(`cutting_trees[${index}][census_tree_id]`,censusTreeId);
          }
          formData.append(`cutting_trees[${index}][tree_cutting_reason_id]`, cuttingReasonId);
          formData.append(`cutting_trees[${index}][tree_name_id]`,treeNameId);
          formData.append(`cutting_trees[${index}][image]`, image);
        });
      }
      if (validateDropDown() && validateTreeCard()) {
        setSubmitLoader(true);
        setBtnDisabled(true);
        if (applicationId) {
          dispatch(UpdateTreeCuttingApplication(formData, applicationId)).then((response) => {
            if (response?.status === 200) {
              setSubmitLoader(false);

              setIsSubmittingapplication(true);
              setBtnDisabled(false);
              
 
              navigate('/dashboard/tree-cutting-application');
            }
          });
        } else {
          dispatch(CreateTreeCuttingApplication(formData)).then((response) => {
              setSubmitLoader(false);
              setBtnDisabled(false);
            if (response.status === 201) {
              
              navigate('/dashboard/tree-cutting-application');
            }
          }).catch((err)=>{
              setSubmitLoader(false);
              setBtnDisabled(false);
          });
        }
      }
    },
  })

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  const handleTopModalAnswer = (answer) => {};

  const handlePropertyNumber = (event, newValue) => {
    const getID = JSON.stringify(newValue, null, ' ');
    const getLocatedUserId = JSON.parse(getID);
    setPropertyNumber(getLocatedUserId.display);
    setFieldValue('property_number', getLocatedUserId.display);
  };

  const handleCuttingPurpose = (event, purposeValue) => {
    setCuttingPurpose(purposeValue.label);
  };

  const findValue = (listOfObj, id) => {
    const found = listOfObj.find((e) => e.id === id);
    if (found) {
      return found.name;
    }
  };

  const handleChangeSelectedTreeList = (value, treeNumberlist) => {
    if (value === 'add') {
      const newSelectedList = [...selectedTreeList];
      treeNumberlist?.map((value, index) => {

        console.log("value76", value)
        const info = {
          census_tree_id: value.id,
          tree_number: value.tree_number,
          tree_cutting_reason_id: value.tree_cutting_reason_id,
          tree_image: value.tree_image,
        };
        newSelectedList.push(info);
        return null;
      });

      setSelectedTreeList(newSelectedList);
    } else if (value === 'delete') {
      const newSelectedList = [...selectedTreeList];
      treeNumberlist?.map((value, index) => {
        newSelectedList.splice(
          newSelectedList.findIndex((item) => item.census_tree_id === value.id),
          1
        );

        return null;
      });

      setSelectedTreeList(newSelectedList);
      setFieldValue('cutting_trees', newSelectedList);
      setFieldValue('total_trees', newSelectedList.length);
    }
  };

  const handleWardChange = (event) => {
    setWardName(event.target.value);
    dispatch(GetPropertyByCouncilAndWard(council[0].id, event.target.value));
  };

  const handleTreeNumberAdd = () => {
    if (values.wards && propertyNumber) {
      dispatch(GetCensusTreeByWardOrPropertyNumber(council[0].id, values.wards, null, 1, 20));
      setTopModalOpen(true);
    } else {
      dispatch(
        SetNewAlert({
          msg: 'Please select ward and property number first',
          alertType: 'danger',
        })
      );
    }
  };


  const onCenterLocationChanged = (newCenter) => {
    setCenterPosition(newCenter)
  }


  const handleOptionChangeWithoutTrees = (newValue) => {
    console.log("NEW VALUE",newValue);
    const newOptions = [...selectedOptions];

    // cut objects

      if (!newOptions.some((existing) => existing.locationId === newValue.latitude)) {
        const option = {};
        option.locationId = newValue.latitude
        option.id = newValue.latitude
        option.cut_obj = {
          tree_location : newValue,
          census_tree_id: null,
          tree_cutting_reason_id: 0,
          tree_image: null,
          tree_number: null,
          reason_error: "",
          image_error: "",
        };

        newOptions.push(option);
      }
    setSelectedOptions(newOptions);

    formik.setFieldValue('cutting_trees', newOptions);
    setShowPinDropMarker(false);
    setPinDropLatlng({})
    const newFixedMarkerPosition = [...fixedMarkerPosition];

    newFixedMarkerPosition.push(newValue);
    setFixedMarkerPosition(newFixedMarkerPosition);
    // if (newOptions.length === newValue.length) {
    //   setError('');
    // } else {
    //   setError('Duplicate options selected.');
    // }
  };


  const handleOptionChange = (event, newValue) => {
    console.log("NEW VALUE",newValue);
    console.log("NEW VALUE",selectedOptions);
    const newOptions = [...selectedOptions];

    // cut objects
    newValue?.forEach((option) => {
      if (!newOptions.some((existing) => existing.id === option.id)) {
        option.cut_obj = {
          census_tree_id: option.tree_name_id,
          tree_cutting_reason_id: 0,
          tree_image: null,
          tree_number: option.tree_number,
          reason_error: "",
          image_error: "",
        };

        newOptions.push(option);
      }
    });
    setSelectedOptions(newOptions);

    formik.setFieldValue('cutting_trees', newOptions);
    // if (newOptions.length === newValue.length) {
    //   setError('');
    // } else {
    //   setError('Duplicate options selected.');
    // }
  };

  /*  #region Temporay code */
  const documentLength = documentList.length;
  const handleAddDocument = (index) => {
    if (!documentType[index]) {
      setError('Please select a document type.');
    }
  };

  const handleFileChange = (event, index) => {
    const tempFile = event.target.files[index];

    if (tempFile) {
      const files = [...newFile];
      const value = files[index];
      if (value) {
        value.file = event.target.files;
        value.document_type = documentType[index];
      }
      files[index] = value;

      const fileExtension = tempFile.name.split('.').pop().toLowerCase();
      if (!allowedFileTypes.includes(tempFile.type) || !allowedExtensions.includes(fileExtension)) {
        setError('Invalid file type or extension.');
        files[index] = null;
        formik.setFieldValue('file', files);
        setNewFile(files);
        return;
      }
      if (tempFile.size > 15 * 1024 * 1024) {
        // 15MB in bytes
        setError('File size exceeds 15MB.');
        // formik.setFieldValue('file', null);
        files[index] = null;
        formik.setFieldValue('file', files);
        setNewFile(files);
        return;
      }

      setNewFile(files);
      formik.setFieldValue('file', newFile);
      setError('');
    }
  };

  const handleDocumentValueChange = (e, index) => {
    setEditDocList([]);
    if (uploadClick) {
      const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);

      const validExtensions = ['png', 'jpeg', 'jpg', 'tiff', 'gif', 'pdf'];
      const fileExtension = e.target.files[0].name.split('.')[1];

      if (validExtensions.includes(fileExtension)) {
        setFileUploadError('');
        if (e.target.files[0].size < 15728640) {
          const uploadedDocument = e.target.files;
          setFileSizeError('');
          const newDocumentList = [...documentList];
          /* #region Code to upload file to server  */
          const value = newDocumentList[index];
          value.documentValue = e.target.value;
          newDocumentList[index] = value;
          /* #endRegion */
          setDocumentList(newDocumentList);

          /* #region Document for form data */
          setError('');
          const newDocument = [...documents];
          newDocument.push({ document_type: documentType[index], file: e.target.files[0] }); // ,'upload_for':'cuttingTreeApplication'

          setDocuments(newDocument);
          formik.setFieldValue('document', [...newDocument]);

          /* #endregion */
        } else {
          setFileSizeError('Please upload documents within 15MB only');
        }
      } else {
        setFileUploadError('Please upload documents with png, jpeg, jpg, tiff, gif, pdf format only');
      }
    } else {
      setUploadClickError('Please Select Document Type First');
    }
  };

  // const handleSubmitErrors = () => {
  //   // console.log("in submit errors");
  //   //  console.log("Formiok submit errors", formik.errors);
  //   const keys = Object.keys(formik.errors);
  //   // const roleElement = document.getElementById("role-label");
  //   // console.log("roleelement", roleElement);
  //   // roleElement.scrollIntoView({ behavior: 'smooth', block: "center", inline: "center" })
  //   //  console.log("keys", keys);
  //   // Whenever there are errors and the form is submitting but finished validating.
  //   if (keys.length > 0) {
  //     // console.log("in keyssssssss")
  //     // We grab the first input element that error by its name.
  //     const errorElement = document.querySelector(`input[name="${keys[0]}"]`);
  //     //  console.log(errorElement);
  //     if (errorElement) {
  //       // When there is an input, scroll this input into view.
  //       errorElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  //     }
  //   } else if (!role) {
  //     const roleElement = document.getElementById('role-label');
  //     // console.log("roleelement", roleElement);
  //     roleElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  //   }
  // };
  const validateDropDown = () => {
    let validated = true;
    let foundDocument = false;
    // if (editDocList.length > 0) {
    //   foundDocument = true;
    // } else {
    documentList.map((value, index) => {
      documentList.map((value2, index2) => {
        if (
          value.documentName !== '' &&
          value2.documentName !== '' &&
          index2 !== index &&
          value2.documentName === value.documentName
        ) {
          const firstDocumentList = [...documentList];
          const value1 = firstDocumentList[index];
          value1.errorName = 'Same document type not allowed';
          firstDocumentList[index] = value1;
          const value2 = firstDocumentList[index2];
          value2.errorName = 'Same document type not allowed';
          firstDocumentList[index2] = value2;
          setDocumentList(firstDocumentList);
          foundDocument = true;
          validated = false;
        }
        return null;
      });
      return null;
    });

    // eslint-disable-next-line array-callback-return
    documentList.map((value, index) => {
      const conditionName = `documentName`;
      const conditionValue = `documentValue`;

      if (!foundDocument) {
        if (value[conditionName] === '') {
          validated = false;
          const newDocumentList = [...documentList];
          const value = newDocumentList[index];
          value.errorName = 'This document type is required';
          newDocumentList[index] = value;
          setDocumentList(newDocumentList);
        } else {
          const newDocumentList = [...documentList];
          const value = newDocumentList[index];
          value.errorName = '';
          newDocumentList[index] = value;
          setDocumentList(newDocumentList);
        }
      }

      if (value[conditionValue] === '') {
        validated = false;
        const newDocumentList = [...documentList];
        const value = newDocumentList[index];
        value.errorValue = 'please Upload document';
        newDocumentList[index] = value;
        setDocumentList(newDocumentList);
      } else {
        const newDocumentList = [...documentList];
        const value = newDocumentList[index];
        value.errorValue = '';
        newDocumentList[index] = value;
        setDocumentList(newDocumentList);
      }
    });
    // }
    return validated;
  };

  // validate card============

  // const validateTreeCard = () => {
  //   let isValidate = true;

  //   selectedOptions.forEach((value, itemIndex) => {
  //     console.log('validationselectedOptions', selectedOptions);
  //     // Validate tree cutting reason
  //     if (value?.cut_obj?.tree_cutting_reason_id === 0) {
  //       value.cut_obj.reason_error = 'Please select reason';
  //       isValidate = false;
  //     } else {
  //       value.cut_obj.reason_error = '';
  //     }

  //     // Validate tree image
  //     if (!value.cut_obj.tree_image) {
  //       value.cut_obj.image_error = 'Please select tree image';
  //       isValidate = false;
  //     }

  //     // Check if the file is an image
  //     const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']; // List of allowed MIME types
  //     if (!allowedTypes.includes(value?.cut_obj?.tree_image?.type)) {
  //       value.cut_obj.image_error = 'Selected file must be an image (JPG,JPEG, PNG, GIF)';
  //       isValidate = false;
  //     }
  //   });

  //   setSelectedOptions(selectedOptions);

  //   return isValidate;
  // };
  const validateTreeCard = () => {
    let isValidate = true;

    selectedOptions.forEach((value, itemIndex) => {
      if (value.cut_obj) {
        if (value.cut_obj.tree_cutting_reason_id === 0) {
          value.cut_obj.reason_error = 'Please select reason';
          isValidate = false;
        } else {
          value.cut_obj.reason_error = '';
        }

        
        if (value.cut_obj.tree_name_id === undefined || value.cut_obj.tree_name_id === 0) {
          value.cut_obj.tree_name_error = 'Please select tree name';
          isValidate = false;
        } else {
          value.cut_obj.tree_name_error = '';
        }

        // Validate tree image
        if (!value.cut_obj.tree_image) {
          value.cut_obj.image_error = 'Please select tree image';
          isValidate = false;
        } else {
          // Check if the file is an image
          const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']; // List of allowed MIME types
          if (!allowedTypes.includes(value.cut_obj.tree_image.type)) {
            value.cut_obj.image_error = 'Selected file must be an image (JPG, JPEG, PNG, GIF)';
            isValidate = false;
          } else {
            value.cut_obj.image_error = ''; // Clear any previous error if the image type is valid
          }
        }
      } else {
        // value.cut_obj.reason_error = '';
        // if (value.tree_cutting_reason_id === 0) {
        //   value.cut_obj.reason_error = 'Please select reason';
        //   isValidate = false;
        // } else {
        //   value.cut_obj.reason_error = '';
        // }
      }
    });

    setSelectedOptions([...selectedOptions]);

    return isValidate;
  };

  const handleTopModalClose = () => {
    setTopModalOpen(false);
  };

  const handlePdfRemove = (e) => {
    setUploadedPdf('');
    setFieldValue('document', '');
  };

  const handleViewDocument = (fpath) => {
    if (fpath.includes(process.env.REACT_APP_BASE_URL)) {
      window.open(fpath, '_blank');
    } else {
      const fLink = process.env.REACT_APP_BASE_URL.concat('/').concat(fpath);
      window.open(fLink, '_blank');
    }
  };

  /* #region Handle Documents */
  const handleDocumentButtonClick = (value, index) => {
    if (value === 'add') {
      if(documentList.length < 2 ) {
        const newDocumentList = [...documentList];

        const infoToAdd = {
          documentName: '',
          documentValue: '',
          errorName: '',
          errorValue: '',
        };
        newDocumentList.push(infoToAdd);
        setDocumentList(newDocumentList);
      }
      
    } else if (value === 'delete') {
      const newDocumentList = [...documentList];
      const newDocument = [...documents];
      // const infoToAdd = {
      //   documentName: '',
      //   documentValue: '',
      //   errorName: '',
      //   errorValue: '',
      // };
      newDocumentList.splice(index, 1);
      newDocument.splice(index, 1);

      console.log('newDocumentList1998', newDocument);
      setDocumentList(newDocumentList);
      setDocuments(newDocument);
      formik.setFieldValue('document', [...newDocument]);
    }

    handleAddDocument(index);
  };

  const handleDocumentNameChange = (e, index) => {
    setEditDocList([]);
    const newDocumentList = [...documentList];
    const value = newDocumentList[index];
    value.documentName = e.target.value;
    newDocumentList[index] = value;
    setDocumentList(newDocumentList);
    setError('');
    setDocumentType((privious) => [...privious, e.target.value]);
    // const newDocument = [...documents];
    // newDocument.push({ document_type: e.target.value, file: newFile[index] });
    // setDocuments(newDocument);
    // formik.setFieldValue('document', [...newDocument]);
    setUploadClick(true);
    setUploadClickError('');
  };

  const uploadRun = React.useRef(true);
  useEffect(() => {
    if (uploadRun.current) {
      uploadRun.current = false;
      return;
    }
    if (uploadFile) {
      setUploadedPdf(uploadFile.url);
      setShowUploadLoader(false);
    }
  }, [uploadFileLog]);

  const placeChange = (location) => {
    setFieldValue('location', location);
  };

  const placeAddressChange = (address) => {
    setFieldValue('address', address);
  };

  const areObjectsEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  const handleSelect = (location) => {
    setFieldValue('location', location);
    geocodeByAddress(location)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setFieldValue('lat', latLng?.lat);
        setFieldValue('long', latLng?.lng);
        if(!areObjectsEqual({ latitude: latLng?.lat, longitude: latLng?.lng },latlong)) {
          setLatitudeLong({ latitude: latLng?.lat, longitude: latLng?.lng });
          setCenterPosition({ latitude: latLng?.lat, longitude: latLng?.lng })
          setIsLocationChanged(!isLocationChanged)
        }
      })
      .catch((error) => console.error('Error', error));
  };

  const handleSelectAddress = (address) => {
    setFieldValue('address', address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setFieldValue('lat', latLng?.lat);
        setFieldValue('long', latLng?.lng);
        setAddressLatitudeLong({ latitude: latLng?.lat, longitude: latLng?.lng });
      })
      .catch((error) => console.error('Error', error));
  };

  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
    chipSelected: {
      backgroundColor: '#214c50',
      color: '#fff',
    },
    chip: {
      backgroundColor: '#fff',
      color: '#000',
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  const libraries = ['places'];
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBcAkxYg1CTn1ackTsVWVt4KKDlkKa6hWc', // Add your API key,
    libraries,
  });

  const UserList = properties?.map((item) => ({
    value: item.id,
    label: `${item.property_number} - ${item.owner_name}`,
    display: `${item.property_number}`,
  }));

  const treeCuttingReasons = cuttingPurpose
    ? cuttingPurpose?.map((item) => ({ value: item.id, label: `${item.reason}` }))
    : [];

  const handleChecked = () => {
    setChecked(!checked);
  };

  const handleReason = () => {
    setReasonState(!reasonForAll);
  };

  const handleChange = (event, index) => {
    handleChecked();
    handleReason();
    if (reasonForAll) {
      setSelectedValue(selectedValue.map(() => value));
    } else {
      setSelectedValue(selectedValue.map((val, i) => (i === index ? value : val)));
    }
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleCloseCart = (id) => {
    const item = selectedOptions.filter((cardIndex) => cardIndex.id !== id);

    setSelectedOptions(item);
    setOpen(false);
    formik.setFieldValue('cutting_trees', item);
    const foundItem = selectedOptions.find(cardIndex => cardIndex.id === id);

    const newFixedMarkerPosition = [...fixedMarkerPosition]

    const filteredFixedMarkerPosition = newFixedMarkerPosition.filter(
      item => item.latitude !== foundItem.cut_obj.tree_location.latitude || item.longitude !== foundItem.cut_obj.tree_location.longitude
    );

    console.log("2 SELECTED OPTIONSSSSS",filteredFixedMarkerPosition)

    setFixedMarkerPosition(filteredFixedMarkerPosition);

  };

  console.log("SELECTED OPTIONSSSSS",selectedOptions)

  const onMarkerClick = (marker) => {
    setDialogOpen(!treeDetailsOpen);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    dispatch(GetSpecificTreeInfo(marker));
  };

  const handleCloseCartOption = (values) => {
    const newOptions = [];

    values.forEach((option) => {
      newOptions.push(option);
      setTreeNumber(option.title ?? option.tree_number);
    });

    
    setSelectedOptions(newOptions);
    handleChangeSelectedTreeList(newOptions, values);
    setFieldValue('cutting_trees', newOptions);
    const newFixedMarkerPosition = [...fixedMarkerPosition];
    const updatedFixedmarkerPosition = newFixedMarkerPosition.filter(item2 =>
      newOptions.some(item1 => 
        item1.cut_obj?.tree_location?.latitude === item2.latitude && item1.cut_obj?.tree_location?.longitude === item2.longitude
      )
    );

    setFixedMarkerPosition(updatedFixedmarkerPosition)

  };
  // method for reason chnge
  const handleSelectChange = (event, index, id) => {
    console.log("event98", event)
    setApplyToAll(false);
    const newOptions = [...selectedOptions];
    console.log("new option", newOptions);

    newOptions.forEach((value, itemIndex) => {
      console.log('itemIndex === index', itemIndex, index, value);
      if (itemIndex === index) {
        // if (value.cut_obj.tree_cutting_reason_id) {
        value.cut_obj.tree_cutting_reason_id = event.target.value;
        // } else {
        //   value.tree_cutting_reason_id = event.target.value;
        // }
        console.log("value98",value)
        console.log("event.target.value",event.target.value)
      }
    });

    setSelectedOptions(newOptions);
  };

  const handleCuttingTreeNameChange = (event, treeNameValue,index ) => {
      const newOptions = [...selectedOptions];
      newOptions.forEach((value, itemIndex) => {
        console.log('itemIndex === index', itemIndex, index, value);
        if (itemIndex === index) {
          value.cut_obj.tree_name_id = treeNameValue.id;
        }
      });
      setSelectedOptions(newOptions);
  }

  // method for name
  const handleName = (event) => {
     setSelectedName(event.target.value);
    
  };
  // method for image chnge
  const handleTreeImageChange = (event, index, id) => {
    // setEditCuttingTree((pre) => [...pre, editCuttingTree[0]?.splice(index, 1)]);
    setShowImage(false);

    const newOptions = [...selectedOptions];

    //  check null check
    newOptions.forEach((value, itemIndex) => {
      if (itemIndex === index) {
        value.cut_obj.tree_image = event.target.files[0];
        // check if value.cut_obj.tree_image exist in selectedOptions if not in else part set it.
        // if (value && value.cut_obj && value.cut_obj.tree_image) {
        //   value.cut_obj.tree_image = event.target.files[0];
        // } else {
        //   const abc = {
        //     tree_image: event.target.files[0],
        //   };
        //   value.cut_obj = abc;
        // }
      }
    });

    setSelectedOptions(newOptions);

    // console.log('idofcard', id);
    // const newSelectedList = [...selectedTreeList];

    // if (newSelectedList.length === 0) {
    //   selectedOptions?.map((value, index) => {
    //     const info = {
    //       census_tree_id: value.id,
    //       tree_number: value.tree_number,
    //       tree_cutting_reason_id: '',
    //       image: null,
    //     };
    //     newSelectedList.push(info);
    //     return null;
    //   });
    // }
    // console.log('newSelectedList', newSelectedList);
    // const itemIndex = newSelectedList.findIndex((item) => item.census_tree_id === id);
    // console.log('itemIdex', itemIndex);
    // if (itemIndex !== -1) {
    //   newSelectedList[itemIndex].image = event.target.files[0];
    //   console.log('newSelectedList[itemIndex].tree_image', newSelectedList[itemIndex].tree_image);
    //   setSelectedTreeList(newSelectedList);
    //   setFieldValue('cutting_trees', newSelectedList);
    //   setFieldValue('total_trees', newSelectedList.length);
    // }
    // setShowList(true);
    // setSelectedValue({ index: event.target.value });
  };

  return (
    <div>
      {treeDetailsOpen ? <TreeDetailsWithReasonDialog isOpen={treeDetailsOpen} data={treeDetails} showAdd /> : null}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {applicationId ? 'Update Application For Tree Cutting' : 'New Application For Tree Cutting'}
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography>
        </Typography>
      </Stack>
      <FullLoader showLoader={submitLoader} />
      
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        {showLoader ? (
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center', padding: '200px', alignItems: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <form name="NewTreeCuttingPermission" onSubmit={handleSubmit}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="applicantName"
                  name="applicantName"
                  autoComplete="applicantName"
                  label="Applicant Name*"
                  placeholder="Applicant Name*"
                  style={{ marginTop: '7px' }}
                  value={values.applicantName}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  error={Boolean(touched.applicantName && errors.applicantName)}
                  helperText={touched.applicantName && errors.applicantName}
                  {...getFieldProps('applicantName')}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="mobile"
                  name="mobile"
                  autoComplete="contact"
                  label="Mobile Number*"
                  placeholder="Mobile Number*"
                  style={{ marginTop: '7px' }}
                  value={values.mobile}
                  onChange={(e) => {
                    handleMobile(e);
                    formik.handleChange(e);
                  }}
                  error={Boolean(touched.mobile && errors.mobile)}
                  helperText={touched.mobile && errors.mobile}
                  {...getFieldProps('mobile')}
                />
                <Typography variant="body2" style={{ color: '#FF0000' }}>
                  {mobileError}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  id="wards"
                  name="wards"
                  style={{ marginTop: '7px' }}
                  displayEmpty
                  label="Ward"
                  value={values.wards}
                  onChange={(e) => {
                    handleWardChange(e);
                    formik.handleChange(e);
                  }}
                  error={Boolean(touched.ward_id && errors.ward_id)}
                  helperText={touched.ward_id && errors.ward_id}
                  {...getFieldProps('ward_id')}
                  // MenuProps={MenuProps}
                  // inputProps={{ 'aria-label': 'Without label' }}

                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}>
                  <MenuItem disabled value="">
                    <em>Select Ward*</em>
                  </MenuItem>
                  {wards?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  disablePortal
                  freeSolo
                  id="combo-box-demo"
                  // value={null}
                  value={values.property_number}
                  options={UserList}
                  onChange={(e, newValue) => {
                    handlePropertyNumber(e, newValue);
                  }}
                  sx={{ marginTop: '7px' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      defaultValue={'Property'}
                      label="Property Number"
                      error={Boolean(touched.property_number && errors.property_number)}
                      helperText={touched.property_number && errors.property_number}
                      {...getFieldProps('property_number')}
                    />
                  )}
                />
              </Grid>
              {/* Address feild  */}
              {/* <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="userLocation"
                  name="userLocation"
                  autoComplete="userLocation"
                  label="Address"
                  placeholder="Address"
                  style={{ marginTop: '7px' }}
                  value={values.userLocation}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  error={Boolean(touched.userLocation && errors.userLocation)}
                  helperText={touched.userLocation && errors.userLocation}
                  {...getFieldProps('userLocation')}
                />
              </Grid> */}

              {/* Address new  */}
              <Grid item xs={6} sx={{ marginTop: '7px' }}>
                {isLoaded ? (
                  <PlacesAutocomplete
                    value={values.address}
                    name="address"
                    onChange={placeAddressChange}
                    onSelect={handleSelectAddress}
                    error={touched.address && errors.address}
                    helperText={touched.address && errors.address}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{ position: 'relative' }}>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Address',
                            className: 'location-search-input',
                          })}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          style={{
                            width: '100%',
                            // marginTop: '8px',
                            color: '#212B36',

                            border: `1px solid ${isHovered ? 'black' : 'rgba(145, 158, 171, 0.24)'}`,
                            borderRadius: '8px',
                            WebkitTapHighlightColor: 'transparent',
                            padding: '18px 14px',
                            overflow: 'auto',
                          }}
                        />
                        {touched.address && errors.address && (
                          <div style={{ color: 'red', marginTop: '5px', fontSize: '10px' }}>{errors.address}</div>
                        )}

                        <div
                          className="autocomplete-dropdown-container"
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: '0',
                            right: '0',
                            zIndex: 1000,
                            backgroundColor: '#ffffff',
                            // border: '1px solid #ccc',
                            borderRadius: '4px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                            maxHeight: '200px',
                            overflowY: 'auto',
                          }}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '10px' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '10px' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={suggestion.placeId}>
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : null}
              </Grid>

              <Grid item xs={6} sx={{ marginTop: '7px' }}>
                {applicationId && editDocList?.length > 0 && (
                  <Box>
                    <Typography> Selected Documents</Typography>
                    {editDocList?.map((value, index) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '10px',
                              justifyContent: 'space-between',
                            }}>
                            <Typography> {value.documentName}</Typography>
                            <Link
                              href={`${process.env.REACT_APP_BASE_URL}/${value?.url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              underline="none"
                              style={{ display: 'block', marginBottom: '10px' }}>
                              <Button
                                variant="outlined"
                                size="small"
                                sx={{ mt: '5px', width: '100px', fontWeight: '300' }}>
                                View File
                              </Button>
                            </Link>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                )}
                <Box>
                  {applicationId && editDocList?.length > 0 && <Typography> Reupload Documents</Typography>}
                  {documentList?.map((value, index) => (
                    <Grid container spacing={2} key={index}>
                      <Grid item xs={6}>
                        <TextField
                          select
                          fullWidth
                          label="Document Type*"
                          id="aadharCard"
                          name="aadharCard"
                          value={value.documentName}
                          displayEmpty
                          // style={{width: '110%'}}
                          onChange={(e) => handleDocumentNameChange(e, index)}
                          error={Boolean(value.errorName)}
                          helperText={value.errorName}
                          inputProps={{
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                          // renderValue={(selected) => {
                          //   if (selected?.length === 0) {
                          //     return <em>Aadhar Card</em>;
                          //   }
                          //   return selected
                          // }}
                        >
                          <MenuItem disabled value="">
                            <em>Document Type*</em>
                          </MenuItem>
                          {documentTypes?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.type}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={5}>
                        {value.documentValue ? (
                          <div
                            style={{
                              width: '100px',
                              height: '70px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              backgroundColor: '#f0f0f0',
                              borderRadius: '10px',
                            }}>
                            <DescriptionIcon style={{ fontSize: 36 }} />
                          </div>
                        ) : (
                          <>
                            <TextField
                              fullWidth
                              id="amount"
                              type={'file'}
                              autoComplete="amount"
                              placeholder="Choose file"
                              value={value.documentValue}
                              error={Boolean(value.errorValue)}
                              helperText={value.errorValue}
                              onChange={(e) => handleDocumentValueChange(e, index)}
                            />
                            <Typography variant="body2" color={'#FF0000'}>
                              {fileUploadError}
                            </Typography>
                            <Typography variant="body2" color={'#FF0000'}>
                              {fileSizeError}
                            </Typography>
                            <Typography variant="body2" color={'#FF0000'}>
                              {uploadClickError}
                            </Typography>
                            {/* <Typography variant="body2">
                          Supported Formats are .pdf, .jpg, .jpeg, .png, .tiff, .gif
                        </Typography>
                        <Typography variant="body2">Supported document size: 5MB</Typography> */}
                          </>
                        )}
                      </Grid>

                      <Grid item xs={1}>
                        <IconButton
                          color={documentList.length < 2 && index + 1 === documentLength ? 'success' : 'error'}
                          aria-label={documentList.length < 2 && index + 1 === documentLength ? 'add' : 'delete'}
                          size="large"
                          onClick={() =>
                            handleDocumentButtonClick(documentList.length < 2 && index + 1 === documentLength ? 'add' : 'delete', index)
                          }>
                          { documentList.length < 2 && index + 1 === documentLength  ? (
                            <AddCircleIcon fontSize="inherit" />
                          ) : (
                            <CancelIcon fontSize="inherit" />
                          )}
                        </IconButton>
                      </Grid>
                      {/* <Grid item xs={12}>
                    <Typography variant="body2">
                      Supported Formats are .pdf, .jpg, .jpeg, .png, .tiff, .gif
                    </Typography>
                    <Typography variant="body2">Supported document size: 5MB</Typography>
                  </Grid> */}
                    </Grid>
                  ))}
                </Box>
              </Grid>

              <Grid item xs={6} sx={{ marginTop: '7px' }}>
                <Autocomplete
                  multiple
                  // freeSolo
                  options={treeByNumberData || []}
                  getOptionLabel={(option) => option.tree_number?option.tree_number:"-"}
                  value={selectedOptions}
                  onInputChange={filterByTreeNumber}
                  onChange={(e, newValue, reason) => {
                    if (reason === 'removeOption') {
                      handleCloseCartOption(newValue);
                    } else {
                      handleOptionChange(e, newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Tree By Using Number *"
                      placeholder="Search..."
                      name="cutting_trees"
                      onKeyDown={handleKeyDown}
                      onChange={(e, newValue) => {
                        // handleOptionChange(e, newValue);
                        formik.handleChange(e);
                      }}
                      error={Boolean(touched.cutting_trees && errors.cutting_trees)}
                      helperText={touched.cutting_trees && errors.cutting_trees}
                      // {...getFieldProps("cutting_trees")}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading && <CircularProgress color="inherit" size={20} style={{ margin: 4 }} />}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      filterSelectedOptions
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6} sx={{ marginTop: '7px' }}>
                {isLoaded ? (
                  <PlacesAutocomplete value={values.location} onChange={placeChange} onSelect={handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div style={{ position: 'relative' }}>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Tree On Map',
                            className: 'location-search-input',
                          })}
                          onMouseEnter={() => setIsHovered2(true)}
                          onMouseLeave={() => setIsHovered2(false)}
                          style={{
                            width: '100%',
                            // marginTop: '8px',
                            color: '#212B36',
                            borderRadius: '8px',

                            border: `1px solid ${isHovered2 ? 'black' : 'rgba(145, 158, 171, 0.24)'}`,

                            WebkitTapHighlightColor: 'transparent',
                            padding: '18px 14px',
                            overflow: 'auto',
                          }}
                        />

                        <div
                          className="autocomplete-dropdown-container"
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: '0',
                            right: '0',
                            zIndex: 1000,
                            backgroundColor: '#ffffff',
                            // border: '1px solid #ccc',
                            borderRadius: '4px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
                            maxHeight: '200px',
                            overflowY: 'auto',
                          }}>
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? { backgroundColor: '#fafafa', cursor: 'pointer', padding: '10px' }
                              : { backgroundColor: '#ffffff', cursor: 'pointer', padding: '10px' };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                                key={suggestion.placeId}>
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                ) : null}
                {showPinLocationMessage ? <div style={{color:'red'}}>
                Unable to find tree?  <Button onClick={handleShowPinDropmarker}>Locate here</Button>
                </div>:null}
              </Grid>

              <Grid item xs={selectedOptions.length > 0 ? 4 : 0}>
                {selectedOptions.length > 0 ? (
                  <Card
                    className="reverse-flex-card"
                    sx={{
                      padding: '15px',
                      position: 'relative',
                      width: 'absolute',
                      marginTop: '5px',
                      overflowY: 'auto',
                      flexGrow: 1,
                      flexDirection: 'column-reverse',
                      maxHeight: '600px',
                      pointer: 'hand',
                    }}>
                    <CardContent className="reverse-flex-container">
                      {selectedOptions.map((option, index) => (
                        <Card
                          key={option.id}
                          variant="outlined"
                          sx={{
                            padding: '15px',
                            position: 'relative',
                            width: 'relative',
                            marginTop: '10px',
                            marginRight: '10px',
                          }}>
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '10px',
                              right: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                              {option.locationId ? null:
                            <IconButton
                              sx={{
                                color: 'black',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                boxShadow: '0 0 5px rgba(0,0,0,0.2)',
                                marginRight: '5px',
                                width: '30px',
                                height: '30px',
                              }}
                              onClick={() => onMarkerClick(option.id)}>
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                            }
                            <IconButton
                              sx={{
                                color: 'black',
                                backgroundColor: 'white',
                                borderRadius: '50%',
                                boxShadow: '0 0 5px rgba(0,0,0,0.2)',
                                width: '30px',
                                height: '30px',
                              }}
                              onClick={() => handleCloseCart(option.id)}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Box>
                          <CardContent>
                            <Typography variant="body1" component="div" sx={{ fontWeight: 500 }}>
                              {option.title ?? option.tree_number ?option.tree_number : ""}
                            </Typography>
                            <Typography sx={{ marginTop: '10px' }}> Select Tree Cutting Reason *</Typography>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              inputProps={{
                                classes: {
                                  icon: classes.icon,
                                },
                              }}
                              // value={option?.tree_cutting_reason_id}
                              value={
                                option?.cut_obj?.tree_cutting_reason_id
                                  ? option?.cut_obj?.tree_cutting_reason_id
                                  : option?.tree_cutting_reason_id
                              }
                              onChange={(e) => handleSelectChange(e, index, option.id)}
                              displayEmpty
                              sx={{ marginTop: '5px', minWidth: 200, height: '32px', fontSize: '0.875rem' }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    maxHeight: '200px',
                                    width: '150px',
                                  },
                                },
                              }}>
                              <MenuItem value="" disabled>
                                Select Reason
                              </MenuItem>
                              {treeCuttingReasons?.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            
                          </CardContent>

                          <Typography variant="body2" color={'#FF0000'}>
                            {option?.cut_obj?.reason_error}
                          </Typography>

                          {/* {index === 0 && selectedOptions.length > 1 ? (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  id="chkReason"
                                  // checked={checked}
                                  checked={applyToAll}
                                  onChange={handleCheckboxChange}
                                  // onChange={(e, index) => handleChange(e, index)}
                                />
                              }
                              label="Apply reason for all trees"
                            />
                          ) : null} */}

                          {/* tree name */}

                          <CardContent>
  <Typography sx={{ marginTop: '10px' }}> Select Tree Name *</Typography>
  <Autocomplete
  size='small'
  
    id="tree-name-autocomplete"
    // value={selectedName}
    onChange={(event, newValue) => handleCuttingTreeNameChange(event,newValue,index)} 
    // options={treeName}
    getOptionLabel={(option) => option.name}
    options={treeName}
    // options={treeName.map((option) => option.name)} // Extract tree names from your data
    renderInput={(params) => (
      <TextField
        {...params}
        label="Select Tree Name" // Label inside the input box
        variant="outlined"
        sx={{ marginTop: '5px', minWidth: 200, height: '32px', fontSize: '0.875rem' }}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDownIcon  sx={{color:"gray"}}/>
            </InputAdornment>
          ),
        }}
      />
    )}
    sx={{ marginTop: '5px', minWidth: 200 }}
    ListboxProps={{
      style: { maxHeight: 200 }, // Controls the dropdown height
    }}
    disableClearable // Optional: disable clear button inside input
  />
</CardContent>
<Typography variant="body2" color={'#FF0000'} style={{marginTop:'10px'}}>
                            {option?.cut_obj?.tree_name_error}
                          </Typography>

                          

                          {/* uplaod file */}

                          <Typography sx={{ marginTop: '10px', marginBottom: '5px' }}>
                            {' '}
                            Tree Image(jpg,jpe,png,gif) *
                          </Typography>

                          <TextField
                            fullWidth
                            id="files-input"
                            name="tree_image"
                            type="file"
                            inputProps={{
                              accept: 'image/*',
                            }}
                            onChange={(e) => handleTreeImageChange(e, index, option.id)}
                            error={formik.touched.tree_image && Boolean(formik.errors.tree_image)}
                            helperText={formik.touched.tree_image && formik.errors.tree_image}
                            aria-describedby="file-upload-label"
                          />

                          <Typography variant="body2" color={'#FF0000'}>
                            {option?.cut_obj?.image_error}
                          </Typography>

                          {/* show img code  */}

                          {option.image_url && !option?.cut_obj?.tree_image && (
                            <CardMedia
                              component="img"
                              height="200"
                              sx={{ padding: '10px', borderRadius: '35px' }}
                              image={`${process.env.REACT_APP_BASE_URL}/${option.image_url}`}
                              alt="tree"
                            />
                          )}
                        </Card>
                      ))}
                    </CardContent>
                  </Card>
                ) : null}
              </Grid>

              <Grid item xs={selectedOptions?.length > 0 ? 8 : 12}>
                {isLoaded ? (
                  <TreeCuttingMap
                    treeLocation={showList ? treesIn5Km : []}
                    showAdd
                    treeCuttingReasons={treeCuttingReasons}
                    onAddTree={handleOptionChange}
                    onAddPinTree={(e,value)=>handleOptionChangeWithoutTrees(value)}
                    pinDropLatlng = {showPinDropMarker ?   [pinDropLatlng] :[]}
                    centerLatLng = {latlong}
                    isLocationChanged = {isLocationChanged}
                    fixedMarkerPosition = {fixedMarkerPosition}
                    onCenterLocationChanged = {(newCenter)=>onCenterLocationChanged(newCenter)}
                    // handleCenterChanged = {(latitudeLong)=>handleCenterChanged(latitudeLong)}
                  />
                ) : null}
              </Grid>
            </Grid>



            {


//             showLoadingButton ? (
//               <div style={{ display: 'flex', marginLeft: '150px', justifyContent: "flex-end", marginTop: '15px' }}>
// <CircularProgress style={{ color: '#214c50' }} />
// </div>
//             ): 

            //   <div style={{ display: 'flex', paddingLeft: '150px', alignItems: 'center', marginTop: '15px' }}>
            //     <CircularProgress style={{ color: '#214c50' }} />
            //   </div>
            // ) : <Button autoFocus onClick={handleSubmit} style={{ background: '#214c50', color: '#fff' }}>
            //   Save
            // </Button>}
            
            <Button
              variant="contained"
              type="submit"
              disabled={btnDisabled}
              onClick={(e) => {
                validateDropDown();
                validateTreeCard();
                formik.handleSubmit(e);
                // handleSubmitErrors();
              }}
              style={{ display: 'flex', fontSize: 15,marginRight: 15, marginTop: 20, alignSelf: 'end', marginLeft: ' 90%' }}>
           {applicationId ? 'Update' : 'Save'}
            </Button>}
          </form>
        )}
      </Card>
    </div>
  );
}
