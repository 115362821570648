import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';
import { useDispatch, useSelector } from 'react-redux';
import {
  Tooltip,
  TableCell,
  Box,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  Pagination,
  TooltipButtion,
  CircularProgress,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import TextWithTooltip from '../../components/Tooltip';
import ArrayTooltip from '../../components/ArrayTooltip';
import Page from '../../components/Page';
import TreeCuttingApplicationToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingApplicationToolbar';
import { UserListHead, UserListToolbar, UserFormListMenu } from '../../sections/@dashboard/user';
import { SearchMeeting, GetMeetings, CancelMeeting, GetExcelMeeting } from '../../actions/MeetingActions';
import StatusChip from '../../components/StatusChip';
import CancelMeetingDialog from '../../components/DialogBox/CancelMeetingDialog';
import UseMoreMenuAction from '../../components/UseMoreMenuAction';

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'MeetingTitle', label: 'Title', alignRight: false },
  { id: 'MeetingDate', label: ' Date & Time', alignRight: false },
  { id: 'Attendees', label: 'Attendees', alignRight: false },
  { id: 'Applications', label: 'Applications', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  { id: 'Actions', label: 'Actions', alignRight: false },
];

export default function ViewMeetings(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maxLength = 5;
  const { meeting, pageInfo, addMeetingLog, loggedUser, excelData } = useSelector((state) => ({
    meeting: state.MeetingReducer.meeting,
    pageInfo: state.MeetingReducer.pageInfo,
    addMeetingLog: state.MeetingReducer.addMeetingLog,
    loggedUser: state.auth.loggedUser,
    excelData: state.MeetingReducer.excelData,
  }));

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [topModalOpen, setTopModalOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    dispatch(GetMeetings(page, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        setLoader(false);
      }
    });
  }, []);

  useEffect(() => {
    setLoader(true);
    dispatch(SearchMeeting(1, rowsPerPage, searchValue)).then((response) => {
      if (response.status === 200) {
        setLoader(false);
      }
    });
  }, [searchValue]);

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchValue) {
      dispatch(SearchMeeting(newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetMeetings(newPage, rowsPerPage));
    }
  };

  const handleDelete = (id) => {
    dispatch(CancelMeeting(deleteId)).then((resposne) => {
      if (resposne.status === 200) {
        dispatch(GetMeetings(page.current, rowsPerPage));
      }
    });

    setTopModalOpen(!topModalOpen);
  };

  const handleOpenDelete = (id) => {
    setTopModalOpen(true);
    setDeleteId(id);
  };

  const handleEdit = (meetingId) => {
    console.log('update-meeting', meetingId);
    navigate(`/dashboard/update-meeting/${meetingId}`);
  };
  // -------------- Export reprt----------------------
  const [exportButtonPressed, setExportButtonPressed] = useState(false);
  const header = ['#', 'Meeting Date ', 'Meeting Time', 'Meeting title', 'Attendees', 'Applications', 'Status'];
  function DownloadReportExcel() {
    const value1 = [];
    excelData?.map((option, index) => {
      const value2 = [index + 1];
      value2.push(option?.date_of_meeting);
      value2.push(option?.time_of_meeting);
      value2.push(option?.meeting_title);
      if (option?.attendees) {
        const attendeesString = option.attendees.map((info) => ` ${info.full_name}`).join(' , ');
        value2.push(attendeesString);
      } else {
        value2.push(''); // Push an empty string if attendees are not defined
      }

      // Concatenate application numbers with <br/> tags
      if (option?.cutting_tree_applications) {
        const applicationsString = option.cutting_tree_applications
          .map((info) => `${info.application_number}`)
          .join(' , ');
        value2.push(applicationsString);
      } else {
        value2.push(''); // Push an empty string if applications are not defined
      }
      value2.push(option?.meeting_status);

      value1.push(value2);
      return null;
    });
    downloadExcel({
      fileName: `Tree Cutting Meetings`,
      sheet: 'Tree Cutting Meetings',
      tablePayload: {
        header,
        // accept two different data structures
        body: value1,
      },
    });
  }
  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel();
      setExportButtonPressed(false);
    }
  }, [excelData]);

  const handleexportReports = () => {
    console.log('export called897789');
    dispatch(GetExcelMeeting());
    setExportButtonPressed(true);
  };

  return (
    <Page title="Tree Cutting Application">
      <CancelMeetingDialog
        isOpenConfirm={topModalOpen}
        handleClose={() => handleDelete()}
        handleCancle={() => setTopModalOpen(!topModalOpen)}
      />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Meetings
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all Meetings and its details
            </Typography>
          </Typography>
          <Button variant="contained" component={RouterLink} to="/dashboard/create-meeting">
            Add Meeting
          </Button>
        </Stack>

        <Card>
          {/* <UserListToolbar numSelected={0} placeHolder={'Search users...'} onFilterName={filterByName} /> */}
          <TreeCuttingApplicationToolbar
            hideFilter
            exportReport
            handleexportReports={handleexportReports}
            onFilterName={filterByName}
          />
          {/* <Scrollbar> */}

          <TableContainer sx={{ minWidth: 700, overflowX: 'auto' }}>
            <Table size="small" aria-label="a dense table">
              <UserListHead headLabel={TABLE_HEAD} />
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={8}>
                      {loader && (
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <CircularProgress />
                        </div>
                      )}
                      {/* Add your table rows here */}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {meeting?.length > 0 ? (
                    meeting?.map((info, index) => {
                      // const formattedTime = moment(info.meeting_time, 'HH:mm').format('h:mm A');
                      // console.log(`Formatted time: ${formattedTime}`);

                      return (
                        <TableRow hover>
                          <TableCell align="left">{(page - 1) * rowsPerPage + (index + 1)}</TableCell>
                          <TableCell>
                            <TextWithTooltip text={info.meeting_title} maxLength={12} />
                          </TableCell>

                          <TableCell align="left">{moment(info.date_of_meeting).format('DD MMMM YYYY')}<br/>{info.meeting_time}</TableCell>

                          <TableCell align="left">
                            {info && info.attendees ? (
                              <TextWithTooltip
                                text={info.attendees.map((app) => app.full_name).join(', ')}
                                maxLength={maxLength}
                              />
                            ) : (
                              <span>---</span>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            {info && info.cutting_tree_applications && info.cutting_tree_applications.length > 0 ? (
                              <ArrayTooltip records={info.cutting_tree_applications} />
                            ) : (
                              <span>---</span>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <StatusChip status={info.meeting_status} />
                          </TableCell>

                          <TableCell align="left">
                            <UseMoreMenuAction
                              id={info?.id}
                              status={info.meeting_status}
                              handleDelete={() => handleOpenDelete(info?.id)}
                              handleEdit={() => handleEdit(info?.id)}
                              editUrl="/dashboard/update-meeting"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found !</Typography>
                        </div>

                        {/* Add your table rows here */}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              count={pageInfo.last_page}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
