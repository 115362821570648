import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import { EXPORT_CALL_LOG, GET_CALL_LOG } from './Types';

const GetCallLog = (userId, fromDate, toDate,page, limit) => async (dispatch) => {
  console.log('FROM DATE', fromDate);
  console.log('To DATE', toDate);
  let url = `/api/user-call-logs?user_id=${userId}&page=${page}&limit=${limit}`

  if(fromDate && toDate){
    url = `${url}&from_date=${fromDate}&to_date=${toDate}`
  }

  try {
    const response = await JWTServer.get(url);
    console.log('responseLog', response);
    dispatch({
      type: GET_CALL_LOG,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const ExportCallLog = (userId, fromDate, toDate) => async (dispatch) => {
  let url = `/api/user-call-logs?user_id=${userId}`
  if(fromDate && toDate){
    url = `${url}&from_date=${fromDate}&to_date=${toDate}`
  }

  try {
    const response = await JWTServer.get(url);
    console.log('responseLog', response);
    dispatch({
      type: EXPORT_CALL_LOG,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export { GetCallLog,ExportCallLog };
