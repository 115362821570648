import * as React from 'react';
import * as Yup from 'yup';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Backdrop, CircularProgress, TextField, Typography } from '@mui/material';
import { AddRole, EditRole, GetPermission, GetRoleById } from '../../actions/RoleAction';
import DefaultInput from '../Inputs/DefaultInput';
import { UploadFile } from '../../actions/UploadActions';
import { AddTraining, EditTraining } from '../../actions/TrainingAction';
import { ImportCensusTrees } from '../../actions/TreeCensusAction';
import FullLoader from '../Loader/FullLoader';
import { ShowLoader } from '../../actions/CommonAction';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ImportTreeDialog(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [uploadedPdf, setUploadedPdf] = React.useState('');
  const [fileUploadError, setFileUploadError] = React.useState('');
  const [fileSizeError, setFileSizeError] = React.useState('');
  const [showUploadLoader, setShowUploadLoader] = React.useState(false);
  const { isOpen, data, selectedRole } = props;
  const [fileValue, setFileValue] = React.useState('');
  const [treeBinaryFile, setTreeBinaryFile] = React.useState('');
  const [zipBinaryFile, setZipBinaryFile] = React.useState('');
  const [zipFileUploadError, setZipFileUploadError] = React.useState('');

  const { addTrainingLog, editTrainingLog, roles, uploadFile, importCensusTreesLog, showLoader } = useSelector(
    (state) => ({
      addTrainingLog: state.trainings.addTrainingLog,
      editTrainingLog: state.trainings.editTrainingLog,
      roles: state.roles.roles,
      uploadFile: state.upload.uploadFile,
      importCensusTreesLog: state.treeCensus.importCensusTreesLog,
      showLoader: state.common.showLoader,
    })
  );

  React.useEffect(() => {
    if (data && isOpen) {
      // dispatch(GetRoleById(data.id));
      setUploadedPdf(data.pdf_url);
    }
  }, [data]);

  const firstRun = React.useRef(true);
  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    props.handleClose();
  }, [importCensusTreesLog]);

  const handleClose = () => {
    props.handleClose();
  };

  const excelChange = (e) => {
    const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);
    const validExtensions = ['xls', 'xlsx'];
    const fileExtension = e.target.files[0].name.split('.')[1];
    if (validExtensions.includes(fileExtension)) {
      setFileUploadError('');
      if (e.target.files[0].size < 15728640) {
        setFileSizeError('');
        const selectedFile = e.target.files[0];
        setTreeBinaryFile(selectedFile);
      } else {
        setFileUploadError('Please upload documents within 15MB only');
      }
    } else {
      setFileUploadError('Please upload excel');
    }
  };

  const zipFileChange = (e) => {
    const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);
    const validExtensions = ['zip', 'application'];
    const fileExtension = e.target.files[0].name.split('.')[1];
    // console.log(fileExtension);
    if (validExtensions.includes(fileExtension)) {
      setZipFileUploadError('');
      const maxSizeInBytes = 150 * 1024 * 1024; // 150 MB
      if (e.target.files[0].size < maxSizeInBytes) {
        const selectedFile = e.target.files[0];
        setZipBinaryFile(selectedFile);
      } else {
        setZipFileUploadError('Please upload documents within 15MB only');
      }
    } else {
      setZipFileUploadError('Please upload a valid ZIP file.');
    }
  };

  const DistrictsSchema = Yup.object().shape({
    excelFile: Yup.string().required('Excel File is required'),
    zipFile: Yup.string().required('Zip File is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      excelFile: '',
      zipFile: '',
    },
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {
      const formData = new FormData();
      formData.append('file', treeBinaryFile);
      formData.append('images', zipBinaryFile);
      dispatch(ImportCensusTrees(formData));
      dispatch(ShowLoader(true));
    },
  });

  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  console.log(' values', values);
  return (
    <div>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}

        // onClose={handleClose}
      >
        <BootstrapDialogTitle onClose={handleClose}>{'Import Trees'}</BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={1}>
            <BootstrapDialogTitle id="customized-dialog-title" style={{ marginLeft: '40px' }}>
              Upload Tree Excel File
            </BootstrapDialogTitle>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                fullWidth
                style={{ width: '80%', marginLeft: '40px' }}
                id="excelFile"
                name="excelFile"
                type={'file'}
                inputProps={{accept: ".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}}

                accept=".ms-excel"
                // placeholder="Upload Logo"
                value={values.excelFile}
                error={Boolean(touched.excelFile && errors.excelFile)}
                helperText={touched.excelFile && errors.excelFile}
                onChange={(e) => {
                  formik.handleChange(e);
                  excelChange(e);
                }}
              />
              <Typography variant="body2" color={'#FF0000'} style={{ marginLeft: '40px' }}>
                {fileUploadError}
              </Typography>
            </Grid>
            <BootstrapDialogTitle id="customized-dialog-title" style={{ marginLeft: '40px' }}>
              Upload Images Zip File
            </BootstrapDialogTitle>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                fullWidth
                style={{ width: '80%', marginLeft: '40px' }}
                id="zipFile"
                name="zipFile"
                type={'file'}
                // placeholder="Upload Logo"
                accept=".zip"
                inputProps={{ accept: ".zip" }}

                value={values.zipFile}
                error={Boolean(touched.zipFile && errors.zipFile)}
                helperText={touched.zipFile && errors.zipFile}
                onChange={(e) => {
                  formik.handleChange(e);
                  zipFileChange(e);
                }}
              />
              <Typography variant="body2" color={'#FF0000'} style={{ marginLeft: '40px' }}>
                {zipFileUploadError}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleSubmit} style={{ background: '#214c50', color: '#fff', height: '40px' }}>
            {showLoader ? <CircularProgress color="inherit" /> : 'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
