import React from 'react';
import { Card } from '@mui/material';
import { Container } from '@material-ui/core';

const DeletionPage = () => {
  return (
    <Container>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        <Container>
          <>
            <h2>How can I delete my profile?</h2>
            <br />
            <p>
            Since our client is a government organization, we are unable to offer the option to delete the account. This organisation will facilitate tree cutting permission through Municipal Council to citizen through this application. This will become an unchangeable government record. Therefore, the delete option is not available.
            </p>
            <br />
            <p>However, we want to emphasize that we take stringent measures and prioritize security protocols to safeguard all sensitive information. Our commitment is to ensure the utmost safety and protection of the data.</p>
            <br />
            <h3>For inquires:</h3>
            <p><b>Email : </b>abellelectrosoft@gmail.com </p>
          </>
        </Container>
      </Card>
    </Container>
  );
};

export default DeletionPage;
