import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  CREATE_TREE_CUTTING_APPLICATION,
  CREATE_TREE_CUTTING_MOM,
  GET_CENSUS_TREE_BY_COUNCIL_OR_PROPERTY,
  GET_DEMANDED_TREE_CUTTING_LIST,
  GET_EXCEL_TREE_CUTTING_APPLICATIONS,
  GET_PERMISSION_LETTER_DETAILS,
  GET_TREE_CUTTING_COMPENSATION_FINE_DETAILS,
  GET_TREE_CUTTING_MOM,
  GET_TREE_CUTTING_APPLICATIONS,
  GET_TREE_NEED_TO_BE_PLANT_LIST,
  POST_TREE_CUTTING_COMPONSATION_FINE,
  UPDATE_TREE_NEED_TO_BE_PLANT,
  GET_SITEINSCPECTOR,
  ADD_SITEINSCPECTOR,
  SEARCH_TREE_CUTTING_APPLICATION,
  GET_TREE_CUTTING_APPLICATION_BY_ID,
  UPDATE_TREE_CUTTING_APPLICATION,
} from './Types';

const GetTreeCuttingApplication = (fromDate, toDate, page, limit) => async (dispatch) => {
  let url = `/api/v1/cutting-tree-applications?page=${page}&limit=${limit}`;
  if (fromDate && toDate) {
    url = `${url}&from_date=${fromDate}&to_date=${toDate}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_TREE_CUTTING_APPLICATIONS,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const CreateTreeCuttingApplication = (obj) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/v1/cutting-tree-application`, obj);
    dispatch({
      type: CREATE_TREE_CUTTING_APPLICATION,
      payload: response,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const UpdateTreeCuttingApplication = (obj, id) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/v1/cutting-tree-application/update-application/${id}`, obj);
    dispatch({
      type: UPDATE_TREE_CUTTING_APPLICATION,
      payload: response,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetExcelTreeCuttingApplication = (fromDate, toDate, search) => async (dispatch) => {
  let url = `/api/v1/cutting-tree-applications`;

  if (search) {
    url = `${url}?search=${search}`;
  }

  if (fromDate && toDate) {
    url = `${url}?from_date=${fromDate}&to_date=${toDate}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_EXCEL_TREE_CUTTING_APPLICATIONS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchTreeCuttingApplication = (page, limit, search) => async (dispatch) => {
  let url = `/api/v1/cutting-tree-applications?page=${page}&limit=${limit}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: SEARCH_TREE_CUTTING_APPLICATION,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetCensusTreeByWardOrPropertyNumber = (coucilId, wardId, propertyId, page, limit) => async (dispatch) => {
  let url = `/api/cutting-tree-applications/census-trees?page=${page}&limit=${limit}`;
  if (wardId) {
    url = `${url}&ward_id=${wardId}`;
  }
  if (coucilId) {
    url = `${url}&council_id=${coucilId}`;
  }
  if (propertyId) {
    url = `${url}&property_id=${coucilId}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_CENSUS_TREE_BY_COUNCIL_OR_PROPERTY,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const CreateTreeCuttingMom = (obj) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/cutting-tree-application/meeting`, obj);
    dispatch({
      type: CREATE_TREE_CUTTING_MOM,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeCuttingMom = (applicationId, page, limit) => async (dispatch) => {
  let url = `/api/cutting-tree-application/meetings?page=${page}&limit=${limit}`;
  if (applicationId) {
    url = `${url}&cutting_tree_application_id=${applicationId}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_TREE_CUTTING_MOM,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeCuttingFineDetails = (applicationId) => async (dispatch) => {
  let url = `/api/cutting-tree-application/compensation-trees-amount`;
  if (applicationId) {
    url = `${url}?cutting_tree_application_id=${applicationId}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_TREE_CUTTING_COMPENSATION_FINE_DETAILS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const PostTreeCuttingFineDetails = (reqObj) => async (dispatch) => {
  const url = `/api/cutting-tree-application/compensation-trees-amount`;
  // if (applicationId) {
  //   url = `${url}?cutting_tree_application_id=${applicationId}`
  // }

  try {
    const response = await JWTServer.post(`${url}`, reqObj);
    dispatch({
      type: POST_TREE_CUTTING_COMPONSATION_FINE,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeNeedToBePlantList = (applicationId) => async (dispatch) => {
  let url = `/api/cutting-tree-application/compensation-trees`;
  if (applicationId) {
    url = `${url}?where[cutting_tree_application_id]=${applicationId}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_TREE_NEED_TO_BE_PLANT_LIST,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const PostTreeNeedToBePlantList = (obj) => async (dispatch) => {
  const url = `/api/cutting-tree-application/compensation-trees`;

  try {
    const response = await JWTServer.post(`${url}`, obj);
    dispatch({
      type: UPDATE_TREE_NEED_TO_BE_PLANT,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const UpdateTreeNeedToBePlantList = (obj) => async (dispatch) => {
  const url = `/api/cutting-tree-application/compensation-trees`;

  try {
    const response = await JWTServer.put(`${url}`, obj);
    dispatch({
      type: UPDATE_TREE_NEED_TO_BE_PLANT,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetPermissionLetterDetails = (applicationId) => async (dispatch) => {
  let url = `/api/cutting-tree-application/download-permission-letter`;
  if (applicationId) {
    url = `${url}?cutting_tree_application_id=${applicationId}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_PERMISSION_LETTER_DETAILS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetDemandedTreeCuttingList = (applicationId) => async (dispatch) => {
  let url = `/api/cutting-tree-application/cutting-trees`;
  if (applicationId) {
    url = `${url}?cutting_tree_application_id=${applicationId}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_DEMANDED_TREE_CUTTING_LIST,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetSiteInscepectorRellList = () => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users?status=1&role_id[]=13`);
    dispatch({
      type: GET_SITEINSCPECTOR,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const AddSiteInspector = (params) => async (dispatch) => {
  console.log('valueinAction', params);
  try {
    const response = await JWTServer.post(`/api/assign-to-site-inspector`, params);
    console.log('response1996', response);
    dispatch({
      type: ADD_SITEINSCPECTOR,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeCuttingApplicationById = (id) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/v1/cutting-tree-applications/${id}`);
    console.log('response7676576', response);
    dispatch({
      type: GET_TREE_CUTTING_APPLICATION_BY_ID,
      payload: response?.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export {
  GetTreeCuttingApplication,
  CreateTreeCuttingApplication,
  GetExcelTreeCuttingApplication,
  GetCensusTreeByWardOrPropertyNumber,
  GetTreeCuttingMom,
  CreateTreeCuttingMom,
  GetTreeCuttingFineDetails,
  PostTreeCuttingFineDetails,
  GetTreeNeedToBePlantList,
  PostTreeNeedToBePlantList,
  UpdateTreeNeedToBePlantList,
  GetPermissionLetterDetails,
  GetDemandedTreeCuttingList,
  GetSiteInscepectorRellList,
  SearchTreeCuttingApplication,
  AddSiteInspector,
  GetTreeCuttingApplicationById,
  UpdateTreeCuttingApplication,
};
