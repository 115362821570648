import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
    Button,
  } from '@mui/material';
import { ConnectingAirportsOutlined } from '@mui/icons-material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ByTreeConditionGraph = (props) => {
  const { slug} = props;
  // const {data: graph, slug} = props;
    // console.log('data_bytreecondition', props);
    const graph = [
      {id: 1, condition: 'Kskqq11', census_trees_count: 0},
      {id: 2, condition: 'Kskqq', census_trees_count: 0},
      {id: 3, condition: 'Ksk', census_trees_count: 0}, 
      {id: 4, condition: 'Diseased', census_trees_count: 8},
      {id: 5, condition: 'Healthy', census_trees_count: 13},
      {id: 6, condition: 'Fully cut', census_trees_count: 14},
      {id: 7, condition: 'Partially Cut', census_trees_count: 9},
      {id: 8, condition: 'Dead', census_trees_count: 5},
      {id: 9, condition: 'Needle Blights pine', census_trees_count: 4},
      {id: 11, condition: 'Half cutt', census_trees_count: 1},
      {id: 12, condition: 'healthy', census_trees_count: 3},
      {id: 13, condition: 'Healthy', census_trees_count: 0},
      {id: 14, condition: 'Need fertilizer', census_trees_count: 1},
      {id: 15, condition: 'tree shades', census_trees_count: 0},
      {id: 16, condition: 'partially cuttee', census_trees_count: 0}]
    const length = graph.length;
    const x = [];
    const y = [];
    
    for(let i=0;i<length;i+=1){
      
        x.push(graph[i].condition)
     
    }

    for(let i=0;i<length;i+=1){
      y.push(graph[i].census_trees_count)
    }
    // console.log(x)
    // console.log(y)
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            // text: 'Chart.js Bar Chart',
          },
        },
      };
    
      const data = {
        labels: x,
        datasets: [
          {
            label: `${slug}`,
            data: y,
            backgroundColor: "rgba(54, 162, 235)",
          },
        ],
      };
      
  return (
   <>
    <Bar options={options} data={data} />
  
   </>
  )
}

export default ByTreeConditionGraph
