import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import { ADD_NEWSPAPER_PROCLAMATION, UPLOAD_PROCLAMATION_RECIPT, ADD_PROCLAMATION_OBJECTION, ADD_DEPOSITE_AMOUNT_RECEIPT } from './Types';

const AddNewsPaperProclamation = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/upload-proclamation `, params);
    dispatch({
      type: ADD_NEWSPAPER_PROCLAMATION,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const uploadProclamationRecipt = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/upload-proclamation-receipt/${id}`, params);
    dispatch({
      type: UPLOAD_PROCLAMATION_RECIPT,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};



const AddProclamationObjection = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/add-objections/${id}`, params);
    dispatch({
      type: ADD_PROCLAMATION_OBJECTION,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


const AddDepositeAmountReceipt = (params) =>async(dispatch)=> {
  try{
    const response = await JWTServer.post(`/api/upload-fees-receipt`, params)
    console.log("response98", response)
    dispatch({
      type: ADD_DEPOSITE_AMOUNT_RECEIPT,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }

}

export { AddNewsPaperProclamation, uploadProclamationRecipt, AddProclamationObjection, AddDepositeAmountReceipt };
