import * as React from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { Checkbox, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { AddDesignations, EditDesignations } from '../../../actions/DesignationAction';
import { CreateTreeCuttingMom } from '../../../actions/TreeCuttingPermissionAction';
import { UploadFile } from '../../../actions/UploadActions';


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MomDialog(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [status, setStatus] = React.useState('Status')
  const { isOpen, data } = props;
  const [allSelected, setAllSelected] = React.useState(false);
  const [selectedTreeList, setSelectedTreeList] = React.useState([])
  const [uploadedPdf, setUploadedPdf] = React.useState('')
  const [fileUploadError, setFileUploadError] = React.useState('');
  const [fileSizeError, setFileSizeError] = React.useState('');
  const [showUploadLoader, setShowUploadLoader] = React.useState(false);

  const {
    uploadFile,
    uploadFileLog,
    addTreeCuttingMomLog,
    treeCuttingApplications,
    demandedTreesCuttingDetails
  } = useSelector((state) => ({
    uploadFile: state.upload.uploadFile,
    uploadFileLog: state.upload.uploadFileLog,
    addTreeCuttingMomLog: state.treeCutting.addTreeCuttingMomLog,
    treeCuttingApplications: state.treeCutting.treeCuttingApplications,
    demandedTreesCuttingDetails: state.treeCutting.demandedTreesCuttingDetails
  }));

  console.log("DEMAMDED TREE CUTTING DETAILS",demandedTreesCuttingDetails);

  const descisionList = [
    {
      id: 'Pending',
      name: 'Pending',
    },
    {
      id: 'Approved',
      name: 'Approved',
    },
    {
        id: 'Unapproved',
        name: 'Rejected',
    },
  ];

  const thirdRun = React.useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    if (uploadFile) {
      setUploadedPdf(uploadFile.url);
    }
  }, [uploadFileLog]);

  const pdfChange = (e) => {
   
    const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);
    const validExtensions = ['pdf'];
    const fileExtension = e.target.files[0].name.split('.')[1];
    // console.log(fileExtension);
    if (validExtensions.includes(fileExtension)) {
      setFileUploadError('');
      console.log("FILE SIZE",e.target.files[0].size)
      if (e.target.files[0].size < 15728640) {
        setFileSizeError('');
        const formData = new FormData();
        formData.append('upload_for', 'cuttingTreesMeeting');
        formData.append('file', e.target.files[0]);
        setShowUploadLoader(true)
        dispatch(UploadFile(formData, 1)).then((response) => {
          // console.log("upload file",response);
        });
      } else {
        setFileUploadError('Please upload documents within 15MB only');
      }
    } else {
      setFileUploadError('Please upload documents with pdf format only');
    }
}

const handlePdfRemove = (e) => {
  setUploadedPdf('');
  setFieldValue('document','')
};

const handleViewDocument = (fpath) => {
  if (fpath.includes(process.env.REACT_APP_BASE_URL)) {
    // console.log("file path", fpath);
    window.open(fpath, '_blank');
  } else {
    const fLink = process.env.REACT_APP_BASE_URL.concat('/').concat(fpath);
    //  console.log("file path", fLink);
    window.open(fLink, '_blank');
  }
};

  const handleSelectedChange = (e,value) => {
    if(e.target.checked===true){
      const newSelectedList = [...selectedTreeList];
      const info = {
        id:value.id
      }
      newSelectedList.push(info);
      setSelectedTreeList(newSelectedList)
    }
    else {
      const newSelectedList = [...selectedTreeList];
      newSelectedList.splice(newSelectedList.findIndex(item=>item.id === value.id ), 1);
      setSelectedTreeList(newSelectedList);

    }

    checkAllSelected();
  }

  const checkAllSelected = () => {
    let allFound = true;
    demandedTreesCuttingDetails?.map((value,index)=>{
      if(selectedTreeList.some(treeNumber => treeNumber.id === value.id)){
        allFound = false
      }
      return null;
    })

    if(allFound){
      setAllSelected(true)
    }
    else {
      setAllSelected(false);
    }
  }

  const handleAllSelectChange = (e) => {
    if(e.target.checked===true){
      const treeList =[]
      demandedTreesCuttingDetails?.map((value,index)=>{
        if(!selectedTreeList.some(treeNumber => treeNumber.id === value.id)){
          treeList.push({id:value.id})
        }
        return null;
        
      })
      setSelectedTreeList(treeList)
      setAllSelected(true)
    }
    else {
      setSelectedTreeList([])
      setAllSelected(false)
    }
  }

  const firstRun = React.useRef(true);
  useEffect(()=>{
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    props.handleClose();
  },[addTreeCuttingMomLog])

const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClose = () => {
    props.handleClose();
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const DesignationsSchema = Yup.object().shape({
    dateOfMeeting: Yup.string().required('Date of meeting required'),
    decision: Yup.string().required('Decision is required'),
    document : Yup.string().required('Document is required')
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateOfMeeting:new Date().toISOString().substring(0, 10),
      decision : "Pending",
      remark : "",
      document:""
    },
    validationSchema: DesignationsSchema,
    onSubmit: (value) => {
        const reqObj = {
          "cutting_tree_application_id": data,
          "date_of_meeting": value.dateOfMeeting,
          "applicant_status": value.decision,
          "remarks": value.remark,
          "cutting_trees":selectedTreeList,
          "cutting_tree_meeting_document":[{url:uploadedPdf}]
        }
        dispatch(CreateTreeCuttingMom(reqObj))
      
    },
  });

  const useStyles = makeStyles({
    
    icon: {
        fill: '#214C50',
    },
   
})
const classes = useStyles()

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps,setFieldValue } = formik;

  return (
    <div>
     
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // width: "100%",
              minWidth: "750px",  // Set your width here
             
            },
          },
        }}
       
        // onClose={handleClose}
      >
        <BootstrapDialogTitle onClose={handleClose}>{"Create MOM"}</BootstrapDialogTitle>
        <Divider/>
        <DialogContent>
        <Grid container spacing={1}>
        <Grid item xs={12} >
        <TextField
              fullWidth
              disabled
              id="dateofMeeting"
              name="dateOfMeeting"
              label="Date of Meeting"
              placeholder="Date of Meeting"
              style={{ marginTop: '7px' }}
              value={values.dateOfMeeting}
              error={Boolean(touched.dateOfMeeting && errors.dateOfMeeting)}
            helperText={touched.dateOfMeeting && errors.dateOfMeeting}
            {...getFieldProps("dateOfMeeting")}
            />
        </Grid>
        {uploadedPdf ?
              
              <Grid item xs={12}>
                  <Button
                      variant="outlined"
                      target="_blank"
                      rel="noopener"
                      onClick={() => {
                        handleViewDocument(uploadedPdf);
                      }}
                      style={{ marginTop: '5px', }}
                    >
                      View Document
                    </Button>

                <IconButton
                  color={'error'}
                  aria-label={'delete'}
                  size="large"
                  onClick={() => handlePdfRemove(uploadedPdf)}>
                  <CancelIcon fontSize="inherit" />
                </IconButton>
            </Grid>:
        <Grid item xs={12}>
          <TextField
              fullWidth
              id="document"
              name="document"
              type="file"
              autoComplete="Document"
              label="Document"
              placeholder="Document"
              style={{ marginTop: '7px' }}
              InputLabelProps={{
                shrink: true,
              }}
              
              onChange={(e) => {
                formik.handleChange(e);
                pdfChange(e)
              }}
              error={Boolean(touched.document && errors.document)}
              helperText={touched.document && errors.document}
            // {...getFieldProps("middleName")}
            />
             <Typography variant="body2" color={'#FF0000'} style={{marginLeft:'40px'}}>
              {fileUploadError}
            </Typography>
          </Grid>}
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={2}
              id="remark"
              name="remark"
              label="Remark"
              placeholder="Remark"
              style={{ marginTop: '7px' }}
              value={values.remark}
              error={Boolean(touched.remark && errors.remark)}
            helperText={touched.remark && errors.remark}
            {...getFieldProps("remark")}
            />
        </Grid>
        <Grid item xs={12}>
        <TextField
          fullWidth
          select
          id="decision"
          name='decision'
          style={{ marginTop: '7px' }}
          displayEmpty
          label="Decision*"
          value={values.decision}
          error={Boolean(touched.decision && errors.decision)}
          helperText={touched.decision && errors.decision}
          {...getFieldProps("decision")}
          // MenuProps={MenuProps}
          // inputProps={{ 'aria-label': 'Without label' }}
          
          inputProps={{
            classes: {
                icon: classes.icon,
            },
        }}
        >
          <MenuItem disabled value="">
            <em>Select Decision*</em>
          </MenuItem>
          {descisionList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
        </TextField>
        </Grid>
        
        {/* {values.decision==="Unapproved"?
        <Grid item xs={12}>
            <TextField
              fullWidth
              id="reason"
              name="reason"
              label="Reason"
              placeholder="Reason"
              style={{ marginTop: '7px' }}
              value={values.reason}
              error={Boolean(touched.reason && errors.reason)}
            helperText={touched.reason && errors.reason}
            {...getFieldProps("reason")}
            />
        </Grid>:null} */}
        
          {values.decision==="Approved"?
          <>
          <Typography variant="h5" style={{ fontWeight: '400',marginTop:'20px' }}>
          Select the tree number which are permitted to cut 
          </Typography>
          <Grid item xs={12}>
          <FormControlLabel 
        control={<Checkbox checked={allSelected} onChange={(e)=>handleAllSelectChange(e)}  />} label="Select All" 
        sx={{ justifyContent: 'right', display: 'flex', mt: 0, mb: 1 }} 
        />
        </Grid>
           
          {demandedTreesCuttingDetails?.map((value,index)=>{
            return <FormControlLabel 
            control={<Checkbox checked={selectedTreeList.some(treeNumber => treeNumber.id === value.id)} onChange={(e)=>handleSelectedChange(e,value)} />} label={value.tree_number} 
            sx={{ justifyContent: 'right', display: 'flex', mt: 1}} 
            />
          })}
          </>:null}
        </Grid>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button onClick={handleSubmit} variant='contained'>{data?"Save":"Add"}</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
