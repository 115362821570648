import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Card, Table, Stack, Button, TableRow, TableBody, TableCell, Container, TableContainer, Pagination, } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { MasterBreadCrumChip } from '../../sections/@dashboard/master/MasterBreadCrumChip';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../sections/@dashboard/user';
import TreeCuttingReasonDialog from '../../components/DialogBox/TreeCuttingReasonDialog';
import { GetTreeCuttingReasons,SearchTreeCuttingReasons,DeleteTreeConditions,TreeCuttingReasonStatusChange } from '../../actions/TreeCuttingReasonAction';
import StatusButton from '../../components/statusbutton/StatusButton';

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'TypeofTreeCuttingPerf', label: 'Tree Cutting Permission Reason', alignRight: false },
  { id: 'TreeCuttingPermissionReasonStatus', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

export default function TreeCuttingPermissionReason() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [dropPage, setDropPage] = useState(10);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleDropChange = (event) => {
    setDropPage(event.target.value);
  };
  const userPermissions = [];

  const { treeCuttingReason, loggedUser ,pageInfo,addTreeCuttingReasonLog,treeCuttingReasonStatusChange,editTreeCuttingReasonLog} = useSelector((state) => ({
    treeCuttingReason: state.treeCuttingReason.treeCuttingReason,
    loggedUser: state.auth.loggedUser,
    pageInfo:state.treeCuttingReason.pageInfo,
    addTreeCuttingReasonLog:state.treeCuttingReason.addTreeCuttingReasonLog,
    treeCuttingReasonStatusChange: state.treeCuttingReason.treeCuttingReasonStatusChange,
    editTreeCuttingReasonLog : state.treeCuttingReason.editTreeCuttingReasonLog
  }));

  const myState = useSelector((state) => state.treeCuttingReason);

  const reasons = [];

  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  const handleAddClick = () => {
    setDialogData(null);
    setOpen(!open);
  };

  const handleCloseClick = () => {
    setDialogData(null);
    dispatch(GetTreeCuttingReasons(page, rowsPerPage));
    setOpen(!open);
  };
  const handleClick = (event) => {
    event.preventDefault();
  };
  let timer = null;

  useEffect(() => {
    dispatch(GetTreeCuttingReasons(page, rowsPerPage));
  }, []);

   useEffect(() => {
    dispatch(GetTreeCuttingReasons(page, rowsPerPage));
  }, [addTreeCuttingReasonLog]);

  useEffect(() =>{
    dispatch(GetTreeCuttingReasons(page, rowsPerPage));
  },[treeCuttingReasonStatusChange])
  
  useEffect(() =>{
    dispatch(GetTreeCuttingReasons(page, rowsPerPage));
  },[editTreeCuttingReasonLog])
  

  const filterByName = (event) => {
    const value = event.currentTarget.value;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
          dispatch(SearchTreeCuttingReasons(1,rowsPerPage,value))
          setSearch(true)
          setPage(1)
          setSearchValue(value);
      } else {
          dispatch(GetTreeCuttingReasons(1,rowsPerPage));
          setSearch(false);
          setPage(1);
          setSearchValue("")
      }
    }, 1000);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (search) {
       dispatch(GetTreeCuttingReasons(newPage, rowsPerPage, searchValue));
    } else {
       dispatch(GetTreeCuttingReasons(newPage, rowsPerPage));
    }
  };

  const handleEdit = (data) => {
    console.log('Data: ', data);
    setDialogData(data);
    setOpen(!open);
  };

  const handleDelete = (data) => {
    dispatch(TreeCuttingReasonStatusChange(data));
  };

  return (
    <Page title="User">
      <Container>
        {open ? <TreeCuttingReasonDialog isOpen={open} handleClose={handleCloseClick} data={dialogData} /> : null}
        <Scrollbar className="padscreen_">
          {userPermissions.includes('create-tree-cutting-reason') ? (
            <Button
              onClick={handleAddClick}
              variant="contained"
              component={RouterLink}
              to="#"
              sx={{ float: 'right', mt: -4 }}
              className="padscreenadd mobbutton">
              Add Tree Cutting Reason
            </Button>
          ) : null}
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={6} mt={5}>
            <div role="presentation" className="mob-master" onClick={handleClick}>
              <MasterBreadCrumChip
                dropDownPage={dropPage}
                handleDropChange={handleDropChange}
                slug={'permission reason'}
              />
            </div>
          </Stack>
        </Scrollbar>
      </Container>

      <Card>
        <UserListToolbar numSelected={0} placeHolder={'Search tree conditions...'} onFilterName={filterByName} />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800}}>
           
            <Table size="small" aria-label="a dense table">
  <UserListHead headLabel={TABLE_HEAD} />
  <TableBody>
    {treeCuttingReason  && treeCuttingReason.length !== 0 ? (
      treeCuttingReason.map((option, index) => (
        <TableRow hover key={index}>
          <TableCell align="left">
            <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
          </TableCell>
          <TableCell align="left">{option.reason}</TableCell>
          <TableCell align="left">
            <StatusButton status={option.status} />
          </TableCell>
          <TableCell align="right">
            <UserMoreMenu
              status={option.status}
              permissions={userPermissions}
              handleEdit={() => handleEdit(option)}
              handleDelete={() => handleDelete(option)}
            />
          </TableCell>
        </TableRow>
      ))
    
    ) : (
      <TableRow>
        <TableCell align="center" colSpan={4}>
          <div style={{ fontWeight: 900 }}>
            No data found
          </div>
        </TableCell>
      </TableRow>
    )}
  </TableBody>
</Table>

          </TableContainer>
        </Scrollbar>
        {treeCuttingReason ? (
          <Pagination
            count={pageInfo.last_page}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
            sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
          />
        ) : null}
      </Card>
    </Page>
  );
}
