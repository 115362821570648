import { Chip } from '@material-ui/core';
import { green, orange, red, gray } from '@mui/material/colors';
import React from 'react';

const StatusChip = ({ status }) => {
  let chipColor = '';
  const chipLabel = status;

  switch (status) {
    case 'Progress':
    case 'Pending':
      chipColor = green[600];
      break;
    case 'Scheduled':
      chipColor = orange[600];
      break;
    case 'Cancelled':
      chipColor = ' #bdbdbd';
      break;
    default:
      chipColor = '#757575'; // default color for unknown statuses
      break;
  }

  return (
    <div>
      <Chip
        label={chipLabel}
        style={{
          background: chipColor,
          height: '20px',
          borderRadius: '10px',
          color: '#fff',
          fontSize: '10px',
          fontWeight: 500,
        }}
      />
    </div>
  );
};

export default StatusChip;
