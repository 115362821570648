import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { ShowLoader } from './CommonAction';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  GET_TREE_CENSUS,
  UPDATE_QC_STATUS_TREE_CENSUS,
  EXPORT_CALL_LOG,
  GET_TREE_CENSUS_HISTORY,
  GET_TREE_CENSUS_PENDING_QC_STATUS,
  UPDATE_CENSUS_TREE,
  REFER_TO_EXPERT,
  DELETE_TREE_CENSUS,
  IMPORT_CENSUS_TREES,
  EXCEPTION_IMPORT_CENSUS_TREES,
  GET_STATUS
} from './Types';

const GetTreeCensus =
  (
    page,
    limit,
    council,
    zone,
    ward,
    addedByForm,
    treeNameFrom,
    heightFrom,
    heightTo,
    girthFrom,
    girthTo,
    fromDateForm,
    toDateForm,
    fromPropertyNumber,
    toPropertyNumber
  ) =>
  async (dispatch) => {
    let url = `/api/census-trees?page=${page}&limit=${limit}`;
    if (council) {
      url = `${url}&where[council_id]=${council}`;
    }
    if (zone) {
      url = `${url}&where[zone_id]=${zone}`;
    }
    if (ward) {
      url = `${url}&where[ward_id]=${ward}`;
    }
    if (addedByForm) {
      url = `${url}&where[added_by_id]=${addedByForm}`;
    }
    if (treeNameFrom) {
      url = `${url}&where[tree_name_id]=${treeNameFrom}`;
    }
    if (heightFrom) {
      url = `${url}&height_from=${heightFrom}`;
    }
    if (heightTo) {
      url = `${url}&height_to=${heightTo}`;
    }
    if (girthFrom) {
      url = `${url}&girth_from=${girthFrom}`;
    }
    if (girthTo) {
      url = `${url}&girth_to=${girthTo}`;
    }
    if (fromDateForm && toDateForm) {
      url = `${url}&from_date=${fromDateForm.split('-').reverse().join('-')}&to_date=${toDateForm
        .split('-')
        .reverse()
        .join('-')}`;
    }
    if (fromPropertyNumber) {
      url = `${url}&property_number_from=${fromPropertyNumber}`;
    }
    if (toPropertyNumber) {
      url = `${url}&property_number_to=${toPropertyNumber}`;
    }

    try {
      const response = await JWTServer.get(`${url}`);
      dispatch({
        type: GET_TREE_CENSUS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: GET_TREE_CENSUS,
        payload: { data: { data: [], last_page: 0 } },
      });
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

const SearchTreeCensus = (page, limit, council, zone, ward, searchValue) => async (dispatch) => {
  let url = `/api/census-trees?page=${page}&limit=${limit}`;
  if (council) {
    url = `${url}&where[council_id]=${council}`;
  }
  if (zone) {
    url = `${url}&where[zone_id]=${zone}`;
  }
  if (ward) {
    url = `${url}&where[ward_id]=${ward}`;
  }

  url = `${url}&search=${searchValue}`;

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_TREE_CENSUS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeCensusHistory = (params, page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/census-trees/history/${params}?page=${page}&limit=${limit}`);
    dispatch({
      type: GET_TREE_CENSUS_HISTORY,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchTreeCensusHistory = (params, page, limit, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(
      `/api/census-trees/history/${params}?page=${page}&limit=${limit}&search=${searchValue}`
    );
    dispatch({
      type: GET_TREE_CENSUS_HISTORY,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const UpdateQCStatusOfTreeCensus = (id, params) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/census-trees/qc/${id}`, params);
    dispatch({
      type: UPDATE_QC_STATUS_TREE_CENSUS,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const UpdateCensusTree = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`/api/census-trees/${id}`, params);
    dispatch({
      type: UPDATE_CENSUS_TREE,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const ReferToExpert = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/census-trees/referred-to-expert/${id}`, params);
    dispatch({
      type: REFER_TO_EXPERT,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeCensusPendingQCStatus =
  (councilId, zoneId, wardId, fromDate, toDate, addedBy, checked) => async (dispatch) => {
    let url = `/api/census-trees/qc/pending`;
    if (councilId) {
      url = `${url}?where[council_id]=${councilId}`;
    }
    if (zoneId) {
      url = `${url}&where[zone_id]=${zoneId}`;
    }
    if (wardId) {
      url = `${url}&where[ward_id]=${wardId}`;
    }
    if (addedBy) {
      url = `${url}&where[added_by_id]=${addedBy}`;
    }
    if (fromDate && toDate) {
      url = `${url}&where[from_date]=${fromDate}&where[to_date]=${toDate}`;
    }
    if (checked) {
      url = `${url}&where[is_heritage]=${checked}`;
    }

    try {
      const response = await JWTServer.get(`${url}`);
      dispatch({
        type: GET_TREE_CENSUS_PENDING_QC_STATUS,
        payload: response.data,
      });
      dispatch(ShowLoader(false));
    } catch (e) {
      dispatch(ShowLoader(false));
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

const DeleteCensusTrees = (params, status) => async (dispatch) => {
  try {
    const response = await JWTServer.delete(`/api/census-trees/${params}?status=${status}`);
    dispatch({
      type: DELETE_TREE_CENSUS,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: 'Census tree deleted successfully!',
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const UpdateViewCensusTree = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`/api/census-trees/${id}/tree-details`, params);
    dispatch({
      type: UPDATE_CENSUS_TREE,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const exportCensusReportsApi = (params, page, limit) => async (dispatch) => {
  console.log('params', params);
  let url = `/api/export-census-trees`;

  if (params.council_id) {
    url = `${url}?where[council_id]=${params.council_id}`;
  }
  if (params.zone_id) {
    url = `${url}&where[zone_id]=${params.zone_id}`;
  }
  if (params.ward_id) {
    url = `${url}&where[ward_id]=${params.ward_id}`;
  }
  if (params.from_date) {
    url = `${url}&from_date=${params.from_date}`;
  }
  if (params.to_date) {
    url = `${url}&to_date=${params.to_date}`;
  }
  if (params.girth_start) {
    url = `${url}&girth_from=${params.girth_start}`;
  }
  if (params.girth_end) {
    url = `${url}&girth_to=${params.girth_end}`;
  }
  if (params.height_start) {
    url = `${url}&height_from=${params.height_start}`;
  }
  if (params.height_end) {
    url = `${url}&height_to=${params.height_end}`;
  }

  try {
    // {where[council_id]=${params.council_id}&zone_id=${params.zone_id}&from-date=${params.from_date}&to_date=${params.to_date}&girth_from=${params.start_girth}&girth_to=${params.end_girth}&height_from=${params.start_height}&height_to=${params.end_height}
    const response = await JWTServer.get(`${url}`);

    dispatch({
      type: EXPORT_CALL_LOG,
      payload: response,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const ImportCensusTrees = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/import-census-trees`, params);
    dispatch({
      type: IMPORT_CENSUS_TREES,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    dispatch(ShowLoader(false));
  } catch (e) {
    dispatch(ShowLoader(false));
    if (e.response.status === 422) {
      if (Array.isArray(e.response.data)) {
        dispatch({
          type: EXCEPTION_IMPORT_CENSUS_TREES,
          payload: e.response.data,
        });
      } else {
        dispatch(
          SetNewAlert({
            msg: e.response.data.message,
            alertType: 'danger',
          })
        );
      }
    } 
  }
};



const ImportStatus = (page,limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/import-census-trees-status?page=${page}&limit=${limit}`);
     console.log("Status RESPONSE",response.data);
     console.log("page",page)
    dispatch({
      type: GET_STATUS, 
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};



export {
  GetTreeCensus,
  SearchTreeCensus,
  GetTreeCensusHistory,
  SearchTreeCensusHistory,
  UpdateQCStatusOfTreeCensus,
  GetTreeCensusPendingQCStatus,
  UpdateCensusTree,
  ReferToExpert,
  DeleteCensusTrees,
  UpdateViewCensusTree,
  exportCensusReportsApi,
  ImportCensusTrees,
  ImportStatus
};
