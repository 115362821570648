import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch } from "./CombineCatch";
import { GET_DENIED_ENTRY, EXPORT_CALL_LOG } from "./Types";

const GetDeniedEntry = (page, limit, council, zone, ward, fromDate, toDate,addedBy) => async (dispatch) => {

  let url = `/api/denied-properties?page=${page}&limit=${limit}`
  if (council) {
    url = `${url}&where[council_id]=${council}`;
  }
  if (zone) {
    url = `${url}&where[zone_id]=${zone}`;
  }
  if (ward) {
    url = `${url}&where[ward_id]=${ward}`
  }
  if (addedBy) {
    url = `${url}&where[added_by]=${addedBy}`
  }
  if (fromDate && toDate) {
    url = `${url}&from_date=${fromDate}&to_date=${toDate}`
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_DENIED_ENTRY,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


const SearchDeniedEntry = (page, limit, council, zone, ward,fromDate,toDate, searchValue) => async (dispatch) => {

  let url = `/api/denied-properties?page=${page}&limit=${limit}`
  if (council) {
    url = `${url}&where[council_id]=${council}`;
  }
  if (zone) {
    url = `${url}&where[zone_id]=${zone}`;
  }
  if (ward) {
    url = `${url}&where[ward_id]=${ward}`
  }

  if (fromDate && toDate) {
    url = `${url}&from_date=${fromDate.split('-').reverse().join('-')}&to_date=${toDate.split('-').reverse().join('-')}`
  }

  url = `${url}&search=${searchValue}`

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_DENIED_ENTRY,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const exportDeniedReports = (params, page, limit) => async (dispatch) => {
  console.log("params",params)
  let url = `/api/export-denied-properties`

  if (params.council_id) {
    url = `${url}?where[council_id]=${params.council_id}`;
  }
  if (params.zone_id) {
    url = `${url}&where[zone_id]=${params.zone_id}`;
  }
  if (params.from_date) {
    url = `${url}&from_date=${params.from_date}`;
  }
  if (params.to_date) {
    url = `${url}&to_date=${params.to_date}`;
  }
  if (params.ward_id) {
    url = `${url}&where[ward_id]=${params.ward_id}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);

    dispatch({
      type: EXPORT_CALL_LOG,
      payload: response,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export {
  GetDeniedEntry,
  SearchDeniedEntry,
  exportDeniedReports
}