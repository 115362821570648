import JWTServer from "../api/withJWTServer";
import formDataJWTServer from "../api/formDataJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch } from "./CombineCatch";
import { GET_ABELL_META_DETAILS, UPDATE_META_DETAILS } from "./Types";

const GetAbellMetaDetails = () => async (dispatch) => {
    // console.log('id', id);
    try {
      const response = await JWTServer.get('/api/abell-meta-details');
      // console.log("GET COUNCIL BY ID RESPONSE",response.data);
      dispatch({
        type: GET_ABELL_META_DETAILS,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const UpdateAbellMetaDetails = (params) => async (dispatch) => {
    try {
      const response = await JWTServer.post('/api/abell-meta-details',params);
      // console.log("EDIT STATE RESPONSE",response.data);
      dispatch({
        type: UPDATE_META_DETAILS,
        payload: response.data,
      });
      dispatch(SetNewAlert({
        msg: response.data.message,
        alertType: "success",
      }));
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  export {
    GetAbellMetaDetails,
    UpdateAbellMetaDetails
  }