import { useRef } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF as Jspdf } from 'jspdf';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Grid, Button } from '@mui/material';
import ReportListToolbar from '../../sections/@dashboard/tree/ReportListToolbar'

ChartJS.register(ArcElement, Tooltip, Legend);

const OverallCountGraph = (props) => {
  // const {data: graph} = props;
  const graph = [
    { id: 1, name: 'Kaduneem', census_trees_count: 16 },
    { id: 2, name: 'Sag', census_trees_count: 14 },
    { id: 3, name: 'Sitaphal', census_trees_count: 10 },
    { id: 3, name: 'Subabhul', census_trees_count: 30 },
    { id: 3, name: 'Peru', census_trees_count: 40 }
  ];
//   console.log('data_', graph);
  const inputRef = useRef(null);
  const wardImage =() => {
    // console.log('WardImageinputRef', inputRef.current);
    return inputRef.current;
  };
  console.log('wardImage...', wardImage());
  const length = graph.length;
  const x = [];
  const y = [];

  for (let i = 0; i < length; i += 1) {
    x.push(graph[i].name);
  }

  for (let i = 0; i < length; i += 1) {
    y.push(graph[i].census_trees_count);
  }
  // console.log(x)
  // console.log(y)
  const customLabel = y.map((val, index) => `${x[index]},  ${val}%`);
  const data = {
    labels: customLabel,
    datasets: [
      {
        label: 'Wards',
        data: y,
        backgroundColor: [
          'rgba(255, 99, 132)',
          'rgba(54, 162, 235)',
          'rgba(255, 206, 86)',
          'rgba(81, 252, 50)',
          'rgba(153, 102, 255)',
          'rgba(255, 159, 64)',
          'rgba(139, 255, 107)',
          'rgba(242, 50, 252)',
          'rgba(0, 249, 216)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(139, 255, 107, 1)',
          'rgba(242, 50, 252,1)',
          'rgba(0, 249, 216,1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <div ref={inputRef}>
        <Pie data={data} />
      </div>
      {/* <ReportListToolbar
            wardImage={wardImage()}
          /> */}
    </>
  );
};
export default OverallCountGraph;
