import React, { useState, useEffect } from 'react';
import {
  CardActionArea,
  CardMedia,
  Card,
  CardContent,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Typography,
  CircularProgress,
  IconButton,
  Link,
  Chip,
  List,
  ListItem,
} from '@mui/material';
import { format } from 'date-fns';
import { green, orange, red, grey } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import { useDispatch, useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import { GetSpecificTreeInfo } from '../../../actions/TreeOnMapAction';
import TreeDetailsWithReasonDialog from '../../../components/DialogBox/TreeCutting/TreeDetailsWithReasonDialog';
import MeetingListModal from '../../../components/DialogBox/meeting/MeetingListModal';
import SideInscpectorList from '../../../components/DialogBox/SiteInscpectorList';
import ApplicationStatusChip from '../../../components/ApplicationStatusChip';
import pdfImg from '../../../Assets/pdf.png';

const ViewMeetingList = ({ applicationDetailsData, showLoader }) => {
  console.log('applicationDetailsData19982', applicationDetailsData);
  const dispatch = useDispatch();
  const { treeDetails, loggedUser } = useSelector((state) => ({
    treeDetails: state.treeLocation.treeDetails,
    loggedUser: state.auth.loggedUser,
  }));
  const [treeDetailsOpen, setDialogOpen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);

  const onMarkerClick = (marker) => {
    setDialogOpen(!treeDetailsOpen);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    dispatch(GetSpecificTreeInfo(marker));
  };

  const [application, setApplication] = useState({ applicationId: '', applicationName: '' });
  const [openSiteIncepectorList, setOpenSiteIncepectorList] = useState(false);
  const [open, setOpen] = useState(false);
  const handleAddToMeeting = () => {
    if (applicationDetailsData) {
      setApplication({
        applicationId: applicationDetailsData?.id,
        applicationName: applicationDetailsData?.application_number,
      });
      setOpen(true);
    }
  };

  const handleAddSiteInscpector = () => {
    setOpenSiteIncepectorList(true);
    if (applicationDetailsData) {
      setApplication({
        applicationId: applicationDetailsData?.id,
        applicationName: applicationDetailsData?.application_number,
      });
    }
  };
  const handleClick = (meetingId, application) => {
    if (application && application?.applicationId) {
      // console.log('APPLICATIONID', application?.applicationId);
    }
  };
  const handleClosesiteList = () => {
    setOpenSiteIncepectorList(false);
  };
  const handleCloseModal = () => {
    setOpen(!open);
  };

  console.log('applicationDetailsData', applicationDetailsData);
  return (
    <Box>
      {showLoader ? (
        <Card sx={{ margin: '15px', padding: '20px' }}>
          <Grid container>
            <Grid item lg={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Box>
          {/* <Card sx={{ margin: '15px', padding: '20px' }}> */}
            {/* <Grid container>
              <Grid item lg={7} sm={12} sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>
                  Application Number : {applicationDetailsData.application_number}
                </Typography>
              </Grid>
            </Grid>
          </Card> */}
          {applicationDetailsData?.cutting_tree_meetings?.map((meetingData, index) => {
            return (
              <>
                <Card sx={{ margin: '15px', padding: '20px' }}>
                  <Grid container spacing={5}>
                    <Grid item lg={12}>
                      <Grid container spacing={5}>
                        <Grid item lg={6} md={6} sm={12}>
                          <Typography sx={{ fontWeight: '700' }}>Meeting Title</Typography>
                          <Typography>{meetingData?.meeting_title}</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                          <Typography sx={{ fontWeight: '700' }}>Meeting Date</Typography>
                          <Typography>
                            {meetingData?.date_of_meeting
                              ? format(new Date(meetingData?.date_of_meeting), 'MMMM d, yyyy')
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                          <Typography sx={{ fontWeight: '700' }}>Meeting Time</Typography>
                          <Typography>{meetingData?.meeting_time}</Typography>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                          <Typography sx={{ fontWeight: '700' }}>Meeting Status</Typography>
                          <Chip
                            label={meetingData?.meeting_status}
                            size="small"
                            style={{
                              marginLeft: '10px',
                              fontWeight: '600',
                              padding: '1px 10px',
                              backgroundColor: grey[600],
                              color: 'white',
                              '&:hover': {
                                backgroundColor: grey[800],
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item lg={6}>
                      <Grid container spacing={5}>
                        <Grid item lg={6} md={6} sm={12}>
                          <Typography sx={{ fontWeight: '700' }}>Meeting Attendees </Typography>

                          <List>
                            {meetingData?.attendees?.map((attendess, index) => (
                              <ListItem
                                key={index}
                                sx={{
                                  '&::before': {
                                    content: '"•"',
                                    display: 'inline-block',
                                    width: '0.5em',
                                    marginRight: 0,
                                    color: 'text.primary',
                                    fontSize: '1rem',
                                  },
                                }}>
                                {attendess?.full_name}
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* application status */}
                    {/* <Grid item lg={6} md={6} sm={12}>
                      <Typography sx={{ fontWeight: '700' }}>Applicantion Status</Typography>
                      <Typography>{meetingData?.applicant_status}</Typography>
                    </Grid> */}
                    <Grid item lg={6} md={6} sm={12}>
                      <Typography sx={{ fontWeight: '700' }}>Meeting Decision</Typography>
                      <Typography>{meetingData?.pivot?.decision}</Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12}>
                      <Typography sx={{ fontWeight: '700' }}>Note</Typography>
                      <Typography>{meetingData?.remarks}</Typography>
                    </Grid>
                    {meetingData?.pivot?.decision === 'Partially Approved' && (
                      <Grid item lg={6} md={6} sm={12}>
                        <Typography sx={{ fontWeight: '700' }}>Approved Trees</Typography>
                        <Typography>
                          {applicationDetailsData?.total_trees_permitted}/{applicationDetailsData?.total_trees}
                        </Typography>
                      </Grid>
                    )}

                    <Grid item lg={12}>
                      <Typography sx={{ fontWeight: '700' }}>Meeting Documents </Typography>
                      <Grid container spacing={5}>
                        {meetingData?.cutting_tree_meeting_document.map((doc) => {
                          return (
                            <Grid item lg={6} md={6} sm={12}>
                              <div
                                style={{
                                  width: '100px',
                                  height: '70px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  backgroundColor: '#f0f0f0',
                                  borderRadius: '10px',
                                }}>
                                <FilePresentIcon style={{ fontSize: 36, color: 'gray' }} />
                              </div>
                              <Link
                                href={`${process.env.REACT_APP_BASE_URL}/${doc.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="none"
                                style={{ display: 'block', marginBottom: '10px' }}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  sx={{ mt: '5px', width: '100px', fontWeight: '300' }}>
                                  View File
                                </Button>
                              </Link>

                              {/* <Card sx={{ position: 'relative' }}>
                                <CardActionArea>
                                  <CardMedia
                                    component="img"
                                    height="200"
                                    sx={{ padding: '10px', borderRadius: '35px' }}
                                    image={`${process.env.REACT_APP_BASE_URL}/${doc?.url}`}
                                    alt="document image"
                                  />
                                </CardActionArea>
                              </Card> */}
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </>
            );
          })}
        </Box>
      )}
      {treeDetailsOpen ? <TreeDetailsWithReasonDialog isOpen={treeDetailsOpen} data={treeDetails} /> : null}
    </Box>
  );
};

export default ViewMeetingList;
