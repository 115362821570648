import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';
import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  IconButton,
  Modal,
} from '@mui/material';
import moment from 'moment';
import { Visibility } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import CountButton from '../../components/statusbutton/CountButton';
import Scrollbar from '../../components/Scrollbar';
import { UserListHead } from '../../sections/@dashboard/user';
import StatusPendngButton from '../../components/statusbutton/StatusPendngButton';
import StatusApprovedButton from '../../components/statusbutton/StatusApprovedButton';
import StatusUnapprovedButton from '../../components/statusbutton/StatusUnapprovedButton';
import { GetDeniedEntry, SearchDeniedEntry } from '../../actions/DeniedEntryAction';
import { GetActiveCouncil, GetCouncilById } from '../../actions/CouncilAction';
import ViewImageDialog from '../../components/DialogBox/tree-data/ViewImageDialog';
import ImageCarousel from '../../components/ImageCarousel';
import TreeCuttingListMenu from '../../sections/@dashboard/tree-cutting/TreeCuttingListMenu';
import TreeCuttingToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingToolbar';
import { GetExcelTreeCuttingApplication, GetTreeCuttingApplication } from '../../actions/TreeCuttingPermissionAction';
import CuttingTreeListModal from '../../components/DialogBox/TreeCutting/CuttingTreeListModal';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'applicationNumber', label: 'Application Number', alignRight: false },
  { id: 'applicantName', label: 'Name of Applicant', alignRight: false },
  { id: 'document', label: 'Document', alignRight: false },
  { id: 'mobile', label: 'Mobile Number', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'ward', label: 'Ward', alignRight: false },
  { id: 'propertyNumber', label: 'Property Number', alignRight: false },
  // { id: 'totalNumbeOfTrees', label: 'Total Trees', alignRight: false },
  { id: 'location', label: 'Location', alignRight: false },
  { id: 'allTreeNumber', label: 'Demanded Permission Trees', alignRight: false },
  { id: 'grantedTrees', label: 'Granted Permission Trees', alignRight: false },
  { id: 'feesPaid', label: 'Total Fees Paid', alignRight: false },
  { id: 'totaltreesTobePlanted', label: 'Total Trees to be planted', alignRight: false },
  { id: 'purpose', label: 'Purpose', alignRight: false },
  { id: 'decision', label: 'Decision', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------

export default function TreeCuttingPermission() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [zoneId, setZoneId] = useState('');
  const [wardId, setWardId] = useState('');
  const [coucilId, setCouncilId] = useState('');
  const [coucilName, setCouncilName] = useState('');
  const [imageList, setImageList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [treeList, setTreeList] = useState('');
  const [letterDetails, setLetterDetails] = useState({});
  const [openImageList, setOpenImageList] = useState(false);
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);
  const {
    council,
    treeCuttingApplications,
    excelTreeCuttingApplications,
    pageInfo,
    isReportDownloadable,
    councilById,
  } = useSelector((state) => ({
    council: state.council.activeCouncil,
    zones: state.zones.zones,
    wards: state.wards.wards,
    treeCuttingApplications: state.treeCutting.treeCuttingApplications,
    excelTreeCuttingApplications: state.treeCutting.excelTreeCuttingApplications,
    pageInfo: state.treeCutting.pageInfo,
    exportCensusReport: state.treeCutting.exportCallLogs,
    isReportDownloadabletCallLogs: state.treeCutting.isReportDownloadable,
    permissionLetterDetails: state.treeCutting.permissionLetterDetails,
    permissionLetterDetailsLog: state.treeCutting.permissionLetterDetailsLog,
    councilById: state.council.councilById,
  }));

  const councilArr = council?.find((val) => val.id === coucilId);
  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  const secondRun = useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    setShowList(true);
  }, [treeCuttingApplications]);

  useEffect(() => {
    dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage));
    dispatch(GetActiveCouncil(1));
    // dispatch(GetBaseColorTreeById(1));
  }, []);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
    }
  }, [pageInfo]);

  const permissionRun = useRef(true);
  useEffect(() => {
    if (permissionRun.current) {
      permissionRun.current = false;
      return;
    }
    exportPdf(letterDetails);
  }, [councilById]);

  const handleNewUserClick = () => {
    setOpen(!open);
  };

  const handlePermissionLetterDownload = (applicationId, data) => {
    console.log('SET LETTER DETAILS', data);
    setLetterDetails(data);
    dispatch(GetCouncilById(council[0].id));
  };

  const exportPdf = (details) => {
    console.log('Details', details);
    const finePerTree = councilById?.cutting_trees_fees_per_tree ? councilById?.cutting_trees_fees_per_tree : '10';
    const doc = new JsPDF({ filters: ['ASCIIHexEncode'] });
    doc.setDrawColor(0);
    doc.setFillColor(255, 255, 255);
    doc.rect(5, 5, 200, 287, 'FD');
    doc.setFont(undefined, 'bold');
    const xOffset =
      doc.internal.pageSize.width / 2 - (doc.getStringUnitWidth('PERMSSION LETTER') * doc.internal.getFontSize()) / 2;
    doc.text('PERMISSION LETTER', 70, 30);
    doc.setFont(undefined, 'normal');
    doc.setFontSize(11);
    doc.text(`Dear ${details?.name},`, 20, 45);

    doc.text(
      `As per your application dated ${details?.created_at}, You requested to cut ${details?.total_trees} Trees. We reviewed the same and `,
      20,
      50
    );

    doc.text(
      `allowing you to cut ${details?.approved_cutting_trees_count} out of it. So compensation, you need to plant ${details?.total_trees_to_be_planted} trees or pay the fees ${finePerTree}  per tree.`,
      20,
      55
    );

    doc.text(`Please see below the list for tree numbers you are allowed to cut.`, 20, 60);

    const letterTableBody = [];
    let innerRow = [];
    details?.approved_cutting_trees?.map((value, index) => {
      if (innerRow.length === 3) {
        letterTableBody.push(innerRow);
        innerRow = [];
      }
      innerRow.push(value?.tree_number);

      return null;
    });
    if (innerRow.length !== 0) {
      letterTableBody.push(innerRow);
      innerRow = [];
    }
    console.log('LETTER TABLE BODY', letterTableBody);
    autoTable(doc, {
      headStyles: { fillColor: [129, 130, 131] },
      alternateRowStyles: { fillColor: [247, 247, 247] },
      tableLineColor: [129, 130, 131],
      tableLineWidth: 0.5,
      margin: { top: 70, bottom: 0 },
      body: letterTableBody,
    });

    doc.save(`Permission Letter.pdf`);
  };

  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen);
    setImageList(images || []);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShowList(false);
    dispatch(GetTreeCuttingApplication(fromDate, toDate, newPage, rowsPerPage));
  };

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        dispatch(SearchDeniedEntry(1, rowsPerPage, coucilId, zoneId, wardId));
        setSearch(true);
        setShowList(false);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetDeniedEntry(1, rowsPerPage, coucilId, zoneId, wardId, fromDate, toDate));
        setShowList(false);
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
  };

  const handleToDate = (e) => {
    setPage(1);
    setShowList(false);
    setToDate(e.target.value);
    dispatch(GetTreeCuttingApplication(fromDate, e.target.value, page, rowsPerPage));
  };
  const handleFromDate = (e) => {
    setPage(1);
    setShowList(false);
    setFromDate(e.target.value);
    dispatch(GetTreeCuttingApplication(e.target.value, toDate, page, rowsPerPage));
  };

  const [exportButtonPressed, setExportButtonPressed] = useState(false);

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel();
      setExportButtonPressed(false);
    }
  }, [isReportDownloadable, excelTreeCuttingApplications]);

  const header = [
    '#',
    'Application Number',
    'Name of Applicant',
    'Property Numebr',
    'Ward',
    'Mobile',
    'Email',
    'Location',
    'Demanded Permission Trees',
    'Granted Permission Trees',
    'Total Fees Paid',
    'Total Trees to be planted',
    'Purpose',
  ];
  function DownloadReportExcel() {
    const value1 = [];
    excelTreeCuttingApplications?.map((option, index) => {
      const value2 = [index + 1];
      value2.push(option?.application_number);
      value2.push(option?.name);
      value2.push(option?.property_number ? option?.property_number : '-');
      value2.push(option?.ward?.name);
      value2.push(option?.mobile);
      value2.push(option?.email);
      // value2.push(option.location_type)
      value2.push(option?.location);
      value2.push(option?.total_trees);
      value2.push(option?.approved_cutting_trees_count);
      value2.push(option?.compensation_trees_amount ? option?.compensation_trees_amount : '-');
      value2.push(option?.total_trees_to_be_planted);
      value2.push(option?.purpose_of_cutting);

      value1.push(value2);
      return null;
    });
    downloadExcel({
      fileName: `Tree Cutting`,
      sheet: 'Tree Cutting',
      tablePayload: {
        header,
        // accept two different data structures
        body: value1,
      },
    });
  }

  const exportReports = () => {
    dispatch(GetExcelTreeCuttingApplication(fromDate, toDate));
    setExportButtonPressed(true);
  };

  const handleSubmit = () => {
    dispatch(GetTreeCuttingApplication(fromDate, toDate, page, rowsPerPage));
  };

  const handleTreeNumberClick = (data, count) => {
    if (count !== 0) {
      setTreeList(data);
      setOpen(!open);
    }
  };

  return (
    <Page title="User">
      <Container>
        {open ? <CuttingTreeListModal isOpen={open} handleClose={handleNewUserClick} treeList={treeList} /> : null}
        {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Tree Cutting
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all tree cutting permission
            </Typography>
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/create-tree-cutting-permission"
            // style={{boxShadow: 'none'}}
            // startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Application
          </Button>
        </Stack>

        <Card>
          <TreeCuttingToolbar
            numSelected={0}
            placeHolder={'Search Base Color...'}
            onFilterName={filterByName}
            fromDate={fromDate}
            toDate={toDate}
            handleFromDate={(e) => handleFromDate(e)}
            handleToDate={(e) => handleToDate(e)}
            exportReports={exportReports}
            handleSubmit={handleSubmit}
            callType="DeniedEntries"
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead headLabel={TABLE_HEAD} />
                {/* {!coucilId ? (
                  <TableRow>
                    <TableCell align="center" colSpan={8} fontWeight={700}>
                      Please select council to get data
                    </TableCell>
                  </TableRow>
                ) : null} */}
                <TableBody>
                  {showList
                    ? treeCuttingApplications?.map((option, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">
                              <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                            </TableCell>
                            <TableCell align="left">{option?.application_number}</TableCell>
                            <TableCell align="left">{option?.name}</TableCell>
                            <TableCell align="left">
                              <IconButton
                                aria-label="delete"
                                size="large"
                                // onClick={()=>handleViewOpen(option.images)}
                                onClick={(e) => {
                                  window.open(option?.url, '_blank');
                                }}
                                sx={{ color: '#214c50' }}>
                                <Visibility />
                              </IconButton>
                            </TableCell>
                            <TableCell align="left">{option?.mobile}</TableCell>
                            <TableCell align="left">{option?.email}</TableCell>
                            <TableCell align="left">{option?.ward?.name}</TableCell>
                            <TableCell align="left">
                              {option?.property_number ? option?.property_number : '-'}
                            </TableCell>
                            {/* <TableCell align="left">
                           {option?.total_trees}
                          </TableCell> */}
                            <TableCell align="left">{option?.location}</TableCell>

                            <TableCell align="left">
                              <CountButton
                                count={option?.total_trees}
                                onClick={() => handleTreeNumberClick(option?.cutting_trees, option?.total_trees)}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <CountButton
                                count={option?.approved_cutting_trees_count}
                                onClick={() =>
                                  handleTreeNumberClick(
                                    option?.approved_cutting_trees,
                                    option?.approved_cutting_trees_count
                                  )
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              {' '}
                              {option?.compensation_trees_amount ? option?.compensation_trees_amount : '-'}
                            </TableCell>
                            <TableCell align="left"> {option?.total_trees_to_be_planted}</TableCell>
                            <TableCell align="left"> {option?.purpose_of_cutting}</TableCell>
                            <TableCell align="left">
                              {option.applicant_status === 'Pending' ? (
                                <StatusPendngButton qcStatus={option.applicant_status} />
                              ) : (
                                ''
                              )}
                              {option.applicant_status === 'Approved' ? (
                                <StatusApprovedButton qcStatus={option.applicant_status} />
                              ) : (
                                ''
                              )}
                              {option.applicant_status === 'Unapproved' ? (
                                <StatusUnapprovedButton qcStatus={option.applicant_status} />
                              ) : (
                                ''
                              )}
                              {option.applicant_status === 'Partial Approved' ? (
                                <StatusPendngButton qcStatus={option.applicant_status} />
                              ) : (
                                ''
                              )}
                            </TableCell>
                            <TableCell align="right">
                              <TreeCuttingListMenu
                                page={page}
                                status={option?.applicant_status}
                                id={option?.id}
                                name={option?.application_number}
                                totalTrees={option?.total_trees_to_be_planted}
                                handlePermissionLetterDownload={() =>
                                  handlePermissionLetterDownload(option?.id, option)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal
              open={openImageList}
              onClose={handleCloseImageList}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description">
              <Container style={{ width: '526px' }}>
                <ImageCarousel imagelist={imageList} />
              </Container>
              {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
            </Modal>
          </Scrollbar>
          {treeCuttingApplications ? (
            <Pagination
              count={showList ? pageInfo.last_page : 0}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
