import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch } from "./CombineCatch";
import { ADD_USER, DELETE_USER, EDIT_USER, GET_RELIGIONS, GET_SALARY_DEDUCTION_TYPES, GET_USER, GET_USER_BY_ID, GET_ACTIVE_USER, GET_USERS_BY_ROLEID, GET_USER_DOCUMENT_TYPES, SEARCH_USER, UNLINK_DEVICE } from "./Types";

const GetUsers = (page,limit) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/users?page=${page}&limit=${limit}`);
      // console.log("DESIGNATIONS RESPONSE",response.data);
      dispatch({
        type: GET_USER,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const GetActiveUsers = (status) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/users?status=${status}`);
      // console.log("active users",response.data);
      dispatch({
        type: GET_ACTIVE_USER,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const GetUsersByRoleID = (status, roleid1, roleid2, roleid3, roleid4, roleid5, roleid6) => async (dispatch) => {
    let url = `/api/users?status=${status}`;

    if(roleid1){
      url = `${url}&role_id[]=${roleid1}`;
    }
    if(roleid2){
      url = `${url}&role_id[]=${roleid2}`;
    }
    if(roleid3){
      url = `${url}&role_id[]=${roleid3}`;
    }
    if(roleid4){
      url = `${url}&role_id[]=${roleid4}`;
    }
    if(roleid5){
      url = `${url}&role_id[]=${roleid5}`;
    }
    if(roleid6){
      url = `${url}&role_id[]=${roleid6}`;
    }

    try {
      const response = await JWTServer.get(`${url}`);
      dispatch({
        type: GET_USERS_BY_ROLEID,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  const GetUsersByRoleArray = (status, roleArray) => async (dispatch) => {
    let url = `/api/users?status=${status}`;
  
    roleArray.map((value,index)=>{
      url = `${url}&role_id[]=${value}`
      return null;
    })
  
    try {
      const response = await JWTServer.get(`${url}`);
      dispatch({
        type: GET_USERS_BY_ROLEID,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  const GetUsersById = (userId) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/users/${userId}`);
      // console.log("DESIGNATIONS RESPONSE",response.data);
      dispatch({
        type: GET_USER_BY_ID,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const SearchUsers = (page,limit,searchValue) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/users?page=${page}&limit=${limit}&search=${searchValue}`);
      dispatch({
        type: GET_USER,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const AddUsers = (params) => async (dispatch) => {
      // console.log("ADD USER PARAMS",params);
    try {
      const response = await JWTServer.post("/api/users",params);
      dispatch({
        type: ADD_USER,
        payload: response.data,
      });
      dispatch(SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const EditUsers = (params, districtsId) => async (dispatch) => {
  // console.log("EDIT USER PARAMS",params)
  try {
    const response = await JWTServer.put(`/api/users/${districtsId}`, params);
    dispatch({
      type: EDIT_USER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const DeleteUsers = (params) => async (dispatch) => {
  // console.log("params", params.data_id, "status", params.data_status);
  try {
    const response = await JWTServer.delete(`/api/users/${params.data_id}?status=${params.data_status}`);
    // console.log("response", response);
    dispatch({
      type: DELETE_USER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: `${params.data_status ? 'Activated Succesfully!' : 'Inactivated Successfully!'}`,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetDeductionType = () => async (dispatch) => {
  try {
    const response = await JWTServer.get('/api/salary-deduction-types');
    dispatch({
      type: GET_SALARY_DEDUCTION_TYPES,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetUserDocumentType = () => async (dispatch) => {
  try {
    const response = await JWTServer.get('/api/user-document-types');
    dispatch({
      type: GET_USER_DOCUMENT_TYPES,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetReligions = () => async (dispatch) => {
  try {
    const response = await JWTServer.get('/api/religions');
    dispatch({
      type: GET_RELIGIONS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const UnlinkDevice = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.post('/api/users/unlink-device', params);
    // console.log("RESPONSEunlink",response);
    dispatch({
      type: UNLINK_DEVICE,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export {
  GetUsers,
  GetUsersById,
  GetActiveUsers,
  GetUsersByRoleID,
  SearchUsers,
  AddUsers,
  EditUsers,
  DeleteUsers,
  GetDeductionType,
  GetUserDocumentType,
  GetReligions,
  UnlinkDevice,
  GetUsersByRoleArray
};
