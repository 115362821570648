import React, { useCallback, useEffect, useRef, useState } from 'react';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { getIcon } from '@iconify/react';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Container,
  Button,
  Select,
  MenuItem,
  Modal,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility } from '@mui/icons-material';
import TreeFill from '../../Assets/tree_on_map.png';
import PinIcon from '../../Assets/pin.png'
import { GetSpecificTreeInfo, SearchTreesInSpecificLocationByMoveOrZoom } from '../../actions/TreeOnMapAction';
import { ShowLoader } from '../../actions/CommonAction';
import ViewImageDialog from '../../components/DialogBox/tree-data/ViewImageDialog';
import ImageCarousel from '../../components/ImageCarousel';

const markers = [
  {
    id: 1,
    name: 'Chicago, Illinois',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    position: { lat: 41.881832, lng: -87.623177 },
  },
  {
    id: 2,
    name: 'Denver, Colorado',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    position: { lat: 39.739235, lng: -104.99025 },
  },
  {
    id: 3,
    name: 'Los Angeles, California',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    position: { lat: 34.052235, lng: -118.243683 },
  },
  {
    id: 4,
    name: 'New York, New York',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    position: { lat: 40.712776, lng: -74.005974 },
  },
];

const center = {
  lat: 21.1613263,
  lng: 78.9076913,
};

function TreeCuttingMap(props) {
  const dispatch = useDispatch();
  const [activeMarker, setActiveMarker] = useState(null);
  const [activePin, setActivePin] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [openImageList, setOpenImageList] = useState(false);
  const [zoom, setZoom] = useState(5);
  const [showAdd, setShowAdd] = useState(false);
  const [treeCuttingReasons, setTreeCuttingReasons] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [newValue, setNewValue] = useState([]);
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);

  const [markerPosition, setMarkerPosition] = useState([])
  const [currentMarkerPosition, setCurrentMarkerPosition] = useState({})
  const [centerPosition, setCenterPosition] = useState({latitude:'',longitude:''})
  const [mapChanged, setMapChanged] = useState(false)
  const mapRef = useRef(null);

  const { treeDetails, showLoader } = useSelector((state) => ({
    treeDetails: state.treeLocation.treeDetails,
    showLoader: state.common.showLoader,
  }));

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    dispatch(ShowLoader(false));
  }, [treeDetails]);

  useEffect(() => {
    setActiveMarker(null);
  }, [props.treeLocation]);
  
  useEffect(()=> {
    if(!mapChanged ){
      setCenterPosition(props.centerLatLng)
    }
    
  },[props.centerLatLng])

  useEffect(()=> {
      setCenterPosition(props.centerLatLng)
  },[props.isLocationChanged])

  function existsInArray(arr, lat, long) {
    return arr.some(item => item.latitude === lat && item.longitude === long);
  }

  useEffect(()=> {
    const newMarkerPosition = [...markerPosition]
    console.log("PIN DROP CHANGED",newMarkerPosition)
    props.pinDropLatlng?.map((value,index)=>{
      if(!existsInArray(newMarkerPosition,value.latitude,value.longitude)){
        newMarkerPosition.push(value);
        setCurrentMarkerPosition(value);
      }
      
      return null;
    })
    console.log("NEW MARKER POSITION",newMarkerPosition)
    setMarkerPosition(newMarkerPosition)
  },[props.pinDropLatlng])

  console.log("PINFDROP",props.pinDropLatlng)

  useEffect(() => {
    setShowAdd(props.showAdd ?? false);
    if (props.showAdd) {
      setTreeCuttingReasons(props.treeCuttingReasons ?? []);
    }
  }, [props.showAdd]);

  const onMarkerDragEnd = useCallback((event,index) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    // setMarkerPosition({ lat, lng });
    // const newMarkerPosition = [...markerPosition];
    // console.log("NEWO",newMarkerPosition);
    // const obj = {latitude:lat,longitude:lng};
    // newMarkerPosition[index] = obj;
    // console.log("NEW 1 MARKER POSITION",newMarkerPosition);
    // setMarkerPosition(newMarkerPosition)
    setCurrentMarkerPosition({latitude:lat,longitude:lng})
  }, []);

  console.log("MARKER POSITION",markerPosition)

  const onMapMove = useCallback((event) => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter();
      const updatedLatLong = {
        latitude: newCenter.lat(),
        longitude: newCenter.lng(),
      }
      setCenterPosition(updatedLatLong);
      props.onCenterLocationChanged(updatedLatLong);
      setMapChanged(true);
      dispatch(SearchTreesInSpecificLocationByMoveOrZoom(updatedLatLong));
    }
  }, []);


  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }

    dispatch(GetSpecificTreeInfo(marker));
    dispatch(ShowLoader(true));
    setActiveMarker(marker);
  };

  const handleDropPinmarker = (marker,index) => {
    if (marker === activePin) {
      return;
    }
    // setCurrentMarkerPosition(markerPosition[index])
    setActivePin(marker);
  }

  const handleOnLoad = (map) => {
    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();
    // markers.forEach(({ position }) => bounds.extend(position));
    bounds.extend(center);
    map.fitBounds(bounds);
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const icon = {
    url: TreeFill,
    scaledSize: new window.google.maps.Size(50, 42),
  };

  const pinIcon = {
    url: PinIcon,
    scaledSize: new window.google.maps.Size(50, 42),
  };

  // console.log("PROPS TREE LOCATION",props.treeLocation);

  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen);
    const imageList = [];
    if (images) {
      images.map((value, index) => {
        imageList.push(value.image_url);
        return null;
      });
    }
    setImageList(imageList);
  };

  const handleAddClick = (event) => {
    console.log('addded', treeDetails.id, treeDetails.tree_number);
    const selectedtrees = [];
    //  const info = {
    //   id: treeDetails.id,
    //   tree_number: treeDetails.tree_number,
    // };
    //  selectedtrees.push(info);
    selectedtrees.push(treeDetails);

    setNewValue(selectedtrees);

    props.onAddTree(event, selectedtrees);
    setActiveMarker(null);
  };

  const handlePinAddClick = (event) => {
    props.onAddPinTree(event, currentMarkerPosition);
    setActivePin(null);
    setMarkerPosition([])
    setCurrentMarkerPosition({})
  }

  console.log("current MARKER POSITION",currentMarkerPosition);

  return (
    <GoogleMap
      // onLoad={handleOnLoad}
      onLoad={onLoad}
      onClick={() => {setActiveMarker(null);setActivePin(null)}}
      onDragEnd={onMapMove}
      onZoomChanged={onMapMove}
      mapContainerStyle={{ width: '100%', height: '500px' }}
      zoom={
        mapChanged ? 16 :
        props?.treeLocation && props.treeLocation[0] ? 16 : props?.pinDropLatlng && props.pinDropLatlng[0]? 16 : zoom}
      center={
        mapChanged ? { lat: Number(centerPosition.latitude), lng: Number(centerPosition.longitude) } :
        props?.treeLocation && props.treeLocation[0]
          ? { lat: Number(centerPosition.latitude), lng: Number(centerPosition.longitude) }
          : props?.pinDropLatlng && props.pinDropLatlng[0]? { lat: Number(currentMarkerPosition?.latitude), lng: Number(currentMarkerPosition?.longitude) } : center
      }>
      {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}
      {props.treeLocation?.map((value, index) => (
        <Marker
          key={value.id}
          position={{ lat: Number(value.lat), lng: Number(value.long) }}
          onClick={() => handleActiveMarker(value.id)}
          icon={icon}>
          {activeMarker === value.id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              {showLoader ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    minHeight: '100px',
                    minWidth: '100px',
                  }}>
                  <CircularProgress style={{ color: '#214c50' }} />
                </div>
              ) : (
                <div>
                  {/* <img src={treeDetails?.images?treeDetails.images[0].image_url:""} alt="tree" style={{height:'100px',width:'100px'}} /> */}
                  <Table
                    style={{ border: 'none' }}
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                      },
                    }}>
                    <TableBody style={{ border: 'none' }}>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingBottom: '0px' }}>
                          Tree Number
                        </TableCell>
                        <TableCell align="left" style={{ paddingBottom: '0px' }}>
                          {treeDetails.tree_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', whiteSpace: 'nowrap' }}>
                          Tree Name
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.tree_name?.name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', whiteSpace: 'nowrap' }}>
                          Botanical Name
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.tree_name?.botanical_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Tree Type
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.tree_type?.tree_type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Location Type
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails?.location_type?.location_type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Property Type
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.property_type?.property_type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Property Number
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.property?.property_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Owner Name
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.property?.owner_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Tenant Name
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.property?.tenant_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingBottom: '0px' }}>
                          Area(Sq feet)
                        </TableCell>
                        <TableCell align="left" style={{ paddingBottom: '0px' }}>
                          {treeDetails.property?.area ? treeDetails.property?.area : '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Plantation Date
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails.plantation_date}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Location Type
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails?.location_type?.location_type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Tree Condition
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails?.tree_condition?.condition}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Girth(cm)
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails?.girth}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Height(markers)
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails?.height}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px' }}>
                          Canopy
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                          {treeDetails?.canopy}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingTop: '0px' }}>
                          Images
                        </TableCell>
                        <TableCell align="left" style={{ paddingTop: '0px' }}>
                          <IconButton
                            aria-label="delete"
                            size="large"
                            // onClick={()=>handleViewOpen(treeDetails.images)}
                            onClick={(e) => {
                              setImageList(treeDetails.images || []);
                              handleOpenImageList(e);
                            }}
                            style={{ color: '#214c50' }}>
                            <Visibility />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {showAdd ? (
                    <div>
                      <hr />
                      {/* <Select 
                  fullWidth                
                  value={selectedValue}
                  onChange={(e)=>handleSelectChange(e)}
                  displayEmpty
                  sx={{ marginTop: '10px' }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '200px', // to control the height of the dropdown menu
                      },
                    },
                  }}>
                  <MenuItem value="" disabled>
                    Select Reason
                  </MenuItem>
                  {treeCuttingReasons?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>                
              ))}
                </Select> */}
                      <Button
                        variant="contained"
                        style={{ fontSize: 12, marginTop: '2%', marginBottom: '2%' }}
                        onClick={(e) => {
                          // validateDropDown();
                          handleAddClick(e);
                        }}>
                        {'Add'}
                      </Button>
                    </div>
                  ) : null}
                </div>
              )}
            </InfoWindow>
          ) : null}
        </Marker>
        
      ))}
      {markerPosition?.map((value,index) => {
        return <Marker
        key={value.latitude}
        icon={pinIcon}
        onClick={() => handleDropPinmarker(value.latitude,index)}
        position={{ lat: Number(currentMarkerPosition.latitude), lng: Number(currentMarkerPosition.longitude) }}
        draggable
        onDragEnd={(e)=>onMarkerDragEnd(e,index)}
        >
           {activePin === value.latitude ? (
            <InfoWindow onCloseClick={() => setActivePin(null)}>

                <div>
                    <div>
                      <Button
                        variant="contained"
                        style={{ fontSize: 12, marginTop: '2%', marginBottom: '2%' }}
                        onClick={(e) => {
                          // validateDropDown();
                          handlePinAddClick(e);
                        }}>
                        {'Add Tree'}
                      </Button>
                    </div>
                </div>
            </InfoWindow>
          ) : null}
          </Marker>
      })}

{props?.fixedMarkerPosition?.map((value,index) => {
        return <Marker
        key={value.latitude}
        icon={pinIcon}
        // onClick={() => handleDropPinmarker(value.latitude,index)}
        position={{ lat: Number(value.latitude), lng: Number(value.longitude) }}
         />
         
      })}
          
      <Modal
        open={openImageList}
        onClose={handleCloseImageList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Container style={{ width: '526px' }}>
          <ImageCarousel imagelist={imageList} />
        </Container>
        {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
      </Modal>
    </GoogleMap>
  );
}

export default TreeCuttingMap;
