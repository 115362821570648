import { filter, includes } from 'lodash';
// import React from 'react';

import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import OutlinedInput from '@mui/material/OutlinedInput';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
  Grid,
  Box,
  Pagination,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/named
import { UserListToolbar, UserFormListMenu } from '../sections/@dashboard/user';

import USERLIST from '../_mock/user';
import NewUserDialog from '../components/DialogBox/NewUserDialog';
import UserTableData from '../components/JsonFiles/UserTableData.json';
import { AssociateDialog, UserListHead } from '../sections/@dashboard/manager';

import { GetManagerRequest, SearchUsers, AddRequestManager } from '../actions/ManagerRequestAction';

import StatusButton from '../components/statusbutton/StatusButton';
import warningSound from '../Assets/warning_sound.mp3';
import { GetUsersByRoleID } from '../actions/UserAction'
import { AddWards, GetActiveWardsByCouncilId, GetNewWardsByCouncilId } from '../actions/WardsActions';
import { GetActiveRole } from "../actions/RoleAction"
import { GetActiveCouncil, GetActiveNewCouncil } from '../actions/CouncilAction';
import { GetActiveZonesByCouncilId, GetNewZonesByCouncilId } from '../actions/ZonesAction'
// import Zone from './Master/Zone';
// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'currentrole', label: 'Current Role', alignRight: false },
  { id: 'currentcouncil', label: 'Current Council', alignRight: false },
  // { id: 'currentzones', label: 'Current Zones', alignRight: false },
  { id: 'currentwards', label: 'Current Wards', alignRight: false },
  { id: 'newrole', label: 'New Role', alignRight: false },
  // { id: 'newcouncil', label: 'New Council', alignRight: false },
  // { id: 'newzones', label: 'New Zones', alignRight: false },
  { id: 'newwards', label: 'New Wards', alignRight: false },
  { id: 'approvedBy', label: 'Approved By', alignRight: false },
  { id: 'lastUpdateBy', label: 'Last Updated By', alignRight: false },
  // { id: 'activeWard', label: 'Active Wards', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function User() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('Name');
  const [filterName, setFilterName] = useState('');
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState();
  const [dialogData, setDialogData] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageCountError, setPageCountError] = useState(false);
  const [topModalOpen, setTopModalOpen] = useState(false);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [reqObj, setReqObj] = useState(null);
  const [requireActiveObj, setReqActiveObj] = useState(null);
  const [showCouncil, setShowCouncil] = useState("");
  const [showNewCouncil, setShowNewCouncil] = useState("");
  const [showCurrentZone, setShowCurrentZone] = useState("")
  const [addWards, setAddWards] = useState("")
  const [addNewWards, setAddNewWards] = useState("")
  const [getCurrentWards, setCurrentWards] = useState([])
  const [getCurrentZone, setCurrentZone] = useState([])
  const [isCurrentWards, setIsCurrentWards] = useState(false)
  const [isCurrentZones, setIsCurrentZones] = useState(false)
  const userPermissions = [];
  const message =
    'Unlinking device will expired the current session of the user and might lose the offline data. Please synch all the Offline data before proceeding.';

  const { state } = useLocation();
  const { users, pageInfo, council, wards, userByRoleID, roles, zones, newZones, newwards } = useSelector((state) => ({
    users: state.managerRequestReducer.users,
    pageInfo: state.managerRequestReducer.pageInfo,
    wards: state.wards.activeWardsByCID,
    council: state.council.activeCouncil,
    newCouncil: state.council.newCouncil,
    userByRoleID: state.users.userByRoleID,
    zones: state.zones.activeZonesByCID,
    newZones: state.zones.newZone,
    newwards: state.wards.newWard,
    roles: state.roles.roles,
  }));
  useEffect(() => {
    if (state) {
      setPage(state.page);
    }
    dispatch(GetActiveRole(1));
    dispatch(GetActiveCouncil(1))
    dispatch(GetActiveNewCouncil(1))
  }, []);

  useEffect(() => {
    if (state) {
      setPage(state.page);
      dispatch(GetManagerRequest(state.page, rowsPerPage));
    } else {
      dispatch(GetManagerRequest(page, rowsPerPage));
    }
  }, []);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
    }
  }, [pageInfo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (search) {
      dispatch(SearchUsers(newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetManagerRequest(newPage, rowsPerPage));
    }
  };


  let timer = null;
  const filterByName = (event) => {
    const value = event.currentTarget.value;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        dispatch(SearchUsers(1, rowsPerPage, value));
        setSearch(true);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetManagerRequest(1, rowsPerPage));
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
  };
  const inputRef = useRef(null);
  const onChangeCouncil = (e) => {
    setShowNewCouncil(e.target.value)
    dispatch(GetNewWardsByCouncilId(1, e.target.value))
    dispatch(GetNewZonesByCouncilId(1, e.target.value))
  }
  const [showNewZone, setShowNewZone] = useState('')
  const onChangeZone = (e) => {
    setShowNewZone(e.target.value)
  }
  const handleAddedByChange = (event) => {
    setAddNewWards(event.target.value)
  }
  const [showRole, setShowRole] = useState()

  useEffect(() => {
    // dispatch(GetUsersByRoleID(1))
  }, [])
  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (wards && !isCurrentWards) {
      setIsCurrentWards(true)
      setCurrentWards(wards)

    }

  }, [wards])
  const zoneRun = React.useRef(true);
  React.useEffect(() => {
    if (zoneRun.current) {
      zoneRun.current = false;
      return;
    }
    if (zones && !isCurrentZones) {
      setIsCurrentZones(true)
      setCurrentZone(zones)

    }

  }, [zones])

  const [showNewRole, setShowNewRole] = useState("")
  const OnChangeRole = (e) => {
    setShowNewRole(e.target.value)
  }
  const [getUserId, setUserId] = useState("")
  const [czwId, setCzwId] = useState("")
  const [getRequestById, setRequestById] = useState("")
  const [getChangeRequestStatus, setChangeRequestStatus] = useState("")
  const handleClickOpen = (e, option) => {
    setOpen(true);
    setUserId(option.user_id.id)
    setCzwId(e.target.id)
    setRequestById(option.requested_by?.id)
    setChangeRequestStatus(option.change_request_status)
    setShowCouncil(option.current_council_id?.id)
    dispatch(GetActiveZonesByCouncilId(1, option.current_council_id?.id))
    dispatch(GetActiveWardsByCouncilId(1, option.current_council_id?.id))
    setAddWards(option.current_ward_id?.id)
    dispatch(GetNewWardsByCouncilId(1, option.new_council_id?.id))
    setAddNewWards(option.new_ward_id?.id)
    setShowCurrentZone(option.current_zone_id?.id)
    dispatch(GetNewZonesByCouncilId(1, option.new_council_id?.id))
    setShowNewZone(option.new_zone_id?.id)
    setShowNewCouncil(option.new_council_id?.id)
    setShowRole(option.current_role_id?.id)
    setShowNewRole(option.new_role_id?.id)
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveAction = () => {
    dispatch(AddRequestManager({
      "user_id": getUserId,
      "current_role_id": showRole,
      "new_role_id": showNewRole,
      "current_council_id": showCouncil,
      "new_council_id": showNewCouncil,
      "current_ward_id": addWards,
      "new_ward_id": addNewWards,
      // "current_zone_id": showCurrentZone,
      // "new_zone_id": showNewZone,
      "requested_by": getRequestById,
    }, czwId))
    // "change_request_status": "approved_by_admin"
    setTimeout(() => {
      setOpen(false);
      dispatch(GetManagerRequest(1, rowsPerPage));
    }, 3000)
  }
  return (
    <Page title="User">
      <Container>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Ward Changes
            <Typography variant="h6" style={{ fontWeight: 400 }}  >
              It is showing list of all ward change request with its details
            </Typography>
          </Typography>
        </Stack>
        <Card>
          <UserListToolbar numSelected={0} placeHolder={'Search users...'} onFilterName={filterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead
                  headLabel={TABLE_HEAD}
                  data={dialogData}
                />
                <TableBody>
                  {users?.data.map((option, index) => {
                    return (
                      <TableRow hover>
                        <TableCell align="left">
                          <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.user_id?.first_name} ${option.user_id?.last_name}`}
                        </TableCell>
                        <TableCell align="left">
                          {`${option.current_role_id?.role}`}
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.current_council_id?.name}`}
                        </TableCell>
                        {/* <TableCell align="left">
                          {`${option.current_zone_id?.name}`}
                        </TableCell> */}
                        <TableCell align="left">
                          {`${option.current_ward_id?.name}`}
                        </TableCell>
                        <TableCell align="left">
                          {`${option.new_role_id?.role}`}
                        </TableCell>
                        {/* <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.new_council_id?.name}`}
                        </TableCell> */}
                        {/* <TableCell align="left">
                          {`${option.new_zone_id?.name}`}
                        </TableCell> */}
                        <TableCell align="left">
                          {`${option.new_ward_id?.name}`}
                        </TableCell>
                        <TableCell align="left">
                          {
                            option.approved_by ?
                              <div>{`${option.approved_by?.first_name} ${option.approved_by?.last_name}`}</div>
                              :
                              <div>-</div>
                          }
                        </TableCell>
                        <TableCell align="left">
                          {
                            option.approved_by ?
                              <div>
                                {`${option.last_updated_by?.first_name} ${option.last_updated_by?.last_name}`}
                              </div>
                              :
                              <div>-</div>
                          }
                        </TableCell>
                        <TableCell align="left">
                          {`${option.change_request_status}`}
                        </TableCell>
                        <TableCell align="left">
                          <div>
                            <Button variant="outlined" fullWidth style={{ width: "150px" }} id={option.id} onClick={e => { handleClickOpen(e, option) }}>
                              take Action
                            </Button>
                            <BootstrapDialog
                              fullWidth
                              onClose={handleClose}
                              aria-labelledby="customized-dialog-title"
                              open={open}

                            >
                              <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                Ward Changes
                              </BootstrapDialogTitle>
                              <DialogContent dividers>
                                <Typography gutterBottom>
                                  <Grid container spacing={1}>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                        <InputLabel id="demo-multiple-name-label">Current Council</InputLabel>
                                        <Select
                                          fullWidth
                                          disabled
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={showCouncil}
                                          // onChange={onChangeCouncil}
                                          input={<OutlinedInput label="Associate User" />}
                                          MenuProps={MenuProps}
                                        >
                                          {council?.map((option) => (
                                            <MenuItem key={option.value} value={option.id}>
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                        <InputLabel id="demo-multiple-name-label">New Council</InputLabel>
                                        <Select
                                          fullWidth
                                          disabled
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={showNewCouncil}
                                          onChange={onChangeCouncil}
                                          input={<OutlinedInput label="Associate User" />}
                                          MenuProps={MenuProps}
                                        >
                                          {council?.map((option) => (
                                            <MenuItem key={option.value} value={option.id}>
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>

                                        <InputLabel id="demo-multiple-name-label">Current Role</InputLabel>
                                        <Select
                                          fullWidth
                                          disabled
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={showRole}
                                          // onChange={onChangeCouncil}
                                          input={<OutlinedInput label="Associate User" />}
                                          MenuProps={MenuProps}
                                        >
                                          {roles?.map((option) => (
                                            <MenuItem key={option.value} value={option.id}>
                                              {option.role}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                        <InputLabel id="demo-multiple-name-label">New Role</InputLabel>
                                        <Select
                                          fullWidth
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={showNewRole}
                                          onChange={OnChangeRole}
                                          input={<OutlinedInput label="Associate User" />}
                                          MenuProps={MenuProps}
                                        >
                                          {roles?.map((option) => (
                                            <MenuItem key={option.value} value={option.id}>
                                              {option.role}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>
                                  {/* <Grid container spacing={1}>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                        <InputLabel id="demo-multiple-name-label">Current Zone</InputLabel>
                                        <Select
                                          fullWidth
                                          disabled
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={showCurrentZone}
                                          input={<OutlinedInput label="Associate User" />}
                                          MenuProps={MenuProps}
                                        >
                                          {
                                            getCurrentZone?.map((option) => (
                                              <MenuItem key={option.value} value={option.id}>
                                                {option.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                        <InputLabel id="demo-multiple-name-label">New Zone</InputLabel>
                                        <Select
                                          fullWidth
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          value={showNewZone}
                                          onChange={onChangeZone}
                                          input={<OutlinedInput label="Associate User" />}
                                          MenuProps={MenuProps}
                                        >
                                          {newZones?.map((option) => (
                                            <MenuItem key={option.value} value={option.id}>
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid> */}
                                  <Grid container spacing={1}>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                        <InputLabel id="demo-multiple-name-label">Current Wards</InputLabel>
                                        <Select
                                          fullWidth
                                          disabled
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          // multiple
                                          value={addWards}
                                          // defaultValue={addWards}
                                          input={<OutlinedInput label="Current Wards" />}
                                          MenuProps={MenuProps}
                                        >
                                          {
                                            getCurrentWards?.map((option) => (
                                              <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={6}>
                                      <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                        <InputLabel id="demo-multiple-name-label">New wards</InputLabel>
                                        <Select
                                          fullWidth
                                          displayEmpty
                                          labelId="demo-multiple-name-label"
                                          id="demo-multiple-name"
                                          ref={inputRef}
                                          // multiple
                                          value={addNewWards}
                                          onChange={handleAddedByChange}
                                          input={<OutlinedInput label="Wards" />}
                                          MenuProps={MenuProps}
                                        >
                                          {
                                            newwards?.map((option) => (
                                              <MenuItem key={option.id} value={option.id}>
                                                {option.name}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                  </Grid>

                                </Typography>
                              </DialogContent>
                              <DialogActions>
                                <Button autoFocus onClick={handleSaveAction}>
                                  Save
                                </Button>
                                <Button autoFocus onClick={handleClose}>
                                  Cancel
                                </Button>
                              </DialogActions>
                            </BootstrapDialog>
                          </div>

                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box>
            {(users?.data) ? (
              <Pagination
                count={pageInfo.last_page}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
              />
            ) : null}
          </Box>
        </Card>
      </Container>
    </Page >
  );
}
