export const titleBody_ = [
  ['Hon. Chief Officer', 'Mr. Dhananjay Borikar'],
  ['', 'Mr. Dhananjay Borikar'],
  ['Hon. Office supretendent', 'Mr. devendra kale'],
  ['Hon. Tree officer', 'Mr. Rajendra kale'],
  ['Hon. Chief Officer', 'Mr. Dhananjay Borikar'],
  ['Hon. Office supretendent', 'Mr. devendra kale'],
  ['Hon. Tree officer', 'Mr. Rajendra kale'],
  ['Hon. Chief Officer', 'Mr. Dhananjay Borikar'],
  ['Hon. Office supretendent', 'Mr. devendra kale'],
];
export const titleBody = [
  [1, 'Acknowledgement'],
  // [2, 'History of Katol', 2],
  [3, 'Tree census'],
  [4, 'Tree Act 1975'],
  [5, 'Why Tree Census Important?'],
  [6, 'Trees are needed to produce enough oxygen'],
  [7, 'Tree census Flow Chart.'],
  [8, 'Tree Census Methodology.'],
  [9, 'GIS & GPS Android Application.'],
  [10, 'Web based mobile application information.'],
  [11, 'Ward Wise Tree Count'],
  [12, 'Ownership Wise Tree Summery.'],
  [13, 'Condition Wise Tree Summery.'],
  [14, 'Overall Tree Data'],
  [15, 'Graph of major trees found .'],
  [16, 'Ward wise tree data count..'],
  [17, 'Overall Trees Species List.'],
  [18, 'Different types of Trees Species List'],
  [19, 'Rare Trees Species List.'],
  [20, 'Scope of Plantation'],
  [21, 'References'],
];

export const treeSpecies = [
  [
    1,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    2,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    3,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    4,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    5,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    6,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    7,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    8,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    9,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    10,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    11,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    12,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    13,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    14,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    15,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    16,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    17,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
  [
    18,
    'AKASHNEEM',
    'MILLINGTONIA HORTENSIS',
    'BIGNONIACEAE',
    'Ornamental tree. Wood used in making bottle corks.',
    'Native',
  ],
];

export const scopeHeader = [['SL.NO', 'COMMON NAME', 'TREE BOTNICAL NAME', 'FAMILY']];
export const scopeBody = [
  [1, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [2, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [3, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [4, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [5, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [6, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [7, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [8, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [9, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [10, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [11, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [12, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [13, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [14, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [15, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [16, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [17, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [18, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [19, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [20, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
  [21, 'AIN', 'TERMINALIA ELLIPTICA', 'COMBRETACEAE'],
];

export const headerBody = [
  ['Wards', 'Total Tree Count'],
  ['Tree Names', 'Total Tree Count'],
  ['Tree Types', 'Total Tree Count'],
  ['Tree Conditions', 'Total Tree Count'],
  ['Property Types', 'Total Tree Count'],
  ['Location Types', 'Total Tree Count'],
];

export const header = [['WARD WISE TREE SUMMARY'], ['BY TREE TYPES'], ['BY TREE CONDITIONS']];
export const treeNameWithCondition = [
  ['WARD WISE TREE SUMMARY'],
  ['BY TREE TYPES'],
  ['BY TREE CONDITIONS'],
  ['BY TREE NAME'],
  ['BY PROPERTY TYPES'],
  ['BY LOCATION TYPES'],
];
export const titleHeader = [['Sr. No', 'Table Content', '#']];

export const history = `
   In the year 1905-06 26,723 carts of raw uncleaned Katol is a city anda municipal
council in Nagpur district of Maharashtra state, India. It is the administrative headquarters
of Katol taluka, one of the 14 talukas of this district. Being located in orange belt it is a
major centre for orange trading. In Dvapara Yuga, during the reign of Raja Chandrahasa,
Katol's name was Kuntalapur. Katol is also mentioned in the Ashwamedh canto of
the Mahabharat as Kuntalapur. Katol is the location of two of the Hemadpanthi temples,
Maa Chandika and Maa Saraswati, which are said to date from the days of Rawan and to
have been built in one night by his demons. The mud fort dates from the time of the Gond
dynasty.


   The Corporation of Katol was established in 1919. Katol reached the first stage of
modern municipal development in 1905 when it was designated as a Town-Fund area. For
the first year its income was only Rs. 4761, but it has now risen to about Rs. 7176. Its
prosperity depends chiefly on cotton trading, and in recent years most of the municipal
income has for that reason been expended on a cotton market. This is a large enclosure of
about 10 acres (40,000 m2), securely fenced, and provided with a weighing shed, well,
water trough, young shade trees, and an arc light.


   Chandika Devi Mandir – The temple is believed to have been built during the reign
of one king Chandrahasa, who is supposed to have ruled over this territory in the dim and
distant past. The construction of the temple is. in the Hemadpanthi style. It is situated in
the old Basti. The 'Gabhara', which contains the idol of the goddess, measures 9.290 m.2
(10' x 10'). It is beautifully carved out of black rock and is in a standing position. On either
side of the entrance to the 'Gabhara' are two images in standing posture which appear to
be the bodyguards of the goddess Chandika. Outside is the ' Sabhamandap' having
windows on all the four sides which serve as inlets for the sunlight. In the center exactly
facing the goddess is a 'Honikunda' housed by a little dome. On the 'Mandap' wall, to the
left of the goddess, is engraved the image of Lord Gajanan with exquisite skill. Against the
outer side of the Mandap-walls are carved the images of animals, wild as well as domestic,
and numerous deities which add a tinge of glory and glamor to the solid and grave
structure of the building. The architectural designs speak eloquently of the architects of
those times. In Navratra , Shardotsava is cele`;

export const treeCensusHistory = {
  message: 'Census tree fetched successfully!',
  data: {
    current_page: 1,
    data: [
      {
        id: 1,
        location_type: {
          id: 2,
          location_type: 'Along the Road',
          status: 1,
          has_property_type: 0,
        },
        property_type: null,
        property: null,
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '20.175778',
        long: '72.7663022',
        location: '20.175778 , 72.7663022',
        location_accuracy: '2.73',
        tree_number: '8765-A1-0001',
        tree_type: {
          id: 9,
          tree_type: 'Flowering',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 1,
          name: 'NORTHHERN BLACK WATTLE',
          botanical_name: 'ACACIA AURICULIFORMIS',
          tree_type_id: 4,
          tree_family_id: 1,
          uses: 'It is  wood is good formaking paper, furniture and tools. Gum from the tree is sold commercially.',
          origin: 'Native',
          flowering_season: 'March-Dec',
          fruiting_season: 'Sept-Oct',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: '12-15 M',
          max_age: '25-30 year',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '51',
        height: '100',
        canopy: '50',
        tree_condition: {
          id: 1,
          condition: 'Healthy',
          status: 1,
        },
        tree_disease: null,
        plantation_date: '2014/06/10',
        age: '0.00',
        referred_to_expert: 0,
        action_need: 'none',
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 12:10 PM',
        updated_on_date: '13 Jun 2023 02:43 PM',
        updated_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_date: '13 Jun 2023 02:43 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/1c9707d9-c551-418a-86f5-6818f1277ddctree_2023_09_13_12_09_59.jpg',
        ],
      },
      {
        id: 2,
        location_type: {
          id: 2,
          location_type: 'Along the Road',
          status: 1,
          has_property_type: 0,
        },
        property_type: null,
        property: null,
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1160821',
        long: '79.0370891',
        location: '21.1160821 , 79.0370891',
        location_accuracy: '1.95',
        tree_number: '8765-A1-0002',
        tree_type: {
          id: 2,
          tree_type: 'Ornamental',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 142,
          name: ' DOMBEY',
          botanical_name: 'DOMBEYA ACUTANGULA',
          tree_type_id: 2,
          tree_family_id: 24,
          uses: 'The plant is harvested from the Wild for local use as a medicine and for the fibre obtained from its bark. Itis grown as an ornamental',
          origin: 'Native',
          flowering_season: 'NA',
          fruiting_season: 'NA',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: 'NA',
          max_age: 'NA',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '263',
        height: '2000',
        canopy: '5464',
        tree_condition: {
          id: 5,
          condition: 'Full Cut',
          status: 1,
        },
        tree_disease: {
          id: 1,
          tree_disease: 'Yes',
          status: 1,
        },
        plantation_date: '2015/06/16',
        age: '0.00',
        referred_to_expert: 0,
        action_need: null,
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 02:20 PM',
        updated_on_date: '13 Jun 2023 02:54 PM',
        updated_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_date: '13 Jun 2023 02:54 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/9804feac-6996-4916-89c7-6181cd8c766atree_2023_20_13_02_20_09.jpg',
        ],
      },
      {
        id: 3,
        location_type: {
          id: 1,
          location_type: 'PROPERTY',
          status: 1,
          has_property_type: 1,
        },
        property_type: {
          id: 2,
          location_type_id: 1,
          property_type: 'OPEN LAYOUT SPACES',
          status: 1,
        },
        property: {
          id: 10,
          property_number: '101',
          owner_name: 'Dheeraj',
          tenant_name: 'raaj',
          area: '2000',
          address: 'Plot no 101',
        },
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1160664',
        long: '79.0370482',
        location: '21.1160664 , 79.0370482',
        location_accuracy: '6.74',
        tree_number: '8765-A1-0003',
        tree_type: {
          id: 2,
          tree_type: 'Ornamental',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 142,
          name: ' DOMBEY',
          botanical_name: 'DOMBEYA ACUTANGULA',
          tree_type_id: 2,
          tree_family_id: 24,
          uses: 'The plant is harvested from the Wild for local use as a medicine and for the fibre obtained from its bark. Itis grown as an ornamental',
          origin: 'Native',
          flowering_season: 'NA',
          fruiting_season: 'NA',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: 'NA',
          max_age: 'NA',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '100',
        height: '106',
        canopy: '300',
        tree_condition: {
          id: 3,
          condition: 'Average',
          status: 1,
        },
        tree_disease: {
          id: 2,
          tree_disease: 'No',
          status: 1,
        },
        plantation_date: '2011/06/06',
        age: '0.00',
        referred_to_expert: 0,
        action_need: null,
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 02:57 PM',
        updated_on_date: '13 Jun 2023 02:58 PM',
        updated_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_date: '13 Jun 2023 02:58 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/c8be20d4-11ac-4d5b-b48b-273ae62b7246tree_2023_57_13_02_57_15.jpg',
        ],
      },
      {
        id: 4,
        location_type: {
          id: 2,
          location_type: 'Along the Road',
          status: 1,
          has_property_type: 0,
        },
        property_type: null,
        property: null,
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1161473',
        long: '79.0370929',
        location: '21.1161473 , 79.0370929',
        location_accuracy: '10',
        tree_number: '8765-A1-0004',
        tree_type: {
          id: 1,
          tree_type: 'Fruiting',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 127,
          name: ' JICARO',
          botanical_name: 'CRESCENTIA ALATA',
          tree_type_id: 1,
          tree_family_id: 41,
          uses: ' are used to treat hematomas, tumors and hypertension. FruItDecOction is used to treat diarrhea, stomachaches, cold, bronchitis, cough, asthma, and urethritis.',
          origin: 'Exotic',
          flowering_season: 'Aug-Oct',
          fruiting_season: 'Aug-Oct',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: '9-10 M',
          max_age: 'NA',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '400',
        height: '900',
        canopy: '200',
        tree_condition: {
          id: 4,
          condition: 'Danger',
          status: 1,
        },
        tree_disease: {
          id: 1,
          tree_disease: 'Yes',
          status: 1,
        },
        plantation_date: '2011/06/09',
        age: '0.00',
        referred_to_expert: 0,
        action_need: 'notification light',
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 03:02 PM',
        updated_on_date: '13 Jun 2023 03:05 PM',
        updated_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_date: '13 Jun 2023 03:05 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/8f24e3eb-8289-4ca3-8c60-313900d7d042tree_2023_04_13_03_04_24.jpg',
        ],
      },
      {
        id: 5,
        location_type: {
          id: 1,
          location_type: 'PROPERTY',
          status: 1,
          has_property_type: 1,
        },
        property_type: {
          id: 2,
          location_type_id: 1,
          property_type: 'OPEN LAYOUT SPACES',
          status: 1,
        },
        property: {
          id: 11,
          property_number: '601',
          owner_name: 'Ingle',
          tenant_name: 'Ashish',
          area: '2300',
          address: 'plot no 601',
        },
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.116062',
        long: '79.0370653',
        location: 'Mokha Tower, Trimurtee Nagar, Nagpur, Maharashtra, 440022',
        location_accuracy: '10',
        tree_number: '8765-A1-0005',
        tree_type: {
          id: 2,
          tree_type: 'Ornamental',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 142,
          name: ' DOMBEY',
          botanical_name: 'DOMBEYA ACUTANGULA',
          tree_type_id: 2,
          tree_family_id: 24,
          uses: 'The plant is harvested from the Wild for local use as a medicine and for the fibre obtained from its bark. Itis grown as an ornamental',
          origin: 'Native',
          flowering_season: 'NA',
          fruiting_season: 'NA',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: 'NA',
          max_age: 'NA',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '106',
        height: '206',
        canopy: '106',
        tree_condition: {
          id: 10,
          condition: 'Out Of Range',
          status: 1,
        },
        tree_disease: {
          id: 1,
          tree_disease: 'Yes',
          status: 1,
        },
        plantation_date: '2012/06/06',
        age: '0.00',
        referred_to_expert: 0,
        action_need: 'noAChop',
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 03:21 PM',
        updated_on_date: '13 Jun 2023 03:26 PM',
        updated_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_date: '13 Jun 2023 03:26 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/015ded0c-606c-487d-847c-bde5cca5ece6tree_2023_21_13_03_21_05.jpg',
          'http://154.61.80.161:8000/images/censustree/ed8c1705-2f62-4d63-a044-18112cd30d43tree_2023_21_13_03_21_14.jpg',
        ],
      },
      {
        id: 6,
        location_type: {
          id: 1,
          location_type: 'PROPERTY',
          status: 1,
          has_property_type: 1,
        },
        property_type: {
          id: 2,
          location_type_id: 1,
          property_type: 'OPEN LAYOUT SPACES',
          status: 1,
        },
        property: {
          id: 11,
          property_number: '601',
          owner_name: 'Ingle',
          tenant_name: 'Ashish',
          area: '2300',
          address: 'plot no 601',
        },
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1160658',
        long: '79.0370615',
        location: 'Mokha Tower, Trimurtee Nagar, Nagpur, Maharashtra, 440022',
        location_accuracy: '10',
        tree_number: '8765-A1-0006',
        tree_type: {
          id: 4,
          tree_type: 'Avenue',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 19,
          name: 'ANKOL',
          botanical_name: 'ALANGIUM SALVIIFOLIUM',
          tree_type_id: 4,
          tree_family_id: 9,
          uses: 'useful for external applications in acute case of rheumatism, leprosy, inflammation and for external and internal application in case of rabid dog bites. ',
          origin: 'Native',
          flowering_season: 'Feb-June',
          fruiting_season: 'Oct-Dec',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: '11 M',
          max_age: '30 year',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '107',
        height: '207',
        canopy: '107',
        tree_condition: {
          id: 6,
          condition: 'Mechanical Cut',
          status: 1,
        },
        tree_disease: {
          id: 1,
          tree_disease: 'Yes',
          status: 1,
        },
        plantation_date: '2006/06/15',
        age: '0.00',
        referred_to_expert: 0,
        action_need: 'noko',
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 03:22 PM',
        updated_on_date: '13 Jun 2023 03:30 PM',
        updated_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_date: '13 Jun 2023 03:31 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/619c8c7f-aa35-4a06-a14c-d31ffb460855tree_2023_22_13_03_22_32.jpg',
        ],
      },
      {
        id: 7,
        location_type: {
          id: 2,
          location_type: 'Along the Road',
          status: 1,
          has_property_type: 0,
        },
        property_type: null,
        property: null,
        team: {
          id: 7,
          name: 'wakad3',
          team_code: 'WK03',
          team_type: 'onsite_qc',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1160586',
        long: '79.0370786',
        location: 'Mokha Tower, Trimurtee Nagar, Nagpur, Maharashtra, 440022',
        location_accuracy: '10',
        tree_number: '8765-A1-0007',
        tree_type: {
          id: 4,
          tree_type: 'Avenue',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 301,
          name: 'AUSTRALIAN UMBRELLA TREE',
          botanical_name: 'SCHEFFLERA ACTINOPHYLLA',
          tree_type_id: 4,
          tree_family_id: 72,
          uses: ' are used to promote the \ncirculation of the blood and to alleviate pain. sore and/or swollen throat. Itcan also help to relieve asthma.',
          origin: 'Native',
          flowering_season: 'Jan-April',
          fruiting_season: 'June-Oct',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: '6-7 M',
          max_age: 'NA',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '109',
        height: '209',
        canopy: '109',
        tree_condition: {
          id: 7,
          condition: 'Diseased',
          status: 1,
        },
        tree_disease: {
          id: 1,
          tree_disease: 'Yes',
          status: 1,
        },
        plantation_date: '2016/06/18',
        age: '0.00',
        referred_to_expert: 0,
        action_need: 'nothing',
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 03:29 PM',
        updated_on_date: '13 Jun 2023 05:54 PM',
        updated_by: {
          id: 6,
          first_name: 'Riya',
          last_name: 'Adhau',
          assigned_roles: 'Census QC - Onsite',
          status: 1,
          full_name: 'Riya Adhau',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 6,
          first_name: 'Riya',
          last_name: 'Adhau',
          assigned_roles: 'Census QC - Onsite',
          status: 1,
          full_name: 'Riya Adhau',
        },
        qc_date: '13 Jun 2023 05:54 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/ca48efeb-3fee-4fcb-89e0-109f6664bffbtree_2023_29_13_03_29_38.jpg',
        ],
      },
      {
        id: 8,
        location_type: {
          id: 2,
          location_type: 'Along the Road',
          status: 1,
          has_property_type: 0,
        },
        property_type: null,
        property: null,
        team: {
          id: 7,
          name: 'wakad3',
          team_code: 'WK03',
          team_type: 'onsite_qc',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1160585',
        long: '79.0370778',
        location: 'Mokha Tower, Trimurtee Nagar, Nagpur, Maharashtra, 440022',
        location_accuracy: '10',
        tree_number: '8765-A1-0008',
        tree_type: {
          id: 1,
          tree_type: 'Fruiting',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 244,
          name: ' BARTONDI',
          botanical_name: 'MORINDA PUBESCENS',
          tree_type_id: 1,
          tree_family_id: 60,
          uses: 'The various parts of this tree has been extensively used as- eczema, fever due to primary complex, ulcer, glandular swellings, digestive disorders especially in children,',
          origin: 'Native',
          flowering_season: 'May-June',
          fruiting_season: 'June-Nov',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: '4-5 M',
          max_age: '40 year',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '110',
        height: '110',
        canopy: '110',
        tree_condition: {
          id: 5,
          condition: 'Full Cut',
          status: 1,
        },
        tree_disease: {
          id: 1,
          tree_disease: 'Yes',
          status: 1,
        },
        plantation_date: '2012/06/05',
        age: '0.00',
        referred_to_expert: 0,
        action_need: 'tum',
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 05:59 PM',
        updated_on_date: '13 Jun 2023 06:01 PM',
        updated_by: {
          id: 6,
          first_name: 'Riya',
          last_name: 'Adhau',
          assigned_roles: 'Census QC - Onsite',
          status: 1,
          full_name: 'Riya Adhau',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 6,
          first_name: 'Riya',
          last_name: 'Adhau',
          assigned_roles: 'Census QC - Onsite',
          status: 1,
          full_name: 'Riya Adhau',
        },
        qc_date: '13 Jun 2023 06:01 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/70c04539-ec54-43db-90e6-035e46f179f4tree_2023_58_13_05_58_55.jpg',
        ],
      },
      {
        id: 9,
        location_type: {
          id: 2,
          location_type: 'Along the Road',
          status: 1,
          has_property_type: 0,
        },
        property_type: null,
        property: null,
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1160889',
        long: '79.0370318',
        location: '21.1160889 , 79.0370318',
        location_accuracy: '7.45',
        tree_number: '8765-A1-0009',
        tree_type: {
          id: 2,
          tree_type: 'Ornamental',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 160,
          name: ' DYE FIG',
          botanical_name: 'FICUS TINCTORIA  SUBSP. GIBBOSA',
          tree_type_id: 2,
          tree_family_id: 15,
          uses: 'The plant is gathered from the Wild for local use as a food, medicine and source of fibre and dyestuff',
          origin: 'Native',
          flowering_season: 'Nov-Feb',
          fruiting_season: 'NA',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: 'NA',
          max_age: 'NA',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '100',
        height: '100',
        canopy: '100',
        tree_condition: {
          id: 2,
          condition: 'Poor',
          status: 1,
        },
        tree_disease: null,
        plantation_date: '',
        age: '0.00',
        referred_to_expert: 0,
        action_need: null,
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 06:07 PM',
        updated_on_date: null,
        updated_by: null,
        qc_status: 'Unapproved',
        qc_remark: {
          id: 1,
          remark: 'Incorrect Tree Data',
          remark_for: 'Census',
          is_protected: 1,
          status: 1,
        },
        qc_by: {
          id: 5,
          first_name: 'Anaya',
          last_name: 'Ingale',
          assigned_roles: 'Census QC - Offsite',
          status: 1,
          full_name: 'Anaya Ingale',
        },
        qc_date: '13 Jun 2023 06:09 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/a830f7c9-f92f-4833-924c-8b1a47bca513tree_2023_06_13_06_06_47.jpg',
        ],
      },
      {
        id: 10,
        location_type: {
          id: 2,
          location_type: 'Along the Road',
          status: 1,
          has_property_type: 0,
        },
        property_type: null,
        property: null,
        team: {
          id: 5,
          name: 'Wakas Census',
          team_code: '8765',
          team_type: 'census',
          status: 1,
        },
        council: {
          id: 3,
          name: 'WAKAD',
          status: 1,
        },
        zone: {
          id: 1,
          name: 'A',
          status: 1,
        },
        ward: {
          id: 1,
          name: 'A1',
          status: 1,
        },
        lat: '21.1160567',
        long: '79.0371326',
        location: '21.1160567 , 79.0371326',
        location_accuracy: '3.70',
        tree_number: '8765-A1-0010',
        tree_type: {
          id: 8,
          tree_type: 'Palms',
          is_protected: 1,
          status: 1,
        },
        tree_name: {
          id: 313,
          name: ' COCOS PALM',
          botanical_name: 'SYAGRUS ROMANZOFFIANA',
          tree_type_id: 8,
          tree_family_id: 14,
          uses: ' Itis used in gardening and landscaping in many parts of the world though in sheltered areas Itwill survive short periods below freezing',
          origin: 'Exotic',
          flowering_season: 'Jan-March',
          fruiting_season: 'NA',
          growth_factor: 'NA',
          oxygen_emit_rate: 'NA',
          max_height: '7-8 M',
          max_age: '50 year',
          growth_ratio: '0.00',
          status: 1,
        },
        girth: '258',
        height: '255',
        canopy: '255',
        tree_condition: {
          id: 5,
          condition: 'Full Cut',
          status: 1,
        },
        tree_disease: {
          id: 2,
          tree_disease: 'No',
          status: 1,
        },
        plantation_date: '2023/06/13',
        age: '0.00',
        referred_to_expert: 0,
        action_need: null,
        added_by: {
          id: 4,
          first_name: 'Sayali',
          last_name: 'Patil',
          assigned_roles: 'Census User',
          status: 1,
          full_name: 'Sayali Patil',
        },
        added_on_date: '13 Jun 2023 06:19 PM',
        updated_on_date: '14 Jun 2023 12:57 PM',
        updated_by: {
          id: 1,
          first_name: 'TreeCensus',
          last_name: 'SuperAdmin',
          assigned_roles: 'Superadmin',
          status: 1,
          full_name: 'TreeCensus SuperAdmin',
        },
        qc_status: 'Approved',
        qc_remark: null,
        qc_by: {
          id: 1,
          first_name: 'TreeCensus',
          last_name: 'SuperAdmin',
          assigned_roles: 'Superadmin',
          status: 1,
          full_name: 'TreeCensus SuperAdmin',
        },
        qc_date: '14 Jun 2023 12:57 PM',
        images: [
          'http://154.61.80.161:8000/images/censustree/590f808e-9839-41dd-bd7a-eab7c434f540tree_2023_19_13_06_19_33.jpg',
        ],
      },
    ],
    first_page_url: 'http://154.61.80.161:8000/api/census-trees?page=1',
    last_page: 2,
    last_page_url: 'http://154.61.80.161:8000/api/census-trees?page=2',
    next_page_url: 'http://154.61.80.161:8000/api/census-trees?page=2',
    per_page: '10',
    to: 10,
    total: 15,
  },
};
