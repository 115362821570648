import { filter, includes } from 'lodash';
// import React from 'react';

import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';

// import { ApproveDialog} from '../sections/@dashboard/manager';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import OutlinedInput from '@mui/material/OutlinedInput';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
  Grid,
  Box,
  Pagination,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/named
import { UserListToolbar, UserFormListMenu } from '../sections/@dashboard/user';

import USERLIST from '../_mock/user';
import NewUserDialog from '../components/DialogBox/NewUserDialog';
import UserTableData from '../components/JsonFiles/UserTableData.json';
import StatusPendngButton from '../components/statusbutton/StatusPendngButton';
import StatusApprovedButton from '../components/statusbutton/StatusApprovedButton';
import StatusUnapprovedButton from '../components/statusbutton/StatusUnapprovedButton';
import { ApproveDialog, UserListHead } from '../sections/@dashboard/manager';

import { GetTravellingDetails, SearchUsers, ApproveUnapproveRequest } from '../actions/TravellingAction';

import StatusButton from '../components/statusbutton/StatusButton';
import warningSound from '../Assets/warning_sound.mp3';
import { GetUsersByRoleID } from '../actions/UserAction'
import { AddWards, GetWardsByCouncilId } from '../actions/WardsActions';
import { GetActiveRole } from "../actions/RoleAction"
import { GetActiveCouncil } from '../actions/CouncilAction';
import { GetActiveZonesByCouncilId } from '../actions/ZonesAction'

// import Zone from './Master/Zone';
// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'associate', label: 'Associate', alignRight: false },
  { id: 'FromCouncil', label: 'From Council', alignRight: false },
  { id: 'ToCouncil', label: 'To Council', alignRight: false },
  { id: 'formDate', label: 'From Date', alignRight: false },
  { id: 'ToDate', label: 'To Date', alignRight: false },
  { id: 'requestedBy', label: 'Requested By', alignRight: false },
  { id: 'NoOfDays', label: 'No. Of Days', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function User() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('Name');
  const [filterName, setFilterName] = useState('');
  const [open, setOpen] = useState(false);
  const [openUnApprove, setUnApproveOpen] = useState(false)
  const [close, setClose] = useState();
  const [dialogData, setDialogData] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageCountError, setPageCountError] = useState(false);
  const [topModalOpen, setTopModalOpen] = useState(false);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [reqObj, setReqObj] = useState(null);
  const [requireActiveObj, setReqActiveObj] = useState(null);
  const [showCouncil, setShowCouncil] = useState("");
  const [showNewCouncil, setShowNewCouncil] = useState("");
  const [showCurrentZone, setShowCurrentZone] = useState("")
  const [addWards, setAddWards] = useState([])
  const [addNewWards, setAddNewWards] = useState([])
  const userPermissions = [];
  const message =
    'Unlinking device will expired the current session of the user and might lose the offline data. Please synch all the Offline data before proceeding.';

  const { state } = useLocation();
  const { users, pageInfo, council, wards, userByRoleID, roles, zones } = useSelector((state) => ({
    users: state.requestTravelling.travellingDetails,
  }));
  useEffect(() => {
    if (state) {
      setPage(state.page);
    }
    dispatch(GetActiveRole(1));
  }, []);

  useEffect(() => {
    if (state) {
      setPage(state.page);
      dispatch(GetTravellingDetails(state.page, rowsPerPage));
    } else {
      dispatch(GetTravellingDetails(page, rowsPerPage));
    }
  }, []);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
    }
  }, [pageInfo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (search) {
      dispatch(SearchUsers(newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetTravellingDetails(newPage, rowsPerPage));
    }
  };


  let timer = null;
  const filterByName = (event) => {
    const value = event.currentTarget.value;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        dispatch(SearchUsers(1, rowsPerPage, value));
        setSearch(true);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetTravellingDetails(1, rowsPerPage));
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
  };



  useEffect(() => {
    dispatch(GetUsersByRoleID(1))
  }, [])

  const [getApproveId, setApproveId] = useState("")
  const handleClickOpen = (e) => {
    setApproveId(e.target.id)
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickClose = () => {
    setOpen(false);
  };
  const handleClickSave = () => {
    dispatch(ApproveUnapproveRequest({
      "request_status": "Approved"
    }, getApproveId))
    setTimeout(() => {
      setOpen(false);
      dispatch(GetTravellingDetails(1, rowsPerPage));
    }, 3000)
  }
  const handleUnApproveClickOpen = (e, option) => {
    setApproveId(e.target.id)
    setUnApproveOpen(true);

  };
  const handleUnApproveClose = () => {
    setUnApproveOpen(false);
  };
  const handleUnApproveClickClose = () => {
    setUnApproveOpen(false);
  };
  const handleUnApproveClickSave = () => {
    dispatch(ApproveUnapproveRequest({
      "request_status": "Unapproved"
    }, getApproveId))
    setTimeout(() => {
      setUnApproveOpen(false);
      dispatch(GetTravellingDetails(1, rowsPerPage));
    }, 3000)
  }
  return (
    <Page title="User">
      <Container>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Travelling Details
            <Typography variant="h6" style={{ fontWeight: 400 }}  >
              It is showing list of all travelling with its details
            </Typography>
          </Typography>
        </Stack>
        <Card>
          <UserListToolbar numSelected={0} placeHolder={'Search users...'} onFilterName={filterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead
                  headLabel={TABLE_HEAD}
                  data={dialogData}
                />
                <TableBody>
                  {users?.map((option, index) => {
                    return (
                      <TableRow hover>
                        <TableCell align="left">
                          <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.associate?.first_name} ${option.associate?.last_name}`}
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.from_council?.name}`}
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.to_council?.name}`}
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.from_date}`}
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.to_date}`}
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {
                            option.requested_by ?
                              <div>
                                {`${option.requested_by?.first_name} ${option.requested_by?.last_name}`}
                              </div>
                              :
                              <div>-</div>
                          }
                        </TableCell>
                        <TableCell align="left">
                          {`${option.no_of_days}`}
                        </TableCell>
                        <TableCell align="left">
                          {option.request_status === 'Pending' ? <StatusPendngButton qcStatus={option.request_status} /> : ''}
                          {option.request_status === 'Approved' ? <StatusApprovedButton qcStatus={option.request_status} /> : ''}
                          {option.request_status === 'Unapproved' ? <StatusUnapprovedButton qcStatus={option.request_status} /> : ''}
                        </TableCell>

                        <TableCell align="left" getDialogId={option.id}>
                          <ApproveDialog
                            page={page}
                            userId={option.id}
                            handleClickOpen={e => { handleClickOpen(e) }}
                            handleClickClose={handleClickClose}
                            handleClickSave={handleClickSave}
                            open={open}
                            handleUnApproveClickOpen={e => { handleUnApproveClickOpen(e) }}
                            handleUnApproveClickClose={handleUnApproveClickClose}
                            handleUnApproveClickSave={handleUnApproveClickSave}
                            openUnaprrove={openUnApprove}
                          />
                        </TableCell>

                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box>
            {/* {users ? (
              <Pagination
                count={pageInfo.last_page}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
              />
            ) : null} */}
          </Box>
        </Card>
      </Container>
    </Page >
  );
}
