import { useRef, useState } from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from '@mui/material';
import Iconify from './Iconify';

export default function NewsPaperMoreAction({ status, handleEdit, handleDelete, handleConfirmDelete }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleConfirmOpen = () => {
    setIsConfirmOpen(true);
    handleClose(); // Close the menu when opening the confirmation dialog
  };

  const handleNo = () => {
    setIsConfirmOpen(false);
  };

  const handleYes = () => {
    handleConfirmDelete();
    setIsConfirmOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={handleClick}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} style={{ color: 'black' }} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MenuItem
          onClick={() => {
            handleEdit();
            handleClose();
          }}>
          <ListItemIcon>
            <Iconify icon="basil:edit-outline" width={24} height={24} style={{ color: 'black' }} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem onClick={handleConfirmOpen}>
          <ListItemIcon>
            <Iconify icon="mdi:delete-outline" width={24} height={24} style={{ color: 'black' }} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isConfirmOpen}
        onClose={handleNo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth>
        <DialogTitle id="alert-dialog-title">{/* Empty title for alignment */}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} variant="contained">
            Yes
          </Button>
          <Button onClick={handleNo} style={{ backgroundColor: '#e85454', color: '#fff' }} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
