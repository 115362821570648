import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
    Button,
  } from '@mui/material';
import { ConnectingAirportsOutlined } from '@mui/icons-material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ByTreeWardGraph = (props) => {
  
  const {slug} = props;
  const graph = [
    {id: 1, name: 'Ward1', census_trees_count: 16},
    {id: 2, name: 'Ward2', census_trees_count: 14},
    {id: 3, name: 'Ward3', census_trees_count: 10},
    {id: 3, name: 'Ward4', census_trees_count: 30},
    {id: 3, name: 'Ward5', census_trees_count: 40}]
    // console.log('data_', graph);
    const length = graph.length;
    const x = [];
    const y = [];
    
    for(let i=0;i<length;i+=1){
      
        x.push(graph[i].name)
     
    }

    for(let i=0;i<length;i+=1){
      y.push(graph[i].census_trees_count)
    }
    // console.log(x)
    // console.log(y)
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            // text: 'Chart.js Bar Chart',
          },
        },
      };
    
      const data = {
        labels: x,
        datasets: [
          {
            label: `${slug}`,
            data: y,
            backgroundColor: "rgba(54, 162, 235)",
          },
        ],
      };
      
  return (
   <>
    <Bar options={options} data={data} />
  
   </>
  )
}

export default ByTreeWardGraph
