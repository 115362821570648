import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// material
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
// component
import { useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import SearchImage from '../../../Assets/Search_Image.png';


// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  backgroundColor: '#F8F8F8 !important',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TreeWardWiseToolbar.propTypes = {
  callType: PropTypes.string,
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  placeHolder: PropTypes.string,
  handleCoucilChange: PropTypes.func,
  handleWardChange: PropTypes.func,
  handleZoneChange: PropTypes.func,
  handleWardWiseSubmit: PropTypes.func,
  coucilId: PropTypes.any,
  zoneId: PropTypes.any,
  wardId: PropTypes.any,
  wardPropertyWiseFilter : PropTypes.any,
  handlewardPropertyWiseChange : PropTypes.func,
  treeNumberList : PropTypes.any,
  fromTreeNumber : PropTypes.func,
  toTreeNumber : PropTypes.func,
  handleFromTreeNumberChange : PropTypes.func,
  handleToTreeNumberChange : PropTypes.func
};

export default function TreeWardWiseToolbar({ numSelected, filterName, onFilterName, handleWardWiseSubmit, wardPropertyWiseFilter, handlewardPropertyWiseChange , treeNumberList, fromTreeNumber, toTreeNumber, handleFromTreeNumberChange, handleToTreeNumberChange }) {
  // const {
  //     council,
  //     zones,
  //     wards,
  //   } = useSelector((state) => ({
  //     council:state.council.activeCouncil,
  //     zones:state.zones.activeZonesByCID,
  //     wards:state.wards.activeWardsByCID,
  //   }));

  const handleChange = (e) => {
    console.log("HANDLE CHANGE CALLED");
    handlewardPropertyWiseChange(e.target.checked)
  };

  const useStyles = makeStyles({
    chipSelected: {
      backgroundColor: '#214c50 !important',
      color: '#fff !important',
      width: '120px',
      
    },
    chip: {
      backgroundColor: '#fff',
      color: '#000',
      width: '120px',
    },
  });
  const classes = useStyles();
  const reLast = /-\d+$/;

  const DesignationsSchema = Yup.object().shape({
    // treeNumber: Yup.string().required('Tree number is required'),
    fromTreeNumber: Yup.number().typeError('Tree number must be number').required('From Tree number is required'),
    toTreeNumber: Yup.number().typeError('Tree number must be number').required('To Tree number is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
    //   fromTreeNumber: fixedTreeNumber ? fixedTreeNumber.split('-')[fixedTreeNumber.split('-').length-1] : "1111"
    fromTreeNumber : "",
    toTreeNumber : ""
    },
    validationSchema: DesignationsSchema,
    onSubmit: (value) => {
        console.log("Hii")
        handleWardWiseSubmit();
    }
 
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;


  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Grid container justifyContent="flex-end">
        {/* {(callType === "BaseColor")?(
         <h5 style={{marginTop: 10}}>Please select council to get  base color data</h5>
         ):null} */}
         <Grid item sm={12} justifyContent="flex-end" style={{ marginRight: 10 }}>

            <FormControlLabel 
            control={<Checkbox checked={wardPropertyWiseFilter} onChange={(e)=>handleChange(e)} />} label={"show only from the mentioned property"} 
            sx={{ justifyContent: 'right', display: 'flex', mt: 1, mb: 1 }} 
            />

        </Grid>
        {/* <Grid item sm={2.5} justifyContent="flex-end" style={{ marginRight: 10 }}>
        <TextField
            fullWidth
            id="fromTreeNumber"
            label="From Tree Number"
            variant="outlined"
            name="fromTreeNumber"
            value={fromTreeNumber.split('-')[fromTreeNumber.split('-').length-1]}
            onChange={(e) => {
                handleFromTreeNumberChange(e)
                formik.handleChange()
            }}
            // onChange={inputsHandler}
            InputProps={{
                startAdornment: <InputAdornment position="start">{`${treeNumberList?.replace(reLast, '')}-`}</InputAdornment>,
                }}
            error={Boolean(touched.fromTreeNumber && errors.fromTreeNumber)}
            helperText={touched.fromTreeNumber && errors.fromTreeNumber}
        />
        </Grid>
        <Grid item sm={2.5} justifyContent="flex-end" style={{ marginRight: 10 }}>
        <TextField
                                                                                    fullWidth
            id="toTreeNumber"
            label="To Tree Number"
            variant="outlined"
            name="toTreeNumber"
            value={toTreeNumber.split('-')[toTreeNumber.split('-').length-1]}
            onChange={(e) => {
                handleToTreeNumberChange(e)
                formik.handleChange()
            }}
            // onChange={inputsHandler}
            InputProps={{
                startAdornment: <InputAdornment position="start">{`${treeNumberList?.replace(reLast, '')}-`}</InputAdornment>,
                }}
            error={Boolean(touched.toTreeNumber && errors.toTreeNumber)}
            helperText={touched.toTreeNumber && errors.toTreeNumber}
        />
        </Grid> */}
        {/* <Grid item sm={2.7} justifyContent="flex-end" style={{ marginRight: 20 }}>
          <Button
            variant="contained"
            onClick={() => formik.handleSubmit()}
            style={{ marginLeft: 30,height: 50, width: 150, marginRight: 2, backgroundColor: '#DF6526' }}
          >
            Submit
          </Button>
        </Grid> */}
      </Grid>
    </RootStyle>
  );
}
