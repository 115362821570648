import React from 'react';
import varitieOne from '../../../Assets/tree_varitie_one.png';
import varitieTwo from '../../../Assets/tree_varitie_two.png';
import varitieThree from '../../../Assets/tree_varitie_three.png';
import varitieFour from '../../../Assets/tree_varitie_four.png';

export default function TreeVarietyTable(){
  return (
    <>
     <table id="mytable" style={{ display: 'none' }}>
          
          <tbody>
            <tr>
              
              <td>
                <img
                  src={varitieOne}
                  alt="img"
                />
                <p>Asupallav</p>
              </td>
              
              <td>
                <img
                  src={varitieTwo}
                  alt="img"
                />
                <p>Asupallav</p>
              </td>
            </tr>
            <tr>
              
              <td>
                <img
                  src={varitieThree}
                  alt="img"
                />
                <p>Asupallav</p>
              </td>
              
              <td>
                <img
                  src={varitieFour}
                  alt="img"
                />
                <p>Asupallav</p>
              </td>
            </tr>
       
          </tbody>
        </table>
    </>
  )
}
