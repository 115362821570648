import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Visibility } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Container } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GetUsers } from '../../../actions/UserAction';
import { AddUserToTeam } from '../../../actions/TeamsAction';
import { GetQcRemarksForBaseColor } from '../../../actions/BaseColorAction';
// import Typography from 'src/theme/overrides/Typography';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles(() => ({
    noBorder: {
        border: "none",
    },
}));
export default function AssignWardDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { isOpen, data, isOpenConfirm, teamId } = props;
    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [state, setState] = React.useState('');
    const [role, setRole] = React.useState([]);
    const [topModalOpen, setTopModalOpen] = React.useState(false);
    const [reqObj, setReqObj] = React.useState(null);
    const [id, setId] = React.useState(null);
    const [viewOpen, setViewOpen] = React.useState(false);
    const [imageList, setImageList] = React.useState([]);
    const [openImageList, setOpenImageList] = React.useState(false);
    const handleOpenImageList = (e) => setOpenImageList(true);
    const handleCloseImageList = () => setOpenImageList(false);
    const handleClickClose = () => {
        props.handleClickClose();
    };
    return (
        <div>

            <Dialog open={props.open} fullWidth
                maxWidth="sm">
                <BootstrapDialogTitle id="customized-dialog-title" className="p-2"
                    onClose={props.handleClickClose}
                >
                    Assign Wards
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {/* {data ? ( */}
                        <div>
                            <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                <InputLabel id="demo-multiple-name-label">Council</InputLabel>
                                <Select
                                    fullWidth
                                    displayEmpty
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={props.showCouncil}
                                    onChange={props.onChangeCouncil}
                                    input={<OutlinedInput label="Council" />}
                                    MenuProps={MenuProps}
                                >
                                    {props.council?.map((option) => (
                                        <MenuItem key={option.value} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                <InputLabel id="demo-multiple-name-label">Wards</InputLabel>
                                <Select
                                    fullWidth
                                    displayEmpty
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={props.addedBy}
                                    onChange={props.onChange}
                                    input={<OutlinedInput label="Wards" />}
                                    MenuProps={MenuProps}
                                >
                                    {props.wards?.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    {/* ) : null} */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClickSave}>Save</Button>
                    <Button onClick={props.handleClickClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
