import JWTServer from '../api/withJWTServer';

import { SetNewAlert } from './AlertActions';

import { HandleExceptionWithSecureCatch } from './CombineCatch';

import { GET_WORK_LOGGED_BY_COUNCIL_ID } from './Types';

const GetWorkLogged = (council, date,userType) => async (dispatch) => {
  if(!council){
    dispatch(SetNewAlert({
      msg: "Please select council",
      alertType: "danger",
    }));
  }

  if(!userType){
    dispatch(SetNewAlert({
      msg: "Please select user type",
      alertType: "danger",
    }));
  }
  if(council){
    const url = `api/get-work-logs?council_id=${council}&date=${date}&type=${userType}`;
    try {
      const response = await JWTServer.get(`${url}`);
      console.log('response ', response.data);
      dispatch({
        type: GET_WORK_LOGGED_BY_COUNCIL_ID,
        payload: response.data,
      });
    } catch (e) {
      dispatch(SetNewAlert({
          msg: e.response.data.message,
          alertType: "danger",
        }));
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  }else{
    dispatch({
      type: GET_WORK_LOGGED_BY_COUNCIL_ID,
      payload: [],
    });
  }
 
};

export { GetWorkLogged };
