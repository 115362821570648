import { EXPORT_CALL_LOG, GET_CALL_LOG, RESET_STATE } from "../actions/Types";
  
  const INIT_STATE = {
    callLogs:[],
    exportCallLogs:[],
    isReportDownloadable:false,
  };
  
  export default function DeniedEntryReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;
  
    switch (type) {
        case GET_CALL_LOG:
          return{
              ...state,
              callLogs: payload.data,
            //   pageInfo: payload.data
        };
        case EXPORT_CALL_LOG:
          return{
              ...state,
              exportCallLogs: payload.data,
              isReportDownloadable: !state.isReportDownloadable
            //   pageInfo: payload.data
        };
        case RESET_STATE:
        return { ...INIT_STATE };

        default:
        return state;
    }
  };
  