import * as React from 'react';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { AddTreeCuttingReason, EditTreeCuttingReason } from '../../actions/TreeCuttingReasonAction';
import DefaultInput from '../Inputs/DefaultInput';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TreeCuttingReasonDialog(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [status, setStatus] = React.useState('Status');
  const { isOpen, data } = props;

  const { treeCuttingReason } = useSelector((state) => ({
    treeCuttingReason: state.treeCuttingReason.treeCuttingReason,
  }));

  const firstRun = React.useRef(true);
  React.useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    props.handleClose();
  }, [treeCuttingReason]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const DesignationsSchema = Yup.object().shape({
    reason: Yup.string()
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .max(50, "Maximum 50 characters")
      .required('Tree cutting reason is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: data ? data.reason : '',
    },
    validationSchema: DesignationsSchema,
    onSubmit: (value) => {
      if (data) {
        dispatch(
          EditTreeCuttingReason(
            {
              reason: value.reason,
            },
            data.id
          )
        );
        props.handleClose();
      } else {
        dispatch(
          AddTreeCuttingReason({
            reason: value.reason,
          })
        );
        props.handleClose();
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <div>
      <Dialog fullWidth={fullWidth} maxWidth={maxWidth} open={isOpen}>
        <BootstrapDialogTitle onClose={handleClose}>
          {data ? 'Edit Tree Cutting Reason' : 'Add Tree Cutting Reason'}
        </BootstrapDialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6} md={6} sm={6}>
              <DefaultInput
                fullWidth
                id="typeOfTreeCutting"
                label="Tree Cutting Reason*"
                placeholder="Tree Cutting Reason*"
                // eslint-disable-next-line react/prop-types
                error={Boolean(touched.reason && errors.reason)}
                helperText={touched.reason && errors.reason}
                {...getFieldProps('reason')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleSubmit} variant="contained">
            {data ? 'Save' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
