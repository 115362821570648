import react, { useState } from "react";
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
  Button
} from '@mui/material';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
// component
import { useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import SearchImage from '../../../Assets/Search_Image.png';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  backgroundColor: '#F8F8F8 !important',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TreeCuttingToolbar.propTypes = {
  callType: PropTypes.string,
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  placeHolder: PropTypes.string,
  handleFromDate: PropTypes.func,
  handleToDate: PropTypes.func,
  fromDate: PropTypes.any,
  toDate: PropTypes.any,
  handleSubmit: PropTypes.func,
  exportReports: PropTypes.func
};

export default function TreeCuttingToolbar({
  callType,
  numSelected,
  filterName,
  onFilterName,
  placeHolder,
  fromDate,
  toDate,
  handleFromDate,
  handleToDate,
  handleSubmit,
  exportReports
}) {
  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();
  const { council, zones, wards } = useSelector((state) => ({
    council: state.council.activeCouncil,
    zones: state.zones.activeZonesByCID,
    wards: state.wards.activeWardsByCID,
  }));

  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  return (
    <>
    
    <Grid container justifyContent="flex-end" style={{padding:'4px',paddingTop:'10px'}}>
        <Grid item sm={2} justifyContent="flex-end" >
          <TextField
            id="date"
            // label="Date Of Birth"
            type="date"
            value={fromDate}
            label="start date*"
            placeholder="start date*"
            style={{ width: '100%' }}
            onChange={handleFromDate}
          // className={classes.textField}
          // error={Boolean(touched.fromDate && errors.fromDate)}
          // helperText={touched.fromDate && errors.fromDate}
          // {...getFieldProps('fromDate')}
          InputLabelProps={{
            shrink: true,
          }}
          // inputProps={{ max: todayDate }}
          />
        </Grid>
        <Grid item sm={2} justifyContent="flex-end">
          <TextField
            id="date"
            // value={toDate}

            type="date"
            label="end date*"
            value={toDate}
            placeholder="end date*"
            style={{ width: '90.5%', marginLeft: "5px", }}
            onChange={handleToDate}
            InputLabelProps={{
                shrink: true,
              }}
          />
        </Grid>
{/* 
        <Grid item sm={2} justifyContent="flex-end">

      <Button
        variant="contained"
        className="desktop-button-"
        onClick={handleSubmit}
        style={{ fontWeight: 500, fontSize: '15px', backgroundColor: '#E8762F', color: '#fff', margin: '2px' }}
      >
        Submit
      </Button>
    </Grid> */}

    <Grid item sm={2} justifyContent="flex-end">

      <Button
        variant="contained"
        className="desktop-button-"
        onClick={exportReports}
        style={{ fontWeight: 500, fontSize: '15px', backgroundColor: '#E8762F', color: '#fff', margin: '2px' }}
      >
        Export Report
      </Button>
    </Grid>
  </Grid>
  </>
  );
}
