import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import { Stack, Typography, Grid, Card, Button, TextField, Box, Chip, IconButton, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import { Divider } from '@material-ui/core';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AddDepositeAmountReceipt } from '../../actions/ProclamationActions';
import { data } from '../Reports/Chart/Piechart';

const UploadProcessingFeesReceipt = () => {
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'application/pdf'];
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ApplicationNumber = location.state?.data?.application_number;
  const createdDate = location.state?.created_at;
  const loggedUser = useSelector((state) => state.auth.loggedUser);
  console.log('loggedUser', loggedUser);

  console.log('data', location.state.data);

  const totalTreePerTree = loggedUser?.council?.processing_fees_per_tree;
  console.log('totalTreePerTree', totalTreePerTree);
  const fileInputRef = useRef(null);
  console.log('fileInputRef', fileInputRef);
  const [imageValue, setImageValue] = useState(null);
  console.log('imageValue', imageValue);
  const [selectedImage, setSelectedImage] = useState(
    ''
  );
  const [imageError, setImageError] = useState(null);
  const [file, setFile] = useState(null);

  const allowImge = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml'];

  const depositeAmountSchema = Yup.object().shape({
    feeReceipt: Yup.string().required('File is required'),
  });

  const totalTrees = location.state?.data?.total_trees || 0;
  const amountPerTree = totalTreePerTree || 0;
  const totalAmount = totalTrees * amountPerTree;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      feeReceipt: null,
    },
    validationSchema: Yup.object({
      feeReceipt: Yup.string().required('Recipt is required').nullable(),
    }),
    onSubmit: (value) => {
      console.log('submitValue', value.feeReceipt);
      // alert("hello")
      const formData = new FormData();
      formData.append('tree_cutting_application_id', location.state?.data?.id);
      formData.append('fees_type', 'processing-fees');
      formData.append('fees_receipt', value.feeReceipt);
      dispatch(AddDepositeAmountReceipt(formData)).then((response)=>{
        console.log("addreciptprocessingfess",response.status)
if(response.status === 201){
  navigate('/dashboard/tree-cutting-application')
}
      })
      
    },
  });

  const handleDocumentValueChange = (e) => {
    console.log('e', e.target);
    const file = e.target.files[0];
    console.log('file', file);
    setFile(file);
  };

  const handleImageChange = (event) => {
    const image = event.target.files[0];
    setSelectedImage(image);
    // console.log('image12', image);
    // // if (!image) return;
    // // setImageValue('');

    const maxFileSize = 14 * 1024 * 1024;
    const isValidFiles = allowImge.includes(image.type);
    const isValidSizes = image.size <= maxFileSize;

    if (!isValidFiles) {
      setImageError('Only image accepted');
      formik.setFieldError('feeReceipt', 'File format not supported');
      setSelectedImage('');
    } else if (!isValidSizes) {
      formik.setFieldError('feeReceipt', 'File size exceeds 14 MB');
    } else if (isValidFiles && isValidSizes) {
      setImageError(null);
      setSelectedImage(image);
      formik.setFieldValue('feeReceipt', event.target.files[0]);
    }
  };

  const handleRemoveImage = () => {
    console.log("removed")
    setSelectedImage('');
    formik.setFieldValue('feeReceipt', '');
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  console.log('values', values);

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {'Add Processing Fees Recipt'}
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography>
        </Typography>
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Card variant="outlined" style={{ padding: '20px', margin: 10, width: 600, boxShadow: '20px 20px gary' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '15px',
              padding: '15px',
            }}
            >
            <Box >
              <Typography sx={{ fontWeight: '500', fontSize: '14px' }}>{createdDate}</Typography>
              <Typography sx={{ fontWeight: '700', fontSize: '20px' }}>{ApplicationNumber}</Typography>
            </Box>
            <Box>
              <Chip
                label={location?.state?.data?.application_status?.application_status}
                sx={{ backgroundColor: '#90EE90' }}
              />
            </Box>
          </Box>
          {/* <Divider sx={{ marginY: '15px' }} /> */}
          <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // gap: '15px',
              padding: '5px',
            }}>

          <Box >
            <Typography sx={{ fontWeight: '500', fontSize: '14px', padding: '5px' }}>Applicant Name:
            <span  style={{ fontWeight: 'bold', fontSize: 14 }}>
              {' '}
              {location.state?.data?.name}
            </span></Typography>
            </Box>
            <Box >
            <Typography sx={{ fontWeight: '500', fontSize: '14px', padding: '5px' }}>Mobile Number:
            <span  style={{ fontWeight: 'bold', fontSize: 14 }}>  {location.state?.data?.mobile}</span>
            </Typography>
          </Box>
          </Box>
          <Divider sx={{ marginY: '15px' }} />

          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
            <Typography sx={{ fontWeight: '500', fontSize: '14px', padding: '5px' }}>
              {' '}
              Total no of trees requested to cut
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 10 }}>
              {' '}
              {location.state?.data?.total_trees}
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '5px',
              marginTop: '15px',
            }}>
            <Typography sx={{ fontWeight: '500', fontSize: '14px', padding: '5px' }}>Amount/tree </Typography>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 12 }}>
              {' '}
              {totalTreePerTree === null ? 0 : totalTreePerTree}
            </Typography>
          </Box>
          <Divider sx={{ marginY: '15' }} />

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '15px',
              padding: '15px',
              backgroundColor: '#90EE90',
            }}>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 700, fontSize: 12 }}>
                Total
              </Typography>
              <Typography>Final Grand Total Amount</Typography>
            </Box>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 600, fontSize: 14 }}>
                {' '}
                ₹{totalAmount}
              </Typography>
            </Box>
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <box style={{ marginTop: '15' }}>
              <Grid item xs={6}>
                {!selectedImage ? (
                  <>
                    <TextField
                      name="feeReceipt"
                      accept="image/*"
                      id="image-upload"
                      value={imageValue}
                      fullWidth
                      type="file"
                      style={{ marginTop: 15 }}
                      onChange={handleImageChange}
                      // error={formik.touched.feeReceipt && Boolean(formik.errors.feeReceipt)}
                      // helperText={formik.touched.image && formik.errors.image}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      aria-describedby="file-upload-label"
                    />
                    <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: '0.75rem' }}>
                      {formik.errors.feeReceipt || imageError}
                    </Typography>
                  </>
                ) : (
                  <Grid item xs={6} sx={{ position: 'relative' }}>
                    <Typography sx={{marginTop:"20px"}}>Uploaded Recipt-{selectedImage.name} </Typography>
                    <div
                      style={{
                        position: 'relative',
                        width: '100px',
                      }}>
                      <div
                        style={{
                          width: '100px',
                          height: '70px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '10px',
                        }}>
                        <DescriptionIcon style={{ fontSize: 36 }} />
                      </div>

                      <IconButton
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          background: 'red',
                          width: '10px',
                          height: '10px',
                        }}
                        onClick={()=>handleRemoveImage()}>
                        <CloseIcon style={{ color: 'white', fontSize: 16 }} />
                      </IconButton>
                    </div>

                  </Grid>
                )}
              </Grid>
            </box>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
              <Button variant="contained" sx={{ width: '14%' }} type="submit">
                Submit
              </Button>
            </Grid>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default UploadProcessingFeesReceipt;
