
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  Link,
  IconButton,
  Modal,
  TextField,
  Grid,
  MenuItem,
  FormControlLabel,
  Drawer,
  Box,

} from '@mui/material';
import moment from 'moment';
import axios from "axios"
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@mui/icons-material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import Iconify from '../../components/Iconify';
import { UserListHead } from '../../sections/@dashboard/user';
import USERLIST from '../../_mock/user';
import TreeData from  '../../components/JsonFiles/TreeData.json';
import BaseColorDialog from "../../components/DialogBox/tree-data/BaseColorDialog";
import BaseColorMoreMenu from '../../sections/@dashboard/tree/BaseColorMoreMenu';
import ViewImageDialog from '../../components/DialogBox/tree-data/ViewImageDialog';
import { GetActiveCouncil, SetActiveCouncil } from '../../actions/CouncilAction';
import { GetActiveUsers, GetUsers, GetUsersByRoleID } from '../../actions/UserAction';
import { GetActiveZonesByCouncilId, GetActiveZones, SetActiveZones } from '../../actions/ZonesAction';
import { GetActiveWardsByCouncilId, GetActiveWards, SetActiveWards} from '../../actions/WardsActions';
import { GetMyActiveTeam } from '../../actions/TeamsAction';
import TeamListToolbar from '../../sections/@dashboard/teams/TeamListToolbar';
import QcStatusDialog from '../../components/DialogBox/tree-data/QcStatusDialog';
import StatusPendngButton from '../../components/statusbutton/StatusPendngButton';
import StatusApprovedButton from '../../components/statusbutton/StatusApprovedButton';
import StatusUnapprovedButton from '../../components/statusbutton/StatusUnapprovedButton';
import ImageCarousel from '../../components/ImageCarousel';
import FullLoader from '../../components/Loader/FullLoader';
import { ShowLoader } from '../../actions/CommonAction';
import {GetReportRequest} from "../../actions/WorkReportAction";
import {SetNewAlert } from "../../actions/AlertActions"
import { GetAllSelfie, UpdatePendingSelfieStatus } from '../../actions/SelfieVerificationAction';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'associateName', label: 'Associate Name', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'council', label: 'Council', alignRight: false },
  { id: 'zone', label: 'Zone', alignRight: false },
  { id: 'ward', label: 'Ward', alignRight: false },
  { id: 'images', label: 'Images', alignRight: false },
  { id: 'addedOn', label: 'Added On', alignRight: false },
  { id: 'verifiedBy', label: 'Verified By', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'remarks', label: 'Remarks', alignRight: false },
];

// ----------------------------------------------------------------------

export default function SelfieList() {
  const dispatch = useDispatch();
  const [councilID, setCouncilID] = React.useState('');
  const [zoneID, setZoneID] = React.useState('');
  const [wardID, setWardID] = React.useState('');
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(10);
  const [open, setOpen ] = React.useState(false);
  const [viewOpen, setViewOpen ] = React.useState(false);
  const [dialogData,setDialogData] = React.useState(null);
   const [stateName, setStateName] = React.useState('');
   const [zoneId,setZoneId] = React.useState('');
   const [wardId,setWardId] = React.useState('');
   const [selectedIndex, setSelectedIndex] = React.useState(0);
   const [coucilId,setCouncilId] = React.useState('');
   const [imageList,setImageList] = React.useState([]);
   const [showList,setShowList] = React.useState(false);  
   const [qcDialogOpen,setQcDialogOpen] = React.useState(false);
   const [addedBy, setAddedBy] = React.useState('');
   const [selfieId,setSelfieId] = React.useState("");
   const userPermissions = [];
   const todayDate = moment(new Date()).format('YYYY-MM-DDTHH:mm');
   const [openImageList, setOpenImageList] = React.useState(false);
   const [addedByForm, setAddedByForm] = React.useState();
   const [formDate, setFromDate] = React.useState();
   const [toDate, setToDate] = React.useState();
   const handleOpenImageList = (e) => setOpenImageList(true);
   const handleCloseImageList = () => setOpenImageList(false);
   const [councilName,setCouncilName] =  React.useState();
   const [buttonClick, setButtonClick] = React.useState(true); 
// console.log("coucilId", coucilId);
   const [newState, setNewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

   const {
    council,
    zones,
    wards,
    selfies,
    userByRoleID,
    activeTeams,
    updatePendingSelfieLog,
    pageInfo,
    loggedUser,
    showLoader,
    activeUsers
  } = useSelector((state) => ({
    council:state.council.activeCouncil,
    zones:state.zones.activeZonesByCID,
    wards:state.wards.activeWardsByCID,
    activeTeams: state.teams.activeTeams,
    selfies:state.selfieVerification.selfies,
    userByRoleID: state.users.userByRoleID,
    updatePendingSelfieLog:state.selfieVerification.updatePendingSelfieLog,
    pageInfo:state.selfieVerification.pageInfo,
    loggedUser:state.auth.loggedUser,
    showLoader: state.common.showLoader,
    activeUsers: state.users.activeUsers
  }));
  loggedUser.roles[0].permissions.map((item, index)=>(
    userPermissions.push(item.name)
  ))

  const councilArr = council?.find((val) => val.id === councilID);

  const { state} = useLocation();
    useEffect(()=>{
      let cId = null;
      let wId = null;
      let zId = null;
      if(state?.councilId){
        setCouncilId(state.councilId)
        cId = state.councilId;
      }
      if(state?.wardId){
        setWardId(state.wardId);
        wId = state.wardId;
      }
      if(state?.zoneId){
        setZoneId(state.zoneId)
        zId = state.zoneId;
      }
      if(state?.pageNumber){
        setPage(state.pageNumber)
      }
      if(state){
        dispatch(GetAllSelfie(state.pageNumber,rowsPerPage,cId,zId,wId))
      }
      dispatch(GetActiveUsers(1))
      
    },[])


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };

  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    // console.log("First Run Function");
    dispatch(ShowLoader(false));
    dispatch(
      GetAllSelfie(
        activeTeams?.active_council_id,
        activeTeams?.active_zone_id,
        activeTeams?.active_ward_id
      )
    );
    setCouncilID(activeTeams?.active_council_id);
    setZoneID(activeTeams?.active_zone_id);
    setWardID(activeTeams?.active_ward_id);
    const activeCouncilObj = {
      data: [{ id: activeTeams?.active_council_id, name: activeTeams?.active_council_name, status: 1 }],
    };
    const activeWardObj = {
      data: [{ id: activeTeams?.active_ward_id, name: activeTeams?.active_ward_name, status: 1 }],
    };
    const activeZoneObj = {
      data: [{ id: activeTeams?.active_ward_id, name: activeTeams?.active_zone_name, status: 1 }],
    };
    dispatch(SetActiveCouncil(activeCouncilObj));
    dispatch(SetActiveWards(activeWardObj));
    dispatch(SetActiveZones(activeZoneObj));
    // setSelectedIndex(0);
  }, [activeTeams]);

  const thirdRun = React.useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    setShowList(true);
    dispatch(ShowLoader(false));
  }, [selfies]);

  useEffect(() => {

    if (loggedUser?.roles[0]?.slug === 'get-base-color-trees') {
      dispatch(GetMyActiveTeam());
      dispatch(ShowLoader(true));
      // dispatch(GetUsersByRoleID(1, 3, 5));
    } else {
      // dispatch(GetUsersByRoleID(1, 3, 5));
      dispatch(GetActiveCouncil(1));
      dispatch(GetActiveWards(1));
      dispatch(GetActiveZones(1));
    }
  }, []);

  useEffect(()=>{
    if(pageInfo){
      setCount(pageInfo?.total)
    }
  },[pageInfo])

  const handleNewUserClick = () => {
    setDialogData(null);
    setOpen(!open)
  }

  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen)
    setImageList(images || []);
  }

  const handleQcDialog = (id) => {
    setQcDialogOpen(!qcDialogOpen);
    setSelfieId(id);
  }

  const handleQcSubmit = (data,id) => {
    const obj = {};

    if(data){
      obj.qc_status = "Unapproved";
      obj.qc_remark_id = data;
    }
    else {
      obj.qc_status = "Approved";
    }

    dispatch(UpdatePendingSelfieStatus(id,obj))

  }

  const handleAddedByChange = (event) => {
    setAddedBy(event.target.value);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setShowList(false);
    dispatch(GetAllSelfie(newPage,rowsPerPage,councilID,zoneID,wardID,addedByForm,formDate,toDate));
  };

  const handleCouncilChange = (e) => {
    setCouncilID(e.target.value);
    setZoneID('');
    setWardID('');
    dispatch(GetActiveZonesByCouncilId(1, e.target.value));
    dispatch(GetActiveWardsByCouncilId(1, e.target.value));

    council.map((value, index) => {
      if (value.id === e.target.value) {
        setCouncilName(value.name);
      }
      return null;
    });
  };

  const handleZoneChange = (event) => {
    setZoneID(event.target.value);
  };

  const handleWardChange = (event) => {
    setWardID(event.target.value);
  };

  function handleClick(event) {
    event.preventDefault();
  }

  console.log("COUNCIL ARR",councilArr)

  const FilterSchema = Yup.object().shape({
    councilForm: Yup.string().required('Please select council'),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      councilForm:  councilArr?.id || councilID,
      wardForm: wardID || '',
      zoneForm: zoneID || '',
      addedByForm: addedBy || '',
      fromDateForm: "",
      toDateForm: "",
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {
      setAddedByForm(value.addedByForm);
      setFromDate(value.fromDateForm);
      setToDate(value.toDateForm);
      setNewState({ ...newState, right: false });
      dispatch(ShowLoader(true));
      setPage(1)

      let convertedStartDate = ''
      let convertedEnddate = ''

      if(value.fromDateForm){
        convertedStartDate = moment(new Date(value.fromDateForm)).format('DD-MM-YYYY hh:mm a');
      }
      if(value.toDateForm){
        convertedEnddate = moment(new Date(value.toDateForm)).format('DD-MM-YYYY hh:mm a');
      }
      dispatch(
        GetAllSelfie(1,rowsPerPage,councilID, zoneID, wardID, value.addedByForm,  convertedStartDate, convertedEnddate),
      );
      setButtonClick(false)
    },
  });

  const useStyles = makeStyles({
    
    icon: {
        fill: '#214C50',
    },
   
})
// console.log("baseColorTrees",baseColorTrees)
const classes = useStyles()
const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
const filterUserList = activeUsers?.filter(value => value.assigned_roles !== "Admin" && value.assigned_roles !== "Superadmin" && value.assigned_roles !== "Data Analyst");
  return (
    <Page title="User">
       <FullLoader showLoader={showLoader}/>
      <Container>
        {open ? <BaseColorDialog isOpen={open} handleClose={handleNewUserClick} data={dialogData} /> : null}

        {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs aria-label="breadcrumb" style={{ color: '#000000', fontWeight: 900, fontSize: '20px'}} separator=":">
              
              <Typography variant="h4" gutterBottom style={{ color: '#000000' }}>
                Selfie Verification
              </Typography>
              <Typography variant="h4" gutterBottom style={{ color: '#000000', fontWeight: 400 }}>
                Selfie
              </Typography>
            </Breadcrumbs>
            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: '400', marginTop: '-8px' }}>
              It is showing list of Selfie with its details
            </Typography>
          </div>
          <Button
           onClick={toggleDrawer('right', true)}
            variant="contained"
            to="#"
          
            startIcon={<Iconify icon="eva:funnel-fill" />}
          >
            Filters
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
      
        <Box sx={{ height: '100' }}>
        
          <Drawer
            sx={{
              '& .MuiDrawer-paper': {
                width: '300px',
                maxWidth: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
            anchor={'right'}
            open={newState.right}
            onClose={toggleDrawer('right', false)}
            // sx={{
            //   display: { xs: 'block', sm: 'none' },
            //   '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            // }}
          >
            <div>
              <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}>
                <Grid item xs={12}>
                  <TextField
                    select
                    // disabled={loggedUser?.roles[0]?.slug === 'qc_census_offsite'}
                    id="councilForm"
                    label="Council*"
                    displayEmpty
                    value={councilID}
                    style={{ width: '100%' }}
                    size="small"
                    onChange={(e) => {
                      handleCouncilChange(e);
                      formik.handleChange(e);
                    }}
                    // onChange={handleCouncilChange}
                    error={Boolean(touched.councilForm && errors.councilForm)}
                    helperText={touched.councilForm && errors.councilForm}
                    inputProps={{
                      classes: {
                          icon: classes.icon,
                      },
                  }}
                  >
                    <MenuItem disabled value="">
                      <em>Select Council*</em>
                    </MenuItem>
                    {council?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    select
                    // disabled={loggedUser?.roles[0]?.slug === 'base_color'}
                    id="zoneForm"
                    label="Zone"
                    displayEmpty
                    value={zoneID}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    onChange={(e) => {
                      handleZoneChange(e);
                      formik.handleChange(e);
                    }}
                    // onChange={handleZoneChange}
                    // error={Boolean(touched.zoneForm && errors.zoneForm)}
                    // helperText={touched.zoneForm && errors.zoneForm}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Select Zone*</em>
                    </MenuItem>
                    {councilID
                      ? zones?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    select
                    // disabled={loggedUser?.roles[0]?.slug === 'base_color'}
                    id="wardForm"
                    label="Ward"
                    displayEmpty
                    value={wardID}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    onChange={(e) => {
                      handleWardChange(e);
                      formik.handleChange(e);
                    }}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Select Ward*</em>
                    </MenuItem>
                    {councilID
                      ? wards?.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    select
                    id="addedBy"
                    label="Added By"
                    displayEmpty
                    value={addedBy}
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    // placeholder='*Status'
                    onChange={(e) => {
                      handleAddedByChange(e);
                      formik.handleChange(e);
                    }}
                    // {...getFieldProps("addedByForm")}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    <MenuItem disabled value="">
                      <em>Select Added By</em>
                    </MenuItem>
                    {filterUserList?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.first_name} {option.last_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="fromDate"
                    type="datetime-local"
                    label="Start Date*"
                    margin="normal"
                    name="fromDateForm"
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    // label="Plantation Date"
                    value={values.toDateForm}
                     error={Boolean(touched.fromDateForm && errors.fromDateForm)}
                    helperText={touched.fromDateForm && errors.fromDateForm}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: todayDate }}
                    {...getFieldProps('fromDateForm')}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="toDate"
                    label="End Date*"
                    type="datetime-local"
                    margin="normal"
                    name="toDateForm"
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
                    // label="Plantation Date"
                    value={values.toDateForm}
                    error={Boolean(touched.toDateForm && errors.toDateForm)}
                    helperText={touched.toDateForm && errors.toDateForm}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: todayDate }}
                    {...getFieldProps('toDateForm')}
                  />
                </Grid>

                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 5}}
                >
                  Apply
                </Button>
              </Grid>
            </div>
            {/* <FilterDrawer data={toggleDrawer("right", false)}/> */}
          </Drawer>
        </Box>
      </Stack>

        <Card sx={{ mt: 2 }}>
          {/* <TeamListToolbar
            numSelected={0}
            placeHolder={'Search Base Color...'}
            onFilterName={filterByName}
            handleCoucilChange={(e) => handleCoucilChange(e)}
            handleWardChange={(e) => handleWardChange(e)}
            handleZoneChange={(e) => handleZoneChange(e)}
            coucilId={coucilId}
            zoneId={zoneId}
            wardId={wardId}
            callType="BaseColor"
          /> */}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table  size="small" aria-label="a dense table">
                <UserListHead headLabel={TABLE_HEAD} />
                {!showList ? (
                  <TableRow>
                    <TableCell align="right" colSpan={8} fontWeight={700}>
                      Please select council to get selfie data
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableBody>
                  {showList
                    ? selfies?.map((option, index) => {
                        return (
                          <TableRow hover>
                            <TableCell align="left">
                              <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                            </TableCell>
                            <TableCell align="left">{option.user?option.user?.first_name:""} {option.user?option.user?.last_name:""}</TableCell>
                            <TableCell align="left">
                            {option.user?option.user?.assigned_roles:"-"}
                            </TableCell>
                            <TableCell align="left">
                            {option.council?option.council?.name:"-"}
                            </TableCell>
                            <TableCell align="left">
                            {option.zone?option.zone?.name:"-"}
                            </TableCell>
                            <TableCell align="left">
                            {option.ward?option.ward?.name:"-"}
                            </TableCell>
                            <TableCell align="left">
                              {/* <Link to="#" onClick={handleViewOpen} style={{cursor:'pointer'}}>View</Link> */}
                              <IconButton
                                aria-label="delete"
                                size="large"
                                // onClick={() => handleViewOpen(option.image_url.split())}
                                onClick={(e) => {
                                  setImageList(option.image_url.split() || []);
                                  handleOpenImageList(e);
                                }}
                                sx={{color: '#214c50'}}
                              >
                                <Visibility />
                              </IconButton>
                            </TableCell>
                            <TableCell align="left" style={{ whiteSpace: 'nowrap' }}>
                              {option.added_on}
                            </TableCell>
                            <TableCell align="left">
                              {option.verified_by?option.verified_by?.first_name:""} {option.verified_by?option.verified_by?.last_name:""}
                            </TableCell>
                            <TableCell align="left">
                              {option.status === 'Pending'?<StatusPendngButton qcStatus={option.status}/>: ''}
                              {option.status === 'Approved'?<StatusApprovedButton qcStatus={option.status}/>: ''}
                              {option.status === 'Unapproved'?<StatusUnapprovedButton qcStatus={option.status}/>: ''}
                              {/* {option.qc_status ? option.qc_status : '-'} */}
                              </TableCell>
                            <TableCell align="left">{option.remark?option.remark:"-" }</TableCell>
                          
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal
              open={openImageList}
              onClose={handleCloseImageList}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Container style={{ width: '526px' }}>
                <ImageCarousel imagelist={imageList}  />
              </Container>
              {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
            </Modal>
          </Scrollbar>
          {selfies ? (
            <Pagination
              count={showList ? pageInfo.last_page : 0}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
