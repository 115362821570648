import React, { useState } from 'react';
import { Card, Grid, Typography, Container, Stack, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import ProjectImg from '../../Assets/hometree-modified.png';

import TreedetailStatusButton from '../../components/statusbutton/TreedetailStatusButton';
// import UserListToolbar from '../../sections/@dashboard/user';
const data = [
    {

        ward_name: "A1",
        tree_count: 2
    },
    {
        ward_name: "A2",
        tree_count: 1
    }
];

const MasterData = (props) => {
    const [data, setData] = useState({
        itemsToShow: 12,
    }); // i named it data youcan name it whatever suits you

    // console.log("masterdata", props);
    const filterByName = () => { };
    const useStyles = makeStyles({
        success: {
            backgroundColor: '#DDFAD1',
            color: '#507C59',
            border: '1px solid #507C59',
            fontSize: '12px',
            borderRadius: '5px',
            padding: '4px 10px',
            fontWeight: 600,
            pointerEvents: 'none',
        },
        pending: {
            backgroundColor: '#efcbbd',
            color: '#CE5623',
            border: '1px solid #CE5623',
            fontSize: '12px',
            borderRadius: '5px',
            padding: '4px 10px',
            fontWeight: 600,
            pointerEvents: 'none',
        },
        border: {
            // border: '1px solid #9b9393',
            borderBottom: '1px solid #000',
            // borderRight: '1px solid #9b9393',
            // borderTop: '1px solid #9b9393',
            // borderLef: '1px solid #9b9393',
            // borderBottom: '1px solid #9b9393',

            width: '60%',
            // margin: '10px'
        },
    });
    const classes = useStyles();
    const showMore = () => {
        if (data.itemsToShow) {
            setData({ ...data, itemsToShow: props.projects?.length })
        }
        else {
            setData({ itemsToShow: 12 })
        }
        //   data.itemsToShow === 3 ? setData({ ...data, itemsToShow: data.cars.length }): setData({ itemsToShow: 3 });
    };
    const ShowLess = () => {
        if (data.itemsToShow) {
            setData({ ...data, itemsToShow: 12 })
        }
        else {
            setData({ ...data, itemsToShow: props.projects?.length })
        }
    }

    return (
        <>
            <Grid container spacing={3} style={{ padding: '20px', maxHeight: data.itemsToShow > 12 ? '400px' : 'auto', overflow: data.itemsToShow > 12 ? 'auto' : 'unset' }}>
                <Grid container rowSpacing={1}>
                    {props.projects?.slice(0, data.itemsToShow).map((item, i) => (
                        <Grid item xs={6} md={6} sm={6} className={classes.border} key={i} style={{ padding: '0' }}>
                            <Grid container rowSpacing={1}>
                                <Grid item xs={10} key={i} style={{ padding: '0' }}>
                                    <Typography
                                        variant="h4"
                                        style={{ color: '#000', fontWeight: 600 }}

                                    >
                                        <Typography variant="h5" style={{ fontSize: '20px', padding: '5%' }}>
                                            {item.tree_name}

                                        </Typography>
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} key={i}>
                                    <Typography
                                        variant="h4"
                                        style={{ color: '#000', fontWeight: 600 }}

                                    >
                                        <Typography variant="h5" style={{ fontSize: '20px', color: '#004d51', padding: '5%' }}>
                                            {item.tree_count}

                                        </Typography>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                    )
                    }
                </Grid>
                <div style={{ textAlign: 'right', padding: '2%' }}>
                    {data.itemsToShow < props.projects?.length && (
                        <Button onClick={showMore} variant='contained' style={{ background: '#000', color: '#fff' }}><KeyboardDoubleArrowDownIcon /></Button>
                    )}
                    {data.itemsToShow > 12 && (
                        <Button onClick={ShowLess} variant='contained' style={{ background: '#000', color: '#fff' }}><KeyboardDoubleArrowUpIcon /></Button>
                    )}
                </div>

            </Grid>
        </>
    );
};

export default MasterData;
