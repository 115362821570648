import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  ADD_USER,
  DELETE_USER,
  GET_MANAGER_ROLE,
  EDIT_USER,
  GET_RELIGIONS,
  GET_SALARY_DEDUCTION_TYPES,
  GET_USER,
  GET_USER_BY_ID,
  GET_ACTIVE_USER,
  GET_USERS_BY_ROLEID,
  GET_USER_DOCUMENT_TYPES,
  SEARCH_USER,
  UNLINK_DEVICE,
  // GET_MANAGER,
} from './Types';

const GetUsers = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-managers?page=${page}&limit=${limit}`);
    dispatch({
      type: GET_USER,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const GetManagerRequest = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/czw-change-requests`);
    dispatch({
      type: GET_USER,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetManagerRole = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-managers`);
    dispatch({
      type: GET_MANAGER_ROLE,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


const GetAssociateByID = (userId) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/manager-assigned-cw?manager_id=${userId}`);
    dispatch({
      type: GET_USER_BY_ID,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchUsers = (page, limit, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-managers?page=${page}&limit=${limit}&search=${searchValue}`);
    dispatch({
      type: GET_USER,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchUserByManager = (page, limit,userId, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/users-assigned-manager/${userId}?search=${searchValue}`);
    dispatch({
      type: GET_USER,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
}
const AddUsersManager = (params, getAssociateId) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/manager-assigned-cw`, params);
    dispatch({
      type: ADD_USER,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


export {
  GetUsers,
  GetManagerRequest,
  GetManagerRole,
  GetAssociateByID,
  SearchUsers,
  AddUsersManager,
  SearchUserByManager
};
