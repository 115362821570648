import { GET_NO_TREE_PROPERTY, RESET_STATE,EXPORT_CALL_LOG } from "../actions/Types";
  
  const INIT_STATE = {
    pageInfo:{},
    noTreeProperty:[],
    exportCallLogs: [],
  isReportDownloadable: false,
  };
  
  export default function NoTreePropertyReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;
  
    switch (type) {
        case GET_NO_TREE_PROPERTY:
          return{
              ...state,
              noTreeProperty: payload.data.data,
              pageInfo: payload.data
        };
        case EXPORT_CALL_LOG:
      return {
        ...state,
        exportCallLogs: payload.data,
        isReportDownloadable: !state.isReportDownloadable
        //   pageInfo: payload.data
      };
        case RESET_STATE:
        return { ...INIT_STATE };

        default:
        return state;
    }
  };
  