import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  GET_TREE_CUTTING_REASON,
  GET_TREE_CUTTING_REASON_LIST,
  UPDATE_CUTTING_REASON,
  ADD_TREE_CUTTING_REASON,
  DELETE_TREE_CUTTING_REASON,
  TREE_CUTTING_REASON_STATUS_CHANGE,
  GET_TREE_NAME
} from './Types';

const GetTreeCuttingReasons = (page, limit) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/tree-cutting-reason?page=${page}&limit=${limit}`);
    console.log("permission reasons",response);
    dispatch({
      type: GET_TREE_CUTTING_REASON,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeCuttingReasonsList = () => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/tree-cutting-reason/status/1`);
    dispatch({
      type: GET_TREE_CUTTING_REASON_LIST,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


const GetTreeNameList = () => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/tree-names?status=1`);
    console.log("ffff",response.data.name);
    dispatch({
      type: GET_TREE_NAME,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchTreeCuttingReasons = (page, limit, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/tree-cutting-reason?page=${page}&limit=${limit}&search=${searchValue}`);
    // console.log("DESIGNATIONS RESPONSE",response.data);
    dispatch({
      type: GET_TREE_CUTTING_REASON,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const AddTreeCuttingReason = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.post('/api/tree-cutting-reason', params);
    dispatch({
      type: ADD_TREE_CUTTING_REASON,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const EditTreeCuttingReason = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.put(`/api/tree-cutting-reason/${id}`, params);
    // console.log("EDIT STATE RESPONSE",response.data);
    dispatch({
      type: UPDATE_CUTTING_REASON,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const DeleteTreeConditions = (params) => async (dispatch) => {
  try {
    const body ={
      "status": params.status ? 0 : 1
    }
    const response = await JWTServer.patch(`/api/tree-cutting-reason/${params.id}/update-status`,body);
    dispatch({
      type: DELETE_TREE_CUTTING_REASON,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const TreeCuttingReasonStatusChange = (params) => async (dispatch) => {
  try {
    const body ={
      "status": params.status ? 0 : 1
    }
    const response = await JWTServer.patch(`/api/tree-cutting-reason/${params.id}/update-status`,body);
    dispatch({
      type: TREE_CUTTING_REASON_STATUS_CHANGE,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export { GetTreeCuttingReasons, AddTreeCuttingReason, EditTreeCuttingReason, DeleteTreeConditions,GetTreeCuttingReasonsList,SearchTreeCuttingReasons,TreeCuttingReasonStatusChange,GetTreeNameList };
