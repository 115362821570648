import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function CancelMeetingDialog(props) {
  const [open, setOpen] = React.useState(false);
  const { isOpenConfirm } = props;
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    props.handleCancle(true);
  };

  const handleTrueClose = () => {
    props.handleClose(true);
  };

  return (
    <div style={{ p: 5 }}>
      <Dialog
        open={isOpenConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{''}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">Do you want to cancle meeting ?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleTrueClose} variant="contained">
            yes
          </Button>
          <Button onClick={handleClose} style={{ backgroundColor: '#e85454', color: '#fff' }} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
