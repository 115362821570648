import { GET_ABELL_META_DETAILS, RESET_STATE, UPDATE_META_DETAILS } from "../actions/Types";
  
  const INIT_STATE = {

    abellMetaDetails:null,
    addAbellMetaDetailsLog: false,
  };
  
  export default function AbellReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;
  
    switch (type) {

        case UPDATE_META_DETAILS:
          return{
              ...state,
              addAbellMetaDetailsLog: !state.addAbellMetaDetailsLog,
        };

        case GET_ABELL_META_DETAILS:
          return{
              ...state,
              abellMetaDetails: payload.data,
        };

        case RESET_STATE:
        return { ...INIT_STATE };

        default:
        return state;
    }
  };
  