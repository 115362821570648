import { useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import OutlinedInput from '@mui/material/OutlinedInput';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button, TextField } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// Using for Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  customTable: {
    "& .MuiTableCell-root": {
      padding: "9px" // <-- arbitrary value
    }
  },
  dialogPaper: {
    width: '100%',
  },
});
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function UserMoreMenu(props) {
  const classes = useStyles()
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const permissions = props.userPermissions;
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleAssignWardsDialog = () => {
    setIsOpen(false)
    props.handleAssignWardsDialog();
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} style={{ color: '#000' }} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          component={RouterLink}
          to={`/dashboard/edit-manager/${props.userId}`}
          sx={{ color: 'text.secondary' }}
          onClick={handleClose}
        >
          <ListItemIcon style={{ color: '#214c50' }}>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        {/* <MenuItem
          component={RouterLink}
          to={`/dashboard/ViewAssociateUser/${props.userId}`}
          sx={{ color: 'text.secondary' }}
          onClick={handleClose}
        >
          <ListItemIcon style={{ color: '#214c50' }}>
            <Iconify icon="ep:view" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="View Wards" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={handleAssignWardsDialog}
        >
          <ListItemIcon style={{ color: '#214c50' }}>
            <Iconify icon="clarity:assign-user-solid" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Assign Wards"  primaryTypographyProps={{ variant: 'body2' }} onClick={props.handleClickOpen} />
          {/* <Button sx={{ boxShadow: 0 }}
            id={props.userId}
            variant="raised" style={{ fontWeight: 200, backgroundColor: 'transparent', padding: 0 }}
            onClick={handleAssignWardsDialog}>
            Assign Wards
          </Button> */}
        </MenuItem>
        {/* <Dialog open={props.open} fullWidth
          maxWidth="sm">
          <BootstrapDialogTitle id="customized-dialog-title" className="p-2" onClose={props.handleClickClose}>
            Assign Wards
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <FormControl style={{ width: '100%', margin: '3% 0px' }}>
              <InputLabel id="demo-multiple-name-label">Council</InputLabel>
              <Select
                fullWidth
                displayEmpty
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                value={props.showCouncil}
                onChange={props.onChangeCouncil}
                input={<OutlinedInput label="Associate User" />}
                MenuProps={MenuProps}
              >
                {props.council?.map((option) => (
                  <MenuItem key={option.value} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: '100%', margin: '3% 0px' }}>
              <InputLabel id="demo-multiple-name-label">Wards</InputLabel>
              <Select
                fullWidth
                displayEmpty
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={props.addedBy}
                onChange={props.onChange}
                input={<OutlinedInput label="Wards" />}
                MenuProps={MenuProps}
              >
                {props.wards?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClickSave}>Save</Button>
            <Button onClick={props.handleClickClose}>Cancel</Button>
          </DialogActions>
        </Dialog> */}

      </Menu>
    </>
  );
}
