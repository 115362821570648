import { GET_ALL_SELFIE, GET_PENDING_SELFIE, RESET_STATE, UPDATE_PENDING_SELFIE_STATUS } from "../actions/Types";
    
    const INIT_STATE = {
      selfies:null,
      pendingSelfie:[],
      updatePendingSelfieLog:false,
      pageInfo:{},
    };
    
    export default function SelfieVerificationReducer (state = INIT_STATE, action)  {
      const { type, payload } = action;
    
      switch (type) {
          case GET_ALL_SELFIE:
            return{
                ...state,
                selfies: payload.data.data,
                pageInfo: payload.data
          };
  
          case GET_PENDING_SELFIE:
            return{
                ...state,
                pendingSelfie: payload,
                pageInfo: payload
          };
  
          case UPDATE_PENDING_SELFIE_STATUS:
              return{
                  ...state,
                  updatePendingSelfieLog: !state.updatePendingSelfieLog,
          };

          case RESET_STATE:
          return { ...INIT_STATE };
  
          default:
          return state;
      }
    };
    