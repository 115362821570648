import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, OutlinedInput, Grid, TextField, InputLabel, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'react-select';
// component
import moment from 'moment';
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  backgroundColor: '#F8F8F8 !important',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

CallLogToolBar.propTypes = {
  callType: PropTypes.string,
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  placeHolder: PropTypes.string,
  handleCoucilChange: PropTypes.func,
  handleWardChange: PropTypes.func,
  handleZoneChange: PropTypes.func,
  coucilId: PropTypes.any,
  zoneId: PropTypes.any,
  wardId: PropTypes.any,
  userName: PropTypes.any,
};

export default function CallLogToolBar({ numSelected, toDate, fromDate, handleToDate, handleFromDate, userName, options, handleUserNameChange , handleExportCallLogReport}) {
  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();
  const todayDate = moment(new Date()).format('DD-MM-YYYY');
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}>
      <Grid container justifyContent="flex-end">
        <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" />
          </IconButton>
        </Tooltip>

        <Grid item xs={2.7}>
          <TextField
            id="fromDate"
            value={fromDate}
            type="datetime-local"
            label="From Date Time"
            placeholder="Date"
            style={{ width: '95%', height: 20 }}
            onChange={handleFromDate}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: todayDate }}
          />
        </Grid>
        <Grid item xs={2.7}>
          <TextField
            id="toDate"
            value={toDate}
            type="datetime-local"
            label="To Date Time"
            placeholder="Date"
            style={{ width: '95%', height: 20 }}
            onChange={handleToDate}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{ max: todayDate }}
          />
        </Grid>

        <Grid item sm={2.7} style={{ position: 'relative' }}>
          <Select
            id="User"
            placeholder="User name"
            label="User name"
            value={userName.label}
          
            className="react-select-container"
            styles={{
              control: (base) => ({
                ...base,
                // border: `1px solid gray`,
                width: '200px',
                marginLeft: '0px',
                height: '55px',
                borderRadius: '5px',
              }),
              menuPortal: (base) => ({
                ...base,
                // border: `1px solid gray`,
                width: '50%',
                marginLeft: '40px',
                height: '50%',
                borderRadius: '5px',
                backgroundColor: 'gray',
              }),
            }}
            options={options}
            onChange={handleUserNameChange}
            
            
          />
          <InputLabel
            id="users-select-label"

            style={{
              position: 'absolute',
              top: -8,
              zIndex: 999,
              left: 30,
              color: '#214c50',
              fontSize: 12,
              background: '#FFF',
              paddingLeft: 5,
              paddingRight: 5,
            }}>
            Users
          </InputLabel>
        </Grid>

        <Grid item sm={2.7} justifyContent="flex-end" style={{ marginLeft: 30,marginRight: -10 }}>
          <Button
            variant="contained"
            onClick={ handleExportCallLogReport}
            style={{ width: '100%', height: 55, backgroundColor: '#DF6526' }}
          >
            Export Report
          </Button>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
