import { CREATE_TRAINING, DELETE_TRAINING, GET_TRAINING, RESET_STATE, UPDATE_TRAINING } from "../actions/Types";
  
  const INIT_STATE = {
    trainings:null,
    addTrainingLog:false,
    editTrainingLog:false,
    deleteTrainingLog:false,
    pageInfo:{}
  };
  
  export default function TrainingReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;
  
    switch (type) {
        case GET_TRAINING:
          return{
              ...state,
              trainings: payload.data.data,
              pageInfo: payload.data
        };
        case CREATE_TRAINING:
            return{
                ...state,
                addTrainingLog: !state.addTrainingLog,
        };
        case UPDATE_TRAINING:
            return{
                ...state,
                editTrainingLog: !state.editTrainingLog,
        };
        case DELETE_TRAINING:
            return{
                ...state,
                deleteTrainingLog: !state.deleteTrainingLog,
        };
        case RESET_STATE:
        return { ...INIT_STATE };

        default:
        return state;
    }
  };
  