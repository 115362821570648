import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Pagination,
  Link,
  IconButton,
  Grid,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
// import ReportListToolbar from './../../sections/@dashboard/tree/ReportListToolbar';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility } from '@mui/icons-material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import autoTable from 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import ByTreeWardGraph from './ByTreeWardGraph';
import ByTreeNameGraph from './ByTreeNameGraph';
import ByTreeTypeGraph from './ByTreeTypeGraph';
import ByTreeConditionGraph from './ByTreeConditionGraph';

import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { GetReports } from '../../actions/ReportsAction';
import { GetActiveCouncil } from '../../actions/CouncilAction';
import { GetZonesByCouncilId } from '../../actions/ZonesAction';
import { GetWardsByCouncilId } from '../../actions/WardsActions';
import ReportListToolbar from '../../sections/@dashboard/tree/ReportListToolbar';
import ByWardReports from './ByWardReports';
import ByTreeNameReports from './ByTreeNameReports';
import ByTreeTypeReports from './ByTreeTypeReports';
import ByTreeConditionReports from './ByTreeConditionReports';
import OverallCountGraph from './OverallCountGraph';
import { ShowLoader, ShowLoadingButton } from '../../actions/CommonAction';
import { TopDataPieChart } from '../Dashboardsection/TopDataPieChart';
import FullLoader from '../../components/Loader/FullLoader';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Reports() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [open, setOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  // const [search,setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [stateName, setStateName] = useState('');
  const [zoneId, setZoneId] = useState('');
  const [wardId, setWardId] = useState('');
  const [coucilId, setCouncilId] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [imageList, setImageList] = useState([]);
  const [showList, setShowList] = useState(false);
  const [qcDialogOpen, setQcDialogOpen] = useState(false);
  const [baseColorId, setBaseColorId] = useState('');
  const [value, setValue] = useState('1');
  const [councilName, setCouncilName] = useState('');
  const [showReport, setShowReport] = useState(false);
  const userPermissions = [];
  const inputRef = useRef(null);
  const inputReftree = useRef(null);
  const inputRefType = useRef(null);
  const inputRefConditon = useRef(null);
  const inputRefOverallCount = useRef(null);
  const inputRefPropertyType = useRef(null);
  const inputRefLocationType = useRef(null);
  const inputRefWardWiseTree = useRef(null);
  const logoReftree = useRef(null);

  const {
    council,
    zones,
    wards,
    reports,
    loggedUser,
    showLoader,
    showLoadingButton
    // editBaseColorTreesLog,
    // deleteBaseColorTreesLog,
    // updateQCStatusLog,
    // pageInfo
  } = useSelector((state) => ({
    council: state.council.activeCouncil,
    reports: state.reports.reports,
    loggedUser: state.auth.loggedUser,
    showLoader: state.common.showLoader,
    showLoadingButton: state.common.showLoadingButton,
  }));
  // console.log('reports', reports, 'council', council,'loggedUser', loggedUser);

  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  const secondRun = React.useRef(true);
  // const image = ()=>{
  //   console.log('inputRef.current',inputRef.current)
  //    html2canvas(inputRef.current).then((canvas) => {

  //     return canvas.toDataURL("image/png");
  //   })
  // }

  const wardImage = async () => {
    // console.log('WardImage', inputRef.current);
    return inputRef.current;
  };
  const treeImage = async () => {
    // console.log('treeImage', inputReftree.current);
    return inputReftree.current;
  };

  const logoImage = async () => {
    // console.log('treeImage', inputReftree.current);
    return logoReftree.current;
  };

  const typeImage = async () => {
    // console.log('typeImage', inputRefType.current);
    return inputRefType.current;
  };
  const conditionImage = async () => {
    // console.log('conditionImage', inputRefConditon.current);
    return inputRefConditon.current;
  };

  const propertyImage = async () => {
    // console.log('conditionImage', inputRefConditon.current);
    return inputRefPropertyType.current;
  };

  const locationImage = async () => {
    // console.log('conditionImage', inputRefConditon.current);
    return inputRefLocationType.current;
  };

  const wardWiseImage = async () => {
    // console.log('conditionImage', inputRefConditon.current);
    return inputRefWardWiseTree.current;
  };

    const wardTreeImage = async () => {
    // console.log('conditionImage', inputRefConditon.current);
    return inputRefWardWiseTree.current;
  };

  const overallCount =async()=>{
    return inputRefOverallCount.current;
  }


  useEffect(() => {

   dispatch(ShowLoadingButton(false));
  }, []);

  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    setShowList(true);
  }, []);
  // console.log("reports", reports)
  // console.log("council", council)

  const separateId = (id) => {
    council.map((value, index) => {
      if (value.id === id) {
        setCouncilName(value.name);
      }
      return null;
    });
  };

  useEffect(() => {
    dispatch(GetActiveCouncil(1));
    // dispatch(GetBaseColorTreeById(1));
  }, []);
  // useEffect(()=>{
  //   dispatch(GetCouncil(1,1000));
  //   // dispatch(GetBaseColorTreeById(1));
  // },[])

  // useEffect(()=>{
  //   if(pageInfo){
  //     setCount(pageInfo?.total)
  //   }
  // },[pageInfo])

  function handleClick(event) {
    event.preventDefault();
    // console.info('You clicked a breadcrumb.');
  }

  // let timer = null;
  // const filterByName = (event) => {
  //   const {value} = event.currentTarget;
  //   clearTimeout(timer);
  //   // Wait for X ms and then process the request
  //   timer = setTimeout(() => {
  //         dispatch(GetReports(coucilId ));
  //         setShowList(false)
  //         // setSearch(false);

  //         // setSearchValue("")

  //   }, 1000);

  // }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCoucilChange = (value) => {
    setCouncilName(value);
    setShowReport(false);
  };

  const handleViewReport = () => {
    setShowReport(true);
  };

  const hideReport = () => {
    setShowReport(false);
  };

  const dataValue = reports?.by_wards;
  const value1 = [];
  dataValue?.map((option, index) => {
    const value2 = [index + 1];
    value2.push(option.name);
    value2.push(option.census_trees_count);
    value1.push(value2);
    return null;
  });

  const TreeName = reports?.by_tree_names;
  const treeNameValue1 = [];
  TreeName?.map((option, index) => {
    const treeNameValue2 = [index + 1];
    treeNameValue2.push(option.name);
    treeNameValue2.push(option.census_trees_count);
    treeNameValue1.push(treeNameValue2);
    return null;
  });

  const treeType = reports?.by_tree_types;
  const treeType1 = [];
  treeType?.map((option, index) => {
    const treeType2 = [index + 1];
    treeType2.push(option.tree_type);
    treeType2.push(option.census_trees_count);
    treeType1.push(treeType2);
    return null;
  });

  const TreeCondition = reports?.by_tree_conditions;
  const TreeCondition1 = [];
  TreeCondition?.map((option, index) => {
    const TreeCondition2 = [index + 1];
    TreeCondition2.push(option.condition);
    TreeCondition2.push(option.census_trees_count);
    TreeCondition1.push(TreeCondition2);
    return null;
  });

  const exportPdf = () => {
    // eslint-disable-next-line new-cap
    const doc = new JsPDF();
    doc.text('Kalmeshwar Muncipal Council', 20, 10);
    // doc.({`${council?.name}`})
    // doc.text1("Council Name : ", 20, 10);
    doc.addPage();
    doc.text('By Wards', 20, 10);
    autoTable(doc, {
      text: ('By Ward', 20, 10),
      head: [['#', 'Tree Names', 'Counts']],
      body: value1,
    });

    autoTable(doc, {
      text: ('By Tree Name', 20, 10),
      head: [['#', 'Tree Names', 'Counts']],
      body: treeNameValue1,
    });

    autoTable(doc, {
      text: ('By Tree Type', 20, 10),
      head: [['#', 'Tree Types', 'Counts']],
      body: treeType1,
    });

    autoTable(doc, {
      text: ('By Tree Condition', 20, 10),
      head: [['#', 'Tree Conditions', 'Counts']],
      body: TreeCondition1,
    });

    doc.save(`${councilName}.pdf`);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();
  // console.log("reports1", reports?.by_wards)
  // const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  // loader
  const loderRun = React.useRef(true);
  // console.log("loderRun from council",loderRun)
  useEffect(() => {
    if (loderRun.current) {
      loderRun.current = false;
      return;
    }
    console.log("rEPortDATA",reports);
    dispatch(ShowLoader(false));
  }, [reports]);

  return (
    <Page title="User">
      <FullLoader showLoader={showLoadingButton} />
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <div role="presentation" onClick={handleClick}>
            <Breadcrumbs
              aria-label="breadcrumb"
              style={{ color: '#000000', fontWeight: 900, fontSize: '20px' }}
              separator=":">
              <Typography variant="h4" gutterBottom style={{ color: '#000000' }}>
                Census
              </Typography>
              <Typography variant="h4" gutterBottom style={{ color: '#000000', fontWeight: 400 }}>
                Reports
              </Typography>
            </Breadcrumbs>
            <Typography variant="h6" style={{ fontSize: '18px', fontWeight: '400', marginTop: '-8px' }}>
              It will show census report
            </Typography>
          </div>
        </Stack>
        <Card>
          <Scrollbar>
            <ReportListToolbar
              handleCouncil={(e) => separateId(e)}
              handleCoucilChange={(e) => handleCoucilChange(e)}
              wardImage={wardImage}
              typeImage={typeImage}
              treeImage={treeImage}
              logoImage = {logoImage}
              conditionImage={conditionImage}
              propertyImage = {propertyImage}
              locationImage = {locationImage}
              wardWiseImage = {wardWiseImage}
              overallCount={overallCount}
              handleViewReport={(e) => handleViewReport(e)}
              hideReport={(e) => hideReport(e)}
              reportData = {reports}
              showReport={showReport}
            />

            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="By Wards" value="1" sx={{ color: '#000' }} />
                        <Tab label="By Tree Names" value="2" sx={{ color: '#000' }} />
                        <Tab label="By Tree Types" value="3" sx={{ color: '#000' }} />
                        <Tab label="By Tree Conditions" value="4" sx={{ color: '#000' }} />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      {!councilName ? (
                        <div align="center" fontWeight={700}>
                          Please select council to get data
                        </div>
                      ) : showReport ? (
                        <>
                          {showLoader ? (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                                height: '100%',
                                padding: '30px',
                              }}>
                              <CircularProgress style={{ color: '#214c50', paddding: '10px' }} />
                            </div>
                          ) : (
                            <ByWardReports data={reports?.by_wards ? reports?.by_wards : []} />
                          )}
                        </>
                      ) : null}
                    </TabPanel>
                    <TabPanel value="2">
                      {!councilName ? (
                        <div align="center" fontWeight={700}>
                          Please select council to get data
                        </div>
                      ) : showReport ? (
                        <>
                          {showLoader ? (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                                height: '100%',
                                padding: '30px',
                              }}>
                              <CircularProgress style={{ color: '#214c50', paddding: '10px' }} />
                            </div>
                          ) : (
                            <ByTreeNameReports data={reports?.by_tree_names ? reports?.by_tree_names : []} />
                          )}
                        </>
                      ) : null}
                    </TabPanel>
                    <TabPanel value="3">
                      {!councilName ? (
                        <div align="center" fontWeight={700}>
                          Please select council to get data
                        </div>
                      ) : showReport ? (
                        <>
                          {showLoader ? (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                                height: '100%',
                                padding: '30px',
                              }}>
                              <CircularProgress style={{ color: '#214c50', paddding: '10px' }} />
                            </div>
                          ) : (
                            <ByTreeTypeReports data={reports?.by_tree_types ? reports?.by_tree_types : []} />
                          )}
                        </>
                      ) : null}
                    </TabPanel>
                    <TabPanel value="4">
                      {!councilName ? (
                        <div align="center" fontWeight={700}>
                          Please select council to get data
                        </div>
                      ) : showReport ? (
                        <>
                          {showLoader ? (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: 'auto',
                                height: '100%',
                                padding: '30px',
                              }}>
                              <CircularProgress style={{ color: '#214c50', paddding: '10px' }} />
                            </div>
                          ) : (
                            <ByTreeConditionReports
                              data={reports?.by_tree_conditions ? reports?.by_tree_conditions : []}
                            />
                          )}
                        </>
                      ) : null}
                    </TabPanel>
                  </TabContext>
                </Box>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      {!councilName ? (
        <></>
      ) : showReport ? (
        <div>
         
          <Grid container spacing={0}>
            <Grid item xs={3} />

            <Grid item xs={6} style={{ position: 'absolute', clipPath: 'inset(0 100% 0 0)' }}>
              <div ref={inputRef}>
                <TopDataPieChart projects={reports?.by_top_8_wards ? reports?.by_top_8_wards.slice(0,8) : []} data={reports?.by_wards ? reports?.by_wards : []} slug={'Wards'} heading={"Ward"} />
              </div>
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={3} />

            <Grid
              item
              xs={6}
              style={{ position: 'absolute', width: '70%', height: '20%', clipPath: 'inset(0 100% 0 0)' }}>
              <div ref={inputReftree}>
                <TopDataPieChart projects={reports?.by_top_8_tree_names ? reports?.by_top_8_tree_names.slice(0,8) : []} heading={"Tree Name"}/>
              </div>
              
            </Grid>
            <Grid item xs={3} />
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={3} />
           
            <Grid
              item
              xs={6}
              style={{ position: 'absolute', width: '70%', height: '20%', clipPath: 'inset(0 100% 0 0)' }}>
                 <div id="myCanvas" />
              <div ref={inputRefType}>
                <TopDataPieChart projects={reports?.by_tree_types ? reports?.by_tree_types.slice(0,8) : []} heading={"Tree Types"}/>
              </div>
            </Grid>
            <Grid item xs={3} />
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3} />

            <Grid
              item
              xs={6}
              style={{ position: 'absolute', width: '70%', height: '20%', clipPath: 'inset(0 100% 0 0)' }}>
              <div ref={inputRefConditon}>
                <TopDataPieChart projects={reports?.by_tree_conditions ? reports?.by_tree_conditions.slice(0,8) : []} heading={'Tree Condition'}/>
              </div>
            </Grid>
            <Grid item xs={3} />
          </Grid>

        <Grid container spacing={0}>
            <Grid item xs={3} />

            <Grid
              item
              xs={6}
              style={{ position: 'absolute', width: '70%', height: '20%', clipPath: 'inset(0 100% 0 0)' }}>
              <div ref={inputRefPropertyType}>
                <TopDataPieChart projects={reports?.by_property_types ? reports?.by_property_types.slice(0,8) : []} heading={'Property Types'}/>
              </div>
              <div >
          <img ref={logoReftree} src={`${reports?.council_details?.logo}`} id="co_logo" alt="co_logo" style={{height:'200px',width:'200px'}} />
          <canvas id="logoCanvas" />
              </div>
            </Grid>
            <Grid item xs={3} />
          </Grid>

          <Grid container spacing={0}>
            <Grid item xs={3} />

            <Grid
              item
              xs={6}
              style={{ position: 'absolute', width: '70%', height: '20%', clipPath: 'inset(0 100% 0 0)' }}>
              <div ref={inputRefLocationType} id="locationTypes">
                <TopDataPieChart projects={reports?.by_location_types ? reports?.by_location_types.slice(0,8) : []} heading={'Location Type'}/>
              </div>
            </Grid>
            <Grid item xs={3} />
          </Grid>

          {
                    reports?.total_trees_by_tree_name_ward ?
                      Object.entries(reports?.total_trees_by_tree_name_ward).map((item) => (
                        
                        <Grid container spacing={0}>
                        <Grid item xs={3} />
            
                        <Grid
                          item
                          xs={6}
                          style={{ position: 'absolute', width: '70%', height: '20%', clipPath: 'inset(0 100% 0 0)' }}>
                          <div ref={inputRefWardWiseTree} id={item[0]}>
                            <TopDataPieChart projects={item[1].slice(0, 8)} heading={item[0]}/>
                          </div>
                        </Grid>
                        <Grid item xs={3} />
                      </Grid>
                      )
                      )

                      :
                      null
                  }

          



          <Grid container spacing={0}  style={{ position: 'absolute', width: '70%', height: '20%', clipPath: 'inset(0 100% 0 0)' }}>
            <Grid item xs={3} />
            <Grid item
              xs={6}
             
             >
              <div ref={inputRefOverallCount}>
                <OverallCountGraph/>
              </div>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </div>
      ) : null}
    </Page>
  );
}
