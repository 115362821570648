import React, { useState, useEffect } from 'react';
import {
  CardActionArea,
  CardMedia,
  Card,
  CardContent,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Typography,
  CircularProgress,
  IconButton,
  Link,
  Chip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { green, orange, red, gray } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { format } from 'date-fns';

const ProclamationApplicationDetails = ({ applicationDetailsData, showLoader }) => {
  console.log('applicationDetailsData1998', applicationDetailsData);
  return (
    <>
      <Box>
        {showLoader ? (
          <Card sx={{ margin: '15px', padding: '20px' }}>
            <Grid container>
              <Grid item lg={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
                <CircularProgress />
              </Grid>
            </Grid>
          </Card>
        ) : (
          applicationDetailsData?.newspaper_proclamations.map((info) => {
            return (
              <Card sx={{ margin: '15px', padding: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Newspaper Name</Typography>
                    <Typography> {info.newspaper.newspaper}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Publish Date </Typography>
                    <Typography>     {info?.published_date ? format(new Date(info.published_date), 'MMMM d, yyyy') : ''}</Typography> 
                    
                  </Grid>

                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}> Waiting Days For Objection</Typography>
                    <Typography>{info.waiting_days_for_objections} Days</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Fees</Typography>
                    <Box sx={{display:'flex',flexDirection:"row"}}>
                    <Typography>
                      {' '}
                      {'\u20B9'}
                      {info.fees}
                    </Typography>

                    {info?.fees_status === "Unpaid" ? (
                      <Chip
                        label="Unpaid"
                        size="small"
                        style={{
                          marginLeft: '10px',
                          fontWeight: '600',
                          padding: '1px 10px',
                          backgroundColor: red[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: red[800],
                          },
                        }}
                      />
                    ) : (
                      <Chip
                        label="Paid"
                        size="small"
                        style={{
                          marginLeft: '10px',
                          fontWeight: '400',
                          padding: '1px 10px',
                          backgroundColor: green[600],
                          color: 'white',
                          '&:hover': {
                            backgroundColor: green[800],
                          },
                        }}
                      />
                    )}
                     </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Newspaper Image</Typography>

                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${info.image}`}
                      alt="abc"
                      style={{
                        width: '300px',
                        height: '200px',
                        borderRadius: '20px',
                      }}
                    />

                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Fees Receipt </Typography>
                    <img src={`${process.env.REACT_APP_BASE_URL}/${info.fees_receipt}`} 
                    alt="abc"
                    style={{
                      width: '300px',
                      height: '200px',
                      borderRadius: '20px',
                    }} />
                  </Grid>
                </Grid>

                <Divider sx={{ marginY: '15px' }} />

                <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection By User</Typography>
                    <Typography>{info?.objection_by_user}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection Date</Typography>
                    <Typography>{info?.objection_date}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection Received</Typography>
                    <Typography>{info?.objection_received}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={{ fontWeight: '700' }}>Objection Document </Typography>
                    <img src={`${process.env.REACT_APP_BASE_URL}/${info.objection_document}`} 
                    alt="abc"
                    style={{
                      width: '300px',
                      height: '200px',
                      borderRadius: '20px',
                    }} />
                  </Grid>
                </Grid>
              </Card>
            );
          })
        )}
      </Box>
    </>
  );
};

export default ProclamationApplicationDetails;
