import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import moment from 'moment';
import Stack from '@mui/material/Stack';

import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Visibility } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Container, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GetUsers } from '../../../actions/UserAction';
import { AddUserToTeam } from '../../../actions/TeamsAction';
import { GetQcRemarksForBaseColor } from '../../../actions/BaseColorAction';
import ViewImageDialog from '../tree-data/ViewImageDialog';
import Iconify from '../../Iconify';
import ImageCarousel from '../../ImageCarousel';
import { GetCouncilMetaDetails,UpdateCouncilMetaDetails } from "../../../actions/CouncilAction";
import { UploadCouncilImage, UploadImage } from "../../../actions/UploadActions";
// import Typography from 'src/theme/overrides/Typography';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));
export default function MetaDetailsDialog(props) {
  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, data, isOpenConfirm, teamId } = props;
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [state, setState] = React.useState('');
  const [role, setRole] = React.useState([]);
  const [topModalOpen, setTopModalOpen] = React.useState(false);
  const [reqObj, setReqObj] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [viewOpen, setViewOpen] = React.useState(false);
  const [imageList, setImageList] = React.useState([]);
  const [openImageList, setOpenImageList] = React.useState(false);
  const [metaValueError, setMetaValueError] = React.useState('');
  const [fileUploadError, setFileUploadError] = useState('');
  const [fileSizeError, setFileSizeError] = useState('');
  const [uploadClick, setUploadClick] = useState('');
  const [filePath, setFilePath] = useState(null);
  const [uploadClickError, setUploadClickError] = useState('');
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);
  const councilMetaType = [
    {
        "id": "Office Supretendent"
    },
    {
        "id": "Tree officer",
    },
    {
        "id": "Construction Department Officer",
    },
    {
        "id": "Garden Department Officer",
    },
    {
        "id": "Garden Supervisor",
    },
    {
        "id": "Health Invigilator",
    },
    {
        "id": "Health Department Clerk",
    },
    {
        "id": "Engineer",
    },
    {
        "id": "Junior Engineer",
    },
    {
        "id": "History Of Council",
    }
]
const userCouncilImageType = [
  {
    "id" : "image_url_1",
    "value" : "Image 1"
  },
  {
    "id" : "image_url_2",
    "value" : "Image 2"
  },
]
  const [metaList, setMetaList] = useState([
    { metaName: '', metaValue: '', errorName: '', errorValue: '' },
  ]);

  const [councilImageList, setCouncilImageList] = useState([
    { imageName: '', imageValue: '', errorName: '', errorValue: '' },
  ]);

  const userPermissions = [];

  const todayDate = moment(new Date()).format('YYYY-MM-DD');

  const {
    councilMetaDetails,
    loggedUser,
    uploadImage,
    uploadImageLog,
    addCouncilMetaDetailsLog
  } = useSelector((state) => ({
    councilMetaDetails: state.council.councilMetaDetails,
    loggedUser: state.auth.loggedUser,
    uploadImage: state.upload.uploadImage,
    uploadImageLog: state.upload.uploadImageLog,
    addCouncilMetaDetailsLog : state.council.addCouncilMetaDetailsLog
  }));
  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  React.useEffect(() => {
    if (data && isOpen) {
      dispatch(GetCouncilMetaDetails(data.id));
    //   setIsEditable(true);
    setMetaList([{ metaName: '', metaValue: '', errorName: '', errorValue: '' }]);
    }
  }, [data]);

  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (councilMetaDetails) {
      seprateMeta(councilMetaDetails);
      separateCouncilImage(councilMetaDetails)
    }
  }, [councilMetaDetails]);

  const thirdRun = React.useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    if (uploadImage) {
      const newCouncilImageList = [...councilImageList];
      const value = newCouncilImageList[uploadImage.index];
      value.imageValue = uploadImage.url;
      newCouncilImageList[uploadImage.index] = value;
      setCouncilImageList(newCouncilImageList);
    }
  }, [uploadImageLog]);

  const forthRun = React.useRef(true);
  useEffect(() => {
    if (forthRun.current) {
      forthRun.current = false;
      return;
    }
    props.handleClose();
  }, [addCouncilMetaDetailsLog]);

  const handleClose = () => {
    props.handleClose();
  };


  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen);
    setImageList(images || []);
  };


  const seprateMeta = (meta) => {
    const metaList = [];

    if (meta.length === 0) {
      const infoToAdd = {
        metaName: '',
        metaValue: '',
        errorName: '',
        errorValue: '',
      };
      metaList.push(infoToAdd);
    } else {
      let notFound = true;
      meta.map((value, index) => {
        if(value.key !== 'image_url_1' && value.key !== 'image_url_2'){
          notFound = false;
          const infoToAdd = {
            metaName: value.key,
            metaValue: value.value,
            errorName: '',
            errorValue: '',
          };
          metaList.push(infoToAdd);
        }
        
        return null;
      });
      if(notFound){
        const infoToAdd = {
          metaName: '',
          metaValue: '',
          errorName: '',
          errorValue: '',
        };
        metaList.push(infoToAdd);
      }
    }
    
    setMetaList(metaList);
  };

  const separateCouncilImage = (councilImage) => {
    const councilImageList = [];

    if (councilImage.length === 0) {
      const infoToAdd = {
        imageName: '',
        imageValue: '',
        errorName: '',
        errorValue: '',
      };
      councilImageList.push(infoToAdd);
    } else {
      let notFound = true;
      councilImage.map((value, index) => {
        if(value.key === 'image_url_1' || value.key === 'image_url_2'){
          notFound = false;
          const infoToAdd = {
            imageName: value.key,
            imageValue: value.value,
            errorName: '',
            errorValue: '',
          };
          councilImageList.push(infoToAdd);
        }
        
        return null;
      });
      if(notFound){
        const infoToAdd = {
          imageName: '',
          imageValue: '',
          errorName: '',
          errorValue: '',
        };
        councilImageList.push(infoToAdd);
      }
    }
    setCouncilImageList(councilImageList);
  };

  const metaLength = metaList.length;
  const councilImageLength = councilImageList.length;

  const handleMetaButtonClick = (value, index) => {
    if (value === 'add') {
      const newMetaList = [...metaList];
      if(metaList[index].metaName!=="" && metaList[index].metaValue!==""){
        const infoToAdd = {
          metaName: '',
          metaValue: '',
          errorName: '',
          errorValue: '',
        };
        newMetaList.push(infoToAdd);
        setMetaList(newMetaList);
      }
      else {
        const value = newMetaList[index];
        if(metaList[index].metaName===""){
          value.errorName = "This field is required";
        }
        if(metaList[index].metaValue===""){
          value.errorValue = "This field is required";
        }
        newMetaList[index] = value;
        setMetaList(newMetaList);
      }
      
    } else if (value === 'delete') {
      const newMetaList = [...metaList];
      newMetaList.splice(index, 1);
      setMetaList(newMetaList);
    }
  };

  const handleMetaNameChange = (e, index) => {
    const newMetaList = [...metaList];
    const value = newMetaList[index];
    value.metaName = e.target.value;
    newMetaList[index] = value;
    setMetaList(newMetaList);
  };

  const handleMetaValueChange = (e, index) => {
    const regex = /^[0-9]*$/;

    const newMetaList = [...metaList];
    const value = newMetaList[index];
    value.metaValue = e.target.value;
    newMetaList[index] = value;
    setMetaList(newMetaList);

    // if (e.target.value.trim()) {
    //   // setMetaValueError('');
    //   const newMetaList = [...metaList];
    //   const value = newMetaList[index];
    //   value.metaValue = e.target.value;
    //   newMetaList[index] = value;
    //   setMetaList(newMetaList);
    // } 
    // else {
    //   setMetaValueError('Please Enter Value');
    // }
  };

  const handleCouncilImageButtonClick = (value, index) => {
    // console.log("HANDLE councilImage BUTTONVCLICKED CALLED");
    if (value === 'add') {
      const newCouncilImageList = [...councilImageList];
      if(councilImageList[index].imageName!=="" && councilImageList[index].imageValue!==""){
        const infoToAdd = {
          imageName: '',
          imageValue: '',
          errorName: '',
          errorValue: '',
        };
        newCouncilImageList.push(infoToAdd);
        setCouncilImageList(newCouncilImageList);
      }
      else {
        const value = newCouncilImageList[index];
        if(councilImageList[index].imageName===""){
          value.errorName = "This field is required";
        }
        if(councilImageList[index].imageValue===""){
          value.errorValue = "This field is required";
        }
        newCouncilImageList[index] = value;
        setCouncilImageList(newCouncilImageList);
      }
      
    } else if (value === 'delete') {
      const newCouncilImageList = [...councilImageList];
      newCouncilImageList.splice(index, 1);
      setCouncilImageList(newCouncilImageList);
    }
  };

  const handleCouncilImageNameChange = (e, index) => {
    const newCouncilImageList = [...councilImageList];
    const value = newCouncilImageList[index];
    value.imageName = e.target.value;
    newCouncilImageList[index] = value;
    // console.log("councilImage LIST",newCouncilImageList);
    setCouncilImageList(newCouncilImageList);
    setUploadClick(true);
    setUploadClickError('');
    // const isValid = /\.jpe?g$/i.test(e.target.value);
    // if (!isValid) {
    // console.log('Only jpg files allowed!');
    // }
    // console.log(isValid);
  };

  const handleViewCouncilImage = (fpath) => {
    if (fpath.includes(process.env.REACT_APP_BASE_URL)) {
      // console.log("file path", fpath);
      // window.open(fpath, '_blank');
      setOpenImageList(!openImageList);
    setImageList([fpath] || []);
    } else {
      const fLink = process.env.REACT_APP_BASE_URL.concat('/').concat(fpath);
      //  console.log("file path", fLink);
      // window.open(fLink, '_blank');
      setOpenImageList(!openImageList);
      setImageList([fLink] || []);
    }
  };

  const handleCouncilImageValueChange = (e, index) => {
    if (uploadClick) {
      // console.log("HANDLE DOCMENT VALUE CAHNGE",e.target.files[0])
      // console.log(e.target.files[0].name);
      // console.log(e.target.files[0].size);
      const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);
      // console.log("file size", i);
      const validExtensions = ['png', 'jpeg', 'jpg'];
      const fileExtension = e.target.files[0].name.split('.')[1];
      // console.log(fileExtension);
      if (validExtensions.includes(fileExtension)) {
        setFileUploadError('');
        if (e.target.files[0].size < 5242880) {
          setFileSizeError('');
          const formData = new FormData();
          formData.append('upload_for', 'councilmeta');
          formData.append('image', e.target.files[0]);
          dispatch(UploadCouncilImage(formData, index)).then((response) => {
            // console.log("upload file",response);
          });
          const newCouncilImageList = [...councilImageList];
          const value = newCouncilImageList[index];
          value.imageValue = e.target.value;
          // console.log(value.imageValue,"||||||")
          newCouncilImageList[index] = value;
          setCouncilImageList(newCouncilImageList);
          // console.log(e.target.value);
          setFilePath(e.target.value);
          // console.log(councilImageList);
        } else {
          setFileSizeError('Please upload councilImages within 5MB only');
        }
      } else {
        setFileUploadError('Please upload councilImages of .jpg .png .jpeg format only');

        // dispatch(SetNewAlert({
        //   msg: "Please upload images only with given format only",
        //   alertType: "danger",
        // }));
      }
    } else {
      setUploadClickError('Please Select CouncilImage Type First');
    }
  };

  const validateDropDown = () => {
    let validated = true;
    const foundMeta = false;
    let foundImage = false;

    // metaList.map((value, index) => {
    //   metaList.map((value2, index2) => {
    //     if (index2 !== index && value2.metaName === value.metaName) {
    //       const firstMetaList = [...metaList];
    //       const value1 = firstMetaList[index];
    //       value1.errorName = 'Same meta type not allowed';
    //       firstMetaList[index] = value1;
    //       const value2 = firstMetaList[index2];
    //       value2.errorName = 'Same meta type not allowed';
    //       firstMetaList[index2] = value2;
    //       setMetaList(firstMetaList);
    //       foundMeta = true;
    //       validated = false;
    //     }
    //     return null;
    //   });
    //   return null;
    // });

    councilImageList.map((value, index) => {
      councilImageList.map((value2, index2) => {
        if (index2 !== index && value2.imageName === value.imageName) {
          const firstImageList = [...councilImageList];
          const value1 = firstImageList[index];
          value1.errorName = 'Same image type not allowed';
          firstImageList[index] = value1;
          const value2 = firstImageList[index2];
          value2.errorName = 'Same image type not allowed';
          firstImageList[index2] = value2;
          setCouncilImageList(firstImageList);
          foundImage = true;
          validated = false;
        }
        return null;
      });
      return null;
    });

    // eslint-disable-next-line array-callback-return
    metaList.map((value, index) => {
      // console.log("VALUE IN VALIDATIONm",value);
      const conditionName = `metaName`;
      const conditionValue = `metaValue`;
      if (!foundMeta) {
        if (value[conditionName] === '') {
          validated = false;
          const newMetaList = [...metaList];
          const value = newMetaList[index];
          value.errorName = 'This field is required';
          newMetaList[index] = value;
          setMetaList(newMetaList);
        } else {
          const newMetaList = [...metaList];
          const value = newMetaList[index];
          value.errorName = '';
          newMetaList[index] = value;
          setMetaList(newMetaList);
        }
      }

      if (value[conditionValue] === '') {
        validated = false;
        const newMetaList = [...metaList];
        const value = newMetaList[index];
        value.errorValue = 'This field is required';
        newMetaList[index] = value;
        setMetaList(newMetaList);
      } else {
        const newMetaList = [...metaList];
        const value = newMetaList[index];
        value.errorValue = '';
        newMetaList[index] = value;
        setMetaList(newMetaList);
      }
    });

    // eslint-disable-next-line array-callback-return
    councilImageList.map((value, index) => {
      const conditionName = `imageName`;
      const conditionValue = `imageValue`;

      if (!foundImage) {
        if (value[conditionName] === '') {
          validated = false;
          const newImageList = [...councilImageList];
          const value = newImageList[index];
          value.errorName = 'This field is required';
          newImageList[index] = value;
          setCouncilImageList(newImageList);
        } else {
          const newImageList = [...councilImageList];
          const value = newImageList[index];
          value.errorName = '';
          newImageList[index] = value;
          setCouncilImageList(newImageList);
        }
      }
      if (value[conditionValue] === '') {
        validated = false;
        const newImageList = [...councilImageList];
        const value = newImageList[index];
        value.errorValue = 'This field is required';
        newImageList[index] = value;
        setCouncilImageList(newImageList);
      } else {
        const newImageList = [...councilImageList];
        const value = newImageList[index];
        value.errorValue = '';
        newImageList[index] = value;
        setCouncilImageList(newImageList);
      }
    });

    return validated;
  };
  

  const [showEdit, setShowEdit] = useState(false)
  const showEditableView = () => {
    // setShowEdit(!showEdit)
  }

  const DesignationsSchema = Yup.object().shape({
    // treeNumber: Yup.string().required('Tree number is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
    },
    validationSchema: DesignationsSchema,
    onSubmit: (value) => {
      if(validateDropDown()){
        const aaplicableMeta = [];
          metaList.map((value, index) => {
            const conditionName = `metaName`;
            const conditionValue = `metaValue`;
            const obj = {
              key: value[conditionName],
              value: value[conditionValue],
            };
            aaplicableMeta.push(obj);
            return null;
          });

          
          councilImageList.map((value, index) => {
            const conditionName = `imageName`;
            const conditionValue = `imageValue`;
            const obj = {
              key: value[conditionName],
              value: value[conditionValue],
            };
            aaplicableMeta.push(obj);
            return null;
          });

          dispatch(UpdateCouncilMetaDetails(aaplicableMeta,data.id))
      }
    }
 
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const reLast = /-\d+$/;

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open max-width dialog
      </Button> */}
      {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        sx={{minHeight:'400px'}}
      // onClose={handleClose}
      // onClose={handleClose}
      >
        <Box>
          <Stack justifyContent="space-between" direction="row"
            useFlexGap flexWrap="wrap">
            <BootstrapDialogTitle  onClose={handleClose}>Council Meta Details</BootstrapDialogTitle>
            
          </Stack>
        </Box>

        <Divider />
        <DialogContent>
          {MetaDetailsDialog ? (
            <div>
                          {metaList?.map((value, index) => (
              //  <Stack spacing={3} key={index}>
              //  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
              <Grid container spacing={4} key={index}>
                <Grid item xs={4.5}>
                  <TextField
                    select
                    fullWidth
                    id="metaType"
                    name="metaType"
                    label="Meta Type*"
                    style={{ marginBottom: '20px' }}
                    displayEmpty
                    // style={{width: '87%',}}
                    onChange={(e) => handleMetaNameChange(e, index)}
                    value={value.metaName}
                    error={Boolean(value.errorName)}
                    helperText={value.errorName}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>PF</em>;
                  //   }
                  //   return selected
                  // }}
                  >
                    <MenuItem disabled value="">
                      <em>Meta Type*</em>
                    </MenuItem>
                    {councilMetaType.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.id}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // style={{marginLeft: 20}}
                    id="panCard"
                    autoComplete="panCard"
                    label="Value"
                    placeholder="Enter Value"
                    multiline={value.metaName==="History Of Council"}
                    rows={2}
                    value={value.metaValue}
                    error={Boolean(value.errorValue)}
                    helperText={value.errorValue}
                    onChange={(e) => handleMetaValueChange(e, index)}
                    inputProps={{ maxLength: 2000 }}
                  // defaultValue={data? data.panCard: ""}
                  // name="contact"
                  // value="contact"
                  />
                  <Typography variant="body2" style={{ color: '#FF0000' }}>
                    {metaValueError}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton
                    color={index + 1 === metaLength ? 'success' : 'error'}
                    aria-label={index + 1 === metaLength ? 'add' : 'delete'}
                    size="large"
                    onClick={() => handleMetaButtonClick(index + 1 === metaLength ? 'add' : 'delete', index)}
                  >
                    {index + 1 === metaLength ? (
                      <AddCircleIcon fontSize="inherit" />
                    ) : (
                      <CancelIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            ))}

<Typography variant="h5" style={{ marginTop: 20, marginBottom: 20 }} gutterBottom>
              council Image
            </Typography>

{councilImageList?.map((value, index) => (
              //  <Stac spacing={3} key={index}>
              //  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
              <Grid container spacing={4} key={index}>
                <Grid item xs={4}>
                  <TextField
                    select
                    fullWidth
                    label="Council Image*"
                    id="aadharCard"
                    name="aadharCard"
                    value={value.imageName}
                    style={{ marginBottom: '20px' }}
                    displayEmpty
                    // style={{width: '110%'}}
                    onChange={(e) => handleCouncilImageNameChange(e, index)}
                    error={Boolean(value.errorName)}
                    helperText={value.errorName}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  // renderValue={(selected) => {
                  //   if (selected?.length === 0) {
                  //     return <em>Aadhar Card</em>;
                  //   }
                  //   return selected
                  // }}
                  >
                    <MenuItem disabled value="">
                      <em>Council Image*</em>
                    </MenuItem>
                    {userCouncilImageType?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6.5}>
                  {/* <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}> */}
                  {
                    value.imageValue ? (
                      <Button
                        variant="outlined"
                        target="_blank"
                        rel="noopener"
                        onClick={() => {
                          handleViewCouncilImage(value.imageValue);
                        }}
                        style={{ marginTop: '5px' }}
                      >
                        View Image
                      </Button>
                    ) : (
                      <>
                        <TextField
                          fullWidth
                          id="amount"
                          type={'file'}
                          autoComplete="amount"
                          style={{ marginBottom: 5 }}
                          placeholder="Choose file"
                          value={value.imageValue}
                          error={Boolean(value.errorValue)}
                          helperText={value.errorValue}
                          onChange={(e) => handleCouncilImageValueChange(e, index)}
                        />

                        <Typography variant="body2" color={'#FF0000'}>
                          {fileUploadError}
                        </Typography>
                        <Typography variant="body2" color={'#FF0000'}>
                          {fileSizeError}
                        </Typography>

                        <Typography variant="body2" color={'#FF0000'}>
                          {uploadClickError}
                        </Typography>
                        {/* <Typography variant="body2">
                          Supported Formats are .jpg, .jpeg, .png
                        </Typography> */}
                        {/* <Typography variant="body2">Supported councilImage size: 5MB</Typography> */}
                      </>
                    )
                     }
                  {/* </Stack> */}
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton
                    color={index + 1 === councilImageLength ? 'success' : 'error'}
                    aria-label={index + 1 === councilImageLength ? 'add' : 'delete'}
                    size="large"
                    onClick={() => handleCouncilImageButtonClick(index + 1 === councilImageLength ? 'add' : 'delete', index)}
                  >
                    {index + 1 === councilImageLength ? (
                      <AddCircleIcon fontSize="inherit" />
                    ) : (
                      <CancelIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            ))}

            </div>
          ) : null}
        </DialogContent>
        <Modal
          open={openImageList}
          onClose={handleCloseImageList}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Container style={{ width: '526px' }}>
            <ImageCarousel imagelist={imageList} />
          </Container>
          {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
        </Modal>
        <Divider />
        <DialogActions>
          
              <Button onClick={handleSubmit} color="success" variant="contained" style={{ color: '#fff' }}>
                Save
              </Button>
              
        </DialogActions>
      </Dialog>
    </div>
  );
}
