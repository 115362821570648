import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card, CardMedia, Box } from '@mui/material';
import PropTypes from 'prop-types';
import NewTreeDas from '../../Assets/NewTreeDash.webp'
import { AppWidgetSummary } from '../../sections/@dashboard/app';

import ProjectImg from '../../Assets/toplant_dashboard.png';
//   import Iconify from '../../../components/Iconify';
function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                // p: 1,
                // m: 1,
                // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                // border: '1px solid',
                // borderColor: (theme) =>
                //     theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                // borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};
const LightCard = (props) => {
    // const { count, title, subtitle } = props.projects;
    const useStyles = makeStyles({
        wrapper: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, auto)',
            padding: '10px 15px',
        },
        paddingClass: {
            padding: '10px 15px',

        },
        cardleftSection: {
            padding: '5px 20px 15px 7px',
            fontWeight: 600,
        },
        cardCount: {
            padding: '5px 5px 5px 5px',
            fontSize: '35px'
            // borderBottom: '1px solid #EEEEEE'
        },
        border: {
            borderBottom: '1px solid #EEEEEE',
            width: '60%'
        },
        borderRight: {
            borderRight: '1px solid'
        },
        borderAll: {
            border: '1px solid'
        }
    });
    const classes = useStyles();
    return (
        <Grid container spacing={1}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card style={{ height: '500' }}>
                        <CardMedia
                            component="img"
                            height="194"
                            image={NewTreeDas}
                            alt="Paella dish"
                        />
                        <Grid item xs={12}>
                            <Grid container rowSpacing={1}>
                                <Grid item xs={6} >
                                    <div className={classes.paddingClass}>
                                        <Box
                                            className={classes.borderRight}
                                            sx={{
                                                display: 'flex',
                                                // justifyContent: 'flex-end',
                                                // p: 1,
                                                // m: 1,
                                                // bgcolor: 'background.paper',
                                                // borderRadius: 1,
                                            }}
                                        >

                                            <Item>
                                                <div>
                                                    <h2 className={classes.cardCount}>
                                                        {props.projects?.active_team?.active_council_name}
                                                        <h6>{props.projects?.active_team?.team_name} | ZONE :- {props.projects?.active_team?.active_zone_name} | WARD :- {props.projects?.active_team?.active_ward_name}</h6>
                                                    </h2>
                                                    {/* </div> */}
                                                </div>
                                            </Item>
                                        </Box>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box
                                        // className={classes.borderRight}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            // p: 1,
                                            // m: 1,
                                            // bgcolor: 'background.paper',
                                            // borderRadius: 1,
                                        }}
                                    >

                                        <Item>
                                            <div>
                                                <h2 style={{ fontSize: '60px' }}>
                                                    {props.projects?.qc_count?.total_qc_count}
                                                </h2>
                                            </div>
                                        </Item>
                                        <Item>
                                            <div className={classes.paddingClass}>
                                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                                    {props.projects?.qc_count?.from_date}-{props.projects?.qc_count?.to_date}
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                                    Total QC Count
                                                </Typography>
                                                <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                                    It is showing total QC Count
                                                </Typography>
                                            </div>
                                        </Item>
                                        <Item>
                                            <div>
                                                <img src={ProjectImg} alt="project" height='100' width='100' style={{ marginTop: '10px' }} />
                                            </div>

                                        </Item>
                                    </Box>

                                </Grid>

                            </Grid>
                        </Grid>

                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LightCard;
