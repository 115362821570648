import { useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import OutlinedInput from '@mui/material/OutlinedInput';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Button, TextField } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
// Using for Dialog
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

    customTable: {
        "& .MuiTableCell-root": {
            padding: "9px" // <-- arbitrary value
        }
    },
    dialogPaper: {
        width: '100%',
    },
});
function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function UserMoreMenu(props) {
    const classes = useStyles()
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const permissions = props.userPermissions;
    const handleClose = () => {
        setIsOpen(false);
    };
    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Iconify icon="eva:more-vertical-fill" width={20} height={20} style={{ color: '#000' }} />
            </IconButton>

            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >

                <MenuItem
                    sx={{ color: 'text.secondary' }}
                >
                    <ListItemIcon style={{ color: '#214c50' }}>
                        <Iconify icon="clarity:assign-user-solid" width={24} height={24} />
                    </ListItemIcon>
                    {/* <ListItemText primary="Assign Associate" id={props.id}  primaryTypographyProps={{ variant: 'body2' }} onClick={props.handleClickOpen} /> */}
                    <Button sx={{ boxShadow: 0 }}
                        id={props.userId}
                        variant="raised" style={{ fontWeight: 200, backgroundColor: 'transparent', padding: 0 }}
                        onClick={props.handleClickOpen}>
                        Approve
                    </Button>
                    <Dialog open={props.open} fullWidth
                        maxWidth="sm">
                        <BootstrapDialogTitle id="customized-dialog-title" className="p-2" onClose={props.handleClickClose}>
                            Approve
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            Are you sure want to approve
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleClickSave}>Save</Button>
                            <Button onClick={props.handleClickClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </MenuItem>
                <MenuItem
                    sx={{ color: 'text.secondary' }}
                >
                    <ListItemIcon style={{ color: '#214c50' }}>
                        <Iconify icon="clarity:assign-user-solid" width={24} height={24} />
                    </ListItemIcon>
                    {/* <ListItemText primary="Assign Associate" id={props.id}  primaryTypographyProps={{ variant: 'body2' }} onClick={props.handleClickOpen} /> */}
                    <Button sx={{ boxShadow: 0 }}
                        id={props.userId}
                        variant="raised" style={{ fontWeight: 200, backgroundColor: 'transparent', padding: 0 }}
                        onClick={props.handleUnApproveClickOpen}>
                        Un-Approve
                    </Button>
                    <Dialog open={props.openUnaprrove} fullWidth
                        maxWidth="sm">
                        <BootstrapDialogTitle id="customized-dialog-title" className="p-2" onClose={props.handleUnApproveClickClose}>
                            Un-Approve
                        </BootstrapDialogTitle>
                        <DialogContent dividers>
                            Are you sure want to unapprove
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleUnApproveClickSave}>Save</Button>
                            <Button onClick={props.handleUnApproveClickClose}>Cancel</Button>
                        </DialogActions>
                    </Dialog>
                </MenuItem>
            </Menu>
        </>
    );
}
