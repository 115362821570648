import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import moment from 'moment';
import Stack from '@mui/material/Stack';

import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Visibility } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Container, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GetAbellMetaDetails, UpdateAbellMetaDetails } from "../../../actions/AbellAction";
// import Typography from 'src/theme/overrides/Typography';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));
export default function AbellDetailsDialog(props) {
  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, data, isOpenConfirm, teamId } = props;
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');

  const [metaValueError, setMetaValueError] = React.useState('');
  const councilMetaType = [
    {
        "id": "Administration"
    },
    {
        "id": "Project Cordinator",
    },
    {
        "id": "Taxonomists",
    },
    {
        "id": "GPS & GIS Expert",
    },
    {
        "id": "Field officer",
    }
]
  const [metaList, setMetaList] = useState([
    { metaName: '', metaValue: '', errorName: '', errorValue: '' },
  ]);


  const userPermissions = [];


  const {
    abellMetaDetails,
    loggedUser,
    addAbellMetaDetailsLog
  } = useSelector((state) => ({
    abellMetaDetails: state.abell.abellMetaDetails,
    loggedUser: state.auth.loggedUser,
    addAbellMetaDetailsLog : state.abell.addAbellMetaDetailsLog
  }));
  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  React.useEffect(() => {
    if (isOpen) {
      dispatch(GetAbellMetaDetails());
    //   setIsEditable(true);
    setMetaList([{ metaName: '', metaValue: '', errorName: '', errorValue: '' }]);
    }
  }, [data]);

  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (abellMetaDetails) {
      seprateMeta(abellMetaDetails);
    }
  }, [abellMetaDetails]);


  const forthRun = React.useRef(true);
  useEffect(() => {
    if (forthRun.current) {
      forthRun.current = false;
      return;
    }
    props.handleClose();
  }, [addAbellMetaDetailsLog]);

  const handleClose = () => {
    props.handleClose();
  };




  const seprateMeta = (meta) => {
    const metaList = [];

    if (meta.length === 0) {
      const infoToAdd = {
        metaName: '',
        metaValue: '',
        errorName: '',
        errorValue: '',
      };
      metaList.push(infoToAdd);
    } else {
      meta.map((value, index) => {
        
          const infoToAdd = {
            metaName: value.key,
            metaValue: value.value,
            errorName: '',
            errorValue: '',
          };
          metaList.push(infoToAdd);
        
        return null;
      });
    }
    
    setMetaList(metaList);
  };

  const metaLength = metaList.length;

  const handleMetaButtonClick = (value, index) => {
    if (value === 'add') {
      const newMetaList = [...metaList];
      const infoToAdd = {
        metaName: '',
        metaValue: '',
        errorName: '',
        errorValue: '',
      };
      newMetaList.push(infoToAdd);
      setMetaList(newMetaList);
    } else if (value === 'delete') {
      const newMetaList = [...metaList];
      newMetaList.splice(index, 1);
      setMetaList(newMetaList);
    }
  };

  const handleMetaNameChange = (e, index) => {
    const newMetaList = [...metaList];
    const value = newMetaList[index];
    value.metaName = e.target.value;
    newMetaList[index] = value;
    setMetaList(newMetaList);
  };

  const handleMetaValueChange = (e, index) => {
    const regex = /^[0-9]*$/;
    if (e.target.value.trim()) {
      setMetaValueError('');
      const newMetaList = [...metaList];
      const value = newMetaList[index];
      value.metaValue = e.target.value;
      newMetaList[index] = value;
      setMetaList(newMetaList);
    } else {
      setMetaValueError('Please Enter Value');
    }
  };


  const validateDropDown = () => {
    let validated = true;
    const foundMeta = false;


    // eslint-disable-next-line array-callback-return
    metaList.map((value, index) => {
      // console.log("VALUE IN VALIDATIONm",value);
      const conditionName = `metaName`;
      const conditionValue = `metaValue`;
      if (!foundMeta) {
        if (value[conditionName] === '') {
          validated = false;
          const newMetaList = [...metaList];
          const value = newMetaList[index];
          value.errorName = 'This field is required';
          newMetaList[index] = value;
          setMetaList(newMetaList);
        } else {
          const newMetaList = [...metaList];
          const value = newMetaList[index];
          value.errorName = '';
          newMetaList[index] = value;
          setMetaList(newMetaList);
        }
      }

      if (value[conditionValue] === '') {
        validated = false;
        const newMetaList = [...metaList];
        const value = newMetaList[index];
        value.errorValue = 'This field is required';
        newMetaList[index] = value;
        setMetaList(newMetaList);
      } else {
        const newMetaList = [...metaList];
        const value = newMetaList[index];
        value.errorValue = '';
        newMetaList[index] = value;
        setMetaList(newMetaList);
      }
    });
    return validated;
  };
  

  const DesignationsSchema = Yup.object().shape({
    // treeNumber: Yup.string().required('Tree number is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
    },
    validationSchema: DesignationsSchema,
    onSubmit: (value) => {
      if(validateDropDown()){
        const aaplicableMeta = [];
          metaList.map((value, index) => {
            const conditionName = `metaName`;
            const conditionValue = `metaValue`;
            const obj = {
              key: value[conditionName],
              value: value[conditionValue],
            };
            aaplicableMeta.push(obj);
            return null;
          });

          dispatch(UpdateAbellMetaDetails(aaplicableMeta))
      }
    }
 
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const reLast = /-\d+$/;

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open max-width dialog
      </Button> */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        sx={{minHeight:'400px'}}
      // onClose={handleClose}
      // onClose={handleClose}
      >
        <Box>
          <Stack justifyContent="space-between" direction="row"
            useFlexGap flexWrap="wrap">
            <BootstrapDialogTitle  onClose={handleClose}>Abell Meta Details</BootstrapDialogTitle>
            
          </Stack>
        </Box>

        <Divider />
        <DialogContent>
          {AbellDetailsDialog ? (
            <div>
                          {metaList?.map((value, index) => (
              //  <Stack spacing={3} key={index}>
              //  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4}>
              <Grid container spacing={4} key={index}>
                <Grid item xs={4.5}>
                  <TextField
                    select
                    fullWidth
                    id="metaType"
                    name="metaType"
                    label="Meta Type*"
                    style={{ marginBottom: '20px' }}
                    displayEmpty
                    // style={{width: '87%',}}
                    onChange={(e) => handleMetaNameChange(e, index)}
                    value={value.metaName}
                    error={Boolean(value.errorName)}
                    helperText={value.errorName}
                    inputProps={{
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  // renderValue={(selected) => {
                  //   if (selected.length === 0) {
                  //     return <em>PF</em>;
                  //   }
                  //   return selected
                  // }}
                  >
                    <MenuItem disabled value="">
                      <em>Meta Type*</em>
                    </MenuItem>
                    {councilMetaType.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.id}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    // style={{marginLeft: 20}}
                    id="panCard"
                    autoComplete="panCard"
                    label="Value"
                    placeholder="Enter Value"
                    multiline={value.metaName==="History Of Council"}
                    rows={2}
                    value={value.metaValue}
                    error={Boolean(value.errorValue)}
                    helperText={value.errorValue}
                    onChange={(e) => handleMetaValueChange(e, index)}
                  // defaultValue={data? data.panCard: ""}
                  // name="contact"
                  // value="contact"
                  />
                  <Typography variant="body2" style={{ color: '#FF0000' }}>
                    {metaValueError}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton
                    color={index + 1 === metaLength ? 'success' : 'error'}
                    aria-label={index + 1 === metaLength ? 'add' : 'delete'}
                    size="large"
                    onClick={() => handleMetaButtonClick(index + 1 === metaLength ? 'add' : 'delete', index)}
                  >
                    {index + 1 === metaLength ? (
                      <AddCircleIcon fontSize="inherit" />
                    ) : (
                      <CancelIcon fontSize="inherit" />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            </div>
          ) : null}
        </DialogContent>
        <Divider />
        <DialogActions>
          
              <Button onClick={handleSubmit} color="success" variant="contained" style={{ color: '#fff' }}>
                Save
              </Button>
              
        </DialogActions>
      </Dialog>
    </div>
  );
}
