import { DEFECIENT_TREE_NOTICE_GENERATED, RESET_STATE } from "../actions/Types";
  
  const INIT_STATE = {
    noticegeneratedLog:false,
  };
  
  export default function DeficientTreeNoticeReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;
  
    switch (type) {
        case DEFECIENT_TREE_NOTICE_GENERATED:
          return{
              ...state,
              noticegeneratedLog: !state.noticegeneratedLog,
        };
        case RESET_STATE:
        return { ...INIT_STATE };

        default:
        return state;
    }
  };
  