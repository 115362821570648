import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  Divider,
  MenuItem,
  Tab,
  Select,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Close } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { form, useFormik } from 'formik';
import * as Yup from 'yup';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ViewMeetingList from '../../sections/@dashboard/tree-cutting/viewMeetingList';
import InspectionReport from '../../sections/@dashboard/tree-cutting/InspectionReport';
import Page from '../../components/Page';
import { addMeetingRemark, detailMeeting } from '../../actions/MeetingActions';
import ApplicationDetails from './ApplicationDetails';
import MeetingApplicationInspectionReport from './Tabcomponents/MeetingApplicationInspectionReport';
import ViewMeetingApplicationDetailsApplications from './Tabcomponents/ViewMeetingApplicationDetailsApplications';
import ProclamationApplicationDetails from './Tabcomponents/ProclamationApplicationDetails';

const useStyles = makeStyles({
  icon: {
    fill: '#214C50',
  },
});

const tabStyles = {
  minWidth: '120px',
  textTransform: 'none',
  fontWeight: 'bold',
  margin: '0 4px',
  // backgroundColor: '#e0e0e0',
  '&.Mui-selected': {
    color: '#214c50',
  },
  '&:hover': {
    backgroundColor: '#c5cae9',
  },
};

const ViewDetailsMeeting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { meetingId } = useParams();

  const { attendees, applications, singleMeeting } = useSelector((state) => ({
    attendees: state.MeetingReducer.attendees,
    applications: state.MeetingReducer.applications,
    singleMeeting: state.MeetingReducer.singleMeeting,
  }));

  const [status, setStatus] = useState('');
  const [value, setValue] = React.useState('1');
  const [expanded, setExpanded] = React.useState(false);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [applicationDetailsData, setApplicationDetailsData] = useState(null);
  const [decision, setDecision] = useState('Pending');

  const [mvpDecision, setMvpDecision] = useState('');
  const [mvpDecisionError, setMvpDecisionError] = useState('');
  const [meetingDecisionError, setMeetingDecisionError] = useState('');

  const [removeId, setRemoveId] = useState();

  const [showLoader, setShowLoader] = useState(false);
  const [meetingData, setMeetingData] = useState([]);
  const [meetingApplicationData, setMeetingApplicationData] = useState([]);
  const [applicationSaveData, SetApplicationSaveData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [uncheckIds, setUncheckIds] = useState();
  const [checkBoxError, setCheckBoxError] = useState();
  const [treeId, setTreeId] = useState();
  console.log("treeId",treeId,decision)
  const [readOnly, setReadOnly] = useState(false);
  const [savedStatus, setSavedStatus] = useState({});
  const classes = useStyles();
  const inputRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  

  const handleSaveClickWithIndex = (index) => {
    setCurrentIndex(index); // Set the current index before submission
  };

  
  const validationCheckBox = (index) => {
 
    let validated = true;
    if (selectedDecision[index] === 'Partially Approved' && !checkedIds.length > 0) {
      validated = false;
     
      setCheckBoxError('Please checked at least One tree');
    }

    return validated;
  };

  useEffect(() => {
    const defaultDecisions = [];

    if (meetingId) {
      setShowLoader(true);
      dispatch(detailMeeting(meetingId)).then((response) => {
       
        if (response.status === 200) {
          setShowLoader(false);
          setMeetingData(response.data.data);
          setApplicationDetailsData(response.data.data?.cutting_tree_applications || []);
          setMeetingApplicationData(response.data.data?.cutting_tree_applications || []);
          response.data.data?.cutting_tree_applications.forEach((meetVal, index) => {
            defaultDecisions[index] = meetVal.pivot.decision || 'Pending';
           
          });

          setSelectedDecision(defaultDecisions);
          // setMvpDecision(defaultMvpDecisions);
          //     if(meetingApplicationData?.cutting_tree_meetings[0]?.id === meetingId){
          // setReadOnly(true)
          //     }
        }
      });
    }
  }, []);


  const handleSaveClick = async (id, index) => {
    setSavedStatus((prev) => ({ ...prev, [id]: true }));
  };
  const filteredUncheckedIds = uncheckIds && uncheckIds[0]?.filter((id) => !checkedIds.includes(id));
  const formik = useFormik({
    initialValues: {
      decision: '',
    },
 

    onSubmit: (values, index) => {
      console.log('submit', values);

         const info = {
        meeting_id: meetingData?.id,
        tree_cutting_application_id: applicationSaveData.id,
        application_status: selectedDecision[currentIndex],

        mvp_decision: '',
      };
      if (mvpDecision) {
        info.mvp_decision = mvpDecision;
      }
      if (selectedDecision[currentIndex] === 'Approved') {
        info.approved_trees = treeId[0] ? treeId[0] : [];
        info.rejected_trees = [];
      }
      if (selectedDecision[currentIndex] === 'Rejected') {
        info.approved_trees = [];
        info.rejected_trees = treeId[0];
      }
      if (selectedDecision[currentIndex] === 'Partially Approved') {
        info.approved_trees = checkedIds;
        info.rejected_trees = [];
      }
      if (validationCheckBox(currentIndex)) {
        if(applicationDetailsData?.application_status_id === 10) {
          if(mvpDecision==="" || mvpDecision === null || mvpDecision === undefined || selectedDecision[currentIndex]==="Pending"){
            
            if(selectedDecision[currentIndex]==="Pending" && (mvpDecision==="" || mvpDecision === null || mvpDecision === undefined) ){
              setMeetingDecisionError("Please select meeting decision")
              setMvpDecisionError("Please select mvp decision")
            }
            else if(selectedDecision[currentIndex]==="Pending") {
              setMvpDecisionError("")
              setMeetingDecisionError("Please select meeting decision")
            }
            else if(mvpDecision==="" || mvpDecision === null || mvpDecision === undefined) {
              setMvpDecisionError("Please select mvp decision")
              setMeetingDecisionError("")
            }
           
          }
          
          else {
            setMvpDecisionError("")
            setMeetingDecisionError("")
            setExpandedIndex(null);
            dispatch(addMeetingRemark(info)).then((response) => {
              console.log('addtomeetingRes', response);
              if (response.status === 200) {
                setReadOnly(true);
                dispatch(detailMeeting(meetingId)).then((response) => {
                  if (response.status === 200) {
                    setShowLoader(false);
                    setMeetingData(response.data.data);
                    setApplicationDetailsData(response.data.data?.cutting_tree_applications || []);
                    setMeetingApplicationData(response.data.data?.cutting_tree_applications || []);
                  }
                });
              }
            });
          }
        }
        else {
          setExpandedIndex(null);
          dispatch(addMeetingRemark(info)).then((response) => {
            console.log('addtomeetingRes', response);
            if (response.status === 200) {
              setReadOnly(true);
              dispatch(detailMeeting(meetingId)).then((response) => {
                if (response.status === 200) {
                  setShowLoader(false);
                  setMeetingData(response.data.data);
                  setApplicationDetailsData(response.data.data?.cutting_tree_applications || []);
                  setMeetingApplicationData(response.data.data?.cutting_tree_applications || []);
                }
              });
            }
          });
        }
       
      }
    },
  });



  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

  const handlegrapId = (save) => {
    const removeId = save.cutting_trees[0].cutting_tree_application_id;
    setRemoveId(removeId);
    SetApplicationSaveData(save);
  };
  const statusMenu = [
    {
      id: 1,
      name: 'Pending',
    },
    {
      id: 2,
      name: 'Approved',
    },
    {
      id: 3,
      name: 'Partially Approved',
    },
    {
      id: 4,
      name: 'Rejected',
    },
    {
      id: 5,
      name: 'Submitted To MVP',
    },
  ];
  const statusMenuMvp = [
    {
      id: 1,
      name: 'Approved',
    },
    {
      id: 2,
      name: 'Rejected',
    },
  ];
  const handleApplicationSelect = (application) => {
    setSelectedApplication(application);
    setApplicationDetailsData(application); // Set application details
  };

  const [selectedDecision, setSelectedDecision] = useState([]);
  // console.log("selectedDecision",selectedDecision)

  const handleSelectChange = (event, index) => {
    const prevSelectedDecision = [...selectedDecision];
    prevSelectedDecision[index] = event.target.value;

    setSelectedDecision(prevSelectedDecision);

    setValue('2');
  };

  const handleSelectMvpChange = (e) => {
    setMvpDecision(e.target.value);
  };

 
  const handleCheckedIdsChange = (id, isChecked, totalIds) => {
    setUncheckIds(totalIds);
    setCheckedIds((prevCheckedIds) =>
      isChecked ? [...prevCheckedIds, id] : prevCheckedIds.filter((checkedId) => checkedId !== id)
    );
  };
  const grabTreeIds = (treeIds) => {

    setTreeId(treeIds);
  };
  const formattedDate = meetingData?.date_of_meeting
    ? format(new Date(meetingData.date_of_meeting), 'MMMM d, yyyy')
    : '';
  const [expandedIndex, setExpandedIndex] = useState(null);

  
  const handleAChange = (index) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : null);
  };

  const handleClose = () => {
    setCheckBoxError('');
  };

  return (
    <Page title="Tree Cutting Application">
      <Dialog
        open={checkBoxError}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ minHeight: '400px' }}>
        <div style={{ position: 'relative', width: '500px' }}>
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              color: 'red',
            }}>
            <ErrorIcon sx={{ color: 'red', mr: 1 }} /> {/* Add ErrorIcon */}
            Error
          </DialogTitle>
          <DialogContent sx={{ padding: '30px', width: '500px' }}>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
            <Typography id="alert-dialog-description" sx={{ color: 'red', mt: 2 }}>
              Please select the approved trees from Inspection Report.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Meeting Details
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all Meeting details
            </Typography>
          </Typography>
        </Stack>

        {showLoader ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'center',
              height: 300,
            }}>
            <CircularProgress style={{ color: '#214c50' }} />
          </div>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <Card sx={{ padding: '20px', margin: '10px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                    <Typography sx={{ fontWeight: 700 }}>Meeting Title</Typography>
                    <Typography>{meetingData?.meeting_title}</Typography>
                    <Typography sx={{ fontWeight: 700 }}>Meeting Assingned Time</Typography>
                    <Typography>
                      {formattedDate} at {meetingData.meeting_time}
                    </Typography>
                    <Typography sx={{ fontWeight: 700 }}>Meeting Agenda</Typography>

                    {meetingData?.agendas?.map((agendaitem) => {
                      return <Typography>{agendaitem?.agenda}</Typography>;
                    })}
                  </Box>
                </Box>
                <Divider style={{ marginY: '15px', marginTop: 20 }} />

                <Typography style={{ marginTop: 10 }}>
                  Attendees :
                  <span>
                    {meetingData?.attendees?.map((atte, index) => (
                      <Chip
                        key={index}
                        label={`${atte.first_name}  ${atte.last_name}`}
                        sx={{ marginLeft: 1, backgroundColor: '#214c50', color: '#fff' }}
                      />
                    ))}
                  </span>
                </Typography>
              </Card>

              <Box sx={{ margin: '10px' }}>
                {meetingApplicationData?.map((meetVal, index) => (
                  <Accordion
                    key={index}
                    sx={{ height: '100%' }}
                    expanded={expandedIndex === index}
                    onChange={handleAChange(index)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
                      aria-controls="panel1-content"
                      onClick={() => handleApplicationSelect(meetVal)} // Select application on click
                      id="panel1-header">
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <Typography style={{ fontWeight: 700 }}>{meetVal.application_number}</Typography>

                        <Chip
                          label={meetVal.application_status.application_status}
                          style={{
                            backgroundColor: '#A1F3D1',
                            borderRadius: '100px',
                            padding: '10px',
                            fontWeight: 400,
                            color: '#000000',
                          }}
                        />
                      </Box>
                      <Divider style={{ marginY: '15px' }} />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Divider sx={{ marginY: '15px' }} />

                      <TabContext value={value}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}>
                          <TabList sx={{ mt: '-18px' }} onChange={handleChange}>
                            <Tab label="Application Detail" value="1" sx={tabStyles} />
                            <Tab label="Inspection Report" value="2" sx={tabStyles} />
                            <Tab label="Meeting Report" value="3" sx={tabStyles} />
                            <Tab label="Proclamation Report" value="4" sx={tabStyles} />
                          </TabList>
                          {applicationDetailsData?.application_status_id === 10 && (
                            <Box>
                              <Typography sx={{ fontWeight: '500', color: '#214C50' }}> Select MVP decision</Typography>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                inputProps={{
                                  classes: {
                                    icon: classes.icon,
                                  },
                                }}
                                onChange={(e) => handleSelectMvpChange(e)}
                                displayEmpty
                                sx={{
                                  marginTop: '5px',
                                  minWidth: 200,
                                  height: '40px',
                                  fontSize: '0.875rem',
                                  border: '2px solid #214C50',
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: '200px', // to control the height of the dropdown menu
                                    },
                                  },
                                }}
                                disabled={ meetVal.pivot.decision}>
                                {/* disabled={savedStatus[meetVal.id]}> */}
                                {statusMenuMvp?.map((option) => (
                                  <MenuItem key={option.id} value={option.name}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                              </Select>
                              <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: ' 0.75rem' }}>
                      {mvpDecisionError}
                    </Typography>
                            </Box>
                          )}
                          <Box>
                            <Typography sx={{ fontWeight: '500', color: '#214C50' }}>
                              Select meeting decision
                            </Typography>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              inputProps={{
                                classes: {
                                  icon: classes.icon,
                                },
                              }}
                              // defaultValue={"Partially Approved"}
                              // value={decision}
                              value={selectedDecision[index] || ''}
                              onChange={(e) => handleSelectChange(e, index)}
                              displayEmpty
                              sx={{
                                marginTop: '5px',
                                minWidth: 200,
                                height: '40px',
                                fontSize: '0.875rem',
                                border: '2px solid #214C50',
                              }}
                              MenuProps={{
                                PaperProps: {
                                  sx: {
                                    maxHeight: '200px',
                                  },
                                },
                              }}
                              disabled={ meetVal.pivot.decision}>
                              {statusMenu?.map((option) => (
                                <MenuItem key={option.id} value={option.name}>
                                  {option.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: ' 0.75rem' }}>
                      {meetingDecisionError}
                    </Typography>
                          </Box>
                        </Box>

                        <Grid item md={12}>
                          <TabPanel value="1" key={index} sx={{ m: 0, p: 0 }}>
                            {applicationDetailsData && (
                              <ViewMeetingApplicationDetailsApplications
                                applicationDetailsData={applicationDetailsData}
                                showLoader={showLoader}
                                // showTitleData
                              />
                            )}
                          </TabPanel>
                          <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                            <MeetingApplicationInspectionReport
                              grabTreeIds={grabTreeIds}
                              checkBoxError={checkBoxError && checkBoxError}
                              applicationDetailsData={applicationDetailsData}
                              decisionbyselect={selectedDecision[index]}
                              showLoader={showLoader}
                              onCheckedIdsChange={handleCheckedIdsChange}
                            />
                          </TabPanel>
                          <TabPanel value="3" sx={{ m: 0, p: 0 }}>
                            <ViewMeetingList applicationDetailsData={applicationDetailsData} showLoader={showLoader} />
                          </TabPanel>

                          <TabPanel value="4" sx={{ m: 0, p: 0 }}>
                            <ProclamationApplicationDetails
                              applicationDetailsData={applicationDetailsData}
                              showLoader={showLoader}
                            />
                          </TabPanel>
                        </Grid>
                      </TabContext>

                      <Button
                        // disabled={!decision || (applicationDetailsData?.application_status_id === 10 && !mvpDecision)}
                        // disabled={readOnly}
                        disabled={ meetVal.pivot.decision}
                        variant="contained"
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          fontSize: 15,
                          marginTop: 40,
                          // alignSelf: 'end',
                          marginLeft: ' 90%',

                          // backgroundColor: decision ? 'orange' : 'silver',
                        }}
                        type="submit"
                        onClick={(e) => {
                          // handleSave(index);
                          handlegrapId(meetVal);
                          validationCheckBox(index);
                          handleSaveClick(meetVal.id);
                          formik.handleSubmit(e);
                          handleSaveClickWithIndex(index);
                          // handleSubmitErrors();
                        }}>
                        {'Save'}
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </form>
          </>
        )}
      </Container>
    </Page>
  );
};

export default ViewDetailsMeeting;
