import { filter, includes } from 'lodash';
// import React from 'react';

import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
  Grid,
  Box,
  Pagination,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/named
import { UserListToolbar, UserFormListMenu } from '../sections/@dashboard/user';

import USERLIST from '../_mock/user';
import NewUserDialog from '../components/DialogBox/NewUserDialog';
import UserTableData from '../components/JsonFiles/UserTableData.json';
import { AssociateDialog, UserListHead } from '../sections/@dashboard/manager';
import AssignWardDialog from "../sections/@dashboard/manager/AssignWardDialog"

import { GetUsers, SearchUsers, AddUsersManager } from '../actions/ManagerAction';

import StatusButton from '../components/statusbutton/StatusButton';
import warningSound from '../Assets/warning_sound.mp3';
import { GetUsersByRoleID } from '../actions/UserAction'
import { GetActiveWardsByCouncilId } from '../actions/WardsActions';
import { GetActiveCouncil } from '../actions/CouncilAction';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  // { id: 'email', label: 'Email', alignRight: false },
  // { id: 'mobile', label: 'Mobile', alignRight: false },
  // { id: 'username', label: 'Username', alignRight: false },
  { id: 'activeCouncil', label: 'Active Council', alignRight: false },
  { id: 'activeWard', label: 'Active Wards', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

export default function User() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('Name');
  const [filterName, setFilterName] = useState('');
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState();
  const [dialogData, setDialogData] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageCountError, setPageCountError] = useState(false);
  const [topModalOpen, setTopModalOpen] = useState(false);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [reqObj, setReqObj] = useState(null);
  const [requireActiveObj, setReqActiveObj] = useState(null);
  const [showCouncil, setShowCouncil] = useState("");
  const [showTableList, setShowTableList] = useState(false);
  const userPermissions = [];
  const message =
    'Unlinking device will expired the current session of the user and might lose the offline data. Please synch all the Offline data before proceeding.';
  const [isCurrentWards, setIsCurrentWards] = useState(false)
  const [getCurrentWards, setCurrentWards] = useState([])
  const { state } = useLocation();
  const { users, pageInfo, council, wards, addUsersLog } = useSelector((state) => ({
    wards: state.wards.activeWardsByCID,
    users: state.managerReducer.users,
    pageInfo: state.managerReducer.pageInfo,
    addUsersLog: state.managerReducer.addUsersLog,
    council: state.council.activeCouncil
  }));
  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (wards && showCouncil) {
      // setIsCurrentWards(false)
      setCurrentWards(wards)
    }

  }, [wards])

  const addManagerRun = React.useRef(true);
  React.useEffect(() => {
    if (addManagerRun.current) {
      addManagerRun.current = false;
      return;
    }

    setOpen(false);
    setAssignWards(false);
    dispatch(GetUsers(page, rowsPerPage));

  }, [addUsersLog])

  useEffect(() => {
    if (state) {
      setPage(state.page);
    }
  }, []);

  useEffect(() => {
    if (state) {
      setPage(state.page);
      dispatch(GetUsers(state.page, rowsPerPage));
    } else {
      dispatch(GetUsers(page, rowsPerPage));
    }
  }, []);

  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setShowTableList(true)
  }, [pageInfo])

  // useEffect(() => {
  //   if (pageInfo) {
  //     setCount(pageInfo?.total);
  //   }
  // }, [pageInfo]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (search) {
      dispatch(SearchUsers(newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetUsers(newPage, rowsPerPage));
    }
  };


  let timer = null;
  const filterByName = (event) => {
    const value = event.currentTarget.value;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        dispatch(SearchUsers(1, rowsPerPage, value));
        setSearch(true);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetUsers(1, rowsPerPage));
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
  };
  const [getAssociateId, setAssociateId] = useState()
  const handleClickOpen = (event) => {
    setAssociateId(event.target.id)
    // setShowCouncil({
    //   showCouncil:""
    // })
    // const {
    //   target: { value },
    // } = event;
    // setAddedBy(
    //   typeof value === 'string' ? value.split(',') : value,
    // );
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };
  const handleClickSave = () => {
    dispatch(AddUsersManager({
      "manager_id": getAssociateId,
      "council_id": showCouncil,
      "ward_id": addedBy
    }))
    
    // setOpen(false);
    // setAssignWards(false);
    // dispatch(GetUsers(page, rowsPerPage));
  }
  const [addedBy, setAddedBy] = React.useState([])
  const {
    userByRoleID
  } = useSelector((state) => ({

    userByRoleID: state.users.userByRoleID
  }))
  const onChangeCouncil = (e) => {
    setShowCouncil(e.target.value)
    if (e.target.value) {
      dispatch(GetActiveWardsByCouncilId(1, e.target.value))
    }
  }
  // useEffect(() => {
  //   dispatch(GetUsersByRoleID(1, 3, 5))
  // }, [])
  const handleAddedByChange = (event) => {
    const {
      target: { value },
    } = event;
    setAddedBy(
      typeof value === 'string' ? value.split(',') : value,
    );
  }
  const [viewAssignWards, setAssignWards] = useState(false)

  const handleAssignWardsDetailsDialog = (option, data) => {
    setAssignWards(!viewAssignWards);
    setAssociateId(option.id)
    setShowCouncil("")
    setAddedBy([
    ])
    setCurrentWards([])
    // setDialogData(data);
  }
  // const handleCloseAssignWardsDetailsDialog = (option) => {
  //   setAssignWards(!viewAssignWards);
  // }
  return (
    <Page title="User">
      {
        viewAssignWards ?
          <AssignWardDialog
            // userId={option.id}
            // handleClickOpen={handleClickOpen}
            handleClickClose={() => handleAssignWardsDetailsDialog()}
            // handleClickClose={()=>{handleAssignWardsDetailsDialog()}
            handleClickSave={handleClickSave}
            open={viewAssignWards}
            onChangeCouncil={
              (e) => {
                onChangeCouncil(e)
              }}
            council={council}
            showCouncil={showCouncil}
            addedBy={addedBy}
            onChange={(e) => {
              handleAddedByChange(e)
            }}
            wards={getCurrentWards}
            data={dialogData}
          // status={option.status} 
          />
          : null
      }
      <Container>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Managers
            <Typography variant="h6" style={{ fontWeight: 400 }}  >
              It is showing list of all Managers with its details
            </Typography>
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/new-manager-Form"
          // style={{boxShadow: 'none'}}
          // startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Manager
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={0} placeHolder={'Search users...'} onFilterName={filterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead
                  headLabel={TABLE_HEAD}
                  data={dialogData}
                />
                <TableBody>
                  {showTableList && users?.map((option, index) => {
                    return (
                      <TableRow hover>
                        <TableCell align="left">
                          <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                        </TableCell>
                        <TableCell align="left" style={{ width: '400px' }} >
                          {`${option.full_name}`}
                        </TableCell>
                        {/* <TableCell align="left">{option.email}</TableCell>
                        <TableCell align="left">{option.mobile}</TableCell>
                        <TableCell align="left">{option.username}</TableCell> */}
                        <TableCell align="left">
                          {option.assigned_council?.assigned_council_name
                            ? option.assigned_council?.assigned_council_name
                            : '-'}
                        </TableCell>
                        <TableCell align="left">
                          {option.assigned_ward?.assigned_ward_name
                            ? option.assigned_ward?.assigned_ward_name
                            : '-'}
                        </TableCell>
                        {/* <TableCell align="left">
                          <StatusButton status={option.status} />
                        </TableCell> */}
                        <TableCell align="right" getDialogId={option.id}>
                          <AssociateDialog
                            handleAssignWardsDialog={() => handleAssignWardsDetailsDialog(option)}
                            page={page}
                            userId={option.id}
                          // handleClickOpen={handleClickOpen}
                          // handleClickClose={handleClickClose}
                          // handleClickSave={handleClickSave}
                          // open={open}
                          // onChangeCouncil={
                          //   (e) => {
                          //     onChangeCouncil(e)
                          //   }}
                          // council={council}
                          // showCouncil={showCouncil}
                          // addedBy={addedBy}
                          // onChange={(e) => {
                          //   handleAddedByChange(e)
                          // }}
                          // wards={wards}
                          // status={option.status}

                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box>
            {users ? (
              <Pagination
                count={pageInfo.last_page}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
              />
            ) : null}
          </Box>
        </Card>
      </Container>
    </Page >
  );
}
