import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import { GET_USER_LOCATION, GET_ROOT_LOCATION } from './Types';

const GetUserLocation = (userId) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/user-current-location?user_id=${userId}`);
    dispatch({
      type: GET_USER_LOCATION,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: GET_USER_LOCATION,
      payload: {data:{}},
    });
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const GetRootLocation = (getRootUserId, dob) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/user-route-tracking?user_id=${getRootUserId}&date=${dob}`);
    // user-tracking-locations

    dispatch({
      type: GET_ROOT_LOCATION,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: GET_ROOT_LOCATION,
      payload: {data:[]}
    });
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export { GetUserLocation, GetRootLocation };
