import { GET_TREE_BY_NUMBER, SEARCH_TREES_IN_5KM, GET_SPECIFIC_TREE_LOCATION_DETAILS, GET_TREE_LOCATION, RESET_STATE } from "../actions/Types";
  
  const INIT_STATE = {
    treeLocation:[],
    treeDetails:{},
    treesIn5Km:[],
    treesByNumber:[]
  };
  
  export default function TreeOnMapReducer (state = INIT_STATE, action)  {
    const { type, payload } = action;
  
    switch (type) {
        case GET_TREE_LOCATION:
          return{
              ...state,
              treeLocation: payload.data
        };

        case GET_SPECIFIC_TREE_LOCATION_DETAILS:
          return{
              ...state,
              treeDetails: payload.data
        };

        case SEARCH_TREES_IN_5KM:          
          return{
            ...state,
            treesIn5Km: payload.data
          };

        case  GET_TREE_BY_NUMBER:
          return{
            ...state,
            treesByNumber: payload.data
          }
        case RESET_STATE:
        return { ...INIT_STATE };

        default:
        return state;
    }
  };