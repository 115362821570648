import React, { useEffect, useState } from 'react';
import axios from 'axios';

import * as Yup from 'yup';
import moment from 'moment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { green } from '@mui/material/colors';
import Icon from '@mui/material/Icon';
import { Stack, Avatar, Checkbox, Container, Drawer, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoadScript } from '@react-google-maps/api';
import { GetActiveUsers, GetUsers, GetUsersByRoleID } from '../../actions/UserAction';
import DefaultInput from '../../components/Inputs/DefaultInput';
import Map from './RootTravel';
import Page from '../../components/Page';
import { GetUserLocation, GetRootLocation } from '../../actions/UserLocationAction';
import { GetActiveCouncil } from '../../actions/CouncilAction';
import Iconify from '../../components/Iconify';

export default function TreeOnMap(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showList, setShowList] = useState(false);
  const [addedBy, setAddedBy] = React.useState(null);
  // const getUserLocation =
  const { isOpen, data } = props;
  const todayDate = moment(new Date()).format('YYYY-MM-DD')

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // AIzaSyAK0pKhRsZq5iEYapo_biv8ZP3EZoZDvD4

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBcAkxYg1CTn1ackTsVWVt4KKDlkKa6hWc', // Add your API key
  });

  const { council, treeLocation, userLocation, userByRoleID, rootTravelData, activeUsers } = useSelector((state) => ({
    council: state.council.activeCouncil,
    treeLocation: state.treeLocation.treeLocation,
    userLocation: state.userLocation.userLocateData,
    userByRoleID: state.users.userByRoleID,
    rootTravelData: state.userLocation.rootTravelData,
    activeUsers: state.users.activeUsers
  }));
  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    setShowList(true);
  }, [rootTravelData]);

  useEffect(() => {
    dispatch(GetActiveCouncil(1));
    dispatch(GetActiveUsers(1))
    // dispatch(GetUsersByRoleID(1, 3, 4, 5, 6, 11));
  }, []);
  const [getRootUserId, setRootUserId] = useState('');
  const handleAddedByChange = (event, newValue) => {
    const getID = JSON.stringify(newValue, null, ' ');
    const getLocatedUserId = JSON.parse(getID);
    setAddedBy(getLocatedUserId.label);
    setRootUserId(getLocatedUserId.value);
  };

  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();
  const [dob, setDob] = React.useState('');

  const handleDobChange = (event) => {
    setDob(event.target.value);
  };
  const handleRootSubmit = () => {
    dispatch(GetRootLocation(getRootUserId, dob));
  };
  
  const filterUserList = activeUsers?.filter(value => value.assigned_roles !== "Admin" && value.assigned_roles !== "Superadmin" && value.assigned_roles !== "Data Analyst");
  const UserList = filterUserList?.map((item) => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }));
  return (
    <Page title="Maps">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography variant="h4" gutterBottom>
                User Travelled Route
                <Typography variant="h6" style={{ fontWeight: 400 }}>
                  It is showing user's travelled route on the map.
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <TextField
                    id="dob"
                    name="dob"
                    sx={{ width: '100%' }}
                    type="date"
                    // label="Date*"
                    value={dob}
                    onChange={(e) => {
                      handleDobChange(e);
                    }}
                    //   error={Boolean(touched.dob && errors.dob)}
                    //   helperText={touched.dob && errors.dob}
                    //   // {...getFieldProps("dob")}
                    //   InputLabelProps={{
                    //     shrink: true,
                    //   }}
                    inputProps={{ max: todayDate }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    // value={null}
                    value={addedBy}
                    options={UserList}
                    onChange={(e, newValue) => {
                      handleAddedByChange(e, newValue);
                    }}
                    // sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Users" />}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    style={{ display: 'flex', fontSize: 15, margin: '10px 0 auto 0px' }}
                    onClick={(e) => {
                      // validateDropDown();
                      // formik.handleSubmit(e);
                      // handleSubmitErrors();
                      handleRootSubmit();
                    }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <Grid container spacing={1} style={{ marginTop: 20 }}>
          {isLoaded ? (
            <Map
              lat={userLocation.lat}
              long={userLocation.long}
              UserLastSeen={userLocation.updated_at}
              id={userLocation.user_id}
              rootTravelIdData={showList ? rootTravelData : []}
              treeLocation={showList ? treeLocation : []}
            />
          ) : null}
        </Grid>
      </Container>
    </Page>
  );
}
