import {
  DELETE_BASE_COLOR_TREES, GET_BASE_COLOR_TREES, GET_BASE_COLOR_TREES_HISTORY,
  GET_QC_REMARKS_FOR_BASE_COLOR, RESET_STATE, UPDATE_QC_STATUS_BASE_COLOR_TREES,
  GET_BASE_COLOR_PENDING_QC_STATUS, EXPORT_CALL_LOG} from "../actions/Types";

const INIT_STATE = {
  baseColorTrees: null,
  addBaseColorTreesLog: false,
  editBaseColorTreesLog: false,
  deleteBaseColorTreesLog: false,
  updateQCStatusLog: false,
  pageInfo: {},
  baseColorRemarks: [],
  baseColorTreeHistory: [],
  baseColorPendingQCStatus: [],
  exportCallLogs: [],
  isReportDownloadable: false,
};

export default function BaseColorReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_BASE_COLOR_TREES:
      return {
        ...state,
        baseColorTrees: payload.data.data,
        pageInfo: payload.data
      };
    case EXPORT_CALL_LOG:
      
      return {
        ...state,
        exportCallLogs: payload.data,
        isReportDownloadable: !state.isReportDownloadable
        //   pageInfo: payload.data
      };
    case GET_BASE_COLOR_TREES_HISTORY:
      return {
        ...state,
        baseColorTreeHistory: payload.data.data,
        pageInfo: payload.data
      };

    case DELETE_BASE_COLOR_TREES:
      return {
        ...state,
        deleteBaseColorTreesLog: !state.deleteBaseColorTreesLog
      };

    case UPDATE_QC_STATUS_BASE_COLOR_TREES:
      return {
        ...state,
        updateQCStatusLog: !state.updateQCStatusLog,
      };

    case GET_QC_REMARKS_FOR_BASE_COLOR:
      return {
        ...state,
        baseColorRemarks: payload.data
      };

    case GET_BASE_COLOR_PENDING_QC_STATUS:
      return {
        ...state,
        baseColorPendingQCStatus: payload.data,
        pageInfo: payload.data
      }

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
};
