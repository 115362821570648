import * as React from 'react';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import { Checkbox, TextField, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { AddDesignations, EditDesignations } from '../../../actions/DesignationAction';
import { CreateTreeCuttingMom, UpdateTreeNeedToBePlantList } from '../../../actions/TreeCuttingPermissionAction';
import { UploadFile } from '../../../actions/UploadActions';
import { SetNewAlert } from '../../../actions/AlertActions';


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function UpdateCompensationModal(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const { isOpen, notUpdateTreeList, updateTreeList} = props;
  const [modalUpdateTreeList, setModalUpdatedTreeList] = React.useState(updateTreeList);
  const [modalNotUpdateTreeList, setModalNotUpdateTreeList] = React.useState(notUpdateTreeList)


  const {
    uploadFile,
    uploadFileLog,
    addTreeCuttingMomLog,
    treeCuttingApplications,
    treeNeedToBePlant,
    treeNeedToBePlantLog,
  } = useSelector((state) => ({
    uploadFile: state.upload.uploadFile,
    uploadFileLog: state.upload.uploadFileLog,
    addTreeCuttingMomLog: state.treeCutting.addTreeCuttingMomLog,
    treeCuttingApplications: state.treeCutting.treeCuttingApplications,
    treeNeedToBePlant: state.treeCutting.treeNeedToBePlant,
    treeNeedToBePlantLog: state.treeCutting.treeNeedToBePlantLog,
  }));

  const descisionList = [
    {
      id: 'Plant Died',
      name: 'Plant Died',
    },
  ];




  const handleClose = () => {
    props.handleClose();
  };

  const handleRemark = (e,index) => {
  const newTreeArray = [...modalNotUpdateTreeList];
  const value = newTreeArray[index];
  value.is_updated = true;
  value.remark = e.target.value
  newTreeArray[index] = value;
  setModalNotUpdateTreeList(newTreeArray);
  const newUpdateTreeList = [...modalUpdateTreeList];
  const obj = {
        "id": modalNotUpdateTreeList[index].id,
        "image_url": modalNotUpdateTreeList[index].image_url,
        "status": "Unavailable",
        "remark" : e.target.value
      }
  newUpdateTreeList.push(obj)
  setModalUpdatedTreeList(newUpdateTreeList);
}

const treeFetchRun = React.useRef(true);
useEffect(() => {
  if (treeFetchRun.current) {
    treeFetchRun.current = false;
    return;
  }
  props.handleClose()
}, [treeNeedToBePlantLog]);


  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleSubmit = () => {
    let isValid = true;
    modalNotUpdateTreeList?.map((value,index)=>{
      if(!value.remark){
        isValid = false
      }
      return null;
    })

    if(isValid){
      console.log("MODAL UPDATE TREE LIST",modalUpdateTreeList)
      dispatch(UpdateTreeNeedToBePlantList(modalUpdateTreeList))
    }
    else {
      dispatch(SetNewAlert({
        msg: "please select Remark for all trees",
        alertType: "danger",
      }));
    }
  }


  const useStyles = makeStyles({
    
    icon: {
        fill: '#214C50',
    },
   
})
const classes = useStyles()

  return (
    <div>
     
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // width: "100%",
              minWidth: "750px",  // Set your width here
             
            },
          },
        }}
       
        // onClose={handleClose}
      >
        <BootstrapDialogTitle onClose={handleClose}>{"Remark"}</BootstrapDialogTitle>
        <Divider/>
        <DialogContent>
        <Grid container spacing={1}>
        
        <Typography variant="h5" style={{ fontWeight: '400',marginBottom:'10px' }}>
          You Haven't updated image for following tree*
          </Typography>

          {notUpdateTreeList?.map((value,index)=>{
            return <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                <Grid item xs={4}>
                <TextField
                    fullWidth
                    disabled
                    id="name"
                    name="name"
                    autoComplete="name"
                    label="Tree Name*"
                    value={value?.tree_name?.name}
                    style={{ marginTop: '7px' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                    type='date'
                    fullWidth
                    disabled
                    id="planatationDate"
                    name="planatationDate"
                    autoComplete="planatationDate"
                    label="Plantation date*"
                    value={value?.plantation_date}
                    style={{ marginTop: '7px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                  <TextField
                    fullWidth
                    select
                    id="remark"
                    name="remark"
                    autoComplete="remark"
                    label="Remark*"
                    value={value?.remark}
                    style={{ marginTop: '7px' }}
                    onChange={(e)=>{
                      handleRemark(e,index)
                    }}
                    inputProps={{
                      classes: {
                          icon: classes.icon,
                      },
                    }}
                    >
                      <MenuItem disabled value="">
            <em>Select Remark*</em>
          </MenuItem>
          {descisionList?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
                      </TextField>
                  </Grid>
            </Grid>
          })}
        
        </Grid>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button onClick={handleSubmit} variant='contained'>Save</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
