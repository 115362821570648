import React, { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, Marker, Polyline } from '@react-google-maps/api';
import { getIcon } from '@iconify/react';
import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Container,
  Modal,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility } from '@mui/icons-material';
import TreeFill from '../../Assets/tree_on_map.png';
import { GetSpecificTreeInfo } from '../../actions/TreeOnMapAction';
import { ShowLoader } from '../../actions/CommonAction';
import ViewImageDialog from '../../components/DialogBox/tree-data/ViewImageDialog';
import ImageCarousel from '../../components/ImageCarousel';
import UserCircle from '../../Assets/location.png';

const markers = [
  {
    id: '1',
    name: 'Chicago, Illinois',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    lat: 19.076,
    long: 72.877426,
  },
  {
    id: '2',
    name: 'Denver, Colorado',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    lat: 18.5204,
    long: 73.8567,
  },
  {
    id: '3',
    name: 'Los Angeles, California',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    lat: 28.7041,
    long: 77.1025,
  },
  {
    id: '4',
    name: 'New York, New York',
    height: '7 Meter',
    age: '20 Year',
    image:
      'https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg',
    lat: 22.5726,
    long: 88.3639,
  },
];

const pathCoordinates = [
  {
    lat: 19.076,
    lng: 72.877426,
  },
  {
    lat: 18.5204,
    lng: 73.8567,
  },
  {
    lat: 28.7041,
    lng: 77.1025,
  },
  {
    lat: 22.5726,
    lng: 88.3639,
  },
];

const center = {
  lat: 21.7679,
  lng: 78.8718,
};

function PolyLineMap(props) {
  const dispatch = useDispatch();
  const [activeMarker, setActiveMarker] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [openImageList, setOpenImageList] = useState(false);
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);
  const { treeDetails, showLoader } = useSelector((state) => ({
    treeDetails: state.treeLocation.treeDetails,
    showLoader: state.common.showLoader,
  }));

  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    dispatch(ShowLoader(false));
  }, [treeDetails]);

  useEffect(() => {
    // setActiveMarker(null);
  }, [props.treeLocation]);

  const handleActiveMarker = (marker) => {
    console.log('marker', marker);
    if (marker === activeMarker) {
      return;
    }
    // dispatch(GetSpecificTreeInfo(marker));
    // dispatch(ShowLoader(true));
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    // eslint-disable-next-line no-undef
    const bounds = new google.maps.LatLngBounds();
    // markers.forEach(({ position }) => bounds.extend(position));
    bounds.extend(center);
    map.fitBounds(bounds);
  };

  //   const icon = {
  //     url: UserCircle,
  //     scaledSize: new window.google.maps.Size(50, 42),
  //   };

  const icon = {
    url: TreeFill,
    scaledSize: new window.google.maps.Size(50, 42),
  };

  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen);
    const imageList = [];
    if (images) {
      images.map((value, index) => {
        imageList.push(value.image_url);
        return null;
      });
    }
    setImageList(imageList);
  };
  const rootData = props.rootTravelIdData;
  console.log("ROOT DATA",rootData);
  const nums = rootData?.map((value) => {
    return {
      lat: Number(value.lat),
      lng: Number(value.long),
    };
  });
  return (
    <GoogleMap
      // onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: '100%', height: '500px' }}
      zoom={9}
      center={center}>
      {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}
      {props.rootTravelIdData?.map((value, index) => (
        <Marker
          key={value.id}
          position={{ lat: Number(value.lat), lng: Number(value.long) }}
          onClick={() => handleActiveMarker(value.id)}
          icon={icon}
          title={value.added_on_date}>
          {activeMarker === value.id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>
                <Table
                  style={{ border: 'none' }}
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: 'none',
                    },
                  }}>
                  <TableBody style={{ border: 'none' }}>
                    {Object.hasOwn(value, 'tree_number') ? (
                      <TableRow>
                        <TableCell align="left" style={{ paddingLeft: '0px', paddingBottom: '0px' }}>
                          Tree Number
                        </TableCell>
                        <TableCell align="left" style={{ paddingBottom: '0px' }}>
                          {value.tree_number}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{ paddingLeft: '0px', paddingTop: '5px', paddingBottom: '0px', whiteSpace: 'nowrap' }}>
                        Added on
                      </TableCell>
                      <TableCell align="left" style={{ paddingTop: '5px', paddingBottom: '0px' }}>
                        {value.added_on_date}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </InfoWindow>
          ) : null}
          <Polyline
            path={nums}
            geodesic
            options={{
              strokeColor: '#0000ff',
              strokeOpacity: 0.75,
              strokeWeight: 2,
              icons: [
                {
                  icon: UserCircle,
                  offset: '0',
                  repeat: '20px',
                },
              ],
            }}
          />
        </Marker>
      ))}
      <Modal
        open={openImageList}
        onClose={handleCloseImageList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Container style={{ width: '526px' }}>
          <ImageCarousel imagelist={imageList} />
        </Container>
        {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
      </Modal>
    </GoogleMap>
  );
}

export default PolyLineMap;
