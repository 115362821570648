import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom'

import { Grid, Typography, Card, CardMedia, Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import NewTreeDas from '../../Assets/NewTreeDash.webp'
import { AppWidgetSummary } from '../../sections/@dashboard/app';

import ProjectImg from '../../Assets/toplant_dashboard.png';
//   import Iconify from '../../../components/Iconify';
function Item(props) {
    const { sx, ...other } = props;
    return (
        <Box
            sx={{
                // p: 1,
                // m: 1,
                // bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
                // color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                // border: '1px solid',
                // borderColor: (theme) =>
                //     theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                // borderRadius: 2,
                fontSize: '0.875rem',
                fontWeight: '700',
                ...sx,
            }}
            {...other}
        />
    );
}

Item.propTypes = {
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
    sx: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
        ),
        PropTypes.func,
        PropTypes.object,
    ]),
};
const LightCard = (props) => {
    // const { count, title, subtitle } = props.projects;
    const useStyles = makeStyles({
        wrapper: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, auto)',
            padding: '10px 15px',
        },
        paddingClass: {
            padding: '15px 20px',

        },
        cardleftSection: {
            padding: '5px 20px 15px 7px',
            fontWeight: 600,
        },
        cardCount: {
            padding: '5px 5px 5px 5px',
            fontSize: '35px'
            // borderBottom: '1px solid #EEEEEE'
        },
        border: {
            borderBottom: '1px solid #EEEEEE',
            width: '60%'
        },
        borderRight: {
            borderRight: '1px solid'
        },
        borderAll: {
            border: '1px solid'
        }
    });
    const classes = useStyles();
    const navigate = useNavigate()

    return (
        <Grid container spacing={1}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                    <Card style={{ height: '500' }}>
                        
                        <Grid item xs={12}>
                            <Grid container rowSpacing={1}>
                                <Grid item xs={12}>
                                    <div className={classes.paddingClass}>

                                        <div>
                                            <div style={{ display: 'flex',padding:'10px', justifyContent: 'space-between', borderBottom: '1px solid #000' }}>
                                                <h4>
                                                    <h2>{props.projects?.tree_detail_change_request_count}</h2>
                                                </h4>
                                                <div>
                                                    <Button variant="contained" onClick={() => {
                                                        navigate(`/dashboard/treeDataChange`)
                                                    }}>View All</Button>
                                                </div>

                                            </div>
                                            <h4 style={{padding:'10px'}}>
                                                Pending Tree Update Request
                                                <h6 style={{ fontWeight: '200' }}>It is showing all your pending tree update request counts</h6>
                                            </h4>
                                        </div>

                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card style={{ height: '500' }}>
                        <Grid item xs={12}>
                            <Grid container rowSpacing={1}>
                                <Grid item xs={12}>
                                    <div className={classes.paddingClass}>

                                        <div>
                                            <div style={{ display: 'flex',padding:'10px', justifyContent: 'space-between', borderBottom: '1px solid #000' }}>
                                                <h4>
                                                    <h2>{props.projects?.ward_change_request_count}</h2>
                                                </h4>
                                                <div>
                                                    <Button variant="contained" onClick={() => {
                                                        navigate(`/dashboard/wardChange`)
                                                    }}>View All</Button>
                                                </div>

                                            </div>
                                            <h4 style={{padding:'10px'}}>
                                                Pending Ward Change Request
                                                <h6 style={{ fontWeight: '200' }}>It is showing all your pending ward change request counts</h6>
                                            </h4>
                                        </div>

                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>

                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LightCard;
