import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
    Button,
  } from '@mui/material';
import { ConnectingAirportsOutlined } from '@mui/icons-material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ByTreeTypeGraph = (props) => {
  // const {data: graph} = props;
    const graph = [
      { id: 1, tree_type: 'Flowers', is_protected: 0, census_trees_count: 15 },
      { id: 2, tree_type: 'Trees', is_protected: 0, census_trees_count: 6 },
      { id: 3, tree_type: 'Coniferous trees', is_protected: 0, census_trees_count: 2 },
      { id: 4, tree_type: 'Deciduous trees', is_protected: 0, census_trees_count: 17 },
      { id: 5, tree_type: 'Mountain Trees', is_protected: 0, census_trees_count: 11 },
      { id: 6, tree_type: 'Tropical Wet Evergreen', is_protected: 0, census_trees_count: 0 },
      { id: 9, tree_type: 'leaf type', is_protected: 0, census_trees_count: 7 },
      { id: 10, tree_type: 'Magroove Trees', is_protected: 0, census_trees_count: 0 },
      { id: 11, tree_type: 'Marshy Type', is_protected: 0, census_trees_count: 0 },
      { id: 12, tree_type: 'outdoor', is_protected: 0, census_trees_count: 0 },
      { id: 13, tree_type: 'asda', is_protected: 0, census_trees_count: 0 },
      { id: 14, tree_type: 'Palm Trees', is_protected: 0, census_trees_count: 0 },
      { id: 15, tree_type: 'forest', is_protected: 0, census_trees_count: 0 },
      { id: 16, tree_type: 'tree limbu', is_protected: 0, census_trees_count: 0 },
      { id: 17, tree_type: 'rosee tree', is_protected: 0, census_trees_count: 0 },
    ];
    // console.log('data_tree_type', graph);
    const length = graph.length;
    const x = [];
    const y = [];
    
    for(let i=0;i<length;i+=1){
      
        x.push(graph[i].tree_type)
     
    }

    for(let i=0;i<length;i+=1){
      y.push(graph[i].census_trees_count)
    }
    // console.log(x)
    // console.log(y)
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            // text: 'Chart.js Bar Chart',
          },
        },
      };
    
      const data = {
        labels: x,
        datasets: [
          {
            label: 'Tree Type',
            data: y,
            backgroundColor: "rgba(54, 162, 235)",
          },
        ],
      };
      
  return (
   <>
    <Bar options={options} data={data} />
  
   </>
  )
}

export default ByTreeTypeGraph
