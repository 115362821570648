import { SetNewAlert } from './AlertActions';
import JWTServer from '../api/withJWTServer';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import { DEFECIENT_TREE_NOTICE_GENERATED } from './Types';

const DeficientTreeNoticeAction = (council, zone, ward,fromPropertyNumber,toPropertyNumber) => async (dispatch) => {
  let url = `/api/request-deficient-trees?council_id=${council}&zone_id=${zone}&ward_id=${ward}`;
  if(fromPropertyNumber){
    url = `${url}&from_property_number=${fromPropertyNumber}`;
  }
  if(toPropertyNumber){
    url = `${url}&to_property_number=${toPropertyNumber}`
  }

  console.log("URL",url);
  try {
    const response = await JWTServer.post(url);
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    dispatch({
      type: DEFECIENT_TREE_NOTICE_GENERATED,
      payload: response.data,
    });
  } catch (e) {
    dispatch(
      SetNewAlert({
        msg: 'Something went wrong',
        alertType: 'danger',
      })
    );
  }
};

export { DeficientTreeNoticeAction };
