import { filter, includes } from 'lodash';
// import React from 'react';

import React, { useEffect, useState, useRef } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { Visibility } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import OutlinedInput from '@mui/material/OutlinedInput';
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TextField,
    Grid,
    Box,
    Pagination,
    CircularProgress,
    Backdrop,
    Modal,
    InputAdornment
} from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/named
import { UserListToolbar, UserFormListMenu } from '../sections/@dashboard/user';
import ViewImageDialog from '../components/DialogBox/tree-data/ViewImageDialog';
import ImageCarousel from '../components/ImageCarousel';
import USERLIST from '../_mock/user';
import NewUserDialog from '../components/DialogBox/NewUserDialog';
import UserTableData from '../components/JsonFiles/UserTableData.json';
import { AssociateDialog, UserListHead } from '../sections/@dashboard/manager';

import { GetTreeChangeRequest, SearchUsers, AddTreeChangeRequest } from '../actions/ManagerRequestAction';
import { GetActiveTreeName } from '../actions/TreeNameAction';
import StatusButton from '../components/statusbutton/StatusButton';
import warningSound from '../Assets/warning_sound.mp3';
import { GetUsersByRoleID } from '../actions/UserAction'


// import Zone from './Master/Zone';
// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const TABLE_HEAD = [
    { id: 'srno', label: '#', alignRight: false },
    { id: 'treecurrentname', label: 'Current Tree Name', alignRight: false },
    { id: 'treecurrentBname', label: 'Current Tree Botanical Name', alignRight: false },
    { id: 'treecurrentnumber', label: 'Current Tree Number', alignRight: false },
    { id: 'treename', label: 'New Tree Name', alignRight: false },
    { id: 'treeNewBname', label: 'New Tree Botanical Name', alignRight: false },
    { id: 'treenumber', label: 'New Tree Number', alignRight: false },
    { id: 'reuestedby', label: 'Requested By', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
];

// ----------------------------------------------------------------------
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function User() {
    const dispatch = useDispatch();

    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(10);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('Name');
    const [filterName, setFilterName] = useState('');
    const [open, setOpen] = useState(false);
    const [close, setClose] = useState();
    const [dialogData, setDialogData] = useState(null);
    const [search, setSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [pageCountError, setPageCountError] = useState(false);
    const [topModalOpen, setTopModalOpen] = useState(false);
    const [activateModalOpen, setActivateModalOpen] = useState(false);
    const [reqObj, setReqObj] = useState(null);
    const [requireActiveObj, setReqActiveObj] = useState(null);
    const [showCouncil, setShowCouncil] = useState("");
    const [showNewTreeName, setShowNewTreeName] = useState("");
    const [showCurrentTreeName, setShowCurrentTreeName] = useState("");

    const [showCurrentZone, setShowCurrentZone] = useState("")
    const [addWards, setAddWards] = useState("")
    const [addNewWards, setAddNewWards] = useState("")
    const [getCurrentWards, setCurrentWards] = useState([])
    const [getCurrentZone, setCurrentZone] = useState([])
    const [isCurrentWards, setIsCurrentWards] = useState(false)
    const [isCurrentZones, setIsCurrentZones] = useState(false)
    const userPermissions = [];
    const message =
        'Unlinking device will expired the current session of the user and might lose the offline data. Please synch all the Offline data before proceeding.';

    const { state } = useLocation();
    const { users, pageInfo, treeName } = useSelector((state) => ({
        users: state.managerRequestReducer.treeDetails,
        pageInfo: state.managerRequestReducer.pageInfo,
        userByRoleID: state.users.userByRoleID,
        treeName: state.treeName.activeTreeName,
    }));

    useEffect(() => {
        if (state) {
            setPage(state.page);
        }
        dispatch(GetActiveTreeName(1));
    }, []);

    useEffect(() => {
        if (state) {
            setPage(state.page);
            dispatch(GetTreeChangeRequest(state.page, rowsPerPage));
        } else {
            dispatch(GetTreeChangeRequest(page, rowsPerPage));
        }
    }, []);

    useEffect(() => {
        if (pageInfo) {
            setCount(pageInfo?.total);
        }
    }, [pageInfo]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);

        if (search) {
            dispatch(SearchUsers(newPage, rowsPerPage, searchValue));
        } else {
            dispatch(GetTreeChangeRequest(newPage, rowsPerPage));
        }
    };


    let timer = null;
    const filterByName = (event) => {
        const value = event.currentTarget.value;
        clearTimeout(timer);
        // Wait for X ms and then process the request
        timer = setTimeout(() => {
            if (value) {
                dispatch(SearchUsers(1, rowsPerPage, value));
                setSearch(true);
                setPage(1);
                setSearchValue(value);
            } else {
                dispatch(GetTreeChangeRequest(1, rowsPerPage));
                setSearch(false);
                setPage(1);
                setSearchValue('');
            }
        }, 1000);
    };
    const inputRef = useRef(null);
    const onChangeCouncil = (e) => {
        setShowCurrentTreeName(e.target.value)
    }
    const onChangeNewTree = (e) => {
        setShowNewTreeName(e.target.value)
        setBoticalTreeName(e.target.value)
    }
    const setBoticalTreeName = (getTreeId) => {
        treeName.map((obj) => {
            if (obj.id === getTreeId) {
                inputField.newTreeBNumber = obj?.botanical_name
            }
            return null
        })
    }
    const [inputField, setInputField] = useState({
        treeNewNumber: "",
        newTreeBNumber: ""
    });
    const inputsHandler = (e) => {
        const { name, value } = e.target;
        setInputField((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const [getCensusTreeId, setCensusTreeId] = useState("")
    const [getRequestById, setRequestById] = useState("")
    const [treeBNumber, setTreeBNumber] = useState("")
    const [treeNumber, setTreeNumber] = useState("")
    const [fixedTreeNumber, setFixedTreeNumber] = useState("")
    const [treeNumberError, setTreeNumberError] = useState("")
    const [getChangeRequestStatus, setChangeRequestStatus] = useState("")
    const handleClickOpen = (e, option) => {
        setOpen(true);
        setCensusTreeId(option.id)
        if (option) {
            setInputField({
                treeNewNumber: option?.tree_number,
                newTreeBNumber: option?.tree_name?.botanical_name

            })
            setFixedTreeNumber(option?.tree_number)
        }
        // setChangeRequestStatus(option.change_request_status)
        setShowNewTreeName(option.tree_name?.id)
        setShowCurrentTreeName(option.census_tree?.tree_name?.id)
        setTreeBNumber(option.census_tree?.tree_name?.botanical_name)
        setTreeNumber(option.census_tree?.tree_number)
        dispatch(GetActiveTreeName(1))
    };
    const handleClose = () => {
        setOpen(false);
    };
    

    const DesignationsSchema = Yup.object().shape({
        // treeNumber: Yup.string().required('Tree number is required'),
        treeNewNumber: Yup.number().typeError('Tree number must be number').required('Tree number is required'),
      });
    
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
          treeNewNumber: fixedTreeNumber ? fixedTreeNumber.split('-')[fixedTreeNumber.split('-').length-1] : "1111"
        },
        validationSchema: DesignationsSchema,
        onSubmit: (value) => {
            handleSaveAction();
        }
     
      });
      const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleSaveAction = () => {
        const reqObj = {};
        if(fixedTreeNumber){
            reqObj.tree_number = `${fixedTreeNumber?.replace(reLast, '')}-${inputField.treeNewNumber.split('-')[inputField.treeNewNumber.split('-').length-1]}`
        }
        if(showNewTreeName){
            reqObj.tree_name_id = showNewTreeName
        }
        dispatch(AddTreeChangeRequest(reqObj, getCensusTreeId))
        // "change_request_status": "approved_by_admin"
        setTimeout(() => {
            setOpen(false);
            dispatch(GetTreeChangeRequest(1, rowsPerPage));
        }, 3000)
    }
    const [viewOpen, setViewOpen] = React.useState(false);
    const [imageList, setImageList] = React.useState([]);
    const [openImageList, setOpenImageList] = React.useState(false);
    const handleOpenImageList = (e) => setOpenImageList(true);
    const handleCloseImageList = () => setOpenImageList(false);
    const handleViewOpen = (images) => {
        setViewOpen(!viewOpen);
        setImageList(images || []);
    };

    const reLast = /-\d+$/;

    return (
        <div>
            {viewOpen ? <ViewImageDialog isOpen={viewOpen} handleClose={handleViewOpen} data={imageList} /> : null}
            <Page title="User">

                <Container>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                        <Typography variant="h4" gutterBottom>
                            Tree Data Changes
                            <Typography variant="h6" style={{ fontWeight: 400 }}  >
                                It is showing list of all tree data changes with its details
                            </Typography>
                        </Typography>
                    </Stack>
                    <Card>
                        <UserListToolbar numSelected={0} placeHolder={'Search users...'} onFilterName={filterByName} />
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table size="small" aria-label="a dense table">
                                    <UserListHead
                                        headLabel={TABLE_HEAD}
                                        data={dialogData}
                                    />
                                    <TableBody>
                                        {users?.map((option, index) => {
                                            return (
                                                <TableRow hover>
                                                    <TableCell align="left">
                                                        <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: '400px' }} >
                                                        {`${option.census_tree?.tree_name?.name?option.census_tree?.tree_name?.name:'-'}`}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: '400px' }} >
                                                        {`${option.census_tree?.tree_name?.botanical_name?option.census_tree?.tree_name?.botanical_name:'-'}`}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: '400px' }} >
                                                        {`${option.census_tree?.tree_number?option.census_tree?.tree_number:'-'}`}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: '400px' }} >
                                                        {`${option.tree_name?.name?option.tree_name?.name:'-'}`}
                                                    </TableCell>
                                                    <TableCell align="left" style={{ width: '400px' }} >
                                                        {`${option.tree_name?.botanical_name?option.tree_name?.botanical_name:"-"}`}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {`${option.tree_number?option.tree_number:"-"}`}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {
                                                            option.requested_by ?
                                                                <div>
                                                                    {`${option.requested_by?.first_name}`} {`${option.requested_by?.last_name}`}
                                                                </div>
                                                                :
                                                                <div>-</div>
                                                        }
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {option.change_request_status}
                                                        {/* <StatusButton status={option.change_request_status} /> */}
                                                    </TableCell>
                                                    <TableCell align="left">

                                                        {/* <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}> */}
                                                        <IconButton aria-label="delete" size="large"
                                                            // onClick={() => handleViewOpen(props.data.images)} 
                                                            onClick={(e) => {
                                                                setImageList(option.census_tree?.images);
                                                                handleOpenImageList(e);
                                                            }}
                                                            sx={{ color: '#214c50' }}>
                                                            <Visibility />
                                                        </IconButton>

                                                        {/* </td> */}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <div>
                                                            <Button variant="outlined" fullWidth style={{ width: "150px" }} id={option.id} onClick={e => { handleClickOpen(e, option) }}>
                                                                take Action
                                                            </Button>
                                                            <BootstrapDialog
                                                                fullWidth
                                                                onClose={handleClose}
                                                                aria-labelledby="customized-dialog-title"
                                                                open={open}

                                                            >
                                                                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                                                    Tree Detail Change
                                                                </BootstrapDialogTitle>
                                                                <DialogContent dividers>
                                                                    <Typography gutterBottom>
                                                                        <Grid container spacing={1}>
                                                                        {showNewTreeName?
                                                                            <Grid item md={6} sm={6} xs={6}>
                                                                                <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                                                                    <InputLabel id="demo-multiple-name-label">Current Tree Name</InputLabel>
                                                                                    <Select
                                                                                        fullWidth
                                                                                        displayEmpty
                                                                                        disabled
                                                                                        labelId="demo-multiple-name-label"
                                                                                        id="demo-multiple-name"
                                                                                        value={showCurrentTreeName}
                                                                                        onChange={onChangeCouncil}
                                                                                        input={<OutlinedInput label="Associate User" />}
                                                                                        MenuProps={MenuProps}
                                                                                    >
                                                                                        {treeName?.map((option) => (
                                                                                            <MenuItem key={option.value} value={option.id}>
                                                                                                {option.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid>
                                                                            :null}
                                                                            {showNewTreeName?
                                                                            <Grid item md={6} sm={6} xs={6}>
                                                                                <FormControl style={{ width: '100%', margin: '3% 0px' }}>
                                                                                    <InputLabel id="demo-multiple-name-label">New Tree Name</InputLabel>
                                                                                    <Select
                                                                                        fullWidth
                                                                                        displayEmpty
                                                                                        labelId="demo-multiple-name-label"
                                                                                        id="demo-multiple-name"
                                                                                        value={showNewTreeName}
                                                                                        onChange={onChangeNewTree}
                                                                                        input={<OutlinedInput label="Associate User" />}
                                                                                        MenuProps={MenuProps}
                                                                                    >
                                                                                        {treeName?.map((option) => (
                                                                                            <MenuItem key={option.value} value={option.id}>
                                                                                                {option.name}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Grid>:null}
                                                                            {showNewTreeName?
                                                                            <Grid item md={6} sm={6} xs={6}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="outlined-basic"
                                                                                    disabled
                                                                                    label="Current Tree B Name"
                                                                                    variant="outlined"
                                                                                    name="treeBNumber"
                                                                                    value={treeBNumber}
                                                                                    onChange={inputsHandler}
                                                                                />
                                                                            </Grid>:null}
                                                                            {showNewTreeName?
                                                                            <Grid item md={6} sm={6} xs={6}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="outlined-basic"
                                                                                    label="New Tree B Name"
                                                                                    variant="outlined"
                                                                                    name="newTreeBNumber"
                                                                                    value={inputField.newTreeBNumber}
                                                                                    onChange={inputsHandler}
                                                                                />
                                                                            </Grid>:null}
                                                                            {fixedTreeNumber?
                                                                            <Grid item md={6} sm={6} xs={6}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    disabled
                                                                                    id="outlined-basic"
                                                                                    label="Current Tree Number"
                                                                                    variant="outlined"
                                                                                    name="treeNumber"
                                                                                    value={treeNumber}
                                                                                    onChange={inputsHandler}
                                                                                />
                                                                            </Grid>:null}
                                                                            {fixedTreeNumber?
                                                                            <Grid item md={6} sm={6} xs={6}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="outlined-basic"
                                                                                    label="New Tree Number"
                                                                                    variant="outlined"
                                                                                    name="treeNewNumber"
                                                                                    value={inputField.treeNewNumber.split('-')[inputField.treeNewNumber.split('-').length-1]}
                                                                                    onChange={(e) => {
                                                                                        inputsHandler(e)
                                                                                        formik.handleChange(e);
                                                                                      }}
                                                                                    // onChange={inputsHandler}
                                                                                    InputProps={{
                                                                                        startAdornment: <InputAdornment position="start">{`${treeNumber?.replace(reLast, '')}-`}</InputAdornment>,
                                                                                      }}
                                                                                    error={Boolean(touched.treeNewNumber && errors.treeNewNumber)}
                                                                                    helperText={touched.treeNewNumber && errors.treeNewNumber}
                                                                            />
                                                                            </Grid>:null}
                                                                            <Grid md={6}>
                                                                                <IconButton aria-label="delete" size="large"
                                                                                    // onClick={() => handleViewOpen(props.data.images)} 
                                                                                    onClick={(e) => {
                                                                                        setImageList(option.census_tree?.images || []);
                                                                                        handleOpenImageList(e);
                                                                                    }}
                                                                                    sx={{ color: '#214c50' }}>
                                                                                    <Visibility />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Typography>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button variant='contained' autoFocus onClick={handleSubmit}>
                                                                        Approve
                                                                    </Button>
                                                                    {/* <Button autoFocus onClick={handleClose}>
                                                                    Cancel
                                                                </Button> */}
                                                                </DialogActions>
                                                            </BootstrapDialog>
                                                        </div>

                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <Box>
                            {(users) ? (
                                <Pagination
                                    count={pageInfo.last_page}
                                    page={page}
                                    variant="outlined"
                                    shape="rounded"
                                    onChange={handleChangePage}
                                    sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
                                />
                            ) : null}
                        </Box>
                    </Card>
                    <Modal
                        open={openImageList}
                        onClose={handleCloseImageList}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Container style={{ width: '526px' }}>
                            <ImageCarousel imagelist={imageList} />
                        </Container>
                        {/* <Box sx={style}>
                                <img src={val.original} alt="gallery" height="650px" width="100%" />
                              </Box> */}
                    </Modal>
                </Container>
            </Page >
        </div>
    );
}
