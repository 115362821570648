import React, { useEffect, useState } from "react";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { getIcon } from "@iconify/react";
import {
  CircularProgress, IconButton, List, ListItem, Container,
  Modal, ListItemText, Table, TableBody, TableCell, tableCellClasses, TableRow
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import UserCircle from '../../Assets/location.png';
import { GetSpecificTreeInfo } from "../../actions/TreeOnMapAction";
import { ShowLoader } from "../../actions/CommonAction";
import ViewImageDialog from "../../components/DialogBox/tree-data/ViewImageDialog";
import ImageCarousel from "../../components/ImageCarousel";



const markers = [
  {
    id: 1,
    name: "Chicago, Illinois",
    height: "7 Meter",
    age: "20 Year",
    image: "https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg",
    position: { lat: 41.881832, lng: -87.623177 }
  },
  {
    id: 2,
    name: "Denver, Colorado",
    height: "7 Meter",
    age: "20 Year",
    image: "https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg",
    position: { lat: 39.739235, lng: -104.99025 }
  },
  {
    id: 3,
    name: "Los Angeles, California",
    height: "7 Meter",
    age: "20 Year",
    image: "https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg",
    position: { lat: 34.052235, lng: -118.243683 }
  },
  {
    id: 4,
    name: "New York, New York",
    height: "7 Meter",
    age: "20 Year",
    image: "https://images.pexels.com/photos/1459495/pexels-photo-1459495.jpeg?cs=srgb&dl=pexels-felix-mittermeier-1459495.jpg&fm=jpg",
    position: { lat: 40.712776, lng: -74.005974 }
  }
];

const center = {
  lat: 21.7679,
  lng: 78.8718
};

function Map(props) {
  const dispatch = useDispatch();
  const [activeMarker, setActiveMarker] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [openImageList, setOpenImageList] = useState(false);
  const handleOpenImageList = (e) => setOpenImageList(true);
  const handleCloseImageList = () => setOpenImageList(false);
  const {
    treeDetails,
    showLoader
  } = useSelector((state) => ({
    treeDetails: state.treeLocation.treeDetails,
    showLoader: state.common.showLoader
  }));

  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    dispatch(ShowLoader(false));
  }, [treeDetails])

  useEffect(() => {
    setActiveMarker(null);
  }, [props.treeLocation])

  const icon = {
    url: UserCircle,
    scaledSize: new window.google.maps.Size(50, 42)
  };


  const handleViewOpen = (images) => {
    setViewOpen(!viewOpen)
    const imageList = [];
    if (images) {
      images.map((value, index) => {
        imageList.push(value.image_url)
        return null;
      })
    }
    setImageList(imageList);
  }
 
  return (
    <GoogleMap
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={{ width: "100%", height: "500px" }}
      zoom={5}
      center={center}
    >
      {viewOpen ?
        <ViewImageDialog
          isOpen={viewOpen}
          handleClose={handleViewOpen}
          data={imageList}
        /> : null
      }
      {props.treeLocation?
      <Marker
        position={{ lat: Number(props.lat), lng: Number(props.long) }}
        icon={icon}
        // title={`Last Updated At: \n ${props.UserLastSeen}`}
      
      />
      :null}
              <Modal
        open={openImageList}
        onClose={handleCloseImageList}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Container style={{ width: '526px' }}>
          <ImageCarousel imagelist={imageList} />
        </Container>
      </Modal>
    </GoogleMap >
  );
}

export default Map;
