import {
  GET_NEWS_PAPERS,
  GET_NEWS_PAPER_BY_ID,
  ADD_NEWS_PAPER,
  UPDATE_NEWS_PAPER,
  DELETE_NEWS_PAPER,
  RESET_STATE,
} from '../actions/Types';

const INIT_STATE = {
  newsPapers: null,
  singleNewsPaper: null,
  addNewsPapaerLog: false,
  updateNewsPaperLog: false,
  deleteNewsPaperLog: false,
  pageInfo: {},
  newsPaperLoader: true,
};

export default function NewsPaperReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_NEWS_PAPERS:
      return {
        ...state,
        newsPapers: payload.data.data,
        pageInfo: payload.data,
        newsPaperLoader: false,
      };
    case GET_NEWS_PAPER_BY_ID:
      return {
        ...state,
        singleNewsPaper: payload.data,
        pageInfo: payload.data,
        newsPaperLoader: false,
      };
    case ADD_NEWS_PAPER:
      return {
        ...state,
        newsPapers: payload.data,
        pageInfo: payload.data,
        addNewsPaperLog: true,
        newsPaperLoader: false,
      };
    case UPDATE_NEWS_PAPER:
      return {
        ...state,
        newsPapers: payload.data,
        pageInfo: payload.data,
        updateNewsPaperLog: true,
        newsPaperLoader: false,
      };
    case DELETE_NEWS_PAPER:
      return {
        ...state,
        // newsPapers: payload.data.data,
        // pageInfo: payload.data,
        deleteNewsPaperLog: true,
        newsPaperLoader: false,
      };

    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
}
