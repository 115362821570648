import JWTServer from '../api/withJWTServer';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  GET_SPECIFIC_TREE_LOCATION_DETAILS,
  GET_TREE_LOCATION,
  SEARCH_TREES_IN_5KM,
  GET_TREE_BY_NUMBER,
} from './Types';

const GetAllTreeLocation = (council, zone, ward, fromDate, toDate, treeNumber) => async (dispatch) => {
  let url = `api/get-locations/census-trees`;
  if (council) {
    url = `${url}?where[council_id]=${council}`;
  }
  if (treeNumber) {
    url = `${url}&where[tree_number]=${treeNumber}`;
  } else {
    if (zone) {
      url = `${url}&where[zone_id]=${zone}`;
    }
    if (ward) {
      url = `${url}&where[ward_id]=${ward}`;
    }
    if (fromDate && toDate) {
      url = `${url}&where[from_date]=${fromDate}&where[to_date]=${toDate}`;
    }
  }
  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_TREE_LOCATION,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetSpecificTreeInfo = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/census-trees/${params}`);
    dispatch({
      type: GET_SPECIFIC_TREE_LOCATION_DETAILS,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchTreesInSpecificLocation = (params) => async (dispatch) => {
  try {
    console.log('Response1', params.latitude);
    console.log('Response2', params.longitude);
    const response = await JWTServer.get(
      `/api/search-locations/census-trees?latitude=${params.latitude}&longitude=${params.longitude}`
    );
    dispatch({
      type: SEARCH_TREES_IN_5KM,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SEARCH_TREES_IN_5KM,
      payload: {data:[]},
    });
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchTreesInSpecificLocationByMoveOrZoom = (params) => async (dispatch) => {
  try {
    console.log('Response1', params.latitude);
    console.log('Response2', params.longitude);
    const response = await JWTServer.get(
      `/api/search-locations/census-trees?latitude=${params.latitude}&longitude=${params.longitude}`
    );
    dispatch({
      type: SEARCH_TREES_IN_5KM,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: SEARCH_TREES_IN_5KM,
      payload: {data:[]},
    });
    // dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const SearchTreesByTreeNumber = (treeNumber, council) => async (dispatch) => {
  try {
    const response = await JWTServer.get(
      `/api/census-trees/by-tree-number/${treeNumber}?council_id=${council}&is_search=true`
    );
    dispatch({
      type: GET_TREE_BY_NUMBER,
      payload: response.data,
    });
    return response;
  } catch (e) {
    // dispatch(HandleExceptionWithSecureCatch(e));
  }
};
export { GetAllTreeLocation, GetSpecificTreeInfo, SearchTreesInSpecificLocation, SearchTreesByTreeNumber,SearchTreesInSpecificLocationByMoveOrZoom };
