import { useEffect, useRef, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
} from '@mui/material';
import moment from 'moment';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { downloadExcel } from 'react-export-table-to-excel';
import { fDateTimeHyf, fDateTimeShort } from '../../../utils/formatTime';
import Page from '../../../components/Page';
import Scrollbar from '../../../components/Scrollbar';
import { UserListHead } from '../../../sections/@dashboard/user';
import CallLogToolBar from '../../../sections/@dashboard/associateTracking/CallLogToolBar';
import { ExportCallLog, GetCallLog } from '../../../actions/CallLogAction';
import { GetUsersByRoleArray } from '../../../actions/UserAction';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'phone number', label: 'To Mobile Number', alignRight: false },
  { id: 'callerName', label: 'To User', alignRight: false },
  { id: 'fromUser', label: 'From User', alignRight: false },
  { id: 'date', label: 'Called At', alignRight: false },
  { id: 'callEndDate', label: 'Called End At', alignRight: false },
  { id: 'Duration', label: 'Call Duration', alignRight: false },
  { id: 'callType', label: 'Call Type', alignRight: false },
];

// ----------------------------------------------------------------------

export default function CallLog() {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState({ label: '', value: '' });
  const [toDate, setToDate] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [formattedDate, setFormattedDate] = useState('');
  const [exportButtonPressed, setExportButtonPressed] = useState(false);
  const [showList, setShowList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const limit = 10;

  const { users, callLogs,exportCallLogs,isReportDownloadable } = useSelector((state) => ({
    users: state.users.userByRoleID,
    callLogs: state.callLogs.callLogs,
    exportCallLogs: state.callLogs.exportCallLogs,
    isReportDownloadabletCallLogs: state.callLogs.isReportDownloadable
  }));

  const secondRun = useRef(true);
  useEffect(() => {
    dispatch(GetUsersByRoleArray(1, [3, 4, 5, 6, 7, 8, 11]));
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    setShowList(true);
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [callLogs]);

  let timer = null;

  const filterByName = (event) => {
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (userName.value && toDate) {
        dispatch(GetCallLog(userName.value, toDate || null, page, limit));
      }
    }, 1000);
  };

  const UserList = users?.map((item) => ({ value: item.id, label: `${item.first_name} ${item.last_name}` }));

  useEffect(() => {
    if (userName.value) {
      setLoading(true);
      if(fromDate && toDate){
        dispatch(GetCallLog(userName.value, moment(new Date(fromDate)).format('DD-MM-YYYY hh:mm a'), moment(new Date(toDate)).format('DD-MM-YYYY hh:mm a'), page, limit));
      }
      else {
        dispatch(GetCallLog(userName.value, null, null, page, limit));
      }
      
      setShowList(true);
    }
  }, [userName, page]);

  useEffect(() => {
    if (userName.value && fromDate && toDate) {
      setLoading(true);
      dispatch(GetCallLog(userName.value, moment(new Date(fromDate)).format('DD-MM-YYYY hh:mm a'), moment(new Date(toDate)).format('DD-MM-YYYY hh:mm a'), page, limit));
      setShowList(true);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel()
      setExportButtonPressed(false);
    }
  }, [isReportDownloadable,exportCallLogs]);

  const header = ["#", "To Mobile Number", "To User", "From User", "Called At", "Called End At", "Call Duration", "Call Type"];
  function DownloadReportExcel() {
    const value1= [];
    exportCallLogs?.map((option, index) => {
      const value2 = [index+1]
      value2.push(option.phone_number)
      value2.push(option.caller_name)
      value2.push(option.from_user)
      value2.push(option.call_date)
      value2.push(option.call_end_date)
      value2.push(option.duration)
      value2.push(option.call_type)
      value1.push(value2)
      return null
    })
    downloadExcel({
      fileName: "Call Log Report",
      sheet: "Report",
      tablePayload: {
        header,
        // accept two different data structures
        body: value1
      },
    });
  }

  const handleSetToDate = (e) => {
    // setLoading(true);
    if (e.target.value) {
      // const formattedDate = fDateTimeHyf(e.target.value);
      setToDate(e.target.value);
      // setFormattedDate(formattedDate);
    } else {
      setToDate('');
      // setFormattedDate('');
    }
  };

  const handleSetFromDate = (e) => {
    // setLoading(true);
    if (e.target.value) {
      setFromDate(e.target.value);
      
    } else {
      setFromDate('');
    }
  };

  const handleUserNameChange = (e) => {
    setLoading(true);
    // kajal changes
    setUserName(e);
    setToDate(toDate);
  };

  const handleExportCallLogReport = (e) => {
    if (userName.value) {
      if(fromDate && toDate){
        dispatch(ExportCallLog(userName.value, moment(new Date(fromDate)).format('DD-MM-YYYY hh:mm a'), moment(new Date(toDate)).format('DD-MM-YYYY hh:mm a')));
      }
      else {
        dispatch(ExportCallLog(userName.value, null, null));
      }

      setExportButtonPressed(true);
      
    }
  }

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setPage(newPage);
  };

  const useStyles = makeStyles({
    button: {
      backgroundColor: '#d0fae2',
      borderRadius: '5px',
      padding: '2px 10px',
      color: '#18a553',
      border: '1.5px solid #18a553',
      fontFamily: 'Poppins',
    },
    danger: {
      backgroundColor: '#F6DDDD',
      borderRadius: '5px',
      padding: '3px',
      color: '#C0374E',
      border: '1.5px solid #C0374E',
      fontFamily: 'Poppins',
      width: '150px',
      fontSize: '12px',
    },
    successDark: {
      backgroundColor: '#DDFAD1',
      borderRadius: '5px',
      padding: '3px',
      color: '#507C59',
      border: '1.5px solid #507C59',
      fontFamily: 'Poppins',
      width: '150px',
      fontSize: '12px',
    },
    warning: {
      backgroundColor: '#F8EED4',
      borderRadius: '5px',
      padding: '3px 0px',
      color: '#E46727',
      border: '1.5px solid #E46727',
      fontFamily: 'Poppins',
      width: '150px',
      fontSize: '12px',
    },
  });
  const classes = useStyles();

  const renderTableData = () => {
    if (callLogs?.data && callLogs?.data.length > 0) {
      return callLogs?.data.map((option, index) => (
        <TableRow hover>
          <TableCell align="left">{(page - 1) * limit + (index + 1)}</TableCell>
          <TableCell align="left" sx={{ display: 'flex', alignItems: 'center' }}>
            <PhoneInTalkIcon
              fontSize={'small'}
              style={{
                color: '#fff',
                background: '#CE5623',
                borderRadius: '15px',
                padding: '3px',
              }}
            />
            &nbsp;
            {option.phone_number}
          </TableCell>
          <TableCell align="left">{option.caller_name}</TableCell>
          <TableCell align="left">{option.from_user}</TableCell>
          <TableCell align="left">{option.call_date}</TableCell>
          <TableCell align="left">{option.call_end_date}</TableCell>
          <TableCell align="left">{option.duration}</TableCell>
          <TableCell align="left">{option.call_type}</TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow>
        <TableCell align="center" colSpan={8} fontWeight={700}>
          {/* kajal change */}
          {callLogs?.data.length <1 && (
            <Typography variant="h6" style={{ fontWeight: 500, marginTop: 15, marginBottom: 15 }}>
              No Data found
            </Typography>
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Page title="TeamList">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0.5}>
          <Typography variant="h4" gutterBottom>
            Users call logs
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing Call logs of users
            </Typography>
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, minHeight: 400 }}>
              <CallLogToolBar
                toDate={toDate}
                fromDate={fromDate}
                handleToDate={handleSetToDate}
                handleFromDate = {handleSetFromDate}
                userName={userName.value}
                options={UserList}
                handleUserNameChange={handleUserNameChange}
                handleExportCallLogReport = {handleExportCallLogReport}
              />
              <Table size="small" aria-label="a dense table">
                <UserListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {!userName.value ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} fontWeight={700}>
                        <Typography variant="h6" style={{ fontWeight: 500, marginTop: 15, marginBottom: 15 }}>
                          Please select user to filter logs by user
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8} fontWeight={700}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  ) : (
                    renderTableData()
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          {callLogs.last_page > 1 && (
            <Pagination
              count={showList ? callLogs.last_page : 0}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          )}
        </Card>
      </Container>
    </Page>
  );
}
