import JWTServer from "../api/withJWTServer";
import { SetNewAlert } from "./AlertActions";
import { HandleExceptionWithSecureCatch } from "./CombineCatch";
import { CREATE_TRAINING, DELETE_TRAINING, GET_TRAINING, UPDATE_TRAINING } from "./Types";

const GetTraining = (page,limit,roleId) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/trainings?page=${page}&limit=${limit}&where[role_id]=${roleId}`);
      // console.log("DESIGNATIONS RESPONSE",response.data);
      dispatch({
        type: GET_TRAINING,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  const SearchTraining = (page,limit,roleId,searchValue) => async (dispatch) => {
    try {
      const response = await JWTServer.get(`/api/trainings?page=${page}&limit=${limit}&where[role_id]=${roleId}&search=${searchValue}`);
      dispatch({
        type: GET_TRAINING,
        payload: response.data,
      });
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };


  const AddTraining = (params) => async (dispatch) => {
      // console.log("ADD ROLE PARAMS",params);
    try {
      const response = await JWTServer.post("/api/training",params);
      dispatch({
        type: CREATE_TRAINING,
        payload: response.data,
      });
      dispatch(SetNewAlert({
        msg: response.data.message,
        alertType: "success",
      }));
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const EditTraining = (params,trainingId) => async (dispatch) => {
    try {
      const response = await JWTServer.put(`/api/training/${trainingId}`,params);
      dispatch({
        type: UPDATE_TRAINING,
        payload: response.data,
      });
      dispatch(SetNewAlert({
        msg: response.data.message,
        alertType: "success",
      }));
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  const DeleteTraining = (params,status) => async (dispatch) => {
    try {
      const response = await JWTServer.delete(`/api/training/${params}`);
      dispatch({
        type: DELETE_TRAINING,
        payload: response.data,
      });
      dispatch(
        SetNewAlert({
          msg: `${'Deleted Successfully!'}`,
          alertType: 'success',
        })
      );
    } catch (e) {
      dispatch(HandleExceptionWithSecureCatch(e));
    }
  };

  export {
      GetTraining,
      AddTraining,
      EditTraining,
      DeleteTraining,
      SearchTraining
  }