import * as React from 'react';
import * as Yup from 'yup';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { TextField, Typography } from '@mui/material';
import { AddRole, EditRole, GetPermission, GetRoleById } from '../../actions/RoleAction';
import DefaultInput from '../Inputs/DefaultInput';
import { UploadFile } from '../../actions/UploadActions';
import { AddTraining, EditTraining } from '../../actions/TrainingAction';


const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TrainingDialog(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [status, setStatus] = React.useState('Status')
  const [uploadedPdf, setUploadedPdf] = React.useState('')
  const [fileUploadError, setFileUploadError] = React.useState('');
  const [fileSizeError, setFileSizeError] = React.useState('');
  const [showUploadLoader, setShowUploadLoader] = React.useState(false);
  const { isOpen, data,selectedRole } = props;

  const {
    addTrainingLog,
    editTrainingLog,
    roles,
    uploadFile,
    uploadFileLog
  } = useSelector((state) => ({
    addTrainingLog:state.trainings.addTrainingLog,
    editTrainingLog:state.trainings.editTrainingLog,
    roles:state.roles.roles,
    uploadFile: state.upload.uploadFile,
    uploadFileLog: state.upload.uploadFileLog,
  }));


  React.useEffect(()=>{
    if(data && isOpen){
      // dispatch(GetRoleById(data.id));
      setUploadedPdf(data.pdf_url)
    }
  },[data])

  const firstRun = React.useRef(true);
  React.useEffect(()=>{
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    props.handleClose()
  },[addTrainingLog,editTrainingLog])


  const handleClose = () => {
    props.handleClose();
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };



  const findValue = (listOfObj,id) => {
    const found = listOfObj.find(e => e.id === id);
    if(found){
      return found.display_name
    }
    
  }

  const thirdRun = React.useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    if (uploadFile) {
      setUploadedPdf(uploadFile.url);
      setShowUploadLoader(false)
    }
  }, [uploadFileLog]);

  const handlePdfRemove = (e) => {
    setUploadedPdf('');
    setFieldValue('trainingPdf','')
  };

  const handleViewDocument = (fpath) => {
    if (fpath.includes(process.env.REACT_APP_BASE_URL)) {
      // console.log("file path", fpath);
      window.open(fpath, '_blank');
    } else {
      const fLink = process.env.REACT_APP_BASE_URL.concat('/').concat(fpath);
      //  console.log("file path", fLink);
      window.open(fLink, '_blank');
    }
  };

  const pdfChange = (e) => {
   
      const i = parseInt(Math.floor(Math.log(e.target.files[0].size) / Math.log(1024)), 10);
      const validExtensions = ['pdf'];
      const fileExtension = e.target.files[0].name.split('.')[1];
      // console.log(fileExtension);
      if (validExtensions.includes(fileExtension)) {
        setFileUploadError('');
        console.log("FILE SIZE",e.target.files[0].size)
        if (e.target.files[0].size < 15728640) {
          setFileSizeError('');
          const formData = new FormData();
          formData.append('upload_for', 'trainings');
          formData.append('file', e.target.files[0]);
          setShowUploadLoader(true)
          dispatch(UploadFile(formData, 1)).then((response) => {
            // console.log("upload file",response);
          });
        } else {
          setFileUploadError('Please upload documents within 15MB only');
        }
      } else {
        setFileUploadError('Please upload documents with pdf format only');
      }
  }

  const DistrictsSchema = Yup.object().shape({
    roles: Yup.string().required('Role is required'),
    topic: Yup.string().required('Topic is required'),
    description: Yup.string().required('Description is required'),
    trainingPdf: Yup.string().required('Pdf is required'),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      roles: data? selectedRole : selectedRole,
      topic: data? data.topic : "",
      description: data ? data.description : "",
      trainingPdf : data ? data.pdf_url : ""
    },
    validationSchema: DistrictsSchema,
    onSubmit: (value) => {
      if(data){
        dispatch(EditTraining({
          "role_id": selectedRole,
          "topic": value.topic,
          "description": value.description, 
          "pdf_url": uploadedPdf
        },data.id))
      }
      else {
        let uplaodedPdfUrl = uploadedPdf;
        if (uploadedPdf.includes(process.env.REACT_APP_BASE_URL)) {
          // console.log("file path", fpath);
          uplaodedPdfUrl = uploadedPdf.replace(`${process.env.REACT_APP_BASE_URL}/`,"")
        }
        dispatch(AddTraining({
          "role_id": selectedRole,
          "topic": value.topic,
          "description": value.description, 
          "pdf_url": uplaodedPdfUrl 
        }))
      }
    },
  });

  const useStyles = makeStyles({
    
    icon: {
        fill: '#214C50',
    },
   
})
const classes = useStyles()
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  console.log(" values",values);
  return (
    <div>
     
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        
        // onClose={handleClose}
      >
        <BootstrapDialogTitle onClose={handleClose}>{data? `Edit Training` : `Create Training`}</BootstrapDialogTitle>
        <Divider/>
        <DialogContent>
        <Grid container spacing={1}>
        <Grid item xs={12} md={12} sm={12} >
          <TextField
                select
                id="roles"
                name="roles"
                displayEmpty
                disabled
                label="Role*"
                value={selectedRole}
                style={{ width: '82.5%', marginLeft: '40px' }}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                // {...getFieldProps("state")}
              >
                <MenuItem disabled value="">
                  <em>Role*</em>
                </MenuItem>
                {roles?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.role}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <TextField
                fullWidth
                id="topic"
                name = "topic"
                label="Topic*"
                placeholder="Topic*"
                style={{ width: '82.5%',marginLeft: 40, marginTop: 5 }}
                value={values.topic}
                error={Boolean(touched.topic && errors.topic)}
                helperText={touched.topic && errors.topic}
               {...getFieldProps('topic')}
              />
            </Grid>

            <Grid item xs={12} md={12} sm={12}>
              <TextField
                fullWidth
                multiline
                rows={2}
                id="description"
                name = "description"
                label="Description*"
                placeholder="Description*"
                style={{ width: '82.5%',marginLeft: 40, marginTop: 5 }}
                value={values.description}
                error={Boolean(touched.description && errors.description)}
                helperText={touched.description && errors.description}
               {...getFieldProps('description')}
              />
            </Grid>

            <BootstrapDialogTitle id="customized-dialog-title" style={{ marginLeft: '40px' }}>
                {uploadedPdf ? 'Uploaded Pdf' : 'Upload Pdf*'}
              </BootstrapDialogTitle>

              {uploadedPdf ?
              <Grid container spacing={1}>
              <Grid item xs={12}>
                  <Button
                      variant="outlined"
                      target="_blank"
                      rel="noopener"
                      onClick={() => {
                        handleViewDocument(uploadedPdf);
                      }}
                      style={{ marginTop: '5px', marginLeft:'42px' }}
                    >
                      View Pdf
                    </Button>

                <IconButton
                  color={'error'}
                  aria-label={'delete'}
                  size="large"
                  onClick={() => handlePdfRemove(uploadedPdf)}>
                  <CancelIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>:

            <Grid item xs={12} md={12} sm={12}>
              <TextField
                      fullWidth
                      style={{ width: '80%', marginLeft: '40px' }}
                      id="trainingPdf"
                      type={'file'}
                      // placeholder="Upload Logo"
                      value={values.trainingPdf}
                      error={Boolean(touched.trainingPdf && errors.trainingPdf)}
                      helperText={touched.trainingPdf && errors.trainingPdf}
                      onChange = { (e) => {
                        formik.handleChange(e)
                        pdfChange(e)
                      }
                      }
              />
            <Typography variant="body2" color={'#FF0000'} style={{marginLeft:'40px'}}>
              {fileUploadError}
            </Typography>
            </Grid>

              }

            
          </Grid>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button onClick={handleSubmit} style={{background: '#214c50', color: '#fff'}}>{data?"Save":"Add"}</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
