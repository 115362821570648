import { Chip } from '@material-ui/core';
import { green, orange, red, gray } from '@mui/material/colors';
import React from 'react';

const ApplicationStatusChip = ({ status }) => {
  console.log("qasdf",status);
  let chipColor = '';
  const chipLabel = status;
  console.log("qaaa",chipLabel);

  switch (status) {
    case 'New Application':
    case 'Awaiting Inspection':
      chipColor = green[600];
      break;
    case 'In Inspection':
      chipColor = orange[600];
      break;
    case 'Awaiting Deposit Amount':
      chipColor = ' #bdbdbd';
      break;
    case 'Awaiting for Meeting Approval':
      chipColor = ' #bdbdbd';
      break;
    case 'Approved':
      chipColor = ' #1b3f42';
      break;
    case ' Partially Approved':
      chipColor = ' ##FFB347';
      break;
    case 'Rejected':
      chipColor = ' #bdbdbd';
      break;
    case 'Awaiting Jahirnama':
      chipColor = ' #bdbdbd';
      break;
    case 'Awaiting for Objections':
      chipColor = ' #FF2400';
      break;
    case 'Awaiting jahirnama Fees':
      chipColor = ' #bdbdbd';
      break;
    case 'Awaiting Reply from MVP':
      chipColor = ' #bdbdbd';
      break;

    case 'Awaiting for Processing Frees':
      chipColor = ' #bdbdbd';
      break;
    case 'Graranted Permission Letter':
      chipColor = ' #bdbdbd';
      break;
    default:
      chipColor = '#757575'; // default color for unknown statuses
      break;
  }

  return (
    <div>
      <Chip
        label={chipLabel}
        style={{
          background: chipColor,
          height: '20px',
          borderRadius: '10px',
          color: '#fff',
          fontSize: '10px',
          fontWeight: 500,
        }}
      />
    </div>
  );
};

export default ApplicationStatusChip;
